import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isEmailConfirmed } from "store/user/userSelector";
import { resendConfirmationAction } from "store/user/userSlice";

export const useUnverifiedEmail = () => {
    const dispatch = useDispatch();
    const isVerified = useSelector(isEmailConfirmed);
    const user = useSelector(getUser);
    async function resendVerificationEmail() {
        await dispatch(resendConfirmationAction());
    }

    return {
        user,
        isVerified,
        resendVerificationEmail
    }
}
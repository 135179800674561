import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControlLabel, Grid, Switch, Typography, withStyles } from '@material-ui/core';
import useEmailsTemplateURI from './useEmailsTemplateURI';
import useParticipantConfiguration from './useParticipantConfiguration';
import EmailValidationResultCell from './EmailValidationResultCell';
import { GroupNavigation } from 'domain/group';
import CustomCard from 'components/CustomCard';
import { IParticipantsConfiguration } from 'domain/group/types';
import TooManyEmailsModal from './TooManyEmailsModal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import theme from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ParticipantConfigurationRoot: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    emailUploadInstruction: {
      marginTop: 8,
      fontSize: 14,
      lineHeight: 1.15,
      "& a": {
        color: "#29ABE2",
        fontWeight: 700,
        textDecoration: "none",
        "&:hover, &:focus": {
          textDecoration: "underline",
        }
      }
    },
    uploadButton: {
      height: "100%",
      display: "flex",
      alignItems: "flex-end",
    },
    emailLicenseUsageText: {
      fontWeight: 700,
    },
    emailLicenseUsage: {
      color: theme.palette.orange.main,
      fontFamily: "inherit",
      fontSize: 28,
      fontWeight: 900,
      lineHeight: 0.8,
    },
    input: {
      display: 'none',
    },
    errorBar: {
      borderRadius: '15px',
      padding: '15px',
      color: 'rgb(97, 26, 21)',
      backgroundColor: 'rgb(253, 236, 234)'
    },
    valid: { color: 'green' },
    duplicate: { color: 'yellow' },
    invalid: { color: 'red' }
  }),
);

const StyledAccordion = withStyles({
  root: {
    borderRadius: 12,
  }
})(Accordion);

const StyledAccordionSummary = withStyles({
  root: {
    backgroundColor: theme.palette.orange.main,
    borderRadius: 12,
  }
})(AccordionSummary);

const AccordionSwitch = withStyles({
  switchBase: {
    color: theme.palette.orange.dark,
    '&$checked': {
      color: theme.palette.orange.highlight,
    },
    '&$checked + $track': {
      backgroundColor: "#F2F2F2",
    },
  },
  checked: {},
  track: {},
})(Switch);

interface IProps {
  handleSubmit: (data: IParticipantsConfiguration) => void;
  nextStep: () => void;
  prevStep?: () => void;
  groupId?: string;
  emails?: string[] | undefined;
  links?: string[] | undefined;
  allowAnonymousUsers?: boolean;
  allowEmailUsers?: boolean;
  maxNumAnonymousUsers?: number;
  maxNumEmailUsers?: number;
  numAvailAnonymousUsers?: number;
  numAvailEmailUsers?: number;
}

const ParticipantConfiguration = (
  {
    handleSubmit, nextStep, prevStep,
    groupId,
    emails = [], links = [],
    allowAnonymousUsers, maxNumAnonymousUsers = 0, numAvailAnonymousUsers = 0,
    allowEmailUsers, maxNumEmailUsers = 0, numAvailEmailUsers = 0,
  }: IProps) => {

  const classes = useStyles();

  const [anonUsersSelected, setAnonUsersSelected] = useState(
    (!allowEmailUsers) && allowAnonymousUsers ? true : false
  );
  const [displayTooManyEmailsModal, setDisplayTooManyEmailsModal] = useState(false);

  const { dataURL: emailsTemplateURI } = useEmailsTemplateURI()
  const { handleInput, isUploaded, isValid, isTooManyEmails, dataValidationResult } = useParticipantConfiguration(numAvailEmailUsers, emails?.map(participant => [participant ?? '']) ?? undefined)

  function handleNext() {
    if (anonUsersSelected)
      handleSubmit({
        anonymousUsers: true,
        participants: []
      });
    else
      handleSubmit({
        anonymousUsers: false,
        participants: dataValidationResult.map((row) => row.email)
      });

    if (!groupId) nextStep();
  }

  useEffect(() => {
    if (isUploaded && isTooManyEmails)
      setDisplayTooManyEmailsModal(true);
  }, [isUploaded, isTooManyEmails])

  const InviteByLink = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {
          links.length > 0 ?
            <>
              <Box textAlign="center" fontSize="16px" fontWeight="700" color={theme.palette.orange.main}>Generated Links:</Box>
              {links.map(link => <Box textAlign="center"><a href={link} target="_blank">{link}</a></Box>)}
            </>
            :
            <Box textAlign="left">After this group is saved, one or more links will be generated that you can share with participants. To view the links, open the newly created group and click on this section.</Box>
        }
      </div>
    )
  }

  const InviteByEmail = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.emailUploadInstruction}>Upload a list of emails that will make up the participants of the group.  You can add emails one at a time or you can upload a .csv file with a list of your groups emails.  To get you started <a download="Emails.csv" href={emailsTemplateURI}>download a sample email list</a> which you can modify and save.  Once you have added your groups emails to the list, simply upload your list by pressing the Upload Email List link below.  Remember that the number of group members will depend on the plan that you have selected.</Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.uploadButton}>
            <input
              type="file"
              accept=".csv"
              onInput={(event) => { setAnonUsersSelected(false); handleInput(event); }}
              className={classes.input}
              id="upload-users-button"
            />
            <label htmlFor="upload-users-button">
              <Button variant="contained" color="primary" component="span">Upload Email List</Button>
            </label>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Box textAlign="right" className={classes.emailLicenseUsageText}>Licenses used</Box>
          <Box textAlign="right" className={classes.emailLicenseUsage}>{maxNumEmailUsers - numAvailEmailUsers + dataValidationResult.length}/{maxNumEmailUsers}</Box>
        </Grid>
        <Grid item xs={12}>
          {isUploaded && !isValid && <Box className={classes.errorBar}>An error was encountered while loading the file!</Box>}
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Preview"
            columns={[
              { title: `Emails`, field: 'email' },
              {
                title: 'Comment', field: 'validation', 'defaultSort': 'asc',
                render: rowData => <EmailValidationResultCell data={rowData.validation} />
              },
            ]}
            data={dataValidationResult}
            options={{
              headerStyle: {
                backgroundColor: '#C8602A',
                color: '#FFF',
              },
            }}
            style={{
              borderRadius: 12,
            }}
            components={{
              Toolbar: props => (
                <div style={{ backgroundColor: '#C8602A', padding: 5, borderTopRightRadius: 12, borderTopLeftRadius: 12 }}>
                </div>
              )
            }}
          />
        </Grid>
        <TooManyEmailsModal
          maxNumLicenses={maxNumEmailUsers}
          isOpen={displayTooManyEmailsModal}
          handleModalClose={() => setDisplayTooManyEmailsModal(false)}
        />
      </Grid>
    )
  }

  const InviteByBoth = () => {
    return (
      <div style={{ width: '100%' }}>
        <StyledAccordion expanded={anonUsersSelected}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <FormControlLabel
              aria-label="Select"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={<AccordionSwitch checked={anonUsersSelected} onChange={() => setAnonUsersSelected(!anonUsersSelected)} />}
              label="Accessible to Anyone with a Link"
              style={{ color: 'white' }}
            />
          </StyledAccordionSummary>
          <AccordionDetails>
            <InviteByLink />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion expanded={!anonUsersSelected}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
            aria-label="Expand"
            aria-controls="additional-actions2-content"
            id="additional-actions2-header"
          >
            <FormControlLabel
              aria-label="Select"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={<AccordionSwitch checked={!anonUsersSelected} onChange={() => setAnonUsersSelected(!anonUsersSelected)} />}
              label="Accessible by Invitation Email Only"
              style={{ color: 'white' }}
            />
          </StyledAccordionSummary>
          <AccordionDetails>
            <InviteByEmail />
          </AccordionDetails>
        </StyledAccordion>
      </div>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomCard>
          {
            allowAnonymousUsers && !allowEmailUsers &&
            <InviteByLink />
          }
          {
            !allowAnonymousUsers && allowEmailUsers &&
            <InviteByEmail />
          }
          {
            allowAnonymousUsers && allowEmailUsers &&
            <InviteByBoth />
          }
        </CustomCard>
      </Grid>
      <Grid item xs={12}>
        <GroupNavigation
          isEdit={groupId ? true : false}
          prevStep={prevStep}
          nextStep={handleNext}
          isNextDisabled={(!anonUsersSelected) && (!isValid || isTooManyEmails)}
        />
      </Grid>
    </Grid>
  );
}

export default ParticipantConfiguration;
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Link, Theme, Typography } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(12),
        },
        heading: {
            marginBottom: 10,
            fontSize: 36,
            fontWeight: 700,
            lineHeight: 1,
        },
        subheading: {
            fontSize: 20,
        },
        heading2: {
            marginTop: 30,
            fontSize: 20,
            fontWeight: 700,
        },
        paragraph: {
            marginBottom: "1em",
            fontSize: 14,
        },
    }),
);

const About = () => {
    const classes = useStyles();

    return (
        <MainLayout>
            <Grid container className={classes.root} spacing={2} justify="center">
                <Grid item md={6}>
                    <Typography variant="h1" className={classes.heading}>About Us</Typography>
                    <Typography variant="body1" className={classes.subheading} gutterBottom>
                        BiasProof is a custom Artificial Intelligence based Solution that helps people gain a deeper understanding of their own unconscious biases.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        We believe that people are inherently good. We also believe that people want to improve themselves and be the best that they can be. When it comes to our biases, we were either born with them, or we've developed them from societal experience. Things like Hollywood Movies, and even modern music can influence out subconscious biases. They're not our fault, but they are our responsibility.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        Knowing that people want to change, and knowing that the first step in personal change is admitting you have a problem that needs to be addressed, we created BiasProof. Our goal is to help you, in the inherent privacy afforded to you by using your mobile device, and our Privacy by Design model, to test yourself for bias, and know where you stand.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        This is the first step, and if you're reading this, we're honoured you've chosen to take this journey with us!
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        <Link href="https://www.biasproof.com/">biasproof.com</Link>
                    </Typography>
                </Grid>
            </Grid>
        </MainLayout>
    );
}

export default About;
import { Button, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import React, { memo } from "react";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            borderWidth: "2px",
            textTransform: "none",
            backgroundColor: theme.palette.red.error,
            "&:hover, &:focus": {
                backgroundColor: theme.palette.red.error,
                borderColor: "#fff",
            },
        },
        secondaryButton: {
            borderWidth: "0px",
            textTransform: "none",
            backgroundColor: "transparent",
            fontWeight: "bold",
            color: theme.palette.red.error,
            "&:hover, &:focus": {
                backgroundColor: "transparent",
                color: theme.palette.red.error,
                borderWidth: "0px",
            },
        }
    }),
);

interface IProps {
    label: string;
    onClick?: (args: any) => any
    disabled?: boolean
    type?: "button" | "reset" | "submit" | undefined
    secondary?: boolean
    className?: any
}



const ErrorButton = ({ label, onClick, disabled = false, type = "button", secondary = false, className }: IProps) => {
    const classes = useStyles();
    if (secondary) return (
        <Button
            className={classes.secondaryButton}
            variant="outlined"
            color="primary"
            onClick={onClick}
            type={type}
            size="medium"
            disabled={disabled}
        >{label}</Button>
    )

    return (
        <Button
            className={`${classes.button} ${className}`}
            variant="contained"
            color="primary"
            onClick={onClick}
            type={type}
            size="medium"
            disabled={disabled}
        >{label}</Button>

    );
};

export default ErrorButton;
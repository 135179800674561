import React from 'react'
import { Link } from "@reach/router";
import { Grid, Box, IconButton } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Paths } from "utils/constants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            display: "flex",
            alignItems: "flex-start",
            marginTop: "auto",
            padding: "32px 24px",
            color: "#fff",
            backgroundColor: "#595959",
            boxSizing: "border-box",
        },
        logo: {
            display: "block",
            width: 136,
        },
        info: {
            marginLeft: 40,
        },
        links: {
            display: "flex",
            lineHeight: 1,
        },
        link: {
            color: "#fff",
            fontSize: 14,
            fontWeight: 700,
            textDecoration: 'none',

            "&:hover, &:focus": {
                textDecoration: 'underline',
            }
        },
        pipe: {
            display: "inline-block",
            margin: "0 1em",
            fontWeight: 700,
        },
        copyright: {
            marginTop: 2,
            fontSize: 12,
            color: theme.palette.shades.grey,
        },
        socialmedia: {
            marginLeft: "auto",
        },
        socialmediaButton: {
            marginLeft: 15,
            padding: 0,
        },
        socialmediaIcon: {
            fontSize: 28,
            color: "#fff",

            "&:hover, &:focus": {
                color: theme.palette.orange.main,
            }
        },
        socialmediaIconTwitter: {
            fontSize: 32,
        },
    }),
);

const Footer = () => {

    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <img className={classes.logo} src="/images/general/logo_ondark.svg" alt="Bias Proof Logo" />

            <div className={classes.info}>
                <div className={classes.links}>
                    <Link to={Paths.ABOUT} className={classes.link}>About BiasProof</Link>
                    <span className={classes.pipe}>|</span>
                    <Link to={Paths.PRIVACY} className={classes.link}>Privacy Policy</Link>
                </div>
                <div className={classes.copyright}>
                    Copyright © 2021 BiasProof. All rights reserved.
                </div>
            </div>
            <div className={classes.socialmedia}>
                <IconButton className={classes.socialmediaButton} aria-label="twitter" onClick={() => window.open('https://twitter.com/BiasProof', "_blank")}>
                    <TwitterIcon className={classes.socialmediaIcon} />
                </IconButton>
            </div>
        </footer>
    )
}

export default Footer;
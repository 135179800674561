import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isEmailConfirmed } from "store/user/userSelector";
import { resendConfirmationAction } from "store/user/userSlice";

export const useUnverifiedEmailModal = () => {
    const [open, setOpen] = useState(false);

    function openUnverifiedEmailModal() {
        setOpen(true);
    }

    function closeUnverifiedEmailModal() {
        setOpen(false);
    }

    return {
        openUnverifiedEmailModal,
        closeUnverifiedEmailModal,
        open
    }
}

import { ConfigurationParameters, Configuration, PlanApi, PlanDTO } from "external-api/license-api";
import Cookies from "js-cookie";
import { trackPromise } from "react-promise-tracker";
import { CookieNames } from "utils/constants";
import { getToken } from "utils/cookieHelper";
import http from "./axiosService";

const apiUrl = `${process.env.REACT_APP_LICENSE_API_URL}`;

function getApi() {
    const token = getToken();
    const configParams: ConfigurationParameters = {
        basePath: apiUrl,
        baseOptions: {
            headers:
            {
                Authorization: `Bearer ${token}`
            }
        }
    };

    const axios = http.axios;
    const configuration = new Configuration(configParams);
    return new PlanApi(configuration, apiUrl, axios);
}

export default class PlanService {

    planApi: PlanApi

    constructor() {
        this.planApi = getApi();
    }

    getPlan = async (id: string) => {
        const resp = await trackPromise(this.planApi.planGetPlan(id));
        return resp.data;
    }
}

import useValidForm from "hooks/useValidForm";
import { IGroupOverviewEmailConfiguration } from "../../types";
import htmlToDraft from 'html-to-draftjs';
import * as yup from 'yup';
import { useEffect } from "react";
import { ContentState, EditorState } from "draft-js";

const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    description: yup.string(),
    emailConfiguration: yup.object().shape({
        invitationEmailSubject: yup.string().required('Email Subject is required'),
        // invitationDates: yup.array().min(1, "At least one date must be added")
    })
    // status: yup.string().required('Status is required'),
    // plan: yup.string().required('Plan is required'),
})

interface IProps {
    onSubmitFunc: (data: IGroupOverviewEmailConfiguration) => void
    data: IGroupOverviewEmailConfiguration
}

export const useGroupOverviewForm = ({ onSubmitFunc, data }: IProps) => {
    useEffect(() => {
        form.reset(data);
    }, [data]);

    const defaultValues: IGroupOverviewEmailConfiguration = {
        name: data.name ?? "",
        description: data.description ?? "",
        emailConfiguration: {
            invitationDates: data.emailConfiguration?.invitationDates ?? [],
            numberOfReminders: data.emailConfiguration?.numberOfReminders ?? 0,
            daysBetweenReminders: data.emailConfiguration?.daysBetweenReminders ?? 0,
            invitationEmailSubject: data.emailConfiguration?.invitationEmailSubject ?? "",
            invitationEmailBody: data.emailConfiguration?.invitationEmailBody ?? "",
            reminderEmailSubject: data.emailConfiguration?.reminderEmailSubject ?? "",
            reminderEmailBody: data.emailConfiguration?.reminderEmailBody ?? "",
        }
    };

    const form = useValidForm<IGroupOverviewEmailConfiguration>(onSubmitFunc, validationSchema, defaultValues, false);

    return {
        form
    }
}


import React from 'react'
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { Button, CardContent, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import FormInput from 'components/formComponents/form-input';
import FormNumber from 'components/formComponents/form-number';
import useValidForm from 'hooks/useValidForm';
import { submitSupportTicketAction } from 'store/ui/uiSlice';
import { Dispatch } from 'store';
import CustomCard from 'components/CustomCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contactUsFormRoot: {
            flexGrow: 1,
            marginTop: theme.spacing(2)
        },
        cardContent: {
            width: '100%',
            borderRadius: 10,
            boxShadow: "0 10px 20px 0 rgba(0,0,0,0.25)",
        },
        submitButton: {
            float: "right",
            width: "12em",
        }
    }),
);

export interface ContactUsFormData {
    email: string;
    phoneNumber: string;
    name: string;
    description: string;
}

const defaultValues: ContactUsFormData = {
    email: '',
    name: '',
    phoneNumber: '',
    description: '',
};

const validationSchema = yup.object().shape({
    name: yup.string().required('A valid name is required'),
    email: yup.string().email('A valid email address is required.').required('A valid email address is required.'),
    phoneNumber: yup.string()
        .matches(/^[+]?[-()./\s0-9]*$/, 'A valid phone number is required.')
        .required('A valid phone number is required'),
    description: yup.string().required("The problem description is required"),
});

const ContactUsForm = () => {
    const classes = useStyles();
    const dispatch: Dispatch = useDispatch();

    const onSubmitFunc = async (formValues: ContactUsFormData) => {
        const resultAction = await dispatch(submitSupportTicketAction(formValues));
        if (submitSupportTicketAction.fulfilled.match(resultAction)) {
            reset()
        } else {
        }
    };

    const { register, errors, reset, onSubmit } = useValidForm<ContactUsFormData>(onSubmitFunc, validationSchema, defaultValues);

    return (

            <form onSubmit={onSubmit} className={classes.contactUsFormRoot}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormInput
                            name="name"
                            label="Your name"
                            placeholder="Name"
                            inputRef={register}
                            error={errors.name ? true : false}
                            helperText={errors.name?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput
                            name="email"
                            label="Your email address"
                            placeholder="Email"
                            inputRef={register}
                            error={errors.email ? true : false}
                            helperText={errors.email?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormNumber
                            name="phoneNumber"
                            label="Your phone number"
                            inputRef={register}
                            error={errors.phoneNumber ? true : false}
                            helperText={errors.phoneNumber?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput
                            multiline
                            rows={5}
                            name="description"
                            label="Problem description"
                            placeholder="Your message"
                            inputRef={register}
                            error={errors.description ? true : false}
                            helperText={errors.description?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" color="primary" variant="contained" size="medium" className={classes.submitButton}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
    )
}

export default ContactUsForm;
import { makeStyles, createStyles, Typography } from '@material-ui/core';
import { SnackbarKey, SnackbarMessage } from 'notistack';
import React from 'react';

const useStyles = makeStyles((theme) =>
    createStyles({
        toaster: {
            minWidth: 344,
            maxWidth: 400,
            padding: "7px 16px",
            color: "#fff",
            fontSize: 16,
            fontWeight: 700,
            textAlign: "center",
            border: "2px solid #fff",
            borderRadius: 8,
            boxShadow: "0 0 5px 0 rgba(0,0,0,0.25)",
        },
        error: {
            backgroundColor: "#D42C22",
        },
        info: {
            backgroundColor: "#27c",
        },
        warning: {
            color: "#000",
            backgroundColor: "#fc0",
            borderColor: "#000",
            boxShadow: "0 0 5px 0 rgba(255,255,255,0.75)",
        },
        success: {
            backgroundColor: "#282",
        },

    })
);
interface IProps {
    id: SnackbarKey;
    message: any;
}

const CustomToaster = React.forwardRef(({ message, id }: IProps, ref: any) => {
    const classes = useStyles();
    function getClassVariant() {
        switch (message.variant) {
            case "error": return classes.error;
            case "success": return classes.success;
            case "info": return classes.info;
            case "warning": return classes.warning;
            default: return classes.success;
        }
    }
    return (
        <div className={classes.toaster + " " + getClassVariant()} ref={ref}>
            {message && message.message}
        </div>
    )
})

export default CustomToaster;
import { trackPromise } from "react-promise-tracker";
import http from "./axiosService";
import { getToken } from "utils/cookieHelper";
import { Configuration, ConfigurationParameters, CreateCheckoutSessionRequest, DowngradeSubscriptionRequest, PaymentsApi, UpgradeSubscriptionReqDTO } from "external-api/license-api";

const apiUrl = `${process.env.REACT_APP_LICENSE_API_URL}`;


function getApi() {
    const token = getToken();
    const configParams: ConfigurationParameters = {
        basePath: apiUrl,
        baseOptions: {
            headers:
            {
                Authorization: `Bearer ${token}`
            }
        }
    };

    const configuration = new Configuration(configParams);
    const axios = http.axios;
    return new PaymentsApi(configuration, apiUrl, axios);
}

export default class EcomService {

    api: PaymentsApi

    constructor() {
        this.api = getApi();
    }
    createCheckoutSession = async (data: CreateCheckoutSessionRequest) => {
        const resp = await trackPromise(this.api.paymentsCreateCheckoutSession(data));
        return resp.data;
    }
    createPortalSession = async () => {
        const resp = await trackPromise(this.api.paymentsCustomerPortal());
        return resp.data;
    }
    createFreeTier = async (data: CreateCheckoutSessionRequest) => {
        const resp = await trackPromise(this.api.paymentsCheckoutFreeTier(data));
        return resp.data;
    }
    downgradeSubscription = async (data: DowngradeSubscriptionRequest) => {
        const resp = await trackPromise(this.api.paymentsSubscriptionDowngradeStart(data));
        return resp.data;
    }
    upgradeSubscription = async (data: UpgradeSubscriptionReqDTO) => {
        const resp = await trackPromise(this.api.paymentsUpgradeSubscription(data));
        return resp.data;
    }
}
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { memo, useState } from "react";
import FormInput from "components/formComponents/form-input";
import GroupNavigation from "../GroupNavigation";
import { IGroupOverviewEmailConfiguration } from "../../types";
import RichTextEditor from "../RichTextEditor";
import CustomCard from "components/CustomCard";
import CustomDatePicker from "components/CustomDatePicker";

import FormSelect from "components/formComponents/form-select";
import { useGroupOverviewForm } from "./useGroupOverviewForm";
import moment from "moment";
import { DateObject } from "react-multi-date-picker";
import CustomButton from "components/CustomButton";
const useStyles = makeStyles(() =>
    createStyles({
        groupOverviewRoot: {
        },
        label: {
            lineHeight: '2em',
        }
    }),
);

interface IProps {
    handleSubmit: (formValues: IGroupOverviewEmailConfiguration) => void
    nextStep: () => void
    prevStep?: () => void
    groupId?: string
    data: IGroupOverviewEmailConfiguration
}

const numberOfReminders = [
    {
        value: 0,
        label: "0"
    },
    {
        value: 1,
        label: "1"
    },
    {
        value: 2,
        label: "2"
    },
    {
        value: 3,
        label: "3"
    },
    {
        value: 4,
        label: "4"
    },
    {
        value: 5,
        label: "5"
    }
]

export interface IDateTime {
    dateTime: string | Date
    initialLoaded: boolean
}

const GroupOverview = ({ handleSubmit, nextStep, prevStep, data, groupId }: IProps) => {
    const onSubmitFunc = async (formValues: IGroupOverviewEmailConfiguration) => {
        const finalValues: IGroupOverviewEmailConfiguration = {
            ...formValues,
            emailConfiguration: {
                ...formValues.emailConfiguration!,
                invitationDates: invitationDates?.map(x => {
                    return {
                        dateTime: x.dateTime,
                        initialLoaded: x.initialLoaded
                    }
                }) || []
            }
        }
        await handleSubmit(finalValues);
        if (!groupId) nextStep();
    }
    const { form } = useGroupOverviewForm({ onSubmitFunc, data });
    const { errors, register, onSubmit, formState, control } = form;

    const savedDates = data.emailConfiguration?.invitationDates.map<IDateTime>(x => {
        return { dateTime: x.dateTime as string, initialLoaded: x.initialLoaded }
    }) || [];

    const [invitationDates, setInvitationDates] = useState<IDateTime[]>(savedDates);

    const { isValid } = formState;

    function renderReminderDelays() {
        const reminderOptions = [{ value: 1, label: `1 day` }];
        for (let i = 2; i <= 30; i++) {
            reminderOptions.push({ value: i, label: `${i} days` });
        }
        return reminderOptions;
    }

    function addDate(date: IDateTime) {



        setInvitationDates((state) => {
            const newDates = [...state, date]
            let sortedDates = newDates.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime();
            });
            return sortedDates
        });
    }

    function editDate(index: number, date: IDateTime) {
        const newDates = [...invitationDates];
        newDates[index] = date;

        let sortedDates = newDates.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime();
        });

        setInvitationDates(sortedDates);
    }

    function deleteDate(index: number) {
        const dates = [...invitationDates];
        const updatedDate = dates.splice(index, 1);
        setInvitationDates(dates);
    }

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomCard>
                        <Grid item xs={12}><Typography variant="h2" component="h2">Overview</Typography></Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="name"
                                label='Group name*'
                                inputRef={register}
                                error={errors.name ? true : false}
                                helperText={errors.name?.message} />
                        </Grid>
                        <Grid item xs={12}><FormInput name="description" label='Group description' inputRef={register} multiline /></Grid>
                    </CustomCard>
                </Grid>
                <Grid item lg={8} xl={9}>
                    <CustomCard>
                        <Grid item xs={12}><Typography variant="h2" component="h2">Invitation Email</Typography></Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="emailConfiguration.invitationEmailSubject"
                                label='Subject'
                                inputRef={register}
                                error={errors.emailConfiguration?.invitationEmailSubject ? true : false}
                                helperText={errors.emailConfiguration?.invitationEmailSubject?.message} />
                        </Grid>
                        <Grid item xs={12}>
                            <RichTextEditor
                                control={control}
                                name="emailConfiguration.invitationEmailBody"
                            />
                        </Grid>
                    </CustomCard>
                </Grid>
                <Grid item lg={4} xl={3}>
                    <CustomCard>
                        <Grid item xs={12}><Typography variant="h2" component="h2">Invitation dates*</Typography></Grid>
                        <Grid item xs={12}>
                            <CustomDatePicker
                                deleteDate={deleteDate}
                                inline
                                onChange={addDate}
                            />
                            {invitationDates.map((date, index) => {
                                return (
                                    <CustomDatePicker
                                        deleteDate={deleteDate}
                                        value={date}
                                        index={index}
                                        onChange={editDate}
                                    />
                                )
                            })}
                        </Grid>
                    </CustomCard>
                </Grid>
                <Grid item lg={8} xl={9}>
                    <CustomCard>
                        <Grid item xs={12}><Typography variant="h2" component="h2">Reminder Email</Typography></Grid>
                        <Grid item xs={12}>
                            <FormInput
                                name="emailConfiguration.reminderEmailSubject"
                                label='Subject'
                                inputRef={register}
                                error={errors.emailConfiguration?.invitationEmailSubject ? true : false}
                                helperText={errors.emailConfiguration?.invitationEmailSubject?.message} />
                        </Grid>
                        <Grid item xs={12}>
                            <RichTextEditor
                                control={control}
                                name="emailConfiguration.reminderEmailBody"
                            />
                        </Grid>
                    </CustomCard>
                </Grid>
                <Grid item lg={4} xl={3}>
                    <CustomCard>
                        <Grid item xs={12}><Typography variant="h2" component="h2">Set up Reminders</Typography></Grid>
                        <Grid item xs={12}>
                            <FormSelect
                                options={numberOfReminders}
                                id="numberOfReminders"
                                label="Number of reminders"
                                name="emailConfiguration.numberOfReminders"
                                control={control}
                                error={errors.emailConfiguration?.numberOfReminders ? true : false}
                                helperText={errors.emailConfiguration?.numberOfReminders?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelect
                                options={renderReminderDelays()}
                                id="daysBetweenReminders"
                                label="Days between reminders"
                                name="emailConfiguration.daysBetweenReminders"
                                control={control}
                                error={errors.emailConfiguration?.daysBetweenReminders ? true : false}
                                helperText={errors.emailConfiguration?.daysBetweenReminders?.message}
                            />
                        </Grid>
                    </CustomCard>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <GroupNavigation
                                isEdit={groupId ? true : false}
                                isFirstStep
                                hasForm
                                prevStep={prevStep}
                                isNextDisabled={!isValid}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>

    );
};

export default memo(GroupOverview);
import React from 'react';
import theme from 'theme';
import { useTheme } from '@nivo/core'
import { BarItemProps } from '@nivo/bar';
import _ from 'lodash';
import { ScoresData } from './ScoreUtils';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export type IATCategoryType =
    | 'strongNegative'
    | 'moderateNegative'
    | 'slightNegative'
    | 'littleOrNo'
    | 'slightPositive'
    | 'moderatePositive'
    | 'strongPositive'

export type CategoryDataType = {
    label: string,
    value: number,
}

export type IATDataType = {
    strongNegative: CategoryDataType,
    moderateNegative: CategoryDataType,
    slightNegative: CategoryDataType,
    littleOrNo: CategoryDataType,
    slightPositive: CategoryDataType,
    moderatePositive: CategoryDataType,
    strongPositive: CategoryDataType,
}

export type IATHistoricalDataType = {
    iterationLabel: string,
    iterationData: IATDataType
}[]


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolTip: {
            display: "block",
            padding: "1em 2em",
            fontSize: 12,
            fontWeight: 500,
            backgroundColor: '#fff',
            border: "1px solid" + theme.palette.orange.main,
            borderRadius: 50,
            boxShadow: "0 4px 4px 0 rgba(0,0,0,0.25)",

            "&::after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left: "50%",
                width: "1.5em",
                height: "1.5em",
                backgroundColor: '#fff',
                borderColor: theme.palette.orange.main,
                borderStyle: "solid",
                borderWidth: "0 0 1px 1px",
                boxShadow: "-4px 4px 4px 0 rgba(0,0,0,0.25)",
                transform: "scaleX(0.25) rotate(-45deg) translate(-160%, -100%)",
                transformOrigin: "center center",
            }
        },
    })
);

const CustomTooltip = ({ data, color }: any) => {
    const classes = useStyles();

    return (
        <strong className={ classes.toolTip }>{data.id}</strong>
    )
}

const CustomVerticalBarComponent = (props: BarItemProps) => {

    return (
        <g
            transform={`translate(${props.x}, ${props.y})`}
            onMouseMove={(event): void => {
                props.showTooltip(<CustomTooltip {...props} />, event as React.MouseEvent<SVGRectElement>);
            }}
            onMouseLeave={(event): void => {
                props.hideTooltip();
            }}
        >
            <rect width={props.width} height={props.height} rx={Math.round(props.width / 2)} ry={Math.round(props.width / 2)} fill={props.color} stroke-width="0" stroke={props.color}></rect>
            <rect width={props.width} height={(props.height > props.width) ? (props.width / 2) : (props.height / 2)} x="0" y={props.height - ((props.height > props.width) ? (props.width / 2) : (props.height / 2))} fill={props.color} stroke-width="0" stroke={props.color}></rect>
            <text
                x={Math.round(props.width / 2)}
                y={(props.height > props.width) ? (((props.label).toString().length > 2) ? "1.2em" : "0.8em") : "-0.5em"}
                text-anchor="middle"
                dominant-baseline="middle"
                style={{
                    ...props.theme?.labels?.text,
                    fill: (props.height > props.width) ? props.labelColor : theme.palette.shades.black,
                    fontFamily: "inherit",
                    fontSize: (((props.label).toString().length > 2) ? props.width * 1 / 2 : props.width * 3 / 4),
                    fontWeight: 700,
                }}
            >
                {props.label}
            </text>
        </g>
    )
}

const CustomHorizontalBarComponent = (props: BarItemProps) => {

    return (
        <g transform={`translate(${props.x}, ${props.y})`}>
            {/* <path d={`M 0 0 V ${height} H ${width} V 0 H 0 `} fill={color} /> */}
            <rect width={props.width} height={props.height} rx={Math.round(props.height / 2)} ry={Math.round(props.height / 2)} fill={props.color} stroke-width="0" stroke={props.color}></rect>
            {/* <rect width={(props.width > props.height) ? (props.height / 2) : (props.width / 2)} height={props.height} x="0" y="0" fill={props.color} stroke-width="0" stroke={props.color}></rect> */}
            <text
                x={props.width}
                y={Math.round(props.height * 1 / 2)}
                text-anchor={(props.width > (props.height * 3 / 2)) ? "end" : "start"}
                dominant-baseline="middle"
                style={{
                    ...props.theme?.labels?.text,
                    fill: (props.width > (props.height * 3 / 2)) ? props.labelColor : theme.palette.shades.black,
                    fontFamily: "inherit",
                    fontSize: (props.height * 7 / 8),
                    fontWeight: 700,
                    transform: (props.width > (props.height * 3 / 2)) ? "translateX(-0.333em)" : "translateX(0.1em)",
                }}
            >
                {props.label}
            </text>
        </g>
    )
}

const CustomTickV = (tick: any) => {
    const themeX = useTheme()

    return (
        <g transform={`translate(${tick.x},${tick.y})`}>
            <text
                x="-5"
                textAnchor="end"
                dominantBaseline="middle"
                style={{
                    ...themeX.axis.ticks.text,
                    fill: theme.palette.shades.black,
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    fontWeight: 700,
                }}
                dangerouslySetInnerHTML={{ __html: tick.value }}
            >
            </text>
        </g>
    )
}

const CustomTickVSmaller = (tick: any) => {
    const themeX = useTheme()

    return (
        <g transform={`translate(${tick.x},${tick.y})`}>
            <text
                x="-5"
                textAnchor="end"
                dominantBaseline="middle"
                style={{
                    ...themeX.axis.ticks.text,
                    fill: theme.palette.shades.black,
                    fontFamily: "inherit",
                    fontSize: "0.8em",
                    fontWeight: 700,
                }}
                dangerouslySetInnerHTML={{ __html: tick.value }}
            >
            </text>
        </g>
    )
}

const CustomTickH = (tick: any) => {
    const themeX = useTheme()

    return (
        <g transform={`translate(${tick.x},${tick.y})`}>
            <line
                x1="0"
                x2="0"
                y1="0"
                y2="5"
                style={{
                    ...themeX.axis.ticks.line,
                    stroke: theme.palette.shades.grey,
                    strokeWidth: 2,
                }}></line>
            <text
                y="20"
                textAnchor="middle"
                dominantBaseline="text-top"
                style={{
                    ...themeX.axis.ticks.text,
                    fill: theme.palette.shades.black,
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    fontWeight: 700,
                }}
                dangerouslySetInnerHTML={{ __html: tick.value }}
            >
            </text>
        </g>
    )
}

const CustomTickHSmaller = (tick: any) => {
    const themeX = useTheme()

    return (
        <g transform={`translate(${tick.x},${tick.y})`}>
            <line
                x1="0"
                x2="0"
                y1="0"
                y2="5"
                style={{
                    ...themeX.axis.ticks.line,
                    stroke: theme.palette.shades.grey,
                    strokeWidth: 2,
                }}></line>
            <text
                y="20"
                textAnchor="middle"
                dominantBaseline="text-top"
                style={{
                    ...themeX.axis.ticks.text,
                    fill: theme.palette.shades.black,
                    fontFamily: "inherit",
                    fontSize: "0.8em",
                    fontWeight: 700,
                }}
                dangerouslySetInnerHTML={{ __html: tick.value }}
            >
            </text>
        </g>
    )
}

export function convertCategoricalDataToBarChartData(data: CategoryDataType[], sort = true) {

    return {
        data: data.map(catData => ({
            'label': catData.label,
            [catData.label]: catData.value
        })),
        indexBy: 'label',
        keys: sort ? data.sort((a, b) => (a.value < b.value ? -1 : 1)).map(catData => catData.label) : data.reverse().map(catData => catData.label),
        groupMode: "stacked",
        colors: [theme.palette.orange.main],
        labelTextColor: "#ffffff",
        layout: "horizontal",
        margin: { top: 0, right: 0, bottom: 25, left: 45 },
        padding: 0.35,
        barComponent: CustomHorizontalBarComponent,
        axisLeft: { renderTick: CustomTickVSmaller, },
        axisBottom: { renderTick: CustomTickH, },
        tooltip: ({ id, value, color }: any) => (
            <strong style={{ color }}>
                {id}: {value}
            </strong>
        ),
        isInteractive: true,
    }
}

export function convertScoreDataToIATHistoricalData(data: ScoresData) {
    return data.scores.map(iteration => ({
        iterationLabel: iteration.date.toLocaleDateString(),
        iterationData: iteration.scoreBreakdown
    })) as IATHistoricalDataType;
}

export function convertIATHistoricalDataToBarChartData(iatHistoricalData: IATHistoricalDataType) {

    const categories: IATCategoryType[] = [
        'strongNegative',
        'moderateNegative',
        'slightNegative',
        'littleOrNo',
        'slightPositive',
        'moderatePositive',
        'strongPositive',
    ]

    let data: { [key in IATCategoryType]: { [key: string]: string | number } } =
    {
        strongNegative: { category: "" },
        moderateNegative: { category: "" },
        slightNegative: { category: "" },
        littleOrNo: { category: "" },
        slightPositive: { category: "" },
        moderatePositive: { category: "" },
        strongPositive: { category: "" },
    }

    iatHistoricalData.forEach(iteration => {
        categories.forEach(category => {
            data[category].category = iteration.iterationData[category].label
            data[category][iteration.iterationLabel] = iteration.iterationData[category].value
        })
    })

    return {
        data: Object.values(data),
        indexBy: 'category',
        keys: iatHistoricalData.map(iteration => iteration.iterationLabel),
        groupMode: "grouped",
        colors: [theme.palette.orange.main, theme.palette.orange.dark1, theme.palette.orange.dark2, theme.palette.orange.dark3, theme.palette.orange.dark4],
        labelTextColor: "#ffffff",
        margin: { top: 10, right: 0, bottom: 45, left: 45 },
        padding: iatHistoricalData.length === 1 ? 0.6 : 0.15,
        innerPadding: 1,
        axisLeft: { legend: 'Percentage', legendPosition: 'middle', legendOffset: -40, renderTick: CustomTickV, },
        barComponent: CustomVerticalBarComponent,
        axisBottom: { renderTick: CustomTickHSmaller, },
        isInteractive: true,
    }
}

export const MOCK_HISTORICAL_IAT_DATA1: IATHistoricalDataType = [
    {
        iterationLabel: "2021-03-31",
        iterationData: {
            strongPositive: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Strong</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Fat)</tspan>",
                value: 0
            },
            moderatePositive: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Moderate</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Fat)</tspan>",
                value: 0
            },
            slightPositive: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Slight</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Fat)</tspan>",
                value: 7
            },
            littleOrNo: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Little</tspan><tspan text-anchor='middle' x='0' dy='1em'>or No</tspan>",
                value: 40
            },
            slightNegative: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Slight</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Thin)</tspan>",
                value: 30
            },
            moderateNegative: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Moderate</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Thin)</tspan>",
                value: 20
            },
            strongNegative: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Strong</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Thin)</tspan>",
                value: 3
            },
        }
    },
]

export const MOCK_HISTORICAL_IAT_DATA2: IATHistoricalDataType = [
    {
        iterationLabel: "2021-03-31",
        iterationData: {
            strongPositive: {
                label: "Strong (Fat)",
                value: 1
            },
            moderatePositive: {
                label: "Moderate (Fat)",
                value: 0
            },
            slightPositive: {
                label: "Slight (Fat)",
                value: 7
            },
            littleOrNo: {
                label: "Little or No",
                value: 40
            },
            slightNegative: {
                label: "Slight (Thin)",
                value: 30
            },
            moderateNegative: {
                label: "Moderate (Thin)",
                value: 20
            },
            strongNegative: {
                label: "Strong (Thin)",
                value: 3
            },
        }
    },
    {
        iterationLabel: "2021-06-30",
        iterationData: {
            strongPositive: {
                label: "Strong (Fat)",
                value: 0
            },
            moderatePositive: {
                label: "Moderate (Fat)",
                value: 2
            },
            slightPositive: {
                label: "Slight (Fat)",
                value: 5
            },
            littleOrNo: {
                label: "Little or No",
                value: 45
            },
            slightNegative: {
                label: "Slight (Thin)",
                value: 35
            },
            moderateNegative: {
                label: "Moderate (Thin)",
                value: 10
            },
            strongNegative: {
                label: "Strong (Thin)",
                value: 3
            },
        }
    }
]

export const MOCK_HISTORICAL_IAT_DATA3: IATHistoricalDataType = [
    {
        iterationLabel: "2021-03-31",
        iterationData: {
            strongPositive: {
                label: "Strong (Fat)",
                value: 0
            },
            moderatePositive: {
                label: "Moderate (Fat)",
                value: 0
            },
            slightPositive: {
                label: "Slight (Fat)",
                value: 7
            },
            littleOrNo: {
                label: "Little or No",
                value: 40
            },
            slightNegative: {
                label: "Slight (Thin)",
                value: 30
            },
            moderateNegative: {
                label: "Moderate (Thin)",
                value: 20
            },
            strongNegative: {
                label: "Strong (Thin)",
                value: 3
            },
        }
    },
    {
        iterationLabel: "2021-06-30",
        iterationData: {
            strongPositive: {
                label: "Strong (Fat)",
                value: 0
            },
            moderatePositive: {
                label: "Moderate (Fat)",
                value: 2
            },
            slightPositive: {
                label: "Slight (Fat)",
                value: 5
            },
            littleOrNo: {
                label: "Little or No",
                value: 45
            },
            slightNegative: {
                label: "Slight (Thin)",
                value: 35
            },
            moderateNegative: {
                label: "Moderate (Thin)",
                value: 10
            },
            strongNegative: {
                label: "Strong (Thin)",
                value: 3
            },
        }
    },
    {
        iterationLabel: "2021-09-30",
        iterationData: {
            strongPositive: {
                label: "Strong (Fat)",
                value: 0
            },
            moderatePositive: {
                label: "Moderate (Fat)",
                value: 2
            },
            slightPositive: {
                label: "Slight (Fat)",
                value: 5
            },
            littleOrNo: {
                label: "Little or No",
                value: 45
            },
            slightNegative: {
                label: "Slight (Thin)",
                value: 35
            },
            moderateNegative: {
                label: "Moderate (Thin)",
                value: 10
            },
            strongNegative: {
                label: "Strong (Thin)",
                value: 3
            },
        }
    }
]

export const MOCK_HISTORICAL_IAT_DATA4: IATHistoricalDataType = [
    {
        iterationLabel: "2021-03-31",
        iterationData: {
            strongPositive: {
                label: "<tspan>Strong (Fat)",
                value: 0
            },
            moderatePositive: {
                label: "Moderate (Fat)",
                value: 0
            },
            slightPositive: {
                label: "Slight (Fat)",
                value: 7
            },
            littleOrNo: {
                label: "Little or No",
                value: 40
            },
            slightNegative: {
                label: "Slight (Thin)",
                value: 30
            },
            moderateNegative: {
                label: "Moderate (Thin)",
                value: 20
            },
            strongNegative: {
                label: "Strong (Thin)",
                value: 3
            },
        }
    },
    {
        iterationLabel: "2021-06-30",
        iterationData: {
            strongPositive: {
                label: "Strong (Fat)",
                value: 0
            },
            moderatePositive: {
                label: "Moderate (Fat)",
                value: 2
            },
            slightPositive: {
                label: "Slight (Fat)",
                value: 5
            },
            littleOrNo: {
                label: "Little or No",
                value: 45
            },
            slightNegative: {
                label: "Slight (Thin)",
                value: 35
            },
            moderateNegative: {
                label: "Moderate (Thin)",
                value: 10
            },
            strongNegative: {
                label: "Strong (Thin)",
                value: 3
            },
        }
    },
    {
        iterationLabel: "2021-09-30",
        iterationData: {
            strongPositive: {
                label: "Strong (Fat)",
                value: 0
            },
            moderatePositive: {
                label: "Moderate (Fat)",
                value: 2
            },
            slightPositive: {
                label: "Slight (Fat)",
                value: 5
            },
            littleOrNo: {
                label: "Little or No",
                value: 45
            },
            slightNegative: {
                label: "Slight (Thin)",
                value: 35
            },
            moderateNegative: {
                label: "Moderate (Thin)",
                value: 10
            },
            strongNegative: {
                label: "Strong (Thin)",
                value: 3
            },
        }
    },
    {
        iterationLabel: "2021-12-31",
        iterationData: {
            strongPositive: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Strong</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Fat)</tspan>",
                value: 0
            },
            moderatePositive: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Moderate</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Fat)</tspan>",
                value: 2
            },
            slightPositive: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Slight</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Fat)</tspan>",
                value: 5
            },
            littleOrNo: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Little</tspan><tspan text-anchor='middle' x='0' dy='1em'>or No</tspan>",
                value: 45
            },
            slightNegative: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Slight</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Thin)</tspan>",
                value: 35
            },
            moderateNegative: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Moderate</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Thin)</tspan>",
                value: 10
            },
            strongNegative: {
                label: "<tspan text-anchor='middle' x='0' dy='-0.25em'>Strong</tspan><tspan text-anchor='middle' x='0' dy='1em'>(Thin)</tspan>",
                value: 3
            },
        }
    }
]

export const MOCK_ETHNICITY_DATA: CategoryDataType[] = [
    {
        label: "White",
        value: 205
    },
    {
        label: "Other",
        value: 61
    },
    {
        label: "Asian",
        value: 23
    },
    {
        label: "Black",
        value: 19
    },
    {
        label: "Mixed",
        value: 4
    },
]

export const MOCK_GENDER_DATA: CategoryDataType[] = [
    {
        label: "Male",
        value: 40
    },
    {
        label: "Female",
        value: 53
    },
    {
        label: "<tspan text-anchor='end' x='-5' dy='-0.5em'>Non-</tspan><tspan text-anchor='end' x='-5' dy='1em'>binary</tspan>",
        value: 3
    },
    {
        label: "<tspan text-anchor='end' x='-5' dy='-0.5em'>Self-</tspan><tspan text-anchor='end' x='-5' dy='1em'>described</tspan>",
        value: 0
    },
    {
        label: "<tspan text-anchor='end' x='-5' dy='-0.5em'>Prefer not</tspan><tspan text-anchor='end' x='-5' dy='1em'>to say</tspan>",
        value: 7
    },
]
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Container, Grid, Link, Theme, Toolbar, Typography } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';
import CustomButton from 'components/CustomButton';
import { navigate, RouteComponentProps } from '@reach/router';
import { Paths } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignContent: "flex-start",
            width: "100%",
            height: "100vh",
            margin: 0,
            background: "url('/images/general/graphic_error500.svg') no-repeat center bottom",
            backgroundSize: "1414px auto",
        },

        headerWrapper: {
            height: "90px",
        },

        logo: {
            display: "block",
            width: 172,
            height: "auto",
        },

        container: {
            maxWidth: 500,
            paddingTop: 70,
        },

        heading: {
            fontSize: 36,
            fontWeight: 700,
        },

        subheading: {
            fontSize: 20,
        },

        error: {
            margin: "20px 0",
            color: theme.palette.shades.greyMiddle,
            fontSize: 14,
        },
        message: {
            fontWeight: 700,
        },
        button: {
            display: "flex",
            margin: "30px auto !important",
            padding: "0 2.5em !important",
        }
    }),
);

interface IProps extends RouteComponentProps {

}

const Page500 = ({ }: IProps) => {
    const classes = useStyles();

    function goHome() {
        navigate(Paths.HOME);
    }

    return (
        <Grid className={classes.root} container spacing={2}>
            <Grid item xs={12}>
                <Toolbar className={classes.headerWrapper}>
                    <img className={classes.logo} src="/images/general/logo.svg" alt="Bias Proof logo" />
                </Toolbar>
            </Grid>
            <Container className={classes.container}>
                <Typography className={classes.heading} component="h1">Dang it!</Typography>
                <Typography className={classes.subheading} component="h3">Not sure what happened... but we're looking into it.</Typography>
                <Typography className={classes.error} component="div">Error code: 500</Typography>
                <Typography className={classes.message} component="p">An error has occurred and we’re working to fix the problem!</Typography>
                <Typography className={classes.message} component="p">We should be up and running shortly.</Typography>
                <br />
                <Typography className={classes.message} component="p">If you need immediate help from our service team please call us. If it isn’t an urgent matter, please visit our website for additional information.</Typography>
                <br />
                <Typography className={classes.message} component="p">Thank you for your patience.</Typography>
                <CustomButton
                    label="Go to Home"
                    onClick={goHome}
                    className={classes.button}
                />
            </Container>
        </Grid>
    );
}

export default Page500;
import React from 'react'
import { gql } from "@apollo/client";
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import useContentfulQuery from 'hooks/useContentfulQuery';
import ContentfulLoading from 'components/contentful-loading';
import ContentfulError from 'components/contentful-error';
import { GetAllSurveysList, GetAllSurveysListVariables } from 'types/GetAllSurveysList';
import TestCategoryDetails from './TestCategoryDetails';
import { useTestingPlans } from './useTestingPlans';

const useStyles = makeStyles(() =>
    createStyles({
        trainingPlanRoot: {
        },
    })
);

const GET_ALL_SURVEY_LIST = gql`
  query GetAllSurveysList($preview: Boolean!) {
    surveyCollection(preview:$preview) {
      items {
        sys{
          id
        }
        title
        slug
        category {
            label
            sys {
                id
            }
        }
      }
    }
  }    
`;

interface IProps {
}

const TestingPlans = ({ }: IProps) => {
    const classes = useStyles();
    const { loading, error, data } = useContentfulQuery<GetAllSurveysList, GetAllSurveysListVariables>(GET_ALL_SURVEY_LIST)
    const { selectedTests, allCategories } = useTestingPlans(data as GetAllSurveysList);

    if (loading) return <ContentfulLoading />;
    if (error) return <ContentfulError />;
    if (!data) return null;
    return (
        <Grid container className={classes.trainingPlanRoot} spacing={2}>
            {allCategories?.map((item, index) => {
                const isSelected = selectedTests?.some(x => x.categoryId === item.id) ?? false;
                return (
                    <Grid item md={4} xs={12} lg={4} key={index}>
                        <TestCategoryDetails
                            isSelected={isSelected}
                            testIds={item.tests}
                            title={item.categoryLabel}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default TestingPlans;
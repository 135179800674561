
import { createSelector } from "@reduxjs/toolkit";
import { PlanDTO } from "external-api/license-api";

const _getPlan = (state: { plan: PlanDTO }) => state.plan;

export const hasPlan = createSelector(
    [_getPlan],
    (plan: PlanDTO) => {
        return plan.id !== "";
    }
)
export const getPlan = createSelector(
    [_getPlan],
    (plan: PlanDTO) => {
        return plan;
    }
)

export const hasStripe = createSelector(
    [_getPlan],
    (plan: PlanDTO) => {
        return plan.hasStripe;
    }
)
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Grid, Theme, Typography } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';
import ContactUsForm from 'domain/support/ContactUsForm';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "auto",
            margin: `${theme.spacing(2)}px 64px ${theme.spacing(12)}px 64px`,

            "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "100vw",
                background: "linear-gradient(to bottom, #e6e6e6, #fff 520px)",
                borderTop: "1px solid" + theme.palette.shades.grey,
                zIndex: -1,
            },
        },
        heading: {
            fontWeight: 500,
            textTransform: "uppercase",
        },
        box: {
            fontWeight: 700,
        },
        title: {
            fontSize: 20,
            textTransform: "uppercase",
        },
    }),
);

const Support = () => {
    const classes = useStyles();

    return (
        <MainLayout>
            <Grid container className={classes.root} spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h1" className={classes.heading}>Support</Typography>
                </Grid>
                <Grid item md={4}>
                </Grid>
                <Grid item md={4}>
                    <Box className={classes.box}>
                        <h2 className={classes.title}>Contact Us Today</h2>
                        <div>Please fill out the quick form and we will be in touch right away.</div>
                    </Box>
                    <ContactUsForm />
                </Grid>
                <Grid item md={4}>
                    <Box className={classes.box}>
                        <h2 className={classes.title}>Have any questions using Bias Proof?</h2>
                        <p>This is the place to start. Get the answers you need from our amazing support team. </p>
                        <p>Whether you have a question about features, trials, or pricing, our team is ready to answer.</p>
                        <h2 className={classes.title}>Have questions about workplace bias?</h2>
                        <p>Our entire team receives specialized training regularly to ensure you are receiving the best possible information. From basic questions to complex compliance inquires, we are here to help. In this case we will work to find a time to provide you access to one of our experts for a one-on-one call. </p>
                        <p>Interested in learning more about our services? Our Team will take the time to discuss your existing bias screening program and help you make smart decisions that best meet <i>your</i> needs.</p>
                    </Box>
                </Grid>
            </Grid>
        </MainLayout>
    );
}

export default Support;
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { getPlan } from "store/plan/planSelectors";
import CustomStepper from "../common/CustomStepper";
import { useEditGroupStepper } from "./useEditGroupStepper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupRoot: {
            width: "auto",
            margin: `${theme.spacing(2)}px 64px ${theme.spacing(12)}px 64px`,

            "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "100vw",
                background: "linear-gradient(to bottom, #e6e6e6, #fff 520px)",
                borderTop: "1px solid" + theme.palette.shades.grey,
                zIndex: -1,
            }
        },
        heading: {
            fontWeight: 500,
            textTransform: "uppercase",
        },
    })
);

interface IProps {
    groupId: string
}

const EditGroupStepper = ({ groupId }: IProps) => {
    const classes = useStyles();
    const plan = useSelector(getPlan);

    // -- grab data from store
    const { currentComponent, labels, activeStep, setStep, ready } = useEditGroupStepper(groupId);

    if (!ready) return null;
    return (
        <Grid container className={classes.groupRoot}>
            <Grid item xs={3}>
                <CustomStepper
                    enableNavigation
                    setActiveStep={setStep}
                    steps={labels}
                    activeStep={activeStep}
                />
            </Grid>

            <Grid item xs={9}>
                {ready &&
                    <>
                        {currentComponent}
                    </>
                }
            </Grid>
        </Grid>
    );
}

export default EditGroupStepper;
import React, { useEffect, useState } from "react";
import { createStyles, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import * as yup from "yup";
import FormInput from "components/formComponents/form-input";
import useValidForm from "hooks/useValidForm";
import { FormProvider } from "react-hook-form";
import { addBadgeAction } from "store/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import FileSaver from 'file-saver';
import { Dispatch } from 'store';
import CustomButton from "components/CustomButton";
import FieldErrorMessage from "components/FieldErrorMessage";
import { getBadge } from "store/user/userSelector";
import { useBadgeUploader } from "./useBadgeUploader";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) =>
  createStyles({
    coursePreviewModal: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      backgroundColor: "white",
      padding: `${theme.spacing(1)}px ${theme.spacing(5)}px ${theme.spacing(3)}px`,
      width: "60vw",
      borderRadius: 12,
    },
    previewContent: {
      maxHeight: "80%",
    },
    richTextPreview: {
      height: "600px",
      overflowY: "scroll",
    },
    root: {
      paddingTop: theme.spacing(3),
      flexGrow: 1,
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
    },
    logobox: {
      minHeight: "200px",
      maxHeight: "200px",
      overflow: 'hidden',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      border: "1px solid rgba(0, 0, 0, 0.23)",
      marginLeft: theme.spacing(1),
      width: "100%",
      position: "relative",
    },
    logoboxSpan: {
      position: "absolute",
      color: "#c8602a",
      top: "10px",
      left: "-10px",
      transform: "translate(14px, -6px) scale(0.75)",
      zIndex: 2
    },
    logoboxImg: {
      overflow: 'hidden',
      width: "100%",
      height: '200px'
    },
    close: {
      position: "absolute",
      top: 8,
      right: 8,
      padding: 1,
      color: "#fff",
      fontWeight: 700,
      background: "#ccc",
      border: "2px solid #fff",

      "&:hover, &:focus": {
        background: theme.palette.shades.grey,
      }
    },
  })
);

export interface BadgeFormData {
  companyName: string;
  websiteUrl: string;
  companyLogo: Blob | null;
}

const validationSchema = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
  websiteUrl: yup.string().required("Website URL is required").url("Please provide a valid URL (e.g., https://www.example.com)"),
  companyLogo: yup.mixed().required("Company logo is required")
});

interface IProps {
  ref: any;
  onModalClose: () => void
}

// -- get course details
const BadgeCardPreview = React.forwardRef(({ ref, onModalClose }: IProps) => {
  const classes = useStyles();
  const dispatch: Dispatch = useDispatch();
  const badge = useSelector(getBadge);
  const [previewImage, setPreviewImage] = useState(badge?.companyLogo ?? "");
  const defaultValues: BadgeFormData = {
    companyName: badge?.companyName ?? "",
    websiteUrl: badge?.websiteUrl ?? "",
    companyLogo: null,
  };

  const { getInputProps, acceptedFiles, open } = useBadgeUploader({ handleUpload });

  function handleUpload(e: ProgressEvent<FileReader>) {
    setPreviewImage(e.target?.result as string);
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setValue("companyLogo", acceptedFiles[0]);
    }
  }, [acceptedFiles])

  const onSubmitFunc = async (formValues: BadgeFormData) => {
    const resp = await dispatch(addBadgeAction(formValues));
    if (addBadgeAction.fulfilled.match(resp)) {
      FileSaver.saveAs(`${process.env.PUBLIC_URL}/badge.zip`, "badge.zip");
      onModalClose();
    }
  };

  const form = useValidForm<BadgeFormData>(onSubmitFunc, validationSchema, defaultValues, false);
  const { register, control, errors, onSubmit, setValue } = form;

  return (
    <Grid container spacing={2} className={classes.coursePreviewModal} ref={ref} direction="row" justify="flex-start">
      <Grid item md={6} lg={6} xs={12}>
        <Grid container spacing={2} direction="row" justify="flex-start">
          <Grid item xs={12}>
            <Typography variant="h2" component="h2">
              Download Badge
            </Typography>
            <IconButton aria-label="Close" component="button" className={classes.close} onClick={onModalClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            Download the badge and add it to your company website.
            <br /> <br />
            The .zip file contains an image of the badge to place on your website. Please link the image back www.biasproof.com.
            <br /> <br />
            Please confirm the information to the right. All information is required.
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} lg={6} xs={12}>
        <FormProvider {...form}>
          <form onSubmit={onSubmit} className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormInput
                  name="companyName"
                  label="Company Name"
                  placeholder="Company Name"
                  inputRef={register}
                  error={errors.companyName ? true : false}
                  helperText={errors.companyName?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  name="websiteUrl"
                  label="Website URL"
                  placeholder="Website URL"
                  inputRef={register}
                  error={errors.websiteUrl ? true : false}
                  helperText={errors.websiteUrl?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.logobox}>
                  <span className={classes.logoboxSpan}>Company logo</span>

                  <div className={classes.logoboxImg}>
                    {previewImage && <img style={{ height: "100%", width: "100%", objectFit: "contain" }} src={previewImage} alt="logo preview" />}
                  </div>

                </div>
                <input {...getInputProps()} />
                {errors.companyLogo && <FieldErrorMessage label={errors.companyLogo?.message ?? "Error has occured"} />}
                <CustomButton
                  onClick={open}
                  label="Upload Logo"
                />

              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <CustomButton
                  type="submit"
                  label="Save"
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
});

export default BadgeCardPreview;

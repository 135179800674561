import { colors } from '@material-ui/core';

const black = '#000000';
const blackGrey = "#202020";
const orangeDark = "#C84C04";
const orangeDarker = "#AF4103";
const orangeLight = "#EE6029";
const orangeMain = "#DB5205";
const white = '#FFFFFF';
const whiteOff = '#f6f6f6';

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        orange: {
            main: React.CSSProperties['color']
            highlight: React.CSSProperties['color']
            dark: React.CSSProperties['color']
            dark1: React.CSSProperties['color']
            dark2: React.CSSProperties['color']
            dark3: React.CSSProperties['color']
            dark4: React.CSSProperties['color']
        },
        red: {
            main: React.CSSProperties['color']
            dark1: React.CSSProperties['color']
            dark2: React.CSSProperties['color']
            dark3: React.CSSProperties['color']
            dark4: React.CSSProperties['color']
            error: React.CSSProperties['color']
        },
        yellow: {
            main: React.CSSProperties['color']
            dark1: React.CSSProperties['color']
            dark2: React.CSSProperties['color']
            dark3: React.CSSProperties['color']
            dark4: React.CSSProperties['color']
        },
        green: {
            main: React.CSSProperties['color']
            dark1: React.CSSProperties['color']
            dark2: React.CSSProperties['color']
            dark3: React.CSSProperties['color']
            dark4: React.CSSProperties['color']
        },
        shades: {
            black: React.CSSProperties['color']
            white: React.CSSProperties['color']
            lightgrey: React.CSSProperties['color']
            grey: React.CSSProperties['color']
            greyMiddle: React.CSSProperties['color']
            grey2: React.CSSProperties['color']
        },
        system: {
            warning: React.CSSProperties['color']
            checkboxGreen: React.CSSProperties['color']
            modalGradientTop: React.CSSProperties['color']
            modalGradientBottom: React.CSSProperties['color']
        },
    }
    interface PaletteOptions {
        orange: {
            main: React.CSSProperties['color']
            highlight: React.CSSProperties['color']
            dark: React.CSSProperties['color']
            dark1: React.CSSProperties['color']
            dark2: React.CSSProperties['color']
            dark3: React.CSSProperties['color']
            dark4: React.CSSProperties['color']
        },
        red: {
            main: React.CSSProperties['color']
            dark1: React.CSSProperties['color']
            dark2: React.CSSProperties['color']
            dark3: React.CSSProperties['color']
            dark4: React.CSSProperties['color']
            error: React.CSSProperties['color']
        },
        yellow: {
            main: React.CSSProperties['color']
            dark1: React.CSSProperties['color']
            dark2: React.CSSProperties['color']
            dark3: React.CSSProperties['color']
            dark4: React.CSSProperties['color']
        },
        green: {
            main: React.CSSProperties['color']
            dark1: React.CSSProperties['color']
            dark2: React.CSSProperties['color']
            dark3: React.CSSProperties['color']
            dark4: React.CSSProperties['color']
        },
        shades: {
            black: React.CSSProperties['color']
            white: React.CSSProperties['color']
            grey: React.CSSProperties['color']
            greyMiddle: React.CSSProperties['color']
            grey2: React.CSSProperties['color']
        },
        system: {
            warning: React.CSSProperties['color']
            checkboxGreen: React.CSSProperties['color']
            modalGradientTop: React.CSSProperties['color']
            modalGradientBottom: React.CSSProperties['color']
        },
    }
}

export default {
    black,
    orangeDark,
    orangeDarker,
    orangeLight,
    orangeMain,
    white,
    whiteOff,
    primary: {
        light: '#e15811',
        main: '#c8602a',
        dark: '#9C4A1B',
        contrastText: '#fff',
    },
    secondary: {
        light: '#6f6f6f',
        main: '#444',
        dark: '#1d1d1d',
        contrastText: '#fff',
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400]
    },
    info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400]
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400]
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400]
    },
    text: {
        primary: blackGrey,
        secondary: blackGrey,
        link: orangeDark,
        header: orangeMain
    },
    background: {
        paper: white
    },
    icon: orangeMain,
    divider: blackGrey,
    colors: {
        orangeDarker: orangeDarker,
    },
    orange: {
        main: '#c8602a',
        highlight: '#e15811',
        dark: '#7a340d',
        dark1: '#9C4A1B',
        dark2: '#7A380B',
        dark3: '#5A2400',
        dark4: '#330300',
    },
    red: {
        main: '#992c24',
        dark1: '#7A231D',
        dark2: '#601B16',
        dark3: '#491511',
        dark4: '#340F0C',
        error: '#d62b22'
    },
    yellow: {
        main: '#ad7a14',
        dark1: '#8A6210',
        dark2: '#6D4D0D',
        dark3: '#543C0A',
        dark4: '#3E2C07',
    },
    green: {
        main: '#8b971c',
        dark1: '#6C7616',
        dark2: '#525910',
        dark3: '#3B410C',
        dark4: '#272A08',
    },
    shades: {
        lightgrey: '#cccccc',
        black: '#1a1a1a',
        white: '#f2f2f2',
        grey: '#9e9e9e',
        greyMiddle: '#808080',
        grey2: '#737373',
    },
    system: {
        warning: '#d42c22',
        checkboxGreen: '#4FDB86',
        modalGradientTop: '#fff',
        modalGradientBottom: '#e5e5e5',
    },
};

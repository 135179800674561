import React, { } from "react";
import { Box, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        errorBar: {
            marginTop: theme.spacing(1),
            borderRadius: '15px',
            padding: '15px',
            color: 'rgb(97, 26, 21)',
            backgroundColor: 'rgb(253, 236, 234)'
        }
    })
);
interface IProps {
    label: string
}

// -- get course details
const FieldErrorMessage = React.forwardRef(({ label }: IProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.errorBar}>{label}</Box>
    );
});

export default FieldErrorMessage;

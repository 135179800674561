import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from '@reach/router';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Divider, Grid, Theme, Typography } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';
import { PlanCard } from 'domain/license';
import { checkoutAction, checkoutFreeTierAction, getAllPlansAction, planSelectionStarted } from 'store/ui/uiSlice';
import { getAllPlans } from 'store/ui/uiSelectors';
import { EcomPlanDTO } from "external-api/license-api";
import StyledModal from 'components/StyledModal';
import { getCountry } from 'store/user/userSelector';
import { Country, countries } from "countries-list"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        heading: {
            marginTop: theme.spacing(2),
            fontWeight: 500,
            textTransform: "uppercase",
        },
        msgHeading: {
            margin: 0,
            fontSize: 36,
            fontWeight: 700,
        },
        msgBody: {
            marginTop: theme.spacing(1),
            fontSize: 18,
            lineHeight: '20px',
            fontWeight: 900,
            textTransform: "uppercase",
        },
        plansContainer: {
            margin: 'auto',
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(2),
            maxWidth: 1200,
        },
        planContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        planImage: {
            width: 210,
            margin: '0px 6px',
        },
        planInfoContainer: {

        },
        planInfoRow: {
            display: "flex",
        },
        modalContentTitle: {
            display: 'block',
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "1.2em",
            fontWeight: 900,
            marginBottom: "10px"
        },
        modalContentBody: {
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "12px",
            fontWeight: 400,
            margin: "10px 0px",
            "& a": {
                color: "#C8602A",
                fontWeight: 400,
                textDecoration: "underline",
                "&:hover, &:focus": {
                    textDecoration: "underline",
                }
            }
        },
        modalContentNumLicenses: {
            fontSize: "16px",
            fontWeight: 700,
            color: "#C8602A",
        },
        modalFieldLabel: {
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "1.5em",
            textAlign: "right",
            width: "33%",
        },
        modalFieldValue: {
            fontSize: "12px",
            lineHeight: "1.5em",
            fontWeight: 400,
            textAlign: "left",
            width: "66%",
            paddingLeft: 18,
        },
        modalActionButtonContainer: {
            textAlign: 'center',
            marginTop: "20px",
        },
        modalCancelButtonContainer: {
            textAlign: 'center',
        },
        modalActionButton: {

        },
        modalCancelButton: {
            borderWidth: "0px",
            textTransform: "none",
            backgroundColor: "transparent",
            fontWeight: "bold",
            "&:hover, &:focus": {
                backgroundColor: "transparent",
                borderWidth: "0px",
            },
        },
        modalDivider: {
            backgroundColor: "#9E9E9E",
        },
    }),
);

export function getCurrencyForCountry(country: string) {
    const countryCodeData = Object.entries(countries).find(([countryCode, countryData]) => {
        return countryCode.toLowerCase() === country.toLowerCase()
    });

    if (countryCodeData) {
        const officialCurrency = countryCodeData[1].currency;
        switch (officialCurrency) {
            case "USD":
                return "USD";
            case "EUR":
                return "EUR";
            case "GBP":
                return "GBP";
            case "CAD":
                return "CAD";
            default:
                return "USD";
        }
    }
    else
        return "USD";
}

interface IProps extends RouteComponentProps {

}

const Plans = ({ }: IProps) => {
    const classes = useStyles();
    const plans = useSelector(getAllPlans);
    const country = useSelector(getCountry);
    const currency = getCurrencyForCountry(country);

    const [selectedPlan, setSelectedPlan] = useState<EcomPlanDTO | null>(null);
    const [selectedNumLicenses, setSelectedNumLicenses] = useState<number>(0);
    const [showConfirmSubscriptionModal, setShowConfirmSubscriptionModal] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllPlansAction());
    }, []);

    function getNumber(str: any, defaultValue: number) {
        const num = parseInt(str, 10);
        return isNaN(num) ? defaultValue : num;
    }

    function sortPlans(a: EcomPlanDTO, b: EcomPlanDTO) {
        const a_order = Number(a.product?.metadata?.Order) ?? 0;
        const b_order = Number(b.product?.metadata?.Order) ?? 0;
        return a_order > b_order ? 1 : -1
    }

    async function handleSelectPlan(plan: EcomPlanDTO, numLicenses: number) {

        setSelectedPlan(plan);
        setSelectedNumLicenses(numLicenses);

        const price = plan.recurringPrices?.find(price => price.currency?.toLowerCase() === currency.toLowerCase())?.unit_amount;

        if (price === 0) {
            if (plan.product?.id) {
                await dispatch(checkoutFreeTierAction(
                    {
                        priceId: plan.recurringPrices?.find(price => price.currency?.toLowerCase() === currency.toLowerCase())?.id ?? "",
                        quantity: numLicenses
                    }
                ));
            }
        } else {
            setShowConfirmSubscriptionModal(true);
        }
    }

    async function handleConfirmSubscription() {
        await dispatch(planSelectionStarted());
        await dispatch(checkoutAction(
            {
                priceId: selectedPlan?.recurringPrices?.find(price => price.currency?.toLowerCase() === currency.toLowerCase())?.id ?? "",
                quantity: selectedNumLicenses
            }
        ));
    }

    const ConfirmSubscriptionModal = () => {
        return (
            <StyledModal type="confirm" pretitle="My Plan" title="Confirm Subscription" isOpen={showConfirmSubscriptionModal} handleModalClose={() => setShowConfirmSubscriptionModal(false)}>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ textAlign: "center" }}>
                            <span className={classes.modalContentTitle}>
                                Please confirm your BiasProof subscription.
                            </span>
                            <Typography variant="body1" component="p" className={classes.modalContentBody}>
                                <strong>By clicking Checkout button, you agree to be charged for</strong>
                            </Typography>
                            <div className={classes.modalContentNumLicenses}>
                                {selectedNumLicenses} licenses
                            </div>
                            <br />
                            {/* <Divider className={classes.modalDivider} />
                            <div className={classes.planInfoContainer} style={{ padding: "12px 0px" }}>
                                <div className={classes.planInfoRow}>
                                    <div className={classes.modalFieldLabel}>Plan:</div>
                                    <div className={classes.modalFieldValue}><strong>Organization</strong></div>
                                </div>
                                <div className={classes.planInfoRow}>
                                    <div className={classes.modalFieldLabel}>Per license:</div>
                                    <div className={classes.modalFieldValue}>$20.00</div>
                                </div>
                                <div className={classes.planInfoRow}>
                                    <div className={classes.modalFieldLabel}>Cost:</div>
                                    <div className={classes.modalFieldValue}>$500.00</div>
                                </div>
                                <div className={classes.planInfoRow}>
                                    <div className={classes.modalFieldLabel}>Tax:</div>
                                    <div className={classes.modalFieldValue}>$65.00</div>
                                </div>
                                <div className={classes.planInfoRow}>
                                    <div className={classes.modalFieldLabel}>Total:</div>
                                    <div className={classes.modalFieldValue}>$565.00</div>
                                </div>
                            </div> */}
                            <Divider className={classes.modalDivider} />
                            <Typography variant="body1" component="p" className={classes.modalContentBody}>
                                Selecting checkout will redirect you to our payment processor. If you have any questions regarding your purchase, please <a href="mailto:support@biasproof.com">contact our support staff</a>.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.modalActionButtonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConfirmSubscription}
                            className={classes.modalActionButton}
                        >
                            Checkout
                        </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.modalCancelButtonContainer}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setShowConfirmSubscriptionModal(false)}
                            className={classes.modalCancelButton}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </StyledModal>
        )
    }

    return (
        <MainLayout>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Typography variant="h1" className={classes.heading}>My Plan</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.msgHeading} align='center'>Choose the best plan that fits your company</Typography>
                    <Typography variant="body1" className={classes.msgBody} align='center'>Plans for companies of all sizes.<br />Scale as you grow.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={classes.plansContainer}>
                        {plans.slice().sort(sortPlans).map(plan => {
                            return (
                                <Grid item xs={12} md={4} lg={4}>
                                    <div className={classes.planContainer}>
                                        <div className={classes.planImage} style={{
                                            width: 220, height: 175, background: `url(${(plan.product?.metadata?.ImageURL)}) no-repeat center bottom`,
                                        }}></div>
                                        <PlanCard
                                            key={plan.product?.id}
                                            onSelectPlan={(numLicenses: number) => handleSelectPlan(plan, numLicenses)}
                                            title={plan.product?.name ?? "Untitled"}
                                            description={plan.product?.description ?? ""}
                                            price={(plan.recurringPrices?.find(price => price.currency?.toLocaleLowerCase() === currency.toLowerCase())?.unit_amount ?? 0) / 100}
                                            currency={currency}
                                            minNumLicenses={getNumber(plan.product?.metadata?.MinQuantity, 1)}
                                            maxNumLicenses={getNumber(plan.product?.metadata?.MaxQuantity, Number.POSITIVE_INFINITY)}
                                        />
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmSubscriptionModal />
        </MainLayout>
    );
}

export default Plans;
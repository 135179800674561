import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

interface IProps {
    handleUpload: (e: ProgressEvent<FileReader>) => any
}

export const useBadgeUploader = ({ handleUpload }: IProps) => {
    const { open, getInputProps, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        multiple: false,
        accept: ['image/png', 'image/jpeg', 'image/svg+xml']
    });

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            const fileReader = new FileReader();
            fileReader.onload = handleUpload;
            fileReader.readAsDataURL(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    return {
        getInputProps,
        acceptedFiles,
        open
    }
}
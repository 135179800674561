import React from "react";
import { FormControl, FormHelperText, IconButton, Input, InputAdornment, makeStyles, Theme, } from "@material-ui/core";
import { Clear, Done, Edit } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        fontSize: 20,
        fontWeight: 900,
        color: theme.palette.orange.main,
        maxWidth: 200,
    },
    inputUnderline: {
        '&:before': {
            borderBottomColor: theme.palette.orange.main, // Semi-transparent underline
        },
        '&:after': {
            borderBottomColor: theme.palette.orange.main, // Solid underline on focus
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottomColor: theme.palette.orange.main, // Solid underline on hover
        },
    },
    icon: {
        color: "black",
        fontSize: "0.75em",
    }
}));

export interface InputProps {
    value: string;
    readonly: boolean;
    onChange: (v: string) => void;
    onClickEdit: () => void;
    onClickCancel: () => void;
    onClickSave: () => void;
    type?: "text" | "email" | "password";
    placeholder?: string;
    error?: boolean;
    helperText?: string;
    rows?: number;
    fullWidth?: boolean;
    multiline?: boolean;
}

const FormEditableInput = ({
    value, readonly,
    onChange, onClickEdit, onClickCancel, onClickSave,
    error, helperText,
}: InputProps) => {

    const classes = useStyles();

    return (
        <FormControl error={error}>
            <Input
                value={value}
                readOnly={readonly}
                onChange={(e) => { onChange(e.target.value as string) }}
                classes={{ "root": classes.input, "underline": classes.inputUnderline }}
                endAdornment={
                    <InputAdornment position="end">
                        {
                            readonly ?
                                <IconButton
                                    aria-label="edit"
                                    onClick={(e) => { onClickEdit(); }}
                                >
                                    <Edit className={classes.icon} />
                                </IconButton>
                                :
                                <>
                                    <IconButton
                                        aria-label="save"
                                        onClick={(e) => { onClickSave(); }}
                                    >
                                        <Done className={classes.icon} />
                                    </IconButton>
                                    <IconButton
                                        aria-label="cancel"
                                        onClick={(e) => { onClickCancel(); }}
                                    >
                                        <Clear className={classes.icon} />
                                    </IconButton>
                                </>
                        }
                    </InputAdornment>
                }
            />
            {
                error &&
                <FormHelperText>{helperText}</FormHelperText>
            }
        </FormControl>
    );
};

export default FormEditableInput;

import * as yup from 'yup';
import { ICourseConfiguration, IPlanConfiguration, ITestConfiguration } from "domain/group/types";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { GetAllCoursesList } from "types/GetAllCoursesList";

const validationSchema = yup.object().shape({
})

interface IProps {
    data: GetAllCoursesList | undefined
}

export const useCoursePlans = ({ data }: IProps) => {
    const form = useFormContext<IPlanConfiguration>();

    const selectedTests = useWatch<ITestConfiguration[]>({
        control: form.control,
        name: 'testConfiguration'
    });

    const categoryIds = selectedTests?.map(x => x.categoryId ?? "");

    const fieldArrayProps = useFieldArray(
        {
            control: form.control,
            name: "courseConfiguration",
            keyName: "fieldId"
        }
    );

    const selectedCourses = useWatch<{ id: string, categoryId: string }[]>({
        control: form.control,
        name: "courseConfiguration"
    });

    return {
        form,
        categories: data?.courseCollection?.items?.filter(x => categoryIds?.includes(x?.category?.sys?.id!)) ?? [],
        ...fieldArrayProps,
        selectedCourses
    }
}

import React, { useState } from "react";
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Link } from "@reach/router";
import { Menu } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            width: 250
        },
        linkText: {
            textDecoration: `none`,
            textTransform: `uppercase`,
            color: `black`
        }
    }),
);

interface IProps {
    navLinks: { title: string, path: string }[]
}
const SideDrawer = ({ navLinks }: IProps) => {
    const classes = useStyles();
    const [displayDrawer, setDisplayDrawer] = useState(false);

    const handleDrawer = (display: boolean) => (event: any) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDisplayDrawer(display);
    };

    const sideDrawerList = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={handleDrawer(false)}
            onKeyDown={handleDrawer(false)}
        >
            <List component="nav">
                {navLinks.map(({ title, path }) => (
                    <Link to={path} key={title} className={classes.linkText}>
                        <ListItem button>
                            <ListItemText primary={title} />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </div>
    );

    return (
        <>
            <IconButton
                edge="start"
                aria-label="menu"
                onClick={handleDrawer(true)}
            >
                <Menu fontSize="large" style={{ color: `black` }} />
            </IconButton>

            <Drawer
                anchor="right"
                open={displayDrawer}
                onClose={handleDrawer(false)}
            >
                {sideDrawerList()}
            </Drawer>
        </>
    );
};

export default SideDrawer;

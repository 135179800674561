import { Button, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import CustomButton from "components/CustomButton";
import React, { memo } from "react";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            textAlign: "end"
        }
    }),
);

interface IProps {
    nextStep?: () => void
    prevStep?: () => void
    isNextDisabled?: boolean
    isFirstStep?: boolean
    isLastStep?: boolean
    hasForm?: boolean
    isEdit?: boolean
}

const GroupNavigation = ({ nextStep, prevStep, isNextDisabled = false, isFirstStep = false, isLastStep = false, hasForm = false, isEdit = false }: IProps) => {
    const classes = useStyles();

    function renderNextButton() {
        if (hasForm && isEdit) return (
            <CustomButton
                label="Update"
                type="submit"
                disabled={isNextDisabled}
            />
        )
        if (isEdit) return (
            <CustomButton
                label="Update"
                onClick={nextStep}
                disabled={isNextDisabled}
            />
        )
        if (isLastStep && nextStep) return (
            <CustomButton
                label="Submit"
                onClick={nextStep}

            />
        )
        if (hasForm) return (
            <CustomButton
                label="Next"
                type="submit"
                disabled={isNextDisabled}
            />
        )
        return (
            <CustomButton
                label="Next"
                onClick={nextStep}
                disabled={isNextDisabled}
            />
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className={classes.buttonContainer}>
                {!isFirstStep && !isEdit &&
                    <CustomButton
                        label="Back"
                        onClick={prevStep}
                    />
                }
                {renderNextButton()}
            </Grid>
        </Grid>
    );
};

export default memo(GroupNavigation);
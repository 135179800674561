import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        valid: { color: 'rgb(30, 70, 32)', backgroundColor: 'rgb(237, 247, 237)' },
        duplicate: { color: 'rgb(102, 60, 0)', backgroundColor: 'rgb(255, 244, 229)' },
        invalid: { color: 'rgb(97, 26, 21)', backgroundColor: 'rgb(253, 236, 234)' }
    }),
);

interface IProps {
    data: 'VALID' | 'DUPLICATE' | 'INVALID'
}
const EmailValidationResultCell = ({ data }: IProps) => {
    const classes = useStyles();

    switch (data) {
        case 'VALID':
            return <span className={classes.valid}>VALID</span>
        case 'DUPLICATE':
            return <span className={classes.duplicate}>DUPLICATE</span>
        case 'INVALID':
            return <span className={classes.invalid}>INVALID</span>
        default:
            return null
    }
}

export default EmailValidationResultCell
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";

export interface SelectProps {
    label: string;
    id?: string;
    defaultValue?: any;
    name: string;
    options: { value: any, label: string }[];
    control: any;
    error?: boolean;
    helperText?: string;
    withoutMargin?: boolean;
    readonly?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {

        "& .MuiInputBase-root": {
            height: "2em",
        },

        "& .MuiSelect-nativeInput": {
            height: "100%",
            padding: 0,
            border: "none",
        },

        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid " + theme.palette.orange.main,
            borderRadius: "1em",
        }
    },
    sel_container: {
        margin: 0,
    },
    sel_container_m: {
        margin: 0,
    },
    select: {
        padding: "0 1em",
        lineHeight: "2em",
        borderRadius: "1em !important",
    },
    label: {
        position: "relative",
        padding: "0 0 0.5em 1.25em",
        color: theme.palette.orange.main,
        fontSize: 12,
        fontWeight: 700,
        transform: "none !important",
    },
}));

const FormSelect: FC<SelectProps> = ({
    label,
    id,
    defaultValue,
    name,
    options,
    control,
    error,
    helperText,
    withoutMargin,
    readonly = false
}) => {

    const classes = useStyles();

    return (
        <FormControl variant="outlined" size="small" fullWidth error={error} classes={{ root: classes.root + " " + (!withoutMargin ? classes.sel_container : classes.sel_container_m) }}>
            <InputLabel className={classes.label}>{label}</InputLabel>
            <Controller
                render={({ onChange, value }) => (
                    <>
                        <Select value={value} defaultValue={defaultValue} onChange={onChange} disabled={readonly} classes={{ root: (readonly ? 'readonly' : ''), select: classes.select }}>
                            {options.map((option, index) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{helperText}</FormHelperText>
                    </>
                )}
                id={id}
                name={name}
                control={control}
            />
        </FormControl>
    );
};

export default FormSelect;
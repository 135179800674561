
import { ConfigurationParameters, GroupApi, Configuration, GroupDTO, GroupOverviewDTO } from "external-api/license-api";

import http from "./axiosService";
import Cookies from "js-cookie";
import { trackPromise } from "react-promise-tracker";
import { CookieNames } from "utils/constants";
import { getToken } from "utils/cookieHelper";

const apiUrl = `${process.env.REACT_APP_LICENSE_API_URL}`;

function getApi() {
    const token = getToken();
    const configParams: ConfigurationParameters = {
        basePath: apiUrl,
        baseOptions: {
            headers:
            {
                Authorization: `Bearer ${token}`
            }
        }
    };

    const configuration = new Configuration(configParams);

    const axios = http.axios;
    return new GroupApi(configuration, apiUrl, axios);
}

export default class GroupService {

    groupApi: GroupApi

    constructor() {
        this.groupApi = getApi();
    }
    addGroup = async (groupData: GroupDTO) => {
        const resp = await trackPromise(this.groupApi.groupAddGroup(groupData));
        return resp.data;
    }

    getGroups = async () => {
        const resp = await trackPromise(this.groupApi.groupGetGroups());
        return resp.data;
    }

    getGroupsResults = async () => {
        const resp = await trackPromise(this.groupApi.groupGetGroupsWithResults());
        return resp.data;
    }

    getGroup = async (id: string) => {
        const resp = await trackPromise(this.groupApi.groupGetGroup(id));
        return resp.data;
    }

    updateGroupOverview = async (groupId: string, groupData: GroupOverviewDTO) => {
        const resp = await trackPromise(this.groupApi.groupUpdateGroupOverview(groupId, groupData));
        return resp.data;
    }

    deleteGroup = async (id: string) => {
        const resp = await trackPromise(this.groupApi.groupDeleteGroup(id));
        return resp.data;
    }
}
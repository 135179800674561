export const Paths = {
    HOME: "/",
    LOGISTER: "/logister",
    MYACCOUNT: "/my-account",
    PLANS: "/plans",
    EDIT_PLAN: "/edit-plan",
    GROUPS: '/groups',
    ADD_GROUP: '/groups/add',
    SUPPORT: '/support',
    PRIVACY: '/privacy',
    ABOUT: '/about',
    LOGOS: '/logos',
    HTTP_404_ERROR: '/404-error',
    HTTP_500_ERROR: '/500-error',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    CONFIRM_EMAIL: '/activate-account',
    PAYMENT_SUCCESS: "/successful-payment",
    PAYMENT_FAILURE: "/failed-payment",
}

export const CookieNames = {
    ACCOUNT_TOKEN: "token",
    REFRESH_TOKEN: "refresh"
}

export const EmailTexts = {
    INVITATION_SUBJECT: 'Company Bias Testing Initiative',
    INVITATION_BODY: "<p>Hi!&nbsp;</p> <p></p> <p>Our company takes diversity and inclusion seriously. As part of our continuing strategy to make this the best place to work we have chosen to address unconscious bias by using BiasProof’s testing and training tools.&nbsp;</p> <p></p> <p>The link below will take you to the BiasProof testing tools. There are a few important things to know:&nbsp;</p> <ol> <li>Your results are completely confidential – nobody other than you will see your results&nbsp;</li> <li>We would like you to complete the testing and training as soon as possible&nbsp;</li> <li>The BiasProof tools are designed to run on your mobile phone, if you haven’t received this email on your phone, please forward it, if you have this on your phone, use it to click the link below&nbsp;</li> <li>As a company, all we will see is the aggregate scores of groups of staff – again, no individual results!&nbsp;</li> <li>We believe that BiasProof is the beginning of a journey towards eliminating the issues in our workplace that are caused by unconscious biases. The test will not instantly make you Bias Proof, but instead provide you proof of your own biases. We hope that you’ll learn from and think about, your results, and that introspection will help you become a less biased person.&nbsp;</li> <li>We plan to retest to you periodically, but we’ve paid for access to the test for a whole year, so feel free to use it again to check your own progress when you feel the time is right.&nbsp;</li> </ol> <p></p> <p>Thanks!&nbsp;</p>",
    REMINDER_SUBJECT: 'Reminder: Company Bias Testing Initiative',
    REMINDER_BODY: "<p>Hey!&nbsp;</p> <p></p> <p>Just a quick follow up, we noticed you haven’t completed the BiasProof testing and training course. As a company, we’re committed to eliminating the problems caused by bias in the workplace and we’d really appreciate it if you could take some time to complete the training.&nbsp;</p> <p></p> <p>Remember, your results are anonymous, but the aggregate scores are important to our company since we will use them to not only benchmark our progress but also to determine if we need to offer more training in the future.&nbsp;</p> <p></p> <p>Thanks!&nbsp;</p>"
}
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router"
import React from "react"

interface IScrollToTop extends RouteComponentProps {
    children: any
}

export const ScrollToTop = ({ children, location }: IScrollToTop) => {
    React.useLayoutEffect(() => window.scrollTo(0, 0), [location?.pathname])
    return children
}

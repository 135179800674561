import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        visCardRoot: {
            width: "auto",
            height: "100%",
            margin: "0 6px",
            padding: "10px 20px",
            backgroundColor: '#fff',
            boxShadow: "8px 10px 12px 0 rgba(0,0,0,0.25)",
            borderRadius: 12,
            overflow: "hidden",
        },
        visCardRootHalf: {
            height: "calc(50% - 6px)",
        },
        visCardRootDouble: {
            position: "relative",
            width: "calc(200% - 12px)",
            zIndex: 1,
        },
        title: {
            margin: "0 0 1em 0",
            fontSize: "12px",
        },
        barChartRoot: {
            height: 370,
            minHeight: 370,
        },
        barChartRootHalf: {
            height: 160,
            minHeight: 160,
        },
    })
);

interface IProps {
    title?: string,
    children: any,
    double?: boolean,
    half?: boolean,
    fontSize?: number,
}
const VisCard = ({ title, children, double, half, fontSize }: IProps) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.visCardRoot + (double ? " " + classes.visCardRootDouble : "") + (half ? " " + classes.visCardRootHalf : "")}>
            {title &&
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.title}>{title}</Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <div className={classes.barChartRoot + (half ? " " + classes.barChartRootHalf : "")} style={{ "fontSize": fontSize, }}>
                    {children}
                </div>
            </Grid>
        </Grid>
    )
}

export default VisCard
import { navigate } from "@reach/router";
import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import moment from "moment";
import { IGroup } from "domain/group/types";
import { CourseConfigurationDTO, EmailConfigurationDTO, GroupDTO, GroupOverviewDTO, GroupResultsDTO, GroupStatus, ParticipantsDTO, PlanConfigurationDTO, TestConfigurationDTO } from "external-api/license-api";

import GroupService from "services/groupService";
import ParticipantService from "services/participantService";
import { IApplicationState, ValidationErrors } from "store";
import { enqueueNotification } from "store/ui/uiSlice";
import { tryRefreshTokenAction } from "store/user/userSlice";
import { Paths } from "utils/constants";
import { handleAuthErrors } from "utils/errorHandling";

export interface IGroupState {
    groupList: GroupResultsDTO[]
    groupData: GroupDTO[]
}

const initialState: IGroupState = {
    groupList: [],
    groupData: []
}

const addGroup = createAsyncThunk<
    GroupDTO,
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/groups/add",
        async (data, thunkAPI) => {
            const { rejectWithValue, getState, dispatch } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());

                const { ui, plan } = getState();
                const { group } = ui;

                const utcDates = group.emailConfiguration?.invitationDates.map(x => x.dateTime as string) ?? [];
                const emailConfiguration: EmailConfigurationDTO = {
                    reminderEmailBody: group.emailConfiguration?.reminderEmailBody as string,
                    reminderEmailSubject: group.emailConfiguration?.reminderEmailSubject,
                    invitationEmailSubject: group.emailConfiguration?.invitationEmailSubject,
                    invitationEmailBody: group.emailConfiguration?.invitationEmailBody as string,
                    numberOfReminders: group.emailConfiguration?.numberOfReminders ?? 0,
                    daysBetweenReminders: group.emailConfiguration?.daysBetweenReminders ?? 0,
                    invitationalEmailDates: utcDates ?? []
                };

                const planConfigurationDTO: PlanConfigurationDTO = {
                    name: group.planConfiguration?.name,
                    testConfiguration: group.planConfiguration?.testConfiguration?.map(x => {
                        return {
                            categoryId: x.categoryId,
                            id: x.id
                        } as TestConfigurationDTO
                    }),
                    courseConfiguration: group.planConfiguration?.courseConfiguration?.map(x => {
                        return {
                            categoryId: x.categoryId,
                            id: x.id,
                            courseModuleIds: x.courseModuleIds?.map(x => x.moduleId)
                        } as CourseConfigurationDTO
                    })
                };

                const participantsDTO: string[] = group.participants ?? [];

                const groupDTO: GroupDTO = {
                    status: GroupStatus.NUMBER_0,
                    name: group.name,
                    description: group.description,
                    numberOfSlotsUsed: participantsDTO.length,
                    planConfiguration: planConfigurationDTO,
                    participants: participantsDTO,
                    anonymousUsers: Boolean(group.anonymousUsers),
                    emailConfiguration: emailConfiguration
                };

                const service = new GroupService();
                const resData: GroupDTO = await service.addGroup(groupDTO);
                const metaData: GroupResultsDTO = {
                    id: resData.id,
                    name: resData.name,
                    numberOfSlotsUsed: resData.numberOfSlotsUsed
                };

                dispatch(appendGroupMeta(metaData));

                dispatch(enqueueNotification({
                    message: 'Successfully added group',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));

                navigate(Paths.GROUPS)
                return resData;
            }
            catch (err) {
                handleAuthErrors(dispatch, err);

                return rejectWithValue(err.body)
            }
        }
    );

const updateGroupOverview = createAsyncThunk<
    GroupOverviewDTO,
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/groups/update-overview",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch, getState } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());
                const { ui } = getState();
                const currentGroup = ui.group;

                const utcDates = currentGroup.emailConfiguration?.invitationDates.map(x => x.dateTime as string) ?? [];

                const groupOverviewDTO: GroupOverviewDTO = {
                    id: currentGroup.id,
                    name: currentGroup.name,
                    emailConfiguration: {
                        invitationEmailBody: currentGroup.emailConfiguration?.invitationEmailBody as string,
                        invitationEmailSubject: currentGroup.emailConfiguration?.invitationEmailSubject,
                        reminderEmailBody: currentGroup.emailConfiguration?.reminderEmailBody as string,
                        reminderEmailSubject: currentGroup.emailConfiguration?.reminderEmailSubject,
                        daysBetweenReminders: currentGroup.emailConfiguration?.daysBetweenReminders ?? 0,
                        numberOfReminders: currentGroup.emailConfiguration?.numberOfReminders ?? 0,
                        invitationalEmailDates: utcDates
                    }
                };

                const service = new GroupService();
                const resData: GroupOverviewDTO = await service.updateGroupOverview(currentGroup.id!, groupOverviewDTO);


                dispatch(enqueueNotification({
                    message: 'Successfully updated group overview',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));

                return resData;
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const updateGroupParticipants = createAsyncThunk<
    ParticipantsDTO,
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/groups/update-participants",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch, getState } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());
                const { ui } = getState();
                const currentGroup = ui.group;

                const participants: string[] = currentGroup.participants ?? [];

                const service = new ParticipantService();
                const resData: ParticipantsDTO = await service.updateGroupParticipants(currentGroup.id!, participants);


                dispatch(enqueueNotification({
                    message: 'Successfully updated participant list',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));
                return resData;
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );


const getGroup = createAsyncThunk<
    GroupDTO,
    string,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/groups/get",
        async (groupId, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {

                await dispatch(tryRefreshTokenAction());

                const service = new GroupService();
                let resData: GroupDTO = await service.getGroup(groupId);
                const returnData = {
                    ...resData,
                    emailConfiguration: {
                        ...resData.emailConfiguration,
                        invitationalEmailDates: resData.emailConfiguration?.invitationalEmailDates?.map(x => {
                            // const newDate = moment.utc(x).local().format("YYYY/MM/DD HH:mm:ss");
                            const newDate = moment(x).toISOString()
                            return newDate;
                        }) ?? []
                    }
                }
                return returnData as GroupDTO;
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const getAllGroups = createAsyncThunk<
    GroupResultsDTO[],
    boolean,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/groups/get-all",
        async (includeResults, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {

                await dispatch(tryRefreshTokenAction());
                const service = new GroupService();
                const resData: GroupResultsDTO[] = includeResults ? await service.getGroupsResults() : await service.getGroups();

                return resData;
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const groupSlice = createSlice({
    name: "@@group",
    initialState,
    reducers: {
        updateGroupMeta(state, action: PayloadAction<GroupResultsDTO>) {
            state.groupList = state.groupList.map(x => {
                if (x.id !== action.payload.id) return x;
                return action.payload;
            });
        },
        appendGroupMeta(state, action: PayloadAction<GroupResultsDTO>) {
            state.groupList.push(action.payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(getAllGroups.fulfilled, (state, action) => {
            state.groupList = action.payload;
        });
        builder.addCase(addGroup.fulfilled, (state, action) => {
            state.groupData.push(action.payload);
        });
        builder.addCase(getGroup.fulfilled, (state, action) => {
            const index = state.groupData.findIndex(x => x.id === action.payload.id);
            if (index === -1) state.groupData.push(action.payload);
            else {
                state.groupData = state.groupData.map(x => {
                    if (x.id !== action.payload.id) return x;
                    return action.payload;
                });
            }
        });
        builder.addCase(updateGroupParticipants.fulfilled, (state, action) => {
            const index = state.groupData.findIndex(x => x.id === action.payload.groupId);
            if (index) {
                state.groupData[index].numberOfSlotsUsed = action.payload.participants?.length ?? 0;
                state.groupData[index].participants = action.payload.participants;
            }
        });
    }
});

export {
    getAllGroups,
    addGroup,
    getGroup,
    updateGroupOverview,
    updateGroupParticipants
}

export const {
    appendGroupMeta,
    updateGroupMeta
} = groupSlice.actions

export default groupSlice.reducer;
import { AuthResult, TokenRequestDTO } from "external-api/account-api";
import Cookies from "js-cookie";
import AccountService from "services/accountService";
import { CookieNames } from "./constants";

export const getToken = () => {
    let token = Cookies.get(CookieNames.ACCOUNT_TOKEN);
    if (token) return token;
}

export const saveToken = (authResult: AuthResult) => {
    Cookies.set(CookieNames.ACCOUNT_TOKEN, authResult?.token!, { sameSite: "strict" });
    Cookies.set(CookieNames.REFRESH_TOKEN, authResult?.refreshToken!, { sameSite: "strict" });
}

export const removeToken = () => {
    Cookies.remove(CookieNames.ACCOUNT_TOKEN);
    Cookies.remove(CookieNames.REFRESH_TOKEN);
}

export const getAccountIdFromToken = (jwtToken?: string): string | null => {
    if (!jwtToken) {
        return null;
    }
    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

    return jwt.unique_name || null;
}

export const getExpirationDate = (jwtToken?: string): number | null => {
    if (!jwtToken) {
        return null;
    }

    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

    // multiply by 1000 to convert seconds into milliseconds
    return jwt && jwt.exp && jwt.exp * 1000 || null;
};

export const isExpired = (exp?: number | null) => {
    if (!exp) {
        return false;
    }

    return Date.now() > exp;
};
import Cookies from "js-cookie";
import { trackPromise } from "react-promise-tracker";
import { CookieNames } from "utils/constants";
import http from "./axiosService";
import { getToken, saveToken } from "utils/cookieHelper";
import { Configuration, ConfigurationParameters, CreateCheckoutSessionRequest, PaymentsApi, ProductApi } from "external-api/license-api";

const apiUrl = `${process.env.REACT_APP_LICENSE_API_URL}`;


function getApi() {
    const token = getToken();
    const configParams: ConfigurationParameters = {
        basePath: apiUrl,
        baseOptions: {
            headers:
            {
                Authorization: `Bearer ${token}`
            }
        }
    };

    const configuration = new Configuration(configParams);
    const axios = http.axios;
    return new ProductApi(configuration, apiUrl, axios);
}

export default class ProductService {

    api: ProductApi

    constructor() {
        this.api = getApi();
    }

    getPlans = async () => {
        const resp = await trackPromise(this.api.productGetAllPlans());
        return resp.data;
    }
}
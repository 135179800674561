import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Theme, Typography, CardActionArea, CardContent, IconButton, withStyles, Button, Icon, Modal } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MuiCard from '@material-ui/core/Card';
import MuiCardActions from '@material-ui/core/CardActions';
import { useGroupListing } from './useGroupListing';
import ParticipantProgressGraph from './ParticipantProgressGraph';
import { MockGroupList } from './GroupListingMock';
import UnverifiedEmailModal from './UnverifiedEmailModal';
import { useUnverifiedEmailModal } from './useUnverifiedEmailModa';
import { useSelector } from 'react-redux';
import { isEmailConfirmed } from 'store/user/userSelector';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardActionArea: {
            backgroundColor: "#DB5205"
        },
        cardHeader: {
            color: "white"
        },
        progressIndicator: {
            color: "white",
            fontSize: "3rem",
            fontWeight: "lighter"
        },
        progressLabel: {
            color: "white",
            fontSize: "0.85rem",
            textAlign: "center"
        },
        dateTimeIndicator: {
            fontSize: "0.8rem"
        },
        addGroupWrapper: {
            display: "flex",
            height: "calc(100vh - 360px)",
        },
        addFirstGroupButton: {
            display: "flex",
            margin: "auto",
            fontSize: 20,
            fontWeight: 700,
            textTransform: "uppercase",

            "& .MuiButton-startIcon": {
                padding: 4,
                color: "#fff",
                fontWeight: 700,
                background: theme.palette.shades.grey,
                borderRadius: 20,

                "& .MuiIcon-root": {
                    fontSize: "1.5em",
                    textShadow: "0.5px 0.5px 0 #fff, -0.5px 0.5px 0 #fff, 0.5px -0.5px 0 #fff, -0.5px -0.5px 0 #fff",
                }
            }
        },
    }),
);

const Card = withStyles({
    root: {
        borderRadius: 12,
        margin: "auto",
        transition: "0.3s",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.2)",
        "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
        minheight: ""
    }
})(MuiCard);

const AddCard = withStyles({
    root: {
        borderRadius: 12,
        margin: "auto",
        transition: "0.3s",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.5)",
        "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
        height: "100%",
        minHeight: "300px",
        display: "flex"
    }
})(MuiCard);

const CardActions = withStyles({
    root: {
        float: "right"
    },
})(MuiCardActions);


const GroupListing = ({ }) => {
    const classes = useStyles();
    const { groupList, deleteGroup, editGroup, addGroup, plan } = useGroupListing();
    const { open, openUnverifiedEmailModal, closeUnverifiedEmailModal } = useUnverifiedEmailModal();
    const isVerified = useSelector(isEmailConfirmed);

    if (groupList.length > 0)
        return (
            <>
                {groupList.map(group => {
                    return (
                        <Grid item md={4} xs={12} lg={3} key={group.id!}>
                            <Card onClick={() => isVerified ? editGroup(group.id!) : openUnverifiedEmailModal()}>
                                <CardActionArea>
                                    <CardContent className={classes.cardActionArea}>
                                        <Typography gutterBottom component="h3" className={classes.cardHeader}>{group.name}</Typography>
                                        <ParticipantProgressGraph
                                            value={plan.features?.maxNumEmailUsers ? Math.round((group.numberOfSlotsUsed / plan.features.maxNumEmailUsers) * 100) : 0}
                                            graphLabel={`${group.numberOfSlotsUsed}/${plan.features?.maxNumEmailUsers ?? 0}`}
                                            label="Total Used Participant Slots"
                                        />
                                    </CardContent>
                                    <CardContent>
                                        <Typography component="span" className={classes.dateTimeIndicator}><strong>Next Test Date:</strong>{group.nextDate ? new Date(group.nextDate).toLocaleDateString() : 'N/A'}</Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions disableSpacing >
                                    <IconButton aria-label="delete" onClick={() => deleteGroup(group.id!)}>
                                        {/* <DeleteOutlineIcon color="error" /> */}
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                })}
                <Grid item md={4} xs={12} lg={3}>
                    <AddCard onClick={isVerified ? addGroup : openUnverifiedEmailModal}>
                        <CardActionArea>
                            <CardContent style={{ textAlign: 'center' }}>
                                <IconButton aria-label="add">
                                    <AddCircleOutlineIcon color="error" style={{ fontSize: "3.5rem" }} />
                                    <Typography component="h3"><strong>Add Group</strong></Typography>
                                </IconButton>
                            </CardContent>
                        </CardActionArea>
                    </AddCard>
                </Grid>
                <UnverifiedEmailModal
                    isOpen={open}
                    handleModalClose={closeUnverifiedEmailModal}
                />
            </>
        );
    return (
        <>
            <Grid item xs={12} className={classes.addGroupWrapper}>
                <Button onClick={isVerified ? addGroup : openUnverifiedEmailModal} className={classes.addFirstGroupButton} aria-label="Add" color="default" startIcon={<Icon>add</Icon>}>
                    Add your very first group
                </Button>
            </Grid>
            <UnverifiedEmailModal
                isOpen={open}
                handleModalClose={closeUnverifiedEmailModal}
            />
        </>
    );
}

export default GroupListing;
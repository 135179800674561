import { GroupResultsDTO } from "external-api/license-api";
import * as _ from "lodash";

export type CourseData = {
    title: string;
    subheader: string;
    courses: {
        date: Date;
        passed: number;
        completed: number;
        numberOfParticipants: number;
    }[];
}

export function convertGroupDataToCourseData(data: GroupResultsDTO, courseTitles: { [key: string]: { title: string } }) {

    if (data.results && data.results.length > 0 && courseTitles !== {}) {
        let courseData = data.results.map(result => result.courses ? result.courses.map(course => ({
            startDate: result.startDate,
            numberOfParticipants: result.numberOfParticipants,
            courseId: course.courseId ?? "",
            numberOfPassed: course.numberOfPassed ?? 0,
            numberOfCompleted: course.numberOfCompleted ?? 0,
            scores: course.scores ?? []
        })) : []).flat()

        data.results.forEach(result => {
            result.courseConfiguration?.forEach(courseConfig => {
                if (_.chain(courseData).find(courseData => (courseData.courseId === courseConfig.id) && (courseData.startDate === result.startDate)).value() === undefined) {
                    courseData.push({
                        startDate: result.startDate,
                        numberOfParticipants: result.numberOfParticipants,
                        courseId: courseConfig.id ?? "",
                        numberOfPassed: 0,
                        numberOfCompleted: 0,
                        scores: []
                    })
                }
            })
        })

        const output = _.chain(courseData).groupBy('courseId').values().map(iterations => (
            {
                title: data.name ?? "Untitled",
                subheader: iterations[0].courseId in courseTitles ? courseTitles[iterations[0].courseId].title : iterations[0].courseId,
                courses: iterations.map(iteration => {
                    return (
                        {
                            "date": new Date(iteration.startDate),
                            "passed": iteration.numberOfPassed,
                            "completed": iteration.numberOfCompleted,
                            "numberOfParticipants": data.numberOfSlotsUsed > 0 ? iteration.numberOfParticipants : iteration.numberOfCompleted, // More hacks, because deploying anonymous links in 24hours is a thing! :)
                        }
                    );
                })
            } as CourseData
        )).value();
        return output;
    }
    else
        return []
}

export function convertGroupDataToCourseCardsData(data: GroupResultsDTO, courseTitles: { [key: string]: { title: string } }) {
    return convertGroupDataToCourseData(data, courseTitles);
}

export function convertGroupsDataToCourseCardsData(data: GroupResultsDTO[], courseTitles: { [key: string]: { title: string } }) {
    return data.map(groupData => convertGroupDataToCourseCardsData(groupData, courseTitles)).flat()
}

import { navigate } from "@reach/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupList } from "store/group/groupSelectors";
import { getPlan } from "store/plan/planSelectors";
import { clearGroup } from "store/ui/uiSlice";
import { Paths } from "utils/constants";

export const useGroupListing = () => {
    const dispatch = useDispatch();
    const _groups = useSelector(getGroupList);
    const _plan = useSelector(getPlan);

    useEffect(() => {
        async function _getAllGroups() {
            // dispatch(getAllGroups(true));
            dispatch(clearGroup());
        }
        _getAllGroups();
    }, []);

    function editGroup(id: string) {
        navigate(`${Paths.GROUPS}/${id}`);
    }
    function deleteGroup(id: string) {

    }
    function addGroup() {
        navigate(`${Paths.GROUPS}/add`);
    }

    return {
        groupList: _groups,
        editGroup,
        deleteGroup,
        addGroup,
        plan: _plan
    }
}
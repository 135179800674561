import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    Tabs,
    Tab,
    Typography
} from "@material-ui/core";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Link, useLocation } from "@reach/router";
import { Paths } from "utils/constants";
import SideDrawer from "components/SideDrawer";
import HeaderMenu from "./HeaderMenu";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated, isEmailConfirmed } from "store/user/userSelector";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CustomButton from "./CustomButton";
import ErrorButton from "./ErrorButton";
import { resendConfirmationAction } from "store/user/userSlice";
import { ReactComponent as IconAlert } from "assets/iconAlert.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import { hasGroup } from "store/group/groupSelectors";
import { hasPlan } from "store/plan/planSelectors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appbar: {
        },
        toolbar: {
            display: `flex`,
            justifyContent: `space-between`,
            height: "80px",
            backgroundColor: 'white',
        },
        logo: {
            display: "block",
            width: 172,
            height: "auto",
        },
        navList: {
            display: `flex`,
            justifyContent: `space-between`
        },
        linkText: {
            textDecoration: `none`,
            textTransform: `uppercase`,
            color: `black`
        },
        tabsHeaderMenu: {
            display: 'flex',
            height: "100%",
        },
        tabs: {
            height: "100%",
        },
        tabIndicator: {
            display: "none",
        },
        tab: {
            height: "100%",
            paddingTop: 25,
            color: "#000",
            fontSize: "16px",
            fontWeight: 700,
            textTransform: "uppercase",
            opacity: 1,

            "&:hover, &:focus": {
                color: theme.palette.orange.highlight,
            }
        },
        tabSelected: {
            color: theme.palette.orange.main,
        },
        headerMenu: {
            paddingLeft: theme.spacing(2)
        },
        banner: {
            width: '100%',
            backgroundColor: theme.palette.red.error,
            color: "white",
            padding: "15px 50px",
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        bannerContent: {
            display: "inline-block",
            verticalAlign: "middle",
            fontWeight: "bold"
        },
        bannerIcon: {
            fontSize: "3em",
            marginRight: "1em"
        },
        bannerButton: {
            marginLeft: "auto"
        }
    }),
);


const Header = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const hasAPlan = useSelector(hasPlan);
    const hasAGroup = useSelector(hasGroup);
    const isAuth = useSelector(isAuthenticated);
    const isVerified = useSelector(isEmailConfirmed);

    const navLinks = [
        { title: `Dashboard`, path: Paths.HOME },
        { title: `Groups`, path: hasAPlan && !hasAGroup ? `${Paths.GROUPS}/add` : Paths.GROUPS },
        { title: `Support`, path: Paths.SUPPORT },
    ] as { title: string, path: string }[];

    const navTabCurrentIndex = navLinks.findIndex((link) => link.path === location.pathname)
    const [navTabIndex, setNavTabIndex] = useState(navTabCurrentIndex);

    const handleNavTabChange = (event: any, value: any) => {
        setNavTabIndex(value);
    };

    async function resendVerificationEmail() {
        await dispatch(resendConfirmationAction());
    }

    return (
        <>
            <AppBar position="static" className={classes.appbar}>
                <Toolbar className={classes.toolbar}>
                    <IconButton edge="start" color="inherit" aria-label="logo" component={Link} to={Paths.HOME}>
                        <img className={classes.logo} src="/images/general/logo.svg" alt="Bias Proof Logo" />
                    </IconButton>
                    {isAuth &&
                        <>
                            <Hidden smDown>
                                <div className={classes.tabsHeaderMenu}>
                                    <Tabs
                                        value={navTabIndex}
                                        onChange={handleNavTabChange}
                                        classes={{ root: classes.tabs, flexContainer: classes.tabs, indicator: classes.tabIndicator }}
                                        centered
                                    >
                                        {navLinks.map(
                                            ({ title, path }, index) => <Tab label={title} to={path} component={Link} classes={{ root: classes.tab, selected: classes.tabSelected }} key={index} />
                                        )}
                                    </Tabs>
                                    <HeaderMenu />
                                </div>
                            </Hidden>
                            <Hidden mdUp>
                                <SideDrawer navLinks={navLinks} />
                            </Hidden>
                        </>
                    }
                </Toolbar>
            </AppBar>
            {isAuth && !isVerified &&
                <div className={classes.banner}>
                    <SvgIcon className={classes.bannerIcon} viewBox="0 0 40 36">
                        <IconAlert />
                    </SvgIcon>

                    <Typography variant="body1" component="span" className={classes.bannerContent}>
                        Please check your email and follow the link to verify your email address
                    </Typography>
                    <ErrorButton
                        className={classes.bannerButton}
                        label="Resend Verification Email"
                        onClick={resendVerificationEmail}
                    />
                </div>
            }
        </>
    );
};

export default Header;

import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { ChangeEventHandler, useRef, useState } from "react";
import Carousel, { ButtonGroupProps } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: "relative",
        },
        container: {
            margin: "0",
        },
        list: {
            margin: "0 -5px",
        },
        item: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            margin: "0 0 50px 0",
        },
        tracker: {
            margin: "0 -5px",
        },
        buttonGroup: {
            position: "absolute",
            top: "50%",
            left: 0,
            right: 0,
            width: "100%",
        },
        buttonDisabled: {
            opacity: 0.5,
        },
        customButton: {
            position: "absolute",
            outline: "0",
            width: 32,
            height: 32,
            minWidth: 0,
            padding: "0 0 0.2em 0",
            color: "#fff",
            lineHeight: 1,
            textAlign: "center",
            background: theme.palette.primary.main,
            border: "3px solid #fff",
            borderRadius: "35px",
            boxShadow: "0 5px 5px 0 rgba(0,0,0,0.25)",
            boxSizing: "border-box",
            transition: "all .5s",
            transform: "translateY(-50%)",
            opacity: 1,
            cursor: "pointer",
            zIndex: 1000,

            "&[disabled]": {
                color: "#fff",
                boxShadow: "0 5px 5px 0 rgba(0,0,0,0.25)",
            },

            "&:hover, &:focus": {
                background: theme.palette.orange.highlight,
            }
        },
        customButtonLabel: {
            position: "absolute",
            display: "block",
            top: "50%",
            left: "50%",
            margin: "-0.4em 0 0 -0.4em",
            width: "0.8em",
            height: "0.8em",
            boxSizing: "border-box",

            "&::before, &:after": {
                content: "''",
                position: "absolute",
                display: "block",
                width: "100%",
                height: 3,
                background: "#fff",
                borderRadius: 10,
            },
        },
        customButtonLeft: {
            left: -60,

            "& .MuiButton-label": {
                marginLeft: "-0.3em",

                "&::before": {
                    bottom: "calc(50% - 1.5px)",
                    left: 0,
                    transform: "rotate(-45deg)",
                    transformOrigin: "left bottom",
                },
                "&::after": {
                    top: "calc(50% - 1.5px)",
                    left: 0,
                    transform: "rotate(45deg)",
                    transformOrigin: "left top",
                }
            }
        },
        customButtonRight: {
            right: -60,

            "& .MuiButton-label": {
                marginLeft: "-0.5em",

                "&::before": {
                    bottom: "calc(50% - 1.5px)",
                    right: 0,
                    transform: "rotate(45deg)",
                    transformOrigin: "right bottom",
                },
                "&::after": {
                    top: "calc(50% - 1.5px)",
                    right: 0,
                    transform: "rotate(-45deg)",
                    transformOrigin: "right top",
                }
            }
        },
        sliderInput: {
            "-webkit-appearance": "none",
            appearance: "none",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: 25,
            margin: 0,
            background: "none",
            borderBottom: "2px solid " + theme.palette.primary.main,

            "&::-webkit-slider-thumb": {
                "-webkit-appearance": "none",
                appearance: "none",
                height: 25,
                width: "20%",
                background: `linear-gradient(to top, ${theme.palette.orange.main} 5px, transparent 5px)`,
                border: "none",
                borderRadius: 0,
                cursor: "ew-resize",
            },

            "&::-moz-range-thumb": {
                height: 25,
                width: "20%",
                background: `linear-gradient(to top, ${theme.palette.orange.main} 5px, transparent 5px)`,
                border: "none",
                borderRadius: 0,
                cursor: "ew-resize",
            },

            "&::-ms-thumb": {
                height: 25,
                width: "20%",
                background: `linear-gradient(to top, ${theme.palette.orange.main} 5px, transparent 5px)`,
                border: "none",
                borderRadius: 0,
                cursor: "ew-resize",
            },
        }
    }),
);

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

interface CarouselButtonGroupProps extends ButtonGroupProps {
    className?: string;
}

interface IProps {
    slider?: boolean;
    children: any;
}

const MultiCarousel = ({ slider = false, children }: IProps) => {
    const classes = useStyles();
    const carouselRef = useRef<Carousel>(null);
    const Slider = ({ carouselState, next, previous }: CarouselButtonGroupProps) => {
        if (carouselState) {

            let value = 0;
            let carouselItemWidth = 0;
            const { transform, currentSlide, totalItems, slidesToShow } = carouselState;

            if (carouselRef && carouselRef.current) {
                carouselItemWidth = carouselRef.current.state.itemWidth;
                const maxTranslateX = Math.round(
                    // so that we don't over-slide
                    carouselItemWidth * (carouselRef.current.state.totalItems - carouselRef.current.state.slidesToShow)
                );
                value = maxTranslateX / 100; // calculate the unit of transform for the slider
            }
            const isNextDisabled = (currentSlide === (totalItems - slidesToShow)) || (totalItems < slidesToShow);
            return (
                <>
                    <div className={classes.buttonGroup}>
                        <Button variant="contained" color="primary" classes={{ root: `${classes.customButton} ${classes.customButtonLeft}`, label: classes.customButtonLabel }} onClick={previous} disabled={currentSlide === 0 ? true : false} title="Previous" aria-label="Previous"></Button>
                        <Button variant="contained" color="primary" classes={{ root: `${classes.customButton} ${classes.customButtonRight}`, label: classes.customButtonLabel }} onClick={next} disabled={isNextDisabled} title="Next" aria-label="Next"></Button>
                    </div>

                    <input
                        type="range"
                        value={Math.round(Math.abs(transform) / value)}
                        defaultValue={0}
                        max={
                            carouselItemWidth * (carouselState.totalItems - carouselState.slidesToShow) / value
                        }
                        onChange={(e: any) => {
                            const nextTransform = e.target.value as number * value;
                            const nextSlide = Math.round(nextTransform / carouselItemWidth);
                            carouselRef?.current?.setState({
                                transform: -nextTransform,
                                currentSlide: nextSlide
                            });
                        }}
                        className={classes.sliderInput}
                    />
                </>
            );
        }
        else return null;
    }

    return (
        <div className={classes.wrapper}>
            <Carousel
                customButtonGroup={slider ? <Slider /> : undefined}
                ref={carouselRef}
                arrows={false}
                slidesToSlide={1}
                additionalTransfrom={0}
                renderButtonGroupOutside={true}
                swipeable
                draggable
                responsive={responsive}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                containerClass={classes.container}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                sliderClass={classes.list}
                itemClass={classes.item}
            >
                {children}
            </Carousel>
        </div>
    )
}

export default MultiCarousel;
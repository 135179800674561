const useEmailListValidation = (data: any) => {
    let result = [] as { email: string, validation: 'VALID' | 'DUPLICATE' | 'INVALID' }[]
    let valid = false

    // See https://emailregex.com/ for more details
    // eslint-disable-next-line
    const EMAIL_VALIDATION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (
        Array.isArray(data)
        && data.length > 0
        && data.every((row) => row.length === 1)
    ) {
        const emails = data.map(row => String(row[0]).trim().toLowerCase())
        const isValidEmail = emails.map(s => EMAIL_VALIDATION_REGEX.test(s))
        const isUnique = emails.map(s => emails.indexOf(s) === emails.lastIndexOf(s))
        result = emails.map((s, i) => {
            return {
                email: s,
                validation: isValidEmail[i] ? (isUnique[i] ? 'VALID' : 'DUPLICATE') : 'INVALID'
            }
        })
        valid = isValidEmail.every(Boolean) && isUnique.every(Boolean)
    }

    return {
        isValid: valid,
        result: result
    }
}

export default useEmailListValidation;
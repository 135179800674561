import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { getPlan, hasPlan as hasPlanSelector, hasStripe as hasStripeSelector } from 'store/plan/planSelectors';
import { getGroupList } from 'store/group/groupSelectors';
import { createCustomerPortalAction } from 'store/user/userSlice';
import { Dispatch } from 'store';
import { navigate } from '@reach/router';
import { Paths } from 'utils/constants';
import StyledModal from 'components/StyledModal';
import ErrorButton from 'components/ErrorButton';
import DeleteAccountModalForm from './DeleteAccountModalForm';
import { getAllPlans } from 'store/ui/uiSelectors';
import { getCountry } from 'store/user/userSelector';
import { getCurrencyForCountry } from 'pages/Plans';
import { downgradePlanAction, getMyPlan } from 'store/plan/planSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subscriptionManagementRoot: {
        },
        title: {
            fontSize: 24,
            fontWeight: 800,
            textTransform: "uppercase",
            marginBottom: theme.spacing(1),
        },
        sectionTitle: {
            fontSize: 20,
            fontWeight: 700,
            textTransform: "uppercase",
            marginBottom: theme.spacing(1),
        },
        divider: {
            maxWidth: "25rem",
            backgroundColor: "#9E9E9E",
        },
        text: {
            fontSize: 16,
            fontWeight: 700,
        },
        fieldLabel: {
            fontSize: 14,
            fontWeight: 700,
            color: theme.palette.orange.main,
            paddingTop: theme.spacing(1)
        },
        fieldValue: {
            fontSize: 18,
            fontWeight: 700,
        },
        link: {
            fontSize: 16,
            fontWeight: 700,
            textTransform: "uppercase",
            padding: "8px 0px",
            "& a": {
                color: theme.palette.orange.main,
                fontWeight: 700,
                textDecoration: "none",
                "&:hover, &:focus": {
                    textDecoration: "underline",
                }
            }
        },
        links: {
            margin: '22px 0px',
        },
        button: {
            margin: '30px 0px',
            padding: "0 3em",
        },
        redButton: {
            backgroundColor: theme.palette.red.error,
            "&:hover": {
                backgroundColor: theme.palette.red.dark1,
            }
        },
        modalContentTitle: {
            display: 'block',
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "1.2em",
            fontWeight: 900,
            marginBottom: "10px"
        },
        modalContentBody: {
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "12px",
            fontWeight: 400,
            "& a": {
                color: "#29ABE2",
                fontWeight: 700,
                textDecoration: "none",

                "&:hover, &:focus": {
                    textDecoration: "underline",
                }
            }
        },
        modalActionButton: {
            textAlign: 'center',
            marginTop: "20px"
        },
        modalCancelButton: {
            textAlign: 'center',
        },
    })
);

function getNumber(str: any, defaultValue: number) {
    const num = parseInt(str, 10);
    return isNaN(num) ? defaultValue : num;
}

function useFreePlanDetails() {
    const plans = useSelector(getAllPlans);
    const country = useSelector(getCountry);
    const currency = getCurrencyForCountry(country);
    const freePlan = plans.find(
        planInfo => planInfo.recurringPrices?.find(
            price => price.currency?.toLowerCase() === currency.toLowerCase()
        )?.unit_amount === 0
    )

    const freePlanPriceId = freePlan?.recurringPrices?.find(x => x.currency?.toLowerCase() === currency.toLowerCase())?.id
    const freePlanMaxQuantity = getNumber(
        freePlan?.product?.metadata?.MaxQuantity
        , Number.POSITIVE_INFINITY
    );

    return { freePlanPriceId, freePlanMaxQuantity }
}

function useIsFreePlan() {
    const plan = useSelector(getPlan);
    const plans = useSelector(getAllPlans);

    const allRecurringPrices = plans.map(
        planInfo => planInfo.recurringPrices
    ).flat();

    const isCurrentPlanFree = allRecurringPrices.find(
        price => price?.id === plan.currentSubscription?.priceID
    )?.unit_amount === 0;

    const isNextPlanFree = allRecurringPrices.find(
        price => price?.id === plan.nextSubscription?.priceID
    )?.unit_amount === 0;

    return { isCurrentPlanFree, isNextPlanFree }
}

interface IProps {
}

const SubscriptionManagement = ({ }: IProps) => {

    const classes = useStyles();
    const dispatch: Dispatch = useDispatch();
    const [portalLink, setPortalLink] = useState('');

    const [showCancelPlanModal, setShowCancelPlanModal] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

    const hasPlan = useSelector(hasPlanSelector);
    const plan = useSelector(getPlan);
    const groups = useSelector(getGroupList);
    const hasStripe = useSelector(hasStripeSelector);

    const { freePlanPriceId, freePlanMaxQuantity } = useFreePlanDetails();
    const { isCurrentPlanFree, isNextPlanFree } = useIsFreePlan();
    const totalNumberOfSlotsUsed = groups.map(group => group.numberOfSlotsUsed).reduce((a, b) => a + b, 0);

    function handleSelectPlan() {
        navigate(Paths.PLANS);
    }

    function handleEditPlan() {
        navigate(Paths.EDIT_PLAN);
    }

    function handleCancelPlan() {
        setShowCancelPlanModal(true);
    }

    function handleDeleteAccount() {
        setShowDeleteAccountModal(true);
    }

    async function onCancelPlan() {

        if (freePlanPriceId) {
            await dispatch(downgradePlanAction(
                {
                    subscriptionID: plan.stripeSubscriptionID,
                    priceId: freePlanPriceId,
                    quantity: freePlanMaxQuantity,
                    quantityRemoved: (plan.nextSubscription?.quantity ?? (plan.currentSubscription?.quantity ?? 0)) - freePlanMaxQuantity,
                }
            ));
            await dispatch(getMyPlan());
            setShowCancelPlanModal(false);
        }
    }

    useEffect(() => {
        async function createCustomerPortalLink() {
            if (hasStripe) {
                const resp = await dispatch(createCustomerPortalAction());
                if (createCustomerPortalAction.fulfilled.match(resp)) {
                    setPortalLink(resp.payload);
                }
            }
        }
        createCustomerPortalLink();
    }, [])

    function SubscriptionDetails() {
        return (
            <>
                <Typography variant="h2" className={classes.sectionTitle}>Subscription Details</Typography>
                <Divider className={classes.divider} />
                <div className={classes.fieldLabel}>Current Plan:</div>
                <div className={classes.fieldValue}>{plan.name} Plan</div>
                <div className={classes.fieldLabel}>Number of Licenses Purchased:</div>
                <div className={classes.fieldValue}>{plan.price === 0 ? "Free (5 licenses)" : (plan.currentSubscription?.quantity ?? plan.features?.maxNumEmailUsers)}</div>
                <div className={classes.fieldLabel}>Number of Licenses Used:</div>
                <div className={classes.fieldValue}>{totalNumberOfSlotsUsed}</div>
                <div className={classes.fieldLabel}>Number of Licenses Available:</div>
                <div className={classes.fieldValue}>{(plan.currentSubscription?.quantity ?? plan.features?.maxNumEmailUsers) ? ((plan.currentSubscription?.quantity ?? plan.features?.maxNumEmailUsers ?? 0) - totalNumberOfSlotsUsed) : 0}</div>
                <Button onClick={handleEditPlan} type="button" color="primary" variant="contained" size="medium" className={classes.button}>Edit Plan</Button>
            </>
        )
    }

    function SubscriptionSettings() {
        return (
            <>
                <Typography variant="h2" className={classes.sectionTitle}>Subscription Settings</Typography>
                <Divider className={classes.divider} />
                {
                    plan.nextSubscription?.startDate &&
                    <>
                        <div className={classes.fieldLabel}>Subscription Renewal Date:</div>
                        <div className={classes.fieldValue}>{new Date(plan.nextSubscription?.startDate).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                    </>
                }
                {/* <div className={classes.fieldLabel}>Number of Licenses to Renew:</div>
                <div className={classes.fieldValue}>{isNextPlanFree ? "Free (5 licenses)" : plan.nextSubscription?.quantity}</div> */}
                <div className={classes.links}>
                    <div className={classes.link}><a href={portalLink}>{"View Billing History >"}</a></div>
                    <div className={classes.link}><a href={portalLink}>{"Update billing information >"}</a></div>
                </div>
            </>
        )
    }

    function CancelSubscription() {
        return (
            <>
                <Typography variant="h2" className={classes.sectionTitle}>Cancel Subscription</Typography>
                <Divider className={classes.divider} />
                <Typography component="p" className={classes.text}> By clicking “Cancel Subscription” below, your subscription renewal payment will be cancelled and your account will be downgraded to the <strong>FREE</strong> tier at the end of your billing cycle.</Typography>
                <br />
                <Typography component="p" className={classes.text}>  Your account will remain operational, and you will still be able to access your account, if at a later date you decide to renew your subscription.</Typography>
                <Button onClick={handleCancelPlan} type="button" color="primary" variant="contained" size="medium" className={classes.button}>Cancel Subscription</Button>
                <StyledModal type="alert" pretitle="Alert" title="Cancel Subscription" isOpen={showCancelPlanModal} handleModalClose={() => setShowCancelPlanModal(false)}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{ textAlign: "center" }}>
                                <span className={classes.modalContentTitle}>
                                    Are you sure you want to cancel your subscription?
                                </span>
                                <Typography variant="body1" component="p" className={classes.modalContentBody}>
                                    By clicking “Cancel Subscription” below, your subscription renewal payment will be cancelled and your account will be downgraded to the <strong>FREE</strong> tier at the end of your billing cycle.
                                </Typography>
                                <br />
                                <Typography variant="body1" component="p" className={classes.modalContentBody}>
                                    Your account will remain operational, and you will still be able to access your account, if at a later date you decide to renew your subscription.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} className={classes.modalActionButton}>
                            <ErrorButton
                                label="Cancel Subscription"
                                onClick={onCancelPlan}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.modalCancelButton}>
                            <ErrorButton
                                secondary
                                label="Cancel"
                                onClick={() => setShowCancelPlanModal(false)}
                            />
                        </Grid>
                    </Grid>
                </StyledModal>
            </>
        )
    }

    function DeleteAccount() {
        return (
            <>
                <Typography variant="h2" className={classes.title}>Delete Account</Typography>
                <Divider className={classes.divider} />
                <Typography component="p" className={classes.text}>By clicking “Delete Account” below, your payment subscription will be cancelled and will cease to have access to your BiasProof account at the end of your next billing cycle.</Typography>
                <Button onClick={handleDeleteAccount} type="button" color="primary" variant="contained" size="medium" classes={{ root: classes.redButton }} className={classes.button}>Delete Account</Button>
                <StyledModal type="alert" pretitle="Warning" title="Delete Account" isOpen={showDeleteAccountModal} handleModalClose={() => setShowDeleteAccountModal(false)}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{ textAlign: "center" }}>
                                <span className={classes.modalContentTitle} style={{ color: "#D42C22" }}>
                                    By deleting your account all associated data will be permanently deleted. This cannot be undone.
                                </span>
                                <Typography variant="body1" component="p" className={classes.modalContentBody}>
                                    We’re sorry to see you go, but we understand that things change and that our services may not be required anymore.
                                </Typography>
                                <br />
                                <Typography variant="body1" component="p" className={classes.modalContentBody}>
                                    If you change your mind between now and the end of this billing cycle, you can reactivate your account by contacting us at:
                                </Typography>
                                <br />
                                <Typography variant="body1" component="p" className={classes.modalContentBody}>
                                    <a href="mailto:support@biasproof.com">support@biasproof.com</a>
                                </Typography>
                                <br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <DeleteAccountModalForm />
                        </Grid>
                        <Grid item xs={12} className={classes.modalCancelButton}>
                            <ErrorButton
                                secondary
                                label="Cancel"
                                onClick={() => setShowDeleteAccountModal(false)}
                            />
                        </Grid>
                    </Grid>
                </StyledModal>
            </>
        )
    }

    return (
        <div className={classes.subscriptionManagementRoot}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.title}>Subscription Management</Typography>
                    {/* <Typography component="p">Select <i>View Billing History</i> below to access your past invoices. Select <i>Update Billing Information</i> below to change your billing information.</Typography> */}
                </Grid>

                <Grid item xs={12}>
                    {hasPlan ?
                        <>
                            <SubscriptionDetails />
                            {hasStripe &&
                                <>
                                    <SubscriptionSettings />
                                    <CancelSubscription />
                                </>
                            }
                            {/* <DeleteAccount /> */}
                        </>
                        :
                        <>
                            <div className={classes.fieldValue}>No plan selected. Please select a plan.</div>
                            <Button onClick={handleSelectPlan} type="button" color="primary" variant="contained" size="medium" className={classes.button}>Select a plan</Button>
                        </>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default SubscriptionManagement;
import React from "react";
import { Grid, Typography, withStyles, makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import theme from "../../../theme";

const LinearProgressBar = withStyles((theme) => ({
    root: {
        width: "100%",
        height: 15,
        borderRadius: 20,
    },
    colorPrimary: {
        backgroundColor: "#ccc",
    },
    bar: {
        borderRadius: 20,
        backgroundColor: theme.palette.orange.main,
    },
}))(LinearProgress);

const useStyles = makeStyles({
    root: {
        width: 140,
    },
    text: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0 10px 5px 10px",
        fontSize: 11,
        fontWeight: 300,
        textAlign: "center",
    },
    meter: {
        position: "relative",
    },
    textPercent: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        padding: "0 5px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: 0.9,
        textAlign: "right",
        boxSizing: "border-box",
    },
    textPercentDark: {
        color: theme.palette.shades.black,
    }
});

interface IProps {
    value: number;
    total: number;
}

const ProgressBar = ({ value, total }: IProps) => {
    const classes = useStyles();
    const textW = 20;
    let percentage = Math.round((value / total) * 100);
    if(Number.isNaN(percentage))
        percentage = 0;
    return (
        <div className={classes.root}>
            <div className={classes.meter}>
                <LinearProgressBar variant="determinate" value={percentage} />
                {/* This needs to somehow sit inside the progress bar... */}
                <div className={classes.textPercent + " " + (percentage > textW ? "" : classes.textPercentDark)} style={{ right: ((percentage > textW) ? (100 - percentage) + "%" : ""), left: ((percentage > textW) ? "" : `${percentage}%`) }}>{value}</div>
            </div>
        </div>
    )
}

export default ProgressBar;

import { createSelector } from "@reduxjs/toolkit";
import { GroupResultsDTO } from "external-api/license-api";
import { IGroupState } from "./groupSlice";

const getId = (_: any, id: any) => id;
const _getGroupList = (state: { group: IGroupState }) => state.group.groupList;
const _getGroupData = (state: { group: IGroupState }) => state.group.groupData;

export const getGroupList = createSelector(
    [_getGroupList],
    (groups: GroupResultsDTO[]) => {
        return groups;
    }
)

export const hasGroup = createSelector(
    [_getGroupList],
    (groups: GroupResultsDTO[]) => {
        return groups.length > 0;
    }
)

export const getAllScoreCards = createSelector(
    [_getGroupList],
    (groups: GroupResultsDTO[]) => {
        return groups;
    }
)

export const canDownloadBadge = createSelector(
    [_getGroupList],
    (groups: GroupResultsDTO[]) => {
        return groups.length > 0;
    }
)

export const getTotalUsedSlots = createSelector(
    [_getGroupList],
    (groups: GroupResultsDTO[]) => {
        return groups.map(group => group.numberOfSlotsUsed).reduce((a, b) => a + b, 0)
    }
)
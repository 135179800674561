import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'layouts/MainLayout';
import { enqueueNotification, planChangeDiscarded } from 'store/ui/uiSlice';
import { Paths } from 'utils/constants';
import { getIsPlanSelectionStarted, getIsPlanUpgradeStarted } from 'store/ui/uiSelectors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignContent: "flex-start",
            width: "auto",
            margin: `${theme.spacing(2)}px 64px ${theme.spacing(12)}px 64px`,
            height: "100%",
            minHeight: "calc(100vh - 300px)",

            "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "100vw",
                background: "linear-gradient(to bottom, #e6e6e6, #fff 520px)",
                borderTop: "1px solid" + theme.palette.shades.grey,
                zIndex: -1,
            }
        },
        heading: {
            fontWeight: 500,
            textTransform: "uppercase",
        },
    }),
);

interface IProps extends RouteComponentProps<{
    src: string
}> { }

const PaymentFailure = ({ src }: IProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isPlanSelectionStarted = useSelector(getIsPlanSelectionStarted);
    const isPlanUpgradeStarted = useSelector(getIsPlanUpgradeStarted);

    useEffect(() => {
        (async () => {
            await dispatch(enqueueNotification({
                message: 'Payment Canceled',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error'
                }
            }));
            if (isPlanSelectionStarted) {
                await dispatch(planChangeDiscarded());
                navigate(Paths.PLANS);
            }
            else if (isPlanUpgradeStarted) {
                await dispatch(planChangeDiscarded());
                navigate(Paths.EDIT_PLAN);
            }
            else {
                navigate(Paths.HOME);
            }
        })()
    }, []);

    return (
        <MainLayout>
            <Grid container className={classes.root} spacing={2}>
            </Grid>
        </MainLayout >
    );
}

export default PaymentFailure;
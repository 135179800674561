import React, { ReactNode } from 'react'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { BLOCKS, Inline, Block } from '@contentful/rich-text-types';
import ContentfulAsset, { IContentfulAsset } from 'components/contentful/ContentfulAsset';
const useStyles = makeStyles((theme) =>
    createStyles({

    })
);


interface IProps {
    data: any
}
// -- get course details
const ContentfulRichText = ({ data }: IProps) => {
    const classes = useStyles();

    const assetBlockMap = new Map(
        data?.links?.assets?.block?.map((asset: { sys: { id: any; }; }) => [asset.sys.id, asset]),
    );

    const entryBlockMap = new Map(
        data?.links?.entries?.block?.map((entry: { sys: { id: any; }; }) => [entry.sys.id, entry]),
    );
    const options = {
        renderMark: {
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => <Typography variant="body1" paragraph>{children}</Typography>,
            [BLOCKS.HEADING_1]: (node: Block | Inline, children: ReactNode) => <Typography variant="h1" component="h1" paragraph>{children}</Typography> as ReactNode,
            [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => <Typography variant="h2" component="h2" paragraph>{children}</Typography> as ReactNode,
            [BLOCKS.HEADING_3]: (node: Block | Inline, children: ReactNode) => <Typography variant="h3" component="h3" paragraph>{children}</Typography> as ReactNode,
            [BLOCKS.HEADING_4]: (node: Block | Inline, children: ReactNode) => <Typography variant="h4" component="h4" paragraph>{children}</Typography> as ReactNode,
            [BLOCKS.HEADING_5]: (node: Block | Inline, children: ReactNode) => <Typography variant="h5" component="h5" paragraph>{children}</Typography> as ReactNode,
            [BLOCKS.HEADING_6]: (node: Block | Inline, children: ReactNode) => <Typography variant="h6" component="h6" paragraph>{children}</Typography> as ReactNode,
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
                const asset = assetBlockMap.get(node.data.target.sys.id) as IContentfulAsset;
                return <ContentfulAsset {...asset} /> as ReactNode
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
                switch (node.data.target.__typename) {
                }
                return <></> as ReactNode
            },
        },
    }
    return (
        <>
            {data && data.json && documentToReactComponents(data.json, options)}
        </>
    )
}

export default ContentfulRichText;
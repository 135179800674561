import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { navigate, RouteComponentProps } from '@reach/router';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Grid, Theme, Typography } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';
import { getPlan } from 'store/plan/planSelectors';
import { getGroupList } from 'store/group/groupSelectors';
import { getIsPlanUpgradeForMoreEmailsRequested } from 'store/ui/uiSelectors';
import { getAllPlansAction, planUpgradeForMoreEmailsCanceled } from 'store/ui/uiSlice';
import AddLicensesField from 'domain/license/AddLicensesField';
import { Paths } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        heading: {
            marginTop: theme.spacing(2),
            fontWeight: 500,
            textTransform: "uppercase",
        },
        msgHeading: {
            margin: 0,
            fontSize: 36,
            fontWeight: 700,
        },
        msgBody: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            fontSize: 18,
            lineHeight: '20px',
            fontWeight: 900,
            textTransform: "uppercase",
        },
        formContainer: {
            margin: "40px 0px",
        },
        formRow: {
            display: "flex",
        },
        fieldLabelContainer: {
            display: "flex",
            width: "50%",
            justifyContent: "flex-end",
            alignItems: "flex-start",
        },
        fieldLabel: {
            fontSize: 12,
            fontWeight: 700,
            lineHeight: "36px",
        },
        fieldValueContainer: {
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        fieldValue: {
            fontSize: 20,
            fontWeight: 900,
            color: theme.palette.orange.main,
            paddingLeft: 32,
        },
        backButtonContainer: {
            textAlign: 'center',
            marginTop: 16,
        },
        backButton: {
            borderWidth: "0px",
            textTransform: "none",
            backgroundColor: "transparent",
            fontWeight: "bold",
            "&:hover, &:focus": {
                backgroundColor: "transparent",
                borderWidth: "0px",
            },
        },
    }),
);

interface IProps extends RouteComponentProps {
}

const EditPlan = ({ }: IProps) => {
    // Assumption: a plan can be selected solely based on the total number of licenses
    const classes = useStyles();
    const dispatch = useDispatch();
    const plan = useSelector(getPlan);
    const groups = useSelector(getGroupList);
    const planUpgradeForMoreEmailsRequested = useSelector(getIsPlanUpgradeForMoreEmailsRequested);

    const totalNumberOfSlotsUsed = groups.map(group => group.numberOfSlotsUsed).reduce((a, b) => a + b, 0);

    async function handleBack() {
        if (planUpgradeForMoreEmailsRequested) {
            await dispatch(planUpgradeForMoreEmailsCanceled());
            navigate(Paths.ADD_GROUP);
        } else {
            navigate(Paths.MYACCOUNT);
        }
    }

    useEffect(() => {
        dispatch(getAllPlansAction());
        return function cleanup() {
            if (planUpgradeForMoreEmailsRequested) {
                dispatch(planUpgradeForMoreEmailsCanceled());
            }
        }
    }, []);

    return (
        <MainLayout>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Typography variant="h1" className={classes.heading}>My Plan</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.msgHeading} align='center'>Update Plan.</Typography>
                    <Typography variant="body1" className={classes.msgBody} align='center'>Add the number of licenses you need.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.formContainer}>
                        <div className={classes.formRow}>
                            <div className={classes.fieldLabelContainer}><div className={classes.fieldLabel}>
                                Licenses Purchased:
                            </div></div>
                            <div className={classes.fieldValueContainer}><div className={classes.fieldValue}>
                                <AddLicensesField />
                            </div></div>
                        </div>
                        <div className={classes.formRow}>
                            <div className={classes.fieldLabelContainer}><div className={classes.fieldLabel}>
                                Licenses Used:
                            </div></div>
                            <div className={classes.fieldValueContainer}><div className={classes.fieldValue}>
                                {totalNumberOfSlotsUsed}
                            </div></div>
                        </div>
                        <div className={classes.formRow}>
                            <div className={classes.fieldLabelContainer}><div className={classes.fieldLabel}>
                                Licenses Available:
                            </div></div>
                            <div className={classes.fieldValueContainer}><div className={classes.fieldValue}>
                                {(plan.currentSubscription?.quantity ?? plan.features?.maxNumEmailUsers) ? ((plan.currentSubscription?.quantity ?? plan.features?.maxNumEmailUsers ?? 0) - totalNumberOfSlotsUsed) : 0}
                            </div></div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.backButtonContainer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleBack}
                        className={classes.backButton}
                    >
                        Back
                    </Button>
                </Grid>
            </Grid>
        </MainLayout>
    );
}

export default EditPlan;
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useMediaQuery, Step, Stepper, StepButton, StepIcon, StepLabel } from "@material-ui/core";
import theme from "../../../theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sidePanel: {
            padding: `${theme.spacing(6)}px 0`,
            backgroundColor: "transparent",

            "& .MuiStepConnector-vertical": {
                marginLeft: 16,
                padding: 0,
            },

            "& .MuiStepConnector-lineVertical": {
                borderLeftWidth: 2,
            }
        },
        stepButton: {
            "& .MuiSvgIcon-root": {
                width: 32,
                height: 32,
                fontSize: 16,
                fontWeight: 900,
                border: "2px solid #fff",
                borderRadius: 100,
                boxSizing: "border-box",
            },

            "& .MuiStepIcon-text": {
                fontSize: "inherit",
                transform: "translateY(0)",
            },

            "& .MuiStepLabel-label": {
                color: theme.palette.shades.greyMiddle,
                fontSize: 16,
                fontWeight: 700,
                textAlign: "left",
                textTransform: "uppercase",

                "&.MuiStepLabel-active": {
                    color: "#000",
                }
            },
        }
    })
)

interface IProps {
    activeStep: number
    steps: string[]
    setActiveStep: (step: number) => void
    enableNavigation?: boolean
}

const CustomStepper = ({ activeStep, steps, setActiveStep, enableNavigation = false }: IProps) => {
    const breakpoint1 = useMediaQuery(theme.breakpoints.up("sm"));
    const breakpoint2 = useMediaQuery(theme.breakpoints.up("lg"));
    const orientationValue = breakpoint1 ? (breakpoint2 ? "vertical" : "horizontal") : "vertical";

    const classes = useStyles();
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>(
        {}
    );

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const setStep = (step: number) => {
        if (enableNavigation) setActiveStep(step);
        if (step < activeStep) setActiveStep(step);
    }

    return (
        <Stepper className={classes.sidePanel} nonLinear activeStep={activeStep} orientation={orientationValue}>
            {steps.map((label, index) => (
                <Step key={label}>
                    <StepButton
                        onClick={() => setStep(index)}
                        completed={completed[index]}
                        className={classes.stepButton}
                    >
                        {label}
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    );
}

export default CustomStepper;
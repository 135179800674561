import Papa from "papaparse";
import { useEffect, useState } from "react";
import useEmailListValidation from "./useEmailListValidation";

const useParticipantConfiguration = (maxNumOfParticipants: number | undefined, initData?: string[][]) => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [data, setData] = useState<any>(initData ?? null)
    const { isValid, result: dataValidationResult } = useEmailListValidation(data)

    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files ?? []
        const file = files.length === 1 ? files[0] : null;
        setUploadedFile(file)
        e.currentTarget.value = '';
    }

    useEffect(() => {
        if (uploadedFile) {
            Papa.parse(uploadedFile, {
                header: false,
                skipEmptyLines: true,
                complete: (results) => {
                    setData(results.data);
                }
            });
        }
    }, [uploadedFile])

    return ({
        handleInput: handleInput,
        isUploaded: data && data.length > 0,
        isValid: isValid,
        isTooManyEmails: maxNumOfParticipants !== undefined ? dataValidationResult.length > maxNumOfParticipants : false,
        dataValidationResult: dataValidationResult
    })
}

export default useParticipantConfiguration;
import React, { useState } from 'react';
import theme from 'theme'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, withStyles, Button, Box, TextField, Collapse } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import MuiCardHeader from '@material-ui/core/CardHeader';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiCardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        planCard: {
            position: 'relative',
            zIndex: 99,
        },
        heading: {
            margin: "20px auto 5px auto",
            fontSize: 18,
            fontWeight: 900,
            textAlign: "center",
        },
        description: {
            fontSize: 16,
            fontWeight: 700,
            color: '#7A340D',
            textAlign: "center",
        },
        price: {
            fontSize: 38,
            fontWeight: 900,
            color: '#C8602A',
            textAlign: "center",
        },
        per: {
            fontSize: 12,
            fontWeight: 600,
            color: '#7A340D',
            paddingTop: 14,
        },
        planButton: {
            margin: '0 auto',
            padding: "0 2em",
        },
        subButton: {
            margin: '0 auto',
            padding: "0 2em",
            '&:hover': {
            },
            '&:disabled': {
                backgroundColor: "#e6e6e6",
            },
        },
        numLicensesCollapse: {
            position: 'relative',
            top: -15,
        },
        numLicensesContainer: {
            display: 'flex',
            flexDirection: 'column',
        },

        numLicensesText: {
            fontSize: 16,
            fontWeight: 700,
            color: 'white',
            textAlign: "center",
            paddingTop: 15,
        },

        numLicensesInput: {
            height: "2em",
            padding: "0 1em",
            "&::placeholder": {
                fontStyle: "italic",
            }
        }
    }),
);


const PlanTextField = withStyles({
    root: {
        background: 'white',
        border: "2px solid " + theme.palette.orange.main,
        borderRadius: "1.3em",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        "& .MuiInputLabel-root": {
            position: "relative",
            padding: "0 0 0.5em 0.5em",
            color: theme.palette.orange.main,
            fontSize: 12,
            fontWeight: 700,
            transform: "none",
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: "0px"
            },
        },
    },
})(TextField);

const Card = withStyles({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: 210,
        margin: "0 6px",
        borderRadius: "12px",
        boxShadow: "8px 10px 12px 0 rgba(0,0,0,0.25)",
        overflow: "hidden",
    }
})(MuiCard);

const CardHeader = withStyles({
    root: {
        padding: '24px 10px 12px 10px',
        color: "#fff",
        background: theme.palette.orange.main,
        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.25)",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: "18px",
        fontWeight: 900,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
})(MuiCardHeader);

const CardContent = withStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        paddingTop: theme.spacing(3),
    }
})(MuiCardContent);

const CardActions = withStyles({
    root: {
        flexDirection: 'column',
        padding: "0 16px 36px 16px",
    },
})(MuiCardActions);

interface ICurrencySymbolProps {
    currency: "USD" | "EUR" | "GBP" | "CAD";
}
const CurrencySymbol = ({ currency }: ICurrencySymbolProps) => {
    switch (currency) {
        case "USD":
            return <span>$</span>;
        case "EUR":
            return <span>€</span>;
        case "GBP":
            return <span>£</span>;
        case "CAD":
            return <><span style={{fontSize: "0.5em"}}>CA </span><span>$</span></>;
    }
}

interface IProps {
    title: string;
    description: string;
    price: number;
    currency: "USD" | "EUR" | "GBP" | "CAD";
    minNumLicenses: number;
    maxNumLicenses: number;
    onSelectPlan: (numLicenses: number) => void
}

const PlanCard = ({ title, description, price, currency, minNumLicenses, maxNumLicenses, onSelectPlan }: IProps) => {
    const classes = useStyles();
    const [showNumLicenses, setShowNumLicenses] = useState(false);
    const [numLicenses, setNumLicenses] = useState<number>(Number.NaN);
    const isNumLicensesValid = () => {
        return (numLicenses >= minNumLicenses) && (numLicenses <= maxNumLicenses)
    }
    return (
        <div>
            <Card className={classes.planCard}>
                <CardHeader
                    title={title}
                />
                <CardContent>
                    <Typography className={classes.description} component="p">{description}</Typography>
                    {
                        price ?
                            <Box display='flex' alignItems='flex-start'>
                                <Typography className={classes.price} component="span"><CurrencySymbol currency={currency} />{price}</Typography>
                                <Typography className={classes.per} component="span">/pers</Typography>
                            </Box>
                            :
                            <Typography className={classes.price} component="span">Free</Typography>
                    }
                </CardContent>
                <CardActions disableSpacing>
                    <Button onClick={e => price === 0 ? onSelectPlan(maxNumLicenses) : setShowNumLicenses(!showNumLicenses)} type="button" color="primary" variant="contained" size="medium" className={classes.planButton}>
                        Choose Plan
                    </Button>
                </CardActions>
            </Card>
            <Collapse in={showNumLicenses} className={classes.numLicensesCollapse}>
                <Card style={{
                    backgroundColor: theme.palette.orange.main,
                }}>
                    <CardContent>
                        <Typography className={classes.numLicensesText} component="p">How many licenses do you need?</Typography>
                        <PlanTextField
                            type="number"
                            placeholder={'How many...'}
                            value={numLicenses}
                            onChange={e => setNumLicenses(parseInt(e.target.value))}
                            InputProps={{
                                inputProps: {
                                    min: minNumLicenses,
                                    max: maxNumLicenses,
                                },
                                classes: {
                                    input: classes.numLicensesInput,
                                }
                            }}
                            fullWidth
                            variant="outlined"
                            size="small"
                        />
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            onClick={e => onSelectPlan(numLicenses)}
                            disabled={!isNumLicensesValid()}
                            type="button" color="primary" variant="contained" size="medium"
                            className={classes.subButton}
                        >
                            Subscribe
                        </Button>
                    </CardActions>
                </Card>
            </Collapse>
        </div>
    );
}

export default PlanCard;
import React, { useState } from 'react'
import * as yup from 'yup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { countries } from "countries-list";
import { getUser } from 'store/user/userSelector';
import useValidForm from 'hooks/useValidForm';
import FormInput from 'components/formComponents/form-input';
import FormNumber from 'components/formComponents/form-number';
import FormSelect from 'components/formComponents/form-select';
import StyledModal from 'components/StyledModal';
import { EditEmail, EditJobTitle, EditName, EditPassword, EditPhoneNumber } from './edit-account';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                color: "#c8602a",
            }
        },
        accountInfoRoot: {
        },
        title: {
            fontSize: 24,
            fontWeight: 800,
            textTransform: "uppercase",
            marginBottom: theme.spacing(1),
        },
    })
);

interface AccountInfoFormData {
    firstName: string;
    lastName: string;
    companyName: string;
    jobTitle: string;
    country: string;
    phoneNumber: string;
    ext: string;
    email: string;
    password: string;
    websiteUrl?: boolean;
}

const validationSchema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    companyName: yup.string().required('Company name is required'),
    jobTitle: yup.string().required('Job title is required'),
    country: yup.string().required("Country is required"),
    phoneNumber: yup.string()
        .typeError('A valid phone number is required')
        .required('A valid phone number is required'),
    ext: yup.string(),
    email: yup.string().email('A valid email address is required.').required('A valid email address is required.'),
    password: yup.string().required('Password is required'),
    websiteUrl: yup.string().required('Website URL is required'),
})

const ListOfCountries = Object.entries(countries)
    .map(([countryCode, countryData]) => ({ label: countryData.name, value: countryCode }))
    .sort((a, b) => a.label > b.label ? 1 : -1);

const AccountInfo = () => {

    const classes = useStyles();
    const user = useSelector(getUser)
    const [showEditFormModal, setShowEditFormModal] = useState({
        open: false,

    });

    const onSubmitFunc = (formValues: AccountInfoFormData) => {
    }

    const { register, control, errors, onSubmit } = useValidForm<AccountInfoFormData>(onSubmitFunc, validationSchema, user);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h2" className={classes.title}>Account Information</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <EditName
                    firstName={user.firstName}
                    lastName={user.lastName}
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <EditEmail
                    email={user.email}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <EditPassword />

            </Grid>
            <Grid item xs={12} sm={9}>
                <EditPhoneNumber
                    phoneNumber={user.phoneNumber}
                    extension={user.ext}
                />

            </Grid>
            <Grid item xs={12} sm={3}>
                <FormInput
                    name="ext"
                    label="Ext."
                    placeholder="Ext."
                    inputRef={register}
                    error={errors.ext ? true : false}
                    helperText={errors.ext?.message}
                    readonly={true}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <EditJobTitle
                    jobTitle={user.jobTitle} />

            </Grid>
            <Grid item xs={12} sm={12}>
                <FormInput
                    name="companyName"
                    label="Company Name"
                    placeholder="Company Name"
                    inputRef={register}
                    error={errors.companyName ? true : false}
                    helperText={errors.companyName?.message}
                    readonly={true}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormSelect
                    label="Country"
                    name="country"
                    options={ListOfCountries}
                    control={control}
                    error={errors.country ? true : false}
                    helperText={errors.country?.message}
                    readonly={true}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormInput
                    name="websiteUrl"
                    label="Website URL"
                    placeholder="Website URL"
                    inputRef={register}
                    error={errors.websiteUrl ? true : false}
                    helperText={errors.websiteUrl?.message}
                    readonly={true}
                />
            </Grid>
        </Grid>


    )
}

export default AccountInfo;
import { TextField, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { FC } from "react";

export interface InputNumberProps {
    name: string;
    inputRef: any;
    label: string;
    placeholder?: string;
    error?: boolean;
    helperText?: string;
    fullWidth?: boolean;
    readonly?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {

        "& .MuiInputLabel-root": {
            position: "relative",
            padding: "0 0 0.5em 1.25em",
            color: theme.palette.orange.main,
            fontSize: 12,
            fontWeight: 700,
            transform: "none",

        },
    },
    rootInput: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid " + theme.palette.orange.main + "!important",
            borderRadius: "1em",

            "& legend": {
                width: 0,
            }
        },
    },
    input: {
        height: "2em",
        padding: "0 1em",

        "&::placeholder": {
            fontStyle: "italic",
        }
    },
    inputMultiline: {
        minHeight: "8.2em",
    },
}));

const FormNumber: FC<InputNumberProps> = ({
    name,
    inputRef,
    label,
    placeholder,
    error,
    helperText,
    fullWidth,
    readonly = false
}) => {

    const classes = useStyles();

    return (
        <TextField
            name={name}
            inputRef={inputRef}
            type="tel"
            label={label}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            fullWidth
            variant="outlined"
            size="small"
            disabled={readonly}
            className={classes.root + " " + (readonly ? 'readonly' : '')}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                classes: {
                    root: classes.rootInput,
                    input: classes.input,
                    inputMultiline: classes.inputMultiline,
                }
            }}
        />
    );
};

export default FormNumber;
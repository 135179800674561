import _ from "lodash";
import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { navigate } from "@reach/router"
import { Paths, CookieNames } from "utils/constants";
import Cookies from "js-cookie";
import AccountService from "services/accountService";
import { ValidationErrors, IApplicationState } from "store";
import { AccountInfo, AuthenticatedModel, BadgeDTO, BadgeResponseDTO, ForgotPasswordModel, ResetPasswordModel, TokenRequestDTO, UpdateAccountInfoDto, UpdateEmailDto, UpdatePasswordDto } from "external-api/account-api";
import { enqueueNotification } from "store/ui/uiSlice";
import { BadgeFormData } from "domain/group/badge/BadgeCardPreview";
import { CreateAccountFormData } from "domain/account/create-account";
import { getAccountIdFromToken, getExpirationDate, isExpired, removeToken, saveToken } from "utils/cookieHelper";
import { handleAuthErrors } from "utils/errorHandling";
import { ForgotPasswordFormData } from "pages/ForgotPassword";
import { ResetPasswordFormData } from "pages/ResetPassword";
import EcomService from "services/ecomService";

interface AuthData {
    email: string;
    password: string;
}

interface UserInfo {
    firstName: string;
    lastName: string;
    companyName: string;
    jobTitle: string;
    country: string;
    phoneNumber: string;
    ext: string;
    email: string;
    password: string;
    sendNews: boolean;
    badge: BadgeResponseDTO;
    planId: string
    authenticatedEmail: boolean
}

export interface IUserState {
    status: 'unauthenticated' | 'pending' | 'authenticated'
    info: UserInfo;
}

const initialState: IUserState = {
    status: 'unauthenticated',
    info: {
        firstName: "",
        lastName: "",
        companyName: "",
        jobTitle: "",
        country: "",
        phoneNumber: "",
        ext: "",
        email: "",
        password: "",
        sendNews: false,
        badge: {
            companyName: "",
            companyLogo: "",
            websiteUrl: ""
        },
        planId: "",
        authenticatedEmail: false
    }
}

//#region Update
const updateUserInfoAction = createAsyncThunk<
    undefined,
    UpdateAccountInfoDto,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/account/update-user-info",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());
                const service = new AccountService();
                const userInfo = await service.updateUserAsync(data);
                dispatch(updateAccount(userInfo));
                dispatch(enqueueNotification({
                    message: 'Successfully updated account information.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );


const updateEmailAction = createAsyncThunk<
    undefined,
    UpdateEmailDto,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/account/update-email",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());
                const service = new AccountService();
                await service.updateEmailAsync(data);
                dispatch(enqueueNotification({
                    message: 'A verification email has been sent. Please follow the directions to verify your new email.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));

            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const updatePasswordAction = createAsyncThunk<
    undefined,
    UpdatePasswordDto,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/account/update-password",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());
                const service = new AccountService();
                await service.updatePasswordAsync(data);

                dispatch(enqueueNotification({
                    message: 'Successfully updated password.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));

            }
            catch (err) {
                handleAuthErrors(dispatch, err, err.data.description);
                return rejectWithValue(err.body)
            }
        }
    );

const reVerifyEmailAction = createAsyncThunk<
    undefined,
    {
        token: string,
        email: string,
        oldEmail: string
    },
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/account/update-email",
        async ({ token, email, oldEmail }, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());
                const service = new AccountService();
                await service.reConfirmEmailAsync(token, email, oldEmail);

                dispatch(enqueueNotification({
                    message: 'Successfully Verified Email, please login again',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));

            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

//#endregion

const addBadgeAction = createAsyncThunk<
    BadgeDTO,
    BadgeFormData,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }
>("@@user/add-badge", async (formdata, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {

        await dispatch(tryRefreshTokenAction());
        const service = new AccountService();

        const resp = await service.addBadgeAsync(formdata.companyName, formdata.websiteUrl, "", formdata.companyLogo!);

        dispatch(enqueueNotification({
            message: 'Successfully downloaded badge.',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success'
            }
        }));

        dispatch(addBadge(resp));
        return resp;
    }
    catch (err) {
        dispatch(enqueueNotification({
            message: 'There was an error',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error'
            }
        }));

        return rejectWithValue(err.body)
    }
});

const loginAction = createAsyncThunk<
    AccountInfo,
    AuthData,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }
>("@@user/login", async (userAuthData: AuthData, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
        const service = new AccountService();
        const request: AuthenticatedModel = {
            accountName: userAuthData.email,
            password: userAuthData.password
        }

        removeToken();

        const resp = await service.loginAsync(request);

        dispatch(enqueueNotification({
            message: 'Successfully logged in',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success'
            }
        }));

        navigate(Paths.HOME);
        return resp;
    }
    catch (err) {
        dispatch(enqueueNotification({
            message: 'You have entered an incorrect username/password',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error'
            }
        }));

        return rejectWithValue(err.body)
    }
});

const createAccountAction = createAsyncThunk<
    {
        status: string,
        userInfo: AccountInfo
    },
    CreateAccountFormData,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }
>("@@user/create-account", async (userInfoData: CreateAccountFormData, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
        const service = new AccountService();
        const request: AccountInfo = {
            emailAddress: userInfoData.email,
            firstName: userInfoData.firstName,
            lastName: userInfoData.lastName,
            password: userInfoData.password,
            companyName: userInfoData.companyName,
            jobTitle: userInfoData.jobTitle,
            country: userInfoData.country,
            phoneNumber: userInfoData.phoneNumber,
            phoneNumberExt: userInfoData.ext,
            emailOptIn: userInfoData.sendNews,
            authenticatedEmail: false
        }
        const resp = await service.createAccountAsync(request);

        dispatch(enqueueNotification({
            message: 'Successfully created account',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success'
            }
        }));

        navigate(Paths.HOME);

        return {
            status: 'success',
            userInfo: resp
        }
    }
    catch (err) {
        handleAuthErrors(dispatch, err, 'There was an issue with creating the account');
        return rejectWithValue(err.body)
    }
});


const tryRefreshTokenAction = createAsyncThunk<
    undefined,
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }
>("@@user/refresh-token", async (data, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {

        let token = Cookies.get(CookieNames.ACCOUNT_TOKEN);
        let refreshToken = Cookies.get(CookieNames.REFRESH_TOKEN);

        if (token && isExpired(getExpirationDate(token))) {
            const accountService = new AccountService();
            const accountId = getAccountIdFromToken(token);
            if (accountId && refreshToken && token) {
                const authRequest: TokenRequestDTO = {
                    token,
                    refreshToken,
                    accountId
                };

                const updatedToken = await accountService.refreshToken(authRequest);
                if (updatedToken.success) {
                    saveToken(updatedToken);
                } else if (updatedToken.errors && updatedToken.errors.length > 0) {
                    dispatch(enqueueNotification({
                        message: updatedToken.errors[0] ?? "Your token Expired.",
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error'
                        }
                    }));
                }
            }
        }
    }
    catch (err) {
        return rejectWithValue(err.body)
    }
});

const forgotPasswordAction = createAsyncThunk<
    undefined,
    ForgotPasswordFormData,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/account/forgot-password",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                const accountService = new AccountService();
                const requestBody: ForgotPasswordModel = {
                    email: data.email
                };
                await accountService.forgotPasswordAsync(requestBody);

                dispatch(enqueueNotification({
                    message: 'We have sent an email to reset your password.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));

                navigate(Paths.LOGISTER);
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const resetPasswordAction = createAsyncThunk<
    undefined,
    ResetPasswordFormData,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/account/reset-password",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                const accountService = new AccountService();
                const requestBody: ResetPasswordModel = {
                    password: data.password,
                    confirmPassword: data.passwordConfirmation,
                    email: data.email,
                    token: data.token
                };

                await accountService.resetPasswordAsync(requestBody);

                dispatch(enqueueNotification({
                    message: 'Your password has been reset. Please login again',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));

                navigate(Paths.LOGISTER);
            }
            catch (err) {

                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );


const confirmEmailAction = createAsyncThunk<
    undefined,
    { token: string, email: string },
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/account/confirm-email",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                const accountService = new AccountService();
                await accountService.confirmEmail(data.email, data.token);

                dispatch(enqueueNotification({
                    message: 'Your email has been verified. Please login now.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));
            }
            catch (err) {

                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const getUserInfoAction = createAsyncThunk<
    AccountInfo,
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/account/get-user-info",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());
                const service = new AccountService();
                const userInfo = await service.getUserInfo();
                return userInfo;
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );


const createCustomerPortalAction = createAsyncThunk<
    string,
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/account/create-customer-portal",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());
                const service = new EcomService();
                const url = await service.createPortalSession();

                return url;
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

// -- For future use - not for MVP
const resendConfirmationAction = createAsyncThunk<
    undefined,
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/account/resend-email-confirmation",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());
                const service = new AccountService();
                const emailConfirmation = await service.resendConfirmationEmail();
                dispatch(enqueueNotification({
                    message: 'We have resent your verification email to your email.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );




const userSlice = createSlice({
    name: "@@user",
    initialState,
    reducers: {
        mockLogin: (state) => {
            state.status = "authenticated"
        },
        logout: (state) => {
            // From here we can take action only at this "counter" state
            // But, as we have taken care of this particular "logout" action
            // in rootReducer, we can use it to CLEAR the complete Redux Store's state
        },
        addBadge: (state, action: PayloadAction<BadgeDTO>) => {
            if (state.info) {
                state.info.badge.companyLogo = action.payload.companyLogo ?? "";
                state.info.badge.companyName = action.payload.companyName ?? "";
                state.info.badge.websiteUrl = action.payload.websiteUrl ?? "";
            }
        },
        updateAccount: (state, action: PayloadAction<AccountInfo>) => {
            if (state.info) {
                state.info = {
                    companyName: action.payload.companyName,
                    jobTitle: action.payload.jobTitle ?? "",
                    country: action.payload.country,
                    phoneNumber: action.payload.phoneNumber,
                    ext: action.payload.phoneNumberExt!,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    email: action.payload.emailAddress,
                    sendNews: action.payload.emailOptIn,
                    password: "",
                    badge: {
                        companyName: action.payload.badge?.companyName ?? "",
                        companyLogo: action.payload.badge?.companyLogo ?? "",
                        websiteUrl: action.payload.badge?.websiteUrl ?? ""
                    },
                    planId: action.payload.planId ?? "",
                    authenticatedEmail: action.payload.authenticatedEmail ?? false
                };
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(loginAction.pending, (state, action) => {
            state.status = "pending";
        })
        builder.addCase(loginAction.fulfilled, (state, action) => {

            state.status = "authenticated";
            state.info = {
                companyName: action.payload.companyName,
                jobTitle: action.payload.jobTitle ?? "",
                country: action.payload.country,
                phoneNumber: action.payload.phoneNumber,
                ext: action.payload.phoneNumberExt!,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.emailAddress,
                sendNews: action.payload.emailOptIn,
                password: "",
                badge: {
                    companyName: action.payload.badge?.companyName ?? "",
                    companyLogo: action.payload.badge?.companyLogo ?? "",
                    websiteUrl: action.payload.badge?.websiteUrl ?? ""
                },
                planId: action.payload.planId ?? "",
                authenticatedEmail: action.payload.authenticatedEmail ?? false
            };

        })
        builder.addCase(getUserInfoAction.fulfilled, (state, action) => {
            state.info = {
                ...state.info,
                ...action.payload,
            }
        })
        builder.addCase(loginAction.rejected, (state, action) => {
            state.status = "unauthenticated";
        })
        builder.addCase(createAccountAction.pending, (state, action) => {
            state.status = "pending";
        })
        builder.addCase(createAccountAction.fulfilled, (state, action) => {
            state.status = "authenticated";
            state.info = {
                companyName: action.payload.userInfo.companyName,
                jobTitle: action.payload.userInfo.jobTitle ?? "",
                country: action.payload.userInfo.country,
                phoneNumber: action.payload.userInfo.phoneNumber,
                ext: action.payload.userInfo.phoneNumberExt!,
                firstName: action.payload.userInfo.firstName,
                lastName: action.payload.userInfo.lastName,
                email: action.payload.userInfo.emailAddress,
                sendNews: action.payload.userInfo.emailOptIn,
                password: "",
                badge: {
                    companyName: "",
                    companyLogo: "",
                    websiteUrl: ""
                },
                planId: "",
                authenticatedEmail: false
            };

        })
        builder.addCase(createAccountAction.rejected, (state, action) => {
            state.status = "unauthenticated";
        })
        builder.addCase(createCustomerPortalAction.fulfilled, (state, action) => {
        })
    }
});

export {
    loginAction,
    createAccountAction,
    addBadgeAction,
    tryRefreshTokenAction,
    forgotPasswordAction,
    resetPasswordAction,
    confirmEmailAction,
    getUserInfoAction,
    resendConfirmationAction,
    createCustomerPortalAction,
    updateUserInfoAction,
    updateEmailAction,
    reVerifyEmailAction,
    updatePasswordAction
}

export const {
    logout,
    mockLogin,
    addBadge,
    updateAccount
} = userSlice.actions
export default userSlice.reducer;

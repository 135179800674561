import React from 'react';
import { useSelector } from 'react-redux';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import NotificationBadge from "components/NotificationBadge";
import { getPlan, hasPlan as hasPlanSelector } from 'store/plan/planSelectors';
import { getGroupList } from 'store/group/groupSelectors';
import { getIsPlanUpgradeCompleted } from 'store/ui/uiSelectors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subscriptionInfoRoot: {
        },
        title: {
            fontSize: "20pt",
            fontWeight: 700,
            textTransform: "uppercase",
            marginBottom: theme.spacing(1),
        },
        fieldLabel: {
            fontSize: 14,
            fontWeight: 700,
            color: theme.palette.orange.main,
            paddingTop: theme.spacing(2)
        },
        fieldValue: {
            color: theme.palette.orange.main,
            fontSize: 28,
            fontWeight: 900,
            lineHeight: 1.2,
        },
        link: {
            fontSize: 16,
            fontWeight: 700,
            textTransform: "uppercase",
            padding: "8px 0px",
            "& a": {
                color: theme.palette.orange.main,
                fontWeight: 700,
                textDecoration: "none",
                "&:hover, &:focus": {
                    textDecoration: "underline",
                }
            }
        },
    })
);

interface IProps {
}

const SubscriptionInfo = ({ }: IProps) => {

    const classes = useStyles();

    const hasPlan = useSelector(hasPlanSelector);
    const plan = useSelector(getPlan);
    const groups = useSelector(getGroupList);
    const planUpgraded = useSelector(getIsPlanUpgradeCompleted);
    // const planDowngraded = useSelector(getIsPlanDowngraded);

    const totalNumberOfSlotsUsed = groups.map(group => group.numberOfSlotsUsed).reduce((a, b) => a + b, 0);

    return (
        <div className={classes.subscriptionInfoRoot}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {hasPlan &&
                        <>
                            <div className={classes.fieldLabel}>Licenses Purchased:{planUpgraded && <NotificationBadge />}</div>
                            <div className={classes.fieldValue}>{plan.price === 0 ? "Free (5 licenses)" : (plan.currentSubscription?.quantity ?? plan.features?.maxNumEmailUsers)}</div>
                            <div className={classes.fieldLabel}>Licenses Available:</div>
                            <div className={classes.fieldValue}>{(plan.currentSubscription?.quantity ?? plan.features?.maxNumEmailUsers) ? ((plan.currentSubscription?.quantity ?? plan.features?.maxNumEmailUsers ?? 0) - totalNumberOfSlotsUsed) : 0}</div>
                        </>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default SubscriptionInfo;
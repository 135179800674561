
import { enqueueNotification } from "store/ui/uiSlice";

export function handleAuthErrors(dispatch: any, error: any, customErrorMessage?: string) {
    const errorResponse = error;
    if (errorResponse) {

        switch (errorResponse.status) {
            case 401:
                dispatch(enqueueNotification({
                    message: 'You were logged out due to inactivity. Please log in again to resume your session.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                }));
                break;
            case 409: {
                dispatch(enqueueNotification({
                    message: 'There is an account with this email already.  Please change your email.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                }));
                break;
            }
            default:
                dispatch(enqueueNotification({
                    message: customErrorMessage ?? "An error has occurred",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                }));
                break;
        }
    }
}

import React from "react";

import { Grid, withStyles } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';

const Card = withStyles({
    root: {
        borderRadius: 12,
        margin: "auto",
        transition: "0.3s",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.5)",
        "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
        height: "100%",
        width: "100%",
        padding: "20px",
        marginBottom: "10px"
    }
})(MuiCard);

interface IProps {
    children: any
    onClick?: (arg: any) => any
}
const CustomCard = ({ children, onClick }: IProps) => {
    return (
        <Card onClick={onClick}>
            <Grid container spacing={2}>
                {children}
            </Grid>
        </Card>
    )
}

export default CustomCard;
import React, { useState } from "react";
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import MuiCardHeader from '@material-ui/core/CardHeader';
import MuiCardContent from '@material-ui/core/CardContent';
import ProgressBar from "./ProgressBar";
import DatePagination from "./DatePagination";
import CourseCircleProgressBar from "./CourseCircleProgressBar";
import theme from "../../../theme";

const Card = withStyles({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        margin: "0 6px",
        borderRadius: "12px",
        boxShadow: "8px 10px 12px 0 rgba(0,0,0,0.25)",
        overflow: "hidden",
    }
})(MuiCard);

const CardHeader = withStyles({
    root: {
        padding: 10,
        color: "#fff",
        background: theme.palette.orange.main,
        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.25)",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: "12px",
        fontWeight: 300,
    },
    subheader: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: "18px",
        fontWeight: 900,
        textTransform: "uppercase",
    },
})(MuiCardHeader);

const CardContent = withStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        padding: "30px 56px",
    },
})(MuiCardContent);

const Box = withStyles({
    root: {
        margin: "10px 0",
        padding: 0,
        textAlign: "center",
    }
})(MuiCardContent);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        circleProgress: {
            width: 150,
            margin: "0 0 10px 0",
        },
        progressBars:{
            marginTop: 'auto',
        },
        progressBarLabel: {
            fontWeight: 700,
        }
    })
);


const usePrevCurrentCourses = (
    courses: {
        date: Date;
        passed: number;
        completed: number;
        numberOfParticipants: number;
    }[]
) => {
    const sortedCourses = courses.map(
        courseData => ({ ...courseData, date: new Date(courseData.date) })
    ).sort(
        (a, b) => b.date.getTime() - a.date.getTime()
    )
    return {
        currentCourse: sortedCourses[0],
        prevCourse: sortedCourses.slice(1)
    }
}

interface IProps {
    title: string;
    subheader: string;
    courses: {
        date: Date;
        passed: number;
        completed: number;
        numberOfParticipants: number;
    }[];
}

const CourseCard = ({ title, subheader, courses }: IProps) => {
    const classes = useStyles();

    const { currentCourse } = usePrevCurrentCourses(courses)

    return (
        <Card>
            <CardHeader
                title={title}
                subheader={subheader}
            />
            <CardContent>
                <Box className={classes.circleProgress}>
                    <CourseCircleProgressBar
                        completed={currentCourse.completed}
                        total={currentCourse.numberOfParticipants}
                    />
                </Box>
                <Box className={classes.progressBars}>
                    <Box className={classes.progressBarLabel}>Pass</Box>
                    <ProgressBar value={currentCourse.passed} total={currentCourse.completed} />
                    <Box className={classes.progressBarLabel}>Fail</Box>
                    <ProgressBar value={currentCourse.completed - currentCourse.passed} total={currentCourse.completed} />
                </Box>
            </CardContent>
        </Card>
    )
}

export default CourseCard;
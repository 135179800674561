import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { getPlan } from "store/plan/planSelectors";
import CustomStepper from "../common/CustomStepper";
import { useAddGroupStepper } from "./useAddGroupStepper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupRoot: {
            width: "auto",
            margin: `${theme.spacing(2)}px 64px ${theme.spacing(12)}px 64px`,

            "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "100vw",
                background: "linear-gradient(to bottom, #e6e6e6, #fff 520px)",
                borderTop: "1px solid" + theme.palette.shades.grey,
                zIndex: -1,
            }
        },
        heading: {
            fontWeight: 500,
            textTransform: "uppercase",
        },
    })
);

interface IProps {
}

const AddGroupStepper = ({ }: IProps) => {
    const classes = useStyles();
    const plan = useSelector(getPlan);

    const { currentComponent, labels, activeStep, setStep } = useAddGroupStepper();
    
    return (
        <Grid container className={classes.groupRoot}>
            <Grid item xs={12}>
                <Typography variant="h1" className={classes.heading}>Groups</Typography>
            </Grid>
            <Grid item lg={3} xs={12}>
                <CustomStepper
                    setActiveStep={setStep}
                    steps={labels}
                    activeStep={activeStep}
                />
            </Grid>
            <Grid item lg={9} xs={12}>
                {currentComponent}
            </Grid>
        </Grid>
    );
}

export default AddGroupStepper;
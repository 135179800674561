
import useValidForm from "hooks/useValidForm";
import { IPlanConfiguration, ITestConfiguration } from "../../types";
import * as yup from 'yup';
import { useEffect } from "react";
import { useWatch } from "react-hook-form";

const validationSchema = yup.object().shape({
})

interface IProps {
    onSubmitFunc: (data: IPlanConfiguration) => void
    data: IPlanConfiguration
}

export const usePlanConfigurationForm = ({ onSubmitFunc, data }: IProps) => {

    useEffect(() => {
        form.reset(data);
    }, [data]);

    const defaultValues: IPlanConfiguration = {
        id: "",
        name: "",
        testConfiguration: [],
        courseConfiguration: [],
        groupId: "",
        planId: ""
    }

    const form = useValidForm<IPlanConfiguration>(onSubmitFunc, validationSchema, defaultValues, false);
    const selectedTests = useWatch<ITestConfiguration[]>({
        control: form.control,
        name: "testConfiguration"
    });

    return {
        form,
        selectedTests
    }
}

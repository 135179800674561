import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PersonIcon from '@material-ui/icons/Person';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/user/userSelector';
import { navigate } from '@reach/router';
import { Paths } from 'utils/constants';
import { logout } from 'store/user/userSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerMenuRoot: {
            display: 'flex',
            borderLeft: "1px solid #ccc",
        },
        button: {
            marginRight: "-24px",
            padding: "25px 40px 6px 40px",
            color: "#000",
            fontSize: "16px",
            fontWeight: 700,
            textTransform: "uppercase",
            borderRadius: 0,

            "&:hover, &:focus": {
                color: theme.palette.primary.main,
                background: "none",
            }
        },
        icon: {
            fontSize: "1.75em !important",
            stroke: 'currentColor',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
        },
        paper: {
            marginRight: theme.spacing(2),
        },
    }),
);

const HeaderMenu = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const dispatch = useDispatch();
    const user = useSelector(getUser)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.headerMenuRoot}>
            <Button
                className={classes.button}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                endIcon={<ArrowDropDownIcon className={classes.icon} />}
            >{user?.firstName ? user?.firstName : "Mock Login"}</Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-end">
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem
                                        onClick={(e) => {
                                            handleClose(e);
                                            navigate(Paths.MYACCOUNT);
                                        }
                                        }>My account</MenuItem>
                                    <MenuItem
                                        onClick={async (e) => {
                                            handleClose(e);
                                            await dispatch(logout());
                                        }
                                        }>Logout</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

export default HeaderMenu;
import { useState } from "react";
import { GetSurveyDetails } from "types/GetSurveyDetails";

export const useTestSelectionData = (data: GetSurveyDetails) => {
    const [activeTestIndex, setActiveTest] = useState(0);
    const tests = (data?.surveyCollection && data?.surveyCollection.items) ?? [];
    const activeTestContent = tests[activeTestIndex];


    return {
        activeTestIndex,
        setActiveTest,
        tests,
        activeTestContent,
        activeTestId: activeTestContent?.sys?.id ?? ""
    }
}
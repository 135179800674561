import React from 'react'
import { gql } from "@apollo/client";
import { CardActionArea, CardContent, createStyles, IconButton, makeStyles, Modal, Typography } from '@material-ui/core';
import useContentfulQuery from 'hooks/useContentfulQuery';
import ContentfulLoading from 'components/contentful-loading';
import ContentfulError from 'components/contentful-error';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CustomCard from 'components/CustomCard';
import CourseModulePreview from './CourseModulePreview';
import { GetCourseDetails, GetCourseDetailsVariables } from 'types/GetCourseDetails';
import { useCoursePreviewModal } from './useCoursePreviewModal';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    close: {
      position: "absolute",
      top: 8,
      right: 8,
      padding: 1,
      color: "#fff",
      fontWeight: 700,
      background: "#ccc",
      border: "2px solid #fff",
      boxShadow: "0 4px 2px rgba(0,0,0,0.25)",

      "&:hover, &:focus": {
        background: theme.palette.shades.grey,
      }
    },
  })
);


const GET_COURSE_DETAILS = gql`
  query GetCourseDetails($preview: Boolean!, $courseLimit: Int!, $courseModuleLimit: Int!, $courseIds: [String!]!) {
    courseCollection(preview: $preview, limit: $courseLimit, where: {sys: {id_in: $courseIds}}) {
    items {
      sys {
        id
      }
      title
      courseTitle
      slug
      category {
        label
        sys {
          id
        }
      }
      courseModulesCollection(limit: $courseModuleLimit) {
        items {
          sys {
            id
          }
          title
          hrAdminPreviewContent {
            json
            links {
              assets {
                block {
                  ... on Asset {
                    sys {
                      id
                    }
                    title
                    contentType
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }    
`;

interface IProps {
  courseId: string
  categoryId: string
  isSelected: boolean
  addCourse: (arg: any) => any
  removeCourse: (arg: any) => any
}

// -- get course details
const CourseDetails = ({ courseId, categoryId, isSelected, addCourse, removeCourse }: IProps) => {
  const { loading, error, data } = useContentfulQuery<GetCourseDetails, GetCourseDetailsVariables>(GET_COURSE_DETAILS, { courseLimit: 5, courseModuleLimit: 10, courseIds: [courseId] });
  const { open, handleOpen, handleClose } = useCoursePreviewModal();
  const classes = useStyles();

  function onModalOpen() {
    addCourse({ courseId, categoryId });
    handleOpen();
  }

  function onModalClose() {
    removeCourse(courseId);
    handleClose();
  }

  if (loading) return <ContentfulLoading />;
  if (error) return <ContentfulError />;
  if (!data) return null;

  return (
    <>
      <CustomCard onClick={onModalOpen}>
        <CardActionArea>
          <CardContent style={{ textAlign: 'center' }}>
            <Typography component="h2">{data?.courseCollection?.items && data?.courseCollection?.items[0]?.courseTitle}</Typography>
            <IconButton aria-label="add">
              {isSelected ?
                <CheckCircleIcon style={{ fontSize: "3.5rem", color: "green" }} />
                :
                <AddCircleOutlineIcon color="error" style={{ fontSize: "3.5rem" }} />
              }
            </IconButton>
          </CardContent>
        </CardActionArea>
      </CustomCard>

      <Modal
        open={open}
        onClose={onModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CourseModulePreview data={data} onChildClick={onModalClose} />
      </Modal>
    </>
  )
}

export default CourseDetails;
import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import theme from "theme";

const useStyles = makeStyles<Theme, { color: string }>(theme =>
    createStyles({
        content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "75%",
            textAlign: "center",
            transform: "translate(-50%,-50%)",
        },
        textCurrent: {
            color: "#000",
            fontFamily: "inherit",
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 0.8,
        },
        textPercent: {
            color: ({ color }) => color,
            marginTop: "10px",
            fontFamily: "inherit",
            fontSize: 38,
            fontWeight: 900,
            lineHeight: 0.8,
        },
        textContext: {
            marginTop: "7px",
            color: "#000",
            fontFamily: "inherit",
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 0.8,
        },
    })
);

interface IProps {
    completed: number;
    total: number
}

const CourseCircleProgressBar = ({ completed, total }: IProps) => {
    const color = theme.palette.orange.main as string;
    const classes = useStyles({ color });

    return (
        <>
            <CircularProgressbarWithChildren
                value={completed}
                maxValue={total}
                strokeWidth={10}
                styles={{
                    path: {
                        stroke: color,
                    },
                    trail: {
                        stroke: "lightgray",
                        strokeWidth: "3"
                    }
                }}
            >
                <div className={classes.content}>
                    <Typography className={classes.textCurrent} component="div">Completed</Typography>
                    <Typography className={classes.textPercent} component="div">{completed}</Typography>
                    <Typography className={classes.textContext} component="div">Out of {total}</Typography>
                </div>
            </CircularProgressbarWithChildren>
        </>
    )
}

export default CourseCircleProgressBar;
import React from 'react'
import * as yup from 'yup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { countries } from "countries-list";
import { getUser } from 'store/user/userSelector';
import useValidForm from 'hooks/useValidForm';
import FormInput from 'components/formComponents/form-input';
import FormNumber from 'components/formComponents/form-number';
import FormSelect from 'components/formComponents/form-select';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

    })
);

const EditCompanyName = () => {

    const classes = useStyles();
    const user = useSelector(getUser)


    return (
        <>
        </>
    )
}

export default EditCompanyName;
import { navigate } from "@reach/router";
import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import { enqueueNotification } from "store/ui/uiSlice";
import { logout } from "store/user/userSlice";
import { Paths } from "utils/constants";

const interceptor = (store: any) => {
    axios.interceptors.request.use(
        (conf) => {
            // you can add some information before send it.
            // conf.headers['Auth'] = 'some token'
            return conf;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        (next) => {
            return Promise.resolve(next);
        },
        (error) => {
            // You can handle error here and trigger warning message without get in the code inside
            if (error.response.status === 401 || error.response.status === 403) {
                store.dispatch(logout());
                navigate(Paths.LOGISTER);
            }
            return Promise.reject(error.response);
        }
    );
};

export default {
    axios,
    interceptor
};
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Container, Tab, Tabs, Toolbar } from '@material-ui/core';
import Login from 'domain/account/login';
import CreateAccount from 'domain/account/create-account';
import { RouteComponentProps } from '@reach/router';
import TabPanel from 'components/TabPanel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            height: "100%",
            minHeight: "100vh",
        },

        // Content
        header: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            background: "none",
            zIndex: 100,
        },
        headerWrapper: {
            height: "90px",
            padding: 0,
            color: "#000",
        },
        logo: {
            display: "block",
        },
        content: {
            flex: "1 1 auto",
            alignItems: "stretch",
            flexWrap: "nowrap",
            flexDirection: "column",
            height: '100vh',

            [theme.breakpoints.up('md')]: {
                flexDirection: "row",
            },
        },
        contentMain: {
            flex: "1 1 auto",
            position: "relative",
            minHeight: "200px",
            overflow: "hidden",
        },
        image: {
            position: "absolute",
            display: "block",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "50% 50%",
        },
        contentForm: {
            flex: "1 1 auto",
            padding: "50px 20px",
            background: "#fff",
            overflow: 'auto',

            [theme.breakpoints.up('md')]: {
                padding: "90px 40px",
            },
        },
        tabs: {
            margin: "0 0 40px 0",
        },
        tab: {
            fontWeight: 700,
        },
    }),
);

interface IProps extends RouteComponentProps {

}

const Logister = ({ }: IProps) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(1);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };


    return (
        <main className={classes.main}>
            <header className={classes.header}>
                <Container>
                    <Toolbar className={classes.headerWrapper}>
                        <img className={classes.logo} src="/images/general/logo.svg" alt="Bias Proof logo" />
                    </Toolbar>
                </Container>
            </header>
            <Grid container className={classes.content}>
                <Grid className={classes.contentMain} item md={6} lg={8}>
                    <img className={classes.image} src="/images/general/logister.jpg" alt="Welcome!" />
                </Grid>
                <Grid className={classes.contentForm} item md={6} lg={4}>

                    <Tabs
                        value={value}
                        onChange={handleChange}
                        className={classes.tabs}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        aria-label="tabs component"
                    >
                        <Tab className={classes.tab} label="Sign In" />
                        <Tab className={classes.tab} label="Register" />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <Login />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <CreateAccount />
                    </TabPanel>

                </Grid>
            </Grid>
        </main>
    );
}

export default Logister;
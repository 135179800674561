import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import theme from "theme";

const useStyles = makeStyles<Theme, { lighterColor: string, darkerColor: string }>(theme =>
    createStyles({
        content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "75%",
            textAlign: "center",
            transform: "translate(-50%,-50%)",
        },
        textCurrent: {
            color: ({ lighterColor }) => lighterColor,
            fontFamily: "inherit",
            fontSize: 12,
            fontWeight: 300,
            lineHeight: 0.8,
        },
        textPercent: {
            color: ({ lighterColor }) => lighterColor,
            marginTop: "10px",
            fontFamily: "inherit",
            fontSize: 38,
            fontWeight: 900,
            lineHeight: 0.8,
        },
        textContext: {
            marginTop: "7px",
            color: "#000",
            fontFamily: "inherit",
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 0.8,
        },
        textPosDifference: {
            marginTop: "10px",
            color: ({ lighterColor }) => lighterColor,
            fontFamily: "inherit",
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 0.8,
        },
        textNegDifference: {
            marginTop: "10px",
            color: ({ darkerColor }) => darkerColor,
            fontFamily: "inherit",
            fontSize: 12,
            fontWeight: 800,
            lineHeight: 0.8,
        },
    })
);

interface IProps {
    currentScore: number | null;
    prevScore:
    | number // prevScore exists and is a valid number
    | null  // prevScore exists but is not a valid number
    | undefined; // prevScore does not exist
}

const ScoreCircleProgressBar = ({ currentScore, prevScore }: IProps) => {
    const lighterColor = theme.palette.orange.main as string;
    const darkerColor = theme.palette.orange.dark as string;
    const classes = useStyles({ lighterColor, darkerColor });

    const currentScoreValue = Number(currentScore);
    const prevScoreValue = prevScore !== undefined ? Number(prevScore) : 0;

    return (
        <>
            {/* Background path */}
            <CircularProgressbarWithChildren
                value={currentScore !== null ? (currentScoreValue > prevScoreValue ? currentScoreValue : prevScoreValue) : 0}
                strokeWidth={10}
                styles={{
                    path: {
                        stroke: currentScoreValue > prevScoreValue ? lighterColor : darkerColor,
                    },
                    trail: {
                        stroke: "lightgray",
                        strokeWidth: "3"
                    }
                }}
            >
                <div className={classes.content}>
                    <Typography className={classes.textPercent} component="div">{(currentScore !== null) ? currentScoreValue : "n/a"}</Typography>
                    <Typography className={classes.textContext} component="div">Out of 100</Typography>
                    {
                        (currentScore !== null) && (prevScore !== null) && (prevScore !== undefined) && (
                            (currentScore - prevScore) > 0 ?
                                <Typography className={classes.textPosDifference} component="div">+{(currentScore - prevScore)}</Typography>
                                :
                                <Typography className={classes.textNegDifference} component="div">{(currentScore - prevScore)}</Typography>
                        )
                    }
                    {
                        (currentScore !== null) && (prevScore === null) &&
                        <Typography className={classes.textNegDifference} component="div">{'n/a'}</Typography>
                    }
                </div>
                {/* Foreground path */}
                <CircularProgressbar
                    value={currentScoreValue > prevScoreValue ? prevScoreValue : currentScoreValue}
                    strokeWidth={10}
                    styles={{
                        path: {
                            stroke: currentScoreValue > prevScoreValue ? darkerColor : lighterColor,
                        },
                        trail: {
                            stroke: "transparent"
                        }
                    }}
                />

            </CircularProgressbarWithChildren>
        </>
    )
}

export default ScoreCircleProgressBar;

import ParticipantConfiguration from "domain/participant/ParticipantConfiguration";
import { RawDraftContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroup, updateGroupOverview, updateGroupParticipants } from "store/group/groupSlice";
import { updatePlanConfiguration } from "store/plan/planSlice";
import { getCurrentGroupAnonLinks, getCurrentGroupIsAnonymous, getCurrentGroupOverview, getCurrentGroupParticipants, getCurrentGroupPlanConfiguration } from "store/ui/uiSelectors";
import { clearGroup, setGroupToEdit, updateOverviewSectionAsync, updateParticipantsSectionAsync, updatePlanConfigurationSectionAsync } from "store/ui/uiSlice";
import { GroupOverview, GroupPlanConfiguration } from "../common";
import { IEmailConfiguration, IGroupOverviewEmailConfiguration, IParticipantsConfiguration, IPlanConfiguration } from "../types";
import { useMaxAvailableSlot } from "../common/useMaxAvailableSlot";
import { getPlan } from "store/plan/planSelectors";
import { IDateTime } from "../common/groupOverview/GroupOverview";

export const useEditGroupStepper = (groupId: string) => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [ready, setReady] = useState(false);

    const groupOverviewValues = useSelector(getCurrentGroupOverview);
    const participantsValues = useSelector(getCurrentGroupParticipants);
    const anonLinksValues = useSelector(getCurrentGroupAnonLinks);
    const isAnonymous = useSelector(getCurrentGroupIsAnonymous);
    const planConfigurationValues = useSelector(getCurrentGroupPlanConfiguration);
    const planValues = useSelector(getPlan);
    const maxAvailableSlot = useMaxAvailableSlot()

    useEffect(() => {
        async function getGroupData() {
            await dispatch(getGroup(groupId));
            await dispatch(setGroupToEdit(groupId))
            setReady(true);
        }
        getGroupData();
    }, [groupId])

    const stepperData = [
        {
            title: "Overview",
            component: <GroupOverview
                data={groupOverviewValues}
                groupId={groupId}
                handleSubmit={editGroupOverview}
                nextStep={nextStep}
                prevStep={prevStep}
            />
        },
        {
            title: "Configure Participants",
            component: <ParticipantConfiguration
                groupId={groupId}
                emails={participantsValues}
                links={anonLinksValues}
                allowAnonymousUsers={isAnonymous && (planValues.features?.allowAnonymousUsers ?? false)}
                allowEmailUsers={!isAnonymous && (planValues.features?.allowEmailUsers ?? false)}
                maxNumAnonymousUsers={planValues.features?.maxNumAnonymousUsers ?? 0}
                maxNumEmailUsers={planValues.features?.maxNumEmailUsers ?? 0}
                numAvailAnonymousUsers={undefined}
                numAvailEmailUsers={maxAvailableSlot}
                handleSubmit={editGroupParticipants}
                nextStep={nextStep}
                prevStep={prevStep}
            />,
        },
        {
            title: "Configure Tests + Training",
            component: <GroupPlanConfiguration
                data={planConfigurationValues!}
                groupId={groupId}
                handleSubmit={editPlanConfiguration}
                nextStep={nextStep}
                prevStep={prevStep}
            />,
        }
    ];

    function setStep(step: number) {
        setActiveStep(step);
    }

    function nextStep() {
        if (activeStep < stepperData.length - 1) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    function prevStep() {
        if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    async function editGroupOverview(data: IGroupOverviewEmailConfiguration) {
        const invitationBodyMarkup = draftToHtml(
            data?.emailConfiguration?.invitationEmailBody as unknown as RawDraftContentState,
        );
        const reminderBodymarkup = draftToHtml(
            data?.emailConfiguration?.reminderEmailBody as unknown as RawDraftContentState,
        );

        const invitationDate = data.emailConfiguration?.invitationDates.sort(
            (a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
        ).map((date: IDateTime) => {
            return {
                dateTime: moment(date.dateTime).toISOString() ?? "",
                initialLoaded: date.initialLoaded ?? false
            }
        }) as IDateTime[];

        const dataSubmission: IGroupOverviewEmailConfiguration = {
            ...data,
            emailConfiguration: {
                ...data?.emailConfiguration,
                invitationEmailBody: invitationBodyMarkup,
                reminderEmailBody: reminderBodymarkup,
                invitationDates: invitationDate
            } as IEmailConfiguration
        }

        await dispatch(updateOverviewSectionAsync(dataSubmission));
        await dispatch(updateGroupOverview());
    }

    async function editGroupParticipants(data: IParticipantsConfiguration) {
        await dispatch(updateParticipantsSectionAsync(data));
        await dispatch(updateGroupParticipants());
    }

    async function editPlanConfiguration(data: IPlanConfiguration) {
        await dispatch(updatePlanConfigurationSectionAsync(data));
        await dispatch(updatePlanConfiguration());
    }

    return {
        currentComponent: stepperData[activeStep].component,
        labels: stepperData.map(x => x.title),
        activeStep,
        setStep,
        ready
    }
}

import { createSelector } from "@reduxjs/toolkit";
import { IUserState } from "./userSlice";

const getId = (_: any, id: any) => id;
const _getUser = (state: { user: IUserState }) => state.user;

export const getUser = createSelector(
    [_getUser],
    (user: IUserState) => {
        return user.info;
    }
)

export const getCountry = createSelector(
    [_getUser],
    (user: IUserState) => {
        return user.info.country;
    }
)

export const isAuthenticated = createSelector(
    [_getUser],
    (user: IUserState) => {
        return user.status === 'authenticated';
    }
)

export const isEmailConfirmed = createSelector(
    [_getUser],
    (user: IUserState) => {
        return user.info.authenticatedEmail;
    }
)

export const getBadge = createSelector(
    [_getUser],
    (user: IUserState) => {
        return user.info?.badge;
    }
)
import { navigate } from "@reach/router";
import {
    createAsyncThunk,
    createSlice, PayloadAction,
} from "@reduxjs/toolkit";
import { loadStripe } from "@stripe/stripe-js";
import { ICourseConfiguration, IGroup, IGroupOverviewEmailConfiguration, IParticipantsConfiguration, IPlanConfiguration, ITestConfiguration } from "domain/group/types";
import { ContactUsFormData } from "domain/support/ContactUsForm";
import { BadgeResponseDTO } from "external-api/account-api";
import { CreateCheckoutSessionRequest, EcomPlanDTO, SupportDTO } from "external-api/license-api";
import AccountService from "services/accountService";
import EcomService from "services/ecomService";
import SupportService from "services/supportService";
import { ValidationErrors, IApplicationState } from "store";
import { EmailTexts } from "utils/constants";
import { handleAuthErrors } from "utils/errorHandling";
import { Paths } from "utils/constants";
import { tryRefreshTokenAction } from "store/user/userSlice";
import ProductService from "services/productService";
import { IDateTime } from "domain/group/common/groupOverview/GroupOverview";

export interface IUiCurrentPlan {
    change: "NONE"
    | "PLAN_SELECTION_STARTED" | "PLAN_SELECTION_COMPLETED"
    | "UPGRADE_STARTED" | "UPGRADE_COMPLETED"
    | "DOWNGRADE_STARTED" | "DOWNGRADE_COMPLETED"
    planUpgradeForMoreEmails: "NOT_NEEDED" | "REQUESTED" | "CANCELED" | "COMPLETED"
}

export interface IUiState {
    group: IGroup
    currentPlan: IUiCurrentPlan
    plans: EcomPlanDTO[]
    logos: BadgeResponseDTO[]
    notifications: any[]
}

const checkoutAction = createAsyncThunk<
    {
        status: string,
        sessionId: string
    },
    { priceId: string, quantity: number },
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@ui/checkout",
        async ({ priceId, quantity }, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {

                await dispatch(tryRefreshTokenAction());
                const stripeKey = `${process.env.REACT_APP_STRIPE_KEY}`;

                const ecomService = new EcomService();
                const body: CreateCheckoutSessionRequest = {
                    priceId: priceId,
                    quantity: quantity
                };

                const stripePromise = loadStripe(stripeKey);

                const stripe = await stripePromise;
                const resp = await ecomService.createCheckoutSession(body);

                if (stripe && resp) {
                    const stripeRes = await stripe.redirectToCheckout({ sessionId: resp });
                }

                return {
                    status: 'success',
                    sessionId: resp
                }
            }
            catch (err) {
                handleAuthErrors(dispatch, err, 'There was an issue with creating checkout session.');
                return rejectWithValue(err.body)
            }
        });

const checkoutFreeTierAction = createAsyncThunk<
    undefined,
    { priceId: string, quantity: number },
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>
    ("@@ui/checkout-free", async ({ priceId, quantity }, thunkAPI) => {
        const { rejectWithValue, dispatch } = thunkAPI;
        try {
            await dispatch(tryRefreshTokenAction());
            const stripeKey = `${process.env.REACT_APP_STRIPE_KEY}`;
            const ecomService = new EcomService();
            const body: CreateCheckoutSessionRequest = {
                priceId: priceId,
                quantity: quantity
            };

            await ecomService.createFreeTier(body);
            navigate(Paths.PAYMENT_SUCCESS);
        }
        catch (err) {
            handleAuthErrors(dispatch, err, 'There was an issue with creating free tier.');
            navigate(Paths.PLANS);
            return rejectWithValue(err.body);
        }
    });

const submitSupportTicketAction = createAsyncThunk<
    {
        status: string,
        supportTicketInfo: SupportDTO
    },
    ContactUsFormData,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }
>("@@ui/submit-support-ticket", async (supportTicketData: ContactUsFormData, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
        await dispatch(tryRefreshTokenAction());
        const supportRequest: SupportDTO = {
            email: supportTicketData.email,
            comment: supportTicketData.description,
            name: supportTicketData.name,
            phoneNumber: supportTicketData.phoneNumber
        };

        const service = new SupportService();
        const resp = await service.submitSupportTicketAsync(supportRequest);

        dispatch(enqueueNotification({
            message: 'Successfully submitted',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success'
            }
        }));

        return {
            status: 'success',
            supportTicketInfo: resp
        }
    }
    catch (err) {
        handleAuthErrors(dispatch, err, 'There was an issue with submitting the ticket');

        return rejectWithValue(err.body)
    }
});

const setGroupToEdit = createAsyncThunk<
    undefined,
    string,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/ui/set-group",
        async (groupId, thunkAPI) => {
            const { rejectWithValue, dispatch, getState } = thunkAPI;
            try {
                const { group } = getState();
                const groupToEdit = group.groupData.find(x => x.id === groupId);
                if (groupToEdit) {
                    const uiGroup: IGroup = {
                        id: groupToEdit.id,
                        numberOfSlotsUsed: groupToEdit.numberOfSlotsUsed,
                        name: groupToEdit.name ?? "",
                        description: groupToEdit.description ?? "",
                        participants: groupToEdit?.participants ?? [],
                        anonymousUsers: groupToEdit?.anonymousUsers ?? false,
                        planConfiguration: {
                            id: groupToEdit.planConfiguration?.id,
                            name: groupToEdit.planConfiguration?.name,
                            testConfiguration: groupToEdit.planConfiguration?.testConfiguration?.map(x => {
                                return {
                                    categoryId: x.categoryId,
                                    id: x.id
                                } as ITestConfiguration
                            }) ?? [],
                            courseConfiguration: groupToEdit.planConfiguration?.courseConfiguration?.map(x => {
                                return {
                                    categoryId: x.categoryId,
                                    id: x.id,
                                    courseModuleIds: x.courseModuleIds?.map(x => { return { moduleId: x } })
                                } as ICourseConfiguration
                            }) ?? []
                        } as IPlanConfiguration,
                        emailConfiguration: {
                            reminderEmailBody: groupToEdit.emailConfiguration?.reminderEmailBody ?? "",
                            reminderEmailSubject: groupToEdit.emailConfiguration?.reminderEmailSubject ?? "",
                            invitationEmailSubject: groupToEdit.emailConfiguration?.invitationEmailSubject ?? "",
                            invitationEmailBody: groupToEdit.emailConfiguration?.invitationEmailBody ?? "",
                            numberOfReminders: groupToEdit.emailConfiguration?.numberOfReminders ?? 0,
                            daysBetweenReminders: groupToEdit.emailConfiguration?.daysBetweenReminders ?? 0,
                            invitationDates: groupToEdit.emailConfiguration?.invitationalEmailDates?.map(x => {
                                return {
                                    dateTime: new Date(x),
                                    initialLoaded: true
                                } as IDateTime
                            }) ?? []
                        },
                        anonymousLinks: groupToEdit?.testSchedules?.map(schedule => ({
                            id: schedule.id ?? "",
                            url: schedule.testUrl ?? "",
                        }))
                    }
                    dispatch(editGroup(uiGroup));
                }
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const updateOverviewSectionAsync = createAsyncThunk<
    undefined,
    IGroupOverviewEmailConfiguration,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/ui/update-overview",
        async (overview, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(updateOverviewSection(overview));
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const updateParticipantsSectionAsync = createAsyncThunk<
    undefined,
    IParticipantsConfiguration,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/ui/update-participants",
        async (participants, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(updateParticipantSection(participants));
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const updatePlanConfigurationSectionAsync = createAsyncThunk<
    undefined,
    IPlanConfiguration,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/ui/update-planconfiguration",
        async (planConfiguration, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(updateConfigurationSection(planConfiguration));
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const getAllPlansAction = createAsyncThunk<
    EcomPlanDTO[],
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }
>("@@user/get-all-plans",
    async (data, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const service = new ProductService();
            const resData: EcomPlanDTO[] = await service.getPlans();
            return resData;
        }
        catch (err) {
            return rejectWithValue(err.body)
        }
    }
);

const getAllLogosAction = createAsyncThunk<
    BadgeResponseDTO[],
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }
>("@@user/get-all-logos",
    async (data, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {

            const service = new AccountService();
            const resData: BadgeResponseDTO[] = await service.getLogos();
            return resData;
        }
        catch (err) {
            return rejectWithValue(err.body)
        }
    }
);

const initialState: IUiState = {
    group: {
        id: "",
        numberOfSlotsUsed: 0,
        name: "",
        description: "",
        emailConfiguration: {
            invitationDates: [],
            numberOfReminders: 0,
            daysBetweenReminders: 0,
            invitationEmailSubject: EmailTexts.INVITATION_SUBJECT,
            invitationEmailBody: EmailTexts.INVITATION_BODY,
            reminderEmailSubject: EmailTexts.REMINDER_SUBJECT,
            reminderEmailBody: EmailTexts.REMINDER_BODY,
        },
        participants: [],
        anonymousUsers: false,
        planConfiguration: {
            name: "",
            testConfiguration: [],
            courseConfiguration: []
        }
    },
    currentPlan: { change: "NONE", planUpgradeForMoreEmails: "NOT_NEEDED" },
    plans: [],
    logos: [],
    notifications: []
}

const uiSlice = createSlice({
    name: "@@ui",
    initialState,
    reducers: {
        editGroup(state, action: PayloadAction<IGroup>) {
            state.group = action.payload;
        },
        clearGroup(state) {
            state.group = initialState.group
        },
        updateParticipantSection(state, action: PayloadAction<IParticipantsConfiguration>) {
            state.group.anonymousUsers = action.payload.anonymousUsers;
            state.group.participants = action.payload.participants;
            state.group.numberOfSlotsUsed = action.payload.participants.length;
        },
        updateOverviewSection(state, action: PayloadAction<IGroupOverviewEmailConfiguration>) {
            state.group.name = action.payload.name;
            state.group.description = action.payload.description;
            state.group.emailConfiguration = action.payload.emailConfiguration;
        },
        updateConfigurationSection(state, action: PayloadAction<IPlanConfiguration>) {
            state.group.planConfiguration = action.payload;
        },
        planSelectionStarted(state) {
            state.currentPlan.change = "PLAN_SELECTION_STARTED";
        },
        planSelectionCompleted(state) {
            state.currentPlan.change = "PLAN_SELECTION_COMPLETED";
        },
        planUpgradeStarted(state) {
            state.currentPlan.change = "UPGRADE_STARTED";
        },
        planUpgradeCompleted(state) {
            state.currentPlan.change = "UPGRADE_COMPLETED";
        },
        planChangeDiscarded(state) {
            state.currentPlan.change = "NONE";
        },
        planUpgradeForMoreEmailsRequested(state) {
            state.currentPlan.planUpgradeForMoreEmails = "REQUESTED";
        },
        planUpgradeForMoreEmailsCompleted(state) {
            state.currentPlan.planUpgradeForMoreEmails = "COMPLETED";
        },
        planUpgradeForMoreEmailsCanceled(state) {
            state.currentPlan.planUpgradeForMoreEmails = "CANCELED";
        },
        planUpgradeForMoreEmailsNotNeeded(state) {
            state.currentPlan.planUpgradeForMoreEmails = "NOT_NEEDED";
        },
        enqueueNotification(state, action) {
            state.notifications = [...state.notifications, {
                ...action.payload,
                key: action.payload.options.key
            }]
        },
        closeNotification(state, action) {
            state.notifications = state.notifications.map(notification => (
                (action.payload.dismissAll || notification.key === action.payload)
                    ? { ...notification, dismissed: true }
                    : { ...notification }
            ))
        },
        removeNotification(state, action) {
            state.notifications = state.notifications.filter(notification => notification.key !== action.payload)
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllLogosAction.fulfilled, (state, action) => {
            state.logos = action.payload;
        })
        builder.addCase(getAllPlansAction.fulfilled, (state, action) => {
            state.plans = action.payload;
        })
    }
});
export {
    checkoutAction,
    submitSupportTicketAction,
    updateOverviewSectionAsync,
    updateParticipantsSectionAsync,
    updatePlanConfigurationSectionAsync,
    getAllLogosAction,
    getAllPlansAction,
    checkoutFreeTierAction
}

export const {
    enqueueNotification,
    removeNotification,
    closeNotification,
    updateParticipantSection,
    updateConfigurationSection,
    updateOverviewSection,
    clearGroup,
    editGroup,
    planSelectionStarted,
    planSelectionCompleted,
    planUpgradeStarted,
    planUpgradeCompleted,
    planChangeDiscarded,
    planUpgradeForMoreEmailsRequested,
    planUpgradeForMoreEmailsCompleted,
    planUpgradeForMoreEmailsCanceled,
    planUpgradeForMoreEmailsNotNeeded
} = uiSlice.actions

export {
    setGroupToEdit
}

export default uiSlice.reducer;
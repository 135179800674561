import React from "react";
import { useDispatch } from "react-redux";
import { navigate } from "@reach/router";
import { makeStyles, Theme, createStyles, withStyles, DialogTitleProps, IconButton, DialogContent, Grid, Typography } from "@material-ui/core";
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import SvgIcon from "@material-ui/core/SvgIcon";
import ErrorButton from "components/ErrorButton";
import { Paths } from "utils/constants";
import { ReactComponent as IconLicense } from "assets/iconLicenses.svg";
import { planUpgradeForMoreEmailsRequested } from "store/ui/uiSlice";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        modalHeading: {
            fontSize: "1.1875rem",
            fontWeight: 400,
            textTransform: "capitalize",
            color: "white",
            backgroundColor: "#D42C22",
            padding: '8px 24px 8px 16px',
        },
        headerTitle: {
            display: 'block',
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "12px",
            fontWeight: 300,
        },
        headerSubtitle: {
            display: 'block',
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "18px",
            fontWeight: 900,
            textTransform: "uppercase",
        },
        contentTitle: {
            display: 'block',
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "1.2em",
            fontWeight: 900,
            marginBottom: "10px"
        },
        contentBody: {
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "12px",
            fontWeight: 400,
        },
        contentEmail: {
            fontSize: "12px",
            fontWeight: 900,
            margin: "10px 0px"
        },
        modalImage: {
            margin: "auto",
            height: 104,
            width: 57,
            marginTop: 10,
            marginBottom: 20,
        },
        modalButton: {
            color: "white",
            fontFamily: "inherit",
            fontSize: "14px",
            fontWeight: 700,
            backgroundColor: "#D42C22",
            borderRadius: '10em',
            textTransform: 'none',
            paddingTop: 6,
            paddingBottom: 6,
            marginTop: 14,
            marginBottom: 8,
        },
        modalCloseButton: {
            color: "#D42C22",
            fontFamily: "inherit",
            fontSize: "14px",
            fontWeight: 700,
            backgroundColor: "white",
            borderRadius: '10em',
            textTransform: 'none',
            textAlign: 'center',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
            padding: '4px',
        },
        resendButton: {
            textAlign: 'center',
            marginTop: "20px"
        },
        cancelButton: {
            textAlign: 'center',
        }
    }),
);

interface IProps {
    maxNumLicenses: number;
    isOpen: boolean;
    handleModalClose: () => void;
}

const TooManyEmailsModal = ({ maxNumLicenses, handleModalClose, isOpen }: IProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleUpgrade = async () => {
        await dispatch(planUpgradeForMoreEmailsRequested());
        navigate(Paths.EDIT_PLAN)
    }

    const Dialog = withStyles((theme: Theme) => ({
        paper: {
            height: "auto",
            width: 291,
            borderRadius: "1rem",
        },
    }))(MuiDialog);

    const DialogTitle = (props: DialogTitleProps) => {

        return (
            <MuiDialogTitle disableTypography className={classes.modalHeading}>
                {props.children}
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleModalClose}>
                    <CloseIcon style={{ color: "white", fontSize: '18px' }} />
                </IconButton>
            </MuiDialogTitle>
        );
    };

    return (
        <>
            <Dialog onClose={handleModalClose} aria-labelledby="customized-dialog-title" open={isOpen}>
                <DialogTitle id="customized-dialog-title">
                    <span className={classes.headerTitle}>Alert</span>
                    <span className={classes.headerSubtitle}>LICENSE LIMIT REACHED</span>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <SvgIcon viewBox="0 0 100 70" style={{ fontSize: "7em", margin: "20px" }}>
                                <IconLicense />
                            </SvgIcon>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ textAlign: "center" }}>
                                <span className={classes.contentTitle}>
                                    You have reached the license threshold of your current subscription.
                                </span>
                                <Typography variant="body1" component="p" className={classes.contentBody}>
                                    You are currently limited to <strong>{maxNumLicenses} licenses</strong>. Consider adding more licenses to your current plan or if you require more licenses than your current plan allows, upgrade to a larger tier that can fulfill your requirements.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} className={classes.resendButton}>
                            <ErrorButton
                                label="Upgrade plan"
                                onClick={handleUpgrade}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.cancelButton}>
                            <ErrorButton
                                secondary
                                label="Close"
                                onClick={handleModalClose}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default TooManyEmailsModal;
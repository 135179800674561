import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
    root: {
    },
});

export type IContentfulAsset = {
    sys: {
        id: string | null
    }
    title: string | null
    contentType: string | null
    url: string | null
    __typename: string | null
}

const ContentfulAsset = ({ title, contentType, url }: IContentfulAsset) => {
    const classes = useStyles();

    function renderAssetType() {
        const assetType = contentType?.split('/')[0]
        switch (assetType) {
            case "video": {
                return (
                    <video controls width="100%">
                        <source src={url as string} type="video/mp4" />
                    </video>
                )
            }
            case "image": {
                return <img src={url as string} />
            }
            default: {
                return (
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <rect fill="#ddd" width="100%" height="100%" />
                        <line stroke="#000" x1="0" y1="0" x2="100%" y2="100%"></line>
                        <line stroke="#000" x1="0" y1="100%" x2="100%" y2="0"></line>
                    </svg>
                )
            }
        }
    }

    return (
        <>
            {renderAssetType()}
        </>
    )
}

export default ContentfulAsset
import { AccountApi, ConfigurationParameters, Configuration, BadgeDTO, TokenRequestDTO, AuthenticatedModel, AccountInfo, ForgotPasswordModel, ResetPasswordModel, UpdateAccountInfoDto, UpdateEmailDto, UpdatePasswordDto } from "external-api/account-api";
import Cookies from "js-cookie";
import { trackPromise } from "react-promise-tracker";
import { CookieNames } from "utils/constants";
import http from "./axiosService";
import { getToken, saveToken } from "utils/cookieHelper";

const apiUrl = `${process.env.REACT_APP_API_URL}`;


function getApi() {
    const token = getToken();
    const configParams: ConfigurationParameters = {
        basePath: apiUrl,
        baseOptions: {
            headers:
            {
                Authorization: `Bearer ${token}`
            }
        }
    };

    const configuration = new Configuration(configParams);
    const axios = http.axios;
    return new AccountApi(configuration, apiUrl, axios);
}

export default class AccountService {

    api: AccountApi

    constructor() {
        this.api = getApi();
    }

    createAccountAsync = async (userInfoData: AccountInfo) => {
        const resp = await trackPromise(this.api.accountCreateHRAdmin(userInfoData));

        saveToken(resp.data.authResult!);
        return resp.data;
    }

    loginAsync = async (userInfoData: AuthenticatedModel) => {
        const resp = await trackPromise(this.api.accountLogin(userInfoData));
        saveToken(resp.data.authResult!);
        return resp.data;
    }

    refreshToken = async (tokenRequest: TokenRequestDTO) => {

        const resp = await this.api.accountRefreshToken(tokenRequest);
        return resp.data;
    }

    forgotPasswordAsync = async (forgotPasswordData: ForgotPasswordModel) => {
        const resp = await trackPromise(this.api.accountForgotPassword(forgotPasswordData));
        return resp.data;
    }

    resetPasswordAsync = async (resetPasswordData: ResetPasswordModel) => {
        const resp = await trackPromise(this.api.accountResetPassword(resetPasswordData));
        return resp.data;
    }

    addBadgeAsync = async (companyName: string, websiteUrl: string, companyLogo: string, logo: any) => {
        const resp = await trackPromise(this.api.accountAddBadge(companyName, websiteUrl, companyLogo, logo));
        return resp.data;
    }

    getLogos = async () => {
        const resp = await trackPromise(this.api.accountGetLogos());
        return resp.data;
    }

    addPlanAsync = async (planId: string) => {
        const resp = await trackPromise(this.api.accountAddPlan(planId));
        return resp.data;
    }

    confirmEmail = async (email: string, token: string) => {
        const resp = await trackPromise(this.api.accountConfirmEmail(token, email));
        return resp.data;
    }

    resendConfirmationEmail = async () => {
        const resp = await trackPromise(this.api.accountResendConfirmation());
        return resp.data;
    }

    getUserInfo = async () => {
        const resp = await trackPromise(this.api.accountGetUserInfo());
        return resp.data;
    }

    updateUserAsync = async (updateUser: UpdateAccountInfoDto) => {
        const resp = await trackPromise(this.api.accountUpdateAccount(updateUser));
        return resp.data;
    }

    updateEmailAsync = async (updateEmail: UpdateEmailDto) => {
        const resp = await trackPromise(this.api.accountUpdateEmail(updateEmail));
        return resp.data;
    }

    reConfirmEmailAsync = async (token: string, email: string, oldEmail: string) => {
        const resp = await trackPromise(this.api.accountReConfirmEmail(token, email, oldEmail));
        return resp.data;
    }

    updatePasswordAsync = async (updatePassword: UpdatePasswordDto) => {
        const resp = await trackPromise(this.api.accountUpdatePassword(updatePassword));
        return resp.data;
    }
}
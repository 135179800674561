import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indicator: {
      display: 'block',
      left: '0px',
      width: '0',
      height: '0px !important',
      border: '9px solid transparent',
      marginTop: '8px',
      borderLeft: '10px solid #c8602a',
      backgroundColor: 'transparent !important'
    },
    accountTabsRoot: {
    },
    accountTabsItem: {
      color: '#C8602A',
      textTransform: 'uppercase',
      textAlign: 'left',
      alignItems: 'flex-start',
      "& > span": {
        color: "#C8602A",
        fontWeight: 600,
        paddingLeft: '10px',
        textAlign: 'left',
        alignItems: 'flex-start',
        cursor: "pointer",
      }
    }
  })
);

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface AccountTabsProps {
  accountTabsList: { title: string, render: () => React.ReactNode }[]
  onTabChange: (n: React.SetStateAction<number>) => void;
};

const AccountTabs = ({ accountTabsList, onTabChange }: AccountTabsProps) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  return (
    <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange} classes={{ indicator: classes.indicator }} className={classes.accountTabsRoot} aria-label="Vertical tabs example">
      {accountTabsList.map((tab: { title: string, render: () => React.ReactNode }, i) => {
        return (<Tab key={i} fullWidth={false} className={classes.accountTabsItem} value={i} label={tab.title} {...a11yProps(i)} />)
      })}
    </Tabs>
  )
}

export default AccountTabs;
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Link, Theme, Typography } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';
import { RouteComponentProps } from '@reach/router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(12),
        },
        heading: {
            marginBottom: 10,
            fontSize: 36,
            fontWeight: 700,
            lineHeight: 1,
        },
        subheading: {
            fontSize: 20,
        },
        heading2: {
            marginTop: 30,
            fontSize: 20,
            fontWeight: 700,
        },
        paragraph: {
            fontSize: 14,
        },
    }),
);


interface IProps extends RouteComponentProps {

}


const Privacy = ({ }: IProps) => {
    const classes = useStyles();

    return (
        <MainLayout>
            <Grid container className={classes.root} spacing={2} justify="center">
                <Grid item md={6}>
                    <Typography variant="h1" className={classes.heading}>Privacy Policy</Typography>
                    <Typography variant="body1" className={classes.subheading} gutterBottom>
                        This privacy policy will explain how BiasProof uses the personal data we collect from you when you use our website.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        Importantly, BiasProof uses a Privacy By Design model. This means that we do not match Personally Identifiable Information about you to information you provide us. What this means is that our systems are designed specifically to ensure that any information you give us as part of testing or training using our platform, (other than an Administrator’s credentials and payment information), is maintained anonymously and cannot be tied back to the individual who provided it.
                    </Typography>
                    <Typography variant="h2" className={classes.heading2} gutterBottom>
                        What data do we collect?
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        BiasProof collects the following data:
                        <ul>
                            <li>Demographic information you have chosen to share with us (gender, age, nationality, country of residence, postcode, level of education, and profession)</li>
                            <li>Data that you input when completing one of our tests (response to open questions and questionnaires)</li>
                        </ul>
                    </Typography>
                    <Typography variant="h2" className={classes.heading2} gutterBottom>
                        How do we collect your data?
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        You directly provide BiasProof with most of the data we collect. We collect data and process data when you:
                        <ul>
                            <li>Complete one of our online tests conducted by us or on behalf of one of our clients.</li>
                            <li>Voluntarily complete a customer test or provide feedback on any of our message boards or via email.</li>
                        </ul>
                    </Typography>
                    <Typography variant="h2" className={classes.heading2} gutterBottom>
                        How will we use your data?
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        BiasProof collects your data so that we can:
                        <ul>
                            <li>Report aggregate, non-personally identifiable data in our HR Administrator portal</li>
                            <li>Conduct data analysis for internal or external R&D projects</li>
                        </ul>
                        BiasProof will not share your data with any third parties.
                    </Typography>
                    <Typography variant="h2" className={classes.heading2} gutterBottom>
                        How do we store your data?
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        BiasProof securely stores your data.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        BiasProof will keep your data for a maximum of 10 years. Once this time period has expired, we will delete your data.
                    </Typography>
                    <Typography variant="h2" className={classes.heading2} gutterBottom>
                        What are your data protection rights?
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        BiasProof want to be fully aware of all of your data protection rights. Every user is entitled to the following:
                        <ul>
                            <li>The right to access</li>
                            <li>The right to rectification</li>
                            <li>The right to erasure</li>
                            <li>The right to restrict processing</li>
                            <li>The right to data portability</li>
                            <li>The right to object to processing</li>
                        </ul>
                        BiasProof does not track you or keep record of your scores, results or any inputs that it can connect to you as an individual user, and therefore does not have access to, and cannot give you access to, your data since we can’t identify your data once it has been collected.
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email <Link href="mailto:support@biasproof.com">support@biasproof.com</Link>.
                    </Typography>
                    <Typography variant="h2" className={classes.heading2} gutterBottom>
                        Cookies
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        Cookies are text files placed on your computer to collect standard Internet log information and visitor behaviour information. We only use cookies to handle login information for Administrators using the Administrator Portal of our site. For further information, visit <Link href="https://www.allaboutcookies.org/">allaboutcookies.org</Link>.
                    </Typography>
                    <Typography variant="h2" className={classes.heading2} gutterBottom>
                        What types of cookies do we use?
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        There are several different types of cookies. Our website uses:
                        <ul>
                            <li><strong>Functionality: </strong>BiasProof uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer or your location and location you are in. A mix of first-party and third-party cookies are used.</li>
                        </ul>
                    </Typography>
                    <Typography variant="h2" className={classes.heading2} gutterBottom>
                        How to manage cookies
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.
                    </Typography>
                    <Typography variant="h2" className={classes.heading2} gutterBottom>
                        Changes to our privacy policy
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        BiasProof keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 20th May, 2021.
                    </Typography>
                    <Typography variant="h2" className={classes.heading2} gutterBottom>
                        How to contact us
                    </Typography>
                    <Typography variant="body1" className={classes.paragraph} gutterBottom>
                        If you have any questions about BiasProof’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us. Email us at: <Link href="mailto:support@biasproof.com">support@biasproof.com</Link>.
                    </Typography>
                </Grid>
            </Grid>
        </MainLayout>
    );
}

export default Privacy;
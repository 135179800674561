import { GroupResultsDTO } from "external-api/license-api";
import * as _ from "lodash";
import { IATDataType } from "./BarUtils";

export type ScoresData = {
    title: string;
    subheader: string;
    scores: {
        date: Date;
        score: number | null;
        scoreBreakdown: IATDataType | null;
        completed: number;
        numberOfParticipants: number;
    }[];
}

export function getIATCategories(scores: number[], labels: string[], iatType: boolean, primaryCategory: boolean) {

    let categories: IATDataType = {
        strongPositive: {
            label: iatType ?
                `<tspan text-anchor='middle' x='0' dy='-0.25em'>Strong</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[0]}</tspan>`
                :
                primaryCategory ?
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Strong</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[0]} - ${labels[2]}</tspan>`
                    :
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Strong</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[1]} - ${labels[3]}</tspan>`,
            value: 0
        },
        moderatePositive: {
            label: iatType ?
                `<tspan text-anchor='middle' x='0' dy='-0.25em'>Moderate</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[0]}</tspan>`
                :
                primaryCategory ?
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Moderate</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[0]} - ${labels[2]}</tspan>`
                    :
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Moderate</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[1]} - ${labels[3]}</tspan>`,
            value: 0
        },
        slightPositive: {
            label: iatType ?
                `<tspan text-anchor='middle' x='0' dy='-0.25em'>Slight</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[0]}</tspan>`
                :
                primaryCategory ?
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Slight</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[0]} - ${labels[2]}</tspan>`
                    :
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Slight</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[1]} - ${labels[3]}</tspan>`,
            value: 0
        },
        littleOrNo: {
            label: `<tspan text-anchor='middle' x='0' dy='-0.25em'>Little or No</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference</tspan>`,
            value: 0
        },
        slightNegative: {
            label: iatType ?
                `<tspan text-anchor='middle' x='0' dy='-0.25em'>Slight</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[1]}</tspan>`
                :
                primaryCategory ?
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Slight</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[1]} - ${labels[2]}</tspan>`
                    :
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Slight</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[0]} - ${labels[3]}</tspan>`,
            value: 0
        },
        moderateNegative: {
            label: iatType ?
                `<tspan text-anchor='middle' x='0' dy='-0.25em'>Moderate</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[1]}</tspan>`
                :
                primaryCategory ?
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Moderate</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[1]} - ${labels[2]}</tspan>`
                    :
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Moderate</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[0]} - ${labels[3]}</tspan>`,
            value: 0
        },
        strongNegative: {
            label: iatType ?
                `<tspan text-anchor='middle' x='0' dy='-0.25em'>Strong</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[1]}</tspan>`
                :
                primaryCategory ?
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Strong</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[1]} - ${labels[2]}</tspan>`
                    :
                    `<tspan text-anchor='middle' x='0' dy='-0.25em'>Strong</tspan><tspan text-anchor='middle' x='0' dy='1em'>Preference for</tspan><tspan text-anchor='middle' x='0' dy='1em'>${labels[0]} - ${labels[3]}</tspan>`,
            value: 0
        },
    }

    scores.forEach(score => {
        if (Number.NEGATIVE_INFINITY <= score && score <= -0.8)
            categories.strongNegative.value += (1 / scores.length) * 100
        else if (-0.8 < score && score <= -0.5)
            categories.moderateNegative.value += (1 / scores.length) * 100
        else if (-0.5 < score && score <= -0.2)
            categories.slightNegative.value += (1 / scores.length) * 100
        else if (-0.2 < score && score < +0.2)
            categories.littleOrNo.value += (1 / scores.length) * 100
        else if (+0.2 <= score && score < +0.5)
            categories.slightPositive.value += (1 / scores.length) * 100
        else if (+0.5 <= score && score < +0.8)
            categories.moderatePositive.value += (1 / scores.length) * 100
        else if (+0.8 <= score && score < Number.POSITIVE_INFINITY)
            categories.strongPositive.value += (1 / scores.length) * 100
    })

    categories.strongNegative.value = Math.round(categories.strongNegative.value);
    categories.moderateNegative.value = Math.round(categories.moderateNegative.value);
    categories.slightNegative.value = Math.round(categories.slightNegative.value);
    categories.littleOrNo.value = Math.round(categories.littleOrNo.value);
    categories.slightPositive.value = Math.round(categories.slightPositive.value);
    categories.moderatePositive.value = Math.round(categories.moderatePositive.value);
    categories.strongPositive.value = Math.round(categories.strongPositive.value);

    return categories;
}

export function calcTop2BoxScore(iatCategories: IATDataType) {
    return Math.min(iatCategories.slightNegative.value + iatCategories.littleOrNo.value + iatCategories.slightPositive.value, 100);
}

export function convertGroupDataToScores(data: GroupResultsDTO, surveyTitles: { [key: string]: { title: string, labels: string[], iatType: boolean, primaryCategory: boolean } }, includeScoreBreakdown: boolean) {

    if (data.results && data.results.length > 0 && surveyTitles !== {}) {
        let testScores = data.results.map(result => result.tests ? result.tests.map(test => ({
            startDate: result.startDate,
            numberOfParticipants: result.numberOfParticipants,
            testId: test.testId ?? "",
            numberOfCompleted: test.numberOfCompleted ?? 0,
            scores: test.scores ?? []
        })) : []).flat()

        data.results.forEach(result => {
            result.testConfiguration?.forEach(testConfig => {
                if (_.chain(testScores).find(testScore => (testScore.testId === testConfig.id) && (testScore.startDate === result.startDate)).value() === undefined) {
                    testScores.push({
                        startDate: result.startDate,
                        numberOfParticipants: result.numberOfParticipants,
                        testId: testConfig.id ?? "",
                        numberOfCompleted: 0,
                        scores: []
                    })
                }
            })
        })

        const output = _.chain(testScores).groupBy('testId').values().map(iterations => (
            {
                title: data.name ?? "Untitled",
                subheader: iterations[0].testId in surveyTitles ? surveyTitles[iterations[0].testId].title : iterations[0].testId,
                scores: iterations.map(iteration => {
                    const iatLabels = surveyTitles[iteration.testId]?.labels ?? ['', ''];
                    const iatType = surveyTitles[iteration.testId]?.iatType;
                    const primaryCategory = surveyTitles[iteration.testId]?.primaryCategory;
                    return (
                        {
                            "date": new Date(iteration.startDate),
                            "score": iteration.scores.length > 0 ? calcTop2BoxScore(getIATCategories(iteration.scores, iatLabels, iatType, primaryCategory)) : null,
                            "scoreBreakdown": includeScoreBreakdown ? getIATCategories(iteration.scores, iatLabels, iatType, primaryCategory) : null,
                            "completed": iteration.numberOfCompleted,
                            "numberOfParticipants": data.numberOfSlotsUsed > 0 ? iteration.numberOfParticipants : 0, // More hacks, because deploying anonymous links in 24hours is a thing! :)
                        }
                    );
                })
            } as ScoresData
        )).value();
        if (data.numberOfSlotsUsed === 0) { // This should mean invitation by link, TODO: find a better way to distinguish between invitation by link vs email
            return output.map(scoreData => ({ ...scoreData, scores: scoreData.scores.filter(score => score.score !== null) })).filter(scoreData => scoreData.scores.length > 0);
        }
        return output;
    }
    else
        return []
}

export function convertGroupDataToScoreCardsData(data: GroupResultsDTO, surveyTitles: { [key: string]: { title: string, labels: string[], iatType: boolean, primaryCategory: boolean } }) {
    return convertGroupDataToScores(data, surveyTitles, false);
}

export function convertGroupsDataToScoreCardsData(data: GroupResultsDTO[], surveyTitles: { [key: string]: { title: string, labels: string[], iatType: boolean, primaryCategory: boolean } }) {
    return data.map(groupData => convertGroupDataToScoreCardsData(groupData, surveyTitles)).flat()
}

export function isCurrentScoreNull(
    scores: {
        date: Date;
        score: number | null;
    }[]
) {
    const sortedScores = scores.map(
        scoreData => ({ ...scoreData, date: new Date(scoreData.date) })
    ).sort(
        (a, b) => b.date.getTime() - a.date.getTime()
    )
    return sortedScores[0].score === null
}

export const MOCK_GET_GROUPS_DATA: GroupResultsDTO[] = [
    {
        "id": "2986678b-d5e0-41fd-a255-d4da0219de15",
        "name": "QA test 1",
        "description": "",
        "numberOfSlotsUsed": 0,
        "nextDate": undefined,
        "results": [
            {
                "iterationId": "e992ac02-2974-43ae-98f3-05e4e114cb44",
                "startDate": "2021-05-27T00:00:00",
                "numberOfParticipants": 11,
                "tests": [
                    {
                        "testId": "4A2js6fmSeQJKJ4BpgRQZu",
                        "numberOfCompleted": 6,
                        "scores": [
                            -0.3,
                            0.14,
                            -0.02,
                            -0.12,
                            -0.08,
                            -0.01
                        ],
                        "surveyResponses": [
                            "{}",
                            "{}",
                            "{}",
                            "{}",
                            "{}",
                            "{}"
                        ]
                    },
                    {
                        "testId": "3jIFUA7GvG0lPmQfw9vxGL",
                        "numberOfCompleted": 5,
                        "scores": [
                            0.1,
                            0.08,
                            0.02,
                            0.22,
                            -0.06
                        ],
                        "surveyResponses": [
                            "{}",
                            "{}",
                            "{}",
                            "{}",
                            "{}"
                        ]
                    }
                ],
                "courses": [],
                "testConfiguration": [
                    {
                        "categoryId": "6ikKIkBHf20vzBtOpOtXdk",
                        "id": "4A2js6fmSeQJKJ4BpgRQZu"
                    },
                    {
                        "categoryId": "6Fp0AWdjCdKsp4ZwTgKZ6A",
                        "id": "3jIFUA7GvG0lPmQfw9vxGL"
                    }
                ],
                "courseConfiguration": [
                    {
                        "categoryId": "6ikKIkBHf20vzBtOpOtXdk",
                        "id": "5OsQNOUCtfGZYlff76Tzf5",
                        "courseModuleIds": [
                            "45QGmPEaKtCvoi5rqzdWHa",
                            "5Yemk78BawZ7SBXtkPLEQJ",
                            "5gyJLrZYZbct0GqSjfWadQ"
                        ]
                    },
                    {
                        "categoryId": "6Fp0AWdjCdKsp4ZwTgKZ6A",
                        "id": "5ymNmeCfObcP6CcIdeZgT2",
                        "courseModuleIds": [
                            "45QGmPEaKtCvoi5rqzdWHa",
                            "5Yemk78BawZ7SBXtkPLEQJ"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "a5693b58-6b8c-4276-a70f-36d1a7c4f076",
        "name": "New group 2",
        "description": "",
        "numberOfSlotsUsed": 0,
        "nextDate": undefined,
        "results": [
            {
                "iterationId": "88744601-2c07-469d-a08f-b50aac89f45f",
                "startDate": "2021-05-28T00:00:00",
                "numberOfParticipants": 5,
                "tests": [
                    {
                        "testId": "4A2js6fmSeQJKJ4BpgRQZu",
                        "numberOfCompleted": 2,
                        "scores": [
                            -0.2,
                            -0.11
                        ],
                        "surveyResponses": [
                            "{}",
                            "{\"ethnicity-questionnaire\":{\"e7853590-50e3-5816-a6a0-ae629f7eaa83\":{\"name\":\"which-statement-best-describes-you-ethnicity\",\"type\":\"ContentfulMultipleChoiceQuestion\",\"data\":{\"radioAnswer\":\"I like Black people and White people equally.\",\"textAnswer\":\"\"}},\"d28b2896-119f-5518-8320-619e8e0371a9\":{\"name\":\"how-warm-or-cold-do-you-feel-towards-white-people\",\"type\":\"ContentfulLikertAssessment\",\"data\":{\"value\":6}},\"781e8302-c729-5986-9daf-1cc3a7f4150f\":{\"name\":\"how-warm-or-cold-do-you-feel-towards-black-people\",\"type\":\"ContentfulLikertAssessment\",\"data\":{\"value\":2}}},\"demographics\":{\"7e0d3eac-8425-579e-af98-431f93d7db4f\":{\"name\":\"year-of-birth-picker\",\"type\":\"ContentfulDateOfBirthPicker\",\"data\":{\"year\":2002}},\"16d5fe72-9ca7-540c-88f3-ce144cd99d21\":{\"name\":\"primary-language\",\"type\":\"ContentfulLanguagePicker\",\"data\":{\"language\":\"az\"}},\"3db31342-eda1-5163-8ab2-0d863065eee2\":{\"name\":\"country-of-residence\",\"type\":\"ContentfulCountryPicker\",\"data\":{\"country\":\"AR\"}},\"a5c07a39-1178-5a88-b601-83d2e26dff24\":{\"name\":\"gender\",\"type\":\"ContentfulMultipleChoiceQuestion\",\"data\":{\"radioAnswer\":\"Male\",\"textAnswer\":\"\"}},\"9f3f54df-a1d5-5ad3-8943-cfa698a63d28\":{\"name\":\"ethnic-group\",\"type\":\"ContentfulMultipleChoiceQuestion\",\"data\":{\"radioAnswer\":\"Chinese\",\"textAnswer\":\"\"}},\"eb4f17ed-c3f6-5f0f-94cd-f67005d37586\":{\"name\":\"highest-level-of-education\",\"type\":\"ContentfulMultipleChoiceQuestion\",\"data\":{\"radioAnswer\":\"Chinese\",\"textAnswer\":\"\"}}}}"
                        ]
                    },
                    {
                        "testId": "3jIFUA7GvG0lPmQfw9vxGL",
                        "numberOfCompleted": 2,
                        "scores": [
                            -0.12,
                            -0.18
                        ],
                        "surveyResponses": [
                            "{}",
                            "{\"questionnaire\":{\"b8c47319-04c6-5fff-8205-fffdd6fd46da\":{\"name\":\"how-warm-or-cold-do-you-feel-towards-females\",\"type\":\"ContentfulLikertAssessment\",\"data\":{\"value\":6}},\"fdd256ef-2d55-5bad-8c76-663618a46a13\":{\"name\":\"which-statement-best-describes-you\",\"type\":\"ContentfulMultipleChoiceQuestion\",\"data\":{\"radioAnswer\":\"I moderately prefer Females to Males.\",\"textAnswer\":\"\"}}},\"demographics\":{\"7e0d3eac-8425-579e-af98-431f93d7db4f\":{\"name\":\"year-of-birth-picker\",\"type\":\"ContentfulDateOfBirthPicker\",\"data\":{\"year\":2003}}}}"
                        ]
                    }
                ],
                "courses": [
                    {
                        "courseId": "5OsQNOUCtfGZYlff76Tzf5",
                        "numberOfPassed": 0,
                        "numberOfCompleted": 1,
                        "scores": [
                            0.42857142857142855
                        ]
                    }
                ],
                "testConfiguration": [
                    {
                        "categoryId": "6ikKIkBHf20vzBtOpOtXdk",
                        "id": "4A2js6fmSeQJKJ4BpgRQZu"
                    },
                    {
                        "categoryId": "6Fp0AWdjCdKsp4ZwTgKZ6A",
                        "id": "3jIFUA7GvG0lPmQfw9vxGL"
                    }
                ],
                "courseConfiguration": [
                    {
                        "categoryId": "6ikKIkBHf20vzBtOpOtXdk",
                        "id": "5OsQNOUCtfGZYlff76Tzf5",
                        "courseModuleIds": [
                            "45QGmPEaKtCvoi5rqzdWHa",
                            "5Yemk78BawZ7SBXtkPLEQJ",
                            "5gyJLrZYZbct0GqSjfWadQ",
                            "4xUUff0aop2B1LiMA3kpUB"
                        ]
                    },
                    {
                        "categoryId": "6Fp0AWdjCdKsp4ZwTgKZ6A",
                        "id": "5ymNmeCfObcP6CcIdeZgT2",
                        "courseModuleIds": [
                            "45QGmPEaKtCvoi5rqzdWHa",
                            "5Yemk78BawZ7SBXtkPLEQJ",
                            "3NDAoVnVutyUdhSeQngUKy",
                            "6FEJryWjshr1wXvnYoDymB"
                        ]
                    }
                ]
            },
            {
                "iterationId": "b324c3e6-ffab-4096-b2b1-eff46145ca7a",
                "startDate": "2021-05-29T00:00:00",
                "numberOfParticipants": 1,
                "tests": [
                    {
                        "testId": "4A2js6fmSeQJKJ4BpgRQZu",
                        "numberOfCompleted": 1,
                        "scores": [
                            0.3
                        ],
                        "surveyResponses": [
                            "{\"ethnicity-questionnaire\":{\"e7853590-50e3-5816-a6a0-ae629f7eaa83\":{\"name\":\"which-statement-best-describes-you-ethnicity\",\"type\":\"ContentfulMultipleChoiceQuestion\",\"data\":{\"radioAnswer\":\"I slightly prefer White people to Black people.\",\"textAnswer\":\"\"}},\"d28b2896-119f-5518-8320-619e8e0371a9\":{\"name\":\"how-warm-or-cold-do-you-feel-towards-white-people\",\"type\":\"ContentfulLikertAssessment\",\"data\":{\"value\":3}},\"781e8302-c729-5986-9daf-1cc3a7f4150f\":{\"name\":\"how-warm-or-cold-do-you-feel-towards-black-people\",\"type\":\"ContentfulLikertAssessment\",\"data\":{\"value\":10}}},\"demographics\":{\"7e0d3eac-8425-579e-af98-431f93d7db4f\":{\"name\":\"year-of-birth-picker\",\"type\":\"ContentfulDateOfBirthPicker\",\"data\":{\"year\":2000}},\"16d5fe72-9ca7-540c-88f3-ce144cd99d21\":{\"name\":\"primary-language\",\"type\":\"ContentfulLanguagePicker\",\"data\":{\"language\":\"az\"}},\"9f3f54df-a1d5-5ad3-8943-cfa698a63d28\":{\"name\":\"ethnic-group\",\"type\":\"ContentfulMultipleChoiceQuestion\",\"data\":{\"radioAnswer\":\"Black/ African/Caribbean/Black British\",\"textAnswer\":\"\"}},\"eb4f17ed-c3f6-5f0f-94cd-f67005d37586\":{\"name\":\"highest-level-of-education\",\"type\":\"ContentfulMultipleChoiceQuestion\",\"data\":{\"radioAnswer\":\"Black/ African/Caribbean/Black British\",\"textAnswer\":\"\"}}}}"
                        ]
                    }
                ],
                "courses": [],
                "testConfiguration": [
                    {
                        "categoryId": "6ikKIkBHf20vzBtOpOtXdk",
                        "id": "4A2js6fmSeQJKJ4BpgRQZu"
                    },
                    {
                        "categoryId": "6Fp0AWdjCdKsp4ZwTgKZ6A",
                        "id": "3jIFUA7GvG0lPmQfw9vxGL"
                    }
                ],
                "courseConfiguration": [
                    {
                        "categoryId": "6ikKIkBHf20vzBtOpOtXdk",
                        "id": "5OsQNOUCtfGZYlff76Tzf5",
                        "courseModuleIds": [
                            "45QGmPEaKtCvoi5rqzdWHa",
                            "5Yemk78BawZ7SBXtkPLEQJ",
                            "5gyJLrZYZbct0GqSjfWadQ",
                            "4xUUff0aop2B1LiMA3kpUB"
                        ]
                    },
                    {
                        "categoryId": "6Fp0AWdjCdKsp4ZwTgKZ6A",
                        "id": "5ymNmeCfObcP6CcIdeZgT2",
                        "courseModuleIds": [
                            "45QGmPEaKtCvoi5rqzdWHa",
                            "5Yemk78BawZ7SBXtkPLEQJ",
                            "3NDAoVnVutyUdhSeQngUKy",
                            "6FEJryWjshr1wXvnYoDymB"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "062fe66e-45bd-4172-bc2a-990e9e02dcde",
        "name": "Email Group",
        "description": "",
        "numberOfSlotsUsed": 2,
        "nextDate": "2021-06-30T00:00:00",
        "results": [
            {
                "iterationId": "3bce2a1b-858b-43c0-9753-5a0b547841b4",
                "startDate": "2021-05-28T00:00:00",
                "numberOfParticipants": 2,
                "tests": [],
                "courses": [],
                "testConfiguration": [
                    {
                        "categoryId": "6Fp0AWdjCdKsp4ZwTgKZ6A",
                        "id": "3jIFUA7GvG0lPmQfw9vxGL"
                    }
                ],
                "courseConfiguration": [
                    {
                        "categoryId": "6Fp0AWdjCdKsp4ZwTgKZ6A",
                        "id": "5ymNmeCfObcP6CcIdeZgT2",
                        "courseModuleIds": [
                            "45QGmPEaKtCvoi5rqzdWHa"
                        ]
                    }
                ]
            },
            {
                "iterationId": "2962ae9b-0bc5-4456-9296-42ccad67b695",
                "startDate": "2021-06-30T00:00:00",
                "numberOfParticipants": 2,
                "tests": [
                    {
                        "testId": "3jIFUA7GvG0lPmQfw9vxGL",
                        "numberOfCompleted": 2,
                        "scores": [],
                        "surveyResponses": []
                    }
                ],
                "courses": [],
                "testConfiguration": [
                    {
                        "categoryId": "6Fp0AWdjCdKsp4ZwTgKZ6A",
                        "id": "3jIFUA7GvG0lPmQfw9vxGL"
                    }
                ],
                "courseConfiguration": [
                    {
                        "categoryId": "6Fp0AWdjCdKsp4ZwTgKZ6A",
                        "id": "5ymNmeCfObcP6CcIdeZgT2",
                        "courseModuleIds": [
                            "45QGmPEaKtCvoi5rqzdWHa"
                        ]
                    }
                ]
            }
        ]
    }
]
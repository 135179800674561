import React from 'react';
import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notificationBadgeRoot: {
            display: "inline-block",
            margin: "0px 4px",
        },
        dot: {
            height: 12,
            width: 12,
            backgroundColor: theme.palette.orange.main,
            border: "2px solid white",
            borderRadius: "50%",
            boxShadow: "0px 1px 2px grey",
            display: "inline-block",
        },
        arrow: {
            "&:before": {
                border: "1px solid #DB5205"
            },
            color: "#F2F2F2",
        },
        tooltip: {
            backgroundColor: "#F2F2F2",
            border: "1px solid #DB5205",
            borderRadius: "2.5em",
            color: "#4A4A4A",
            boxShadow: "0px 1px 2px grey",
            fontSize: 12,
            lineHeight: "2.5em",
        }
    })
);

interface IProps {
}

const NotificationBadge = ({ }: IProps) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div className={classes.notificationBadgeRoot}>
            <Tooltip
                title="Recently Updated"
                arrow
                placement="top"
                open={open} onClose={handleClose} onOpen={handleOpen}
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            >
                <span className={classes.dot}></span>
            </Tooltip>
        </div>
    );
}

export default NotificationBadge;
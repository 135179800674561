import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Grid, Theme, Typography } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';
import { RouteComponentProps } from '@reach/router';
import * as yup from 'yup';
import useValidForm from 'hooks/useValidForm';
import FormInput from 'components/formComponents/form-input';
import { resetPasswordAction } from 'store/user/userSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backgroundLeft: {
            flexGrow: 1,
            marginTop: theme.spacing(1),
            height: "calc(100vh - 80px)",
            background: "url('/images/general/reset-password-left.svg') no-repeat left bottom",
            backgroundSize: "auto 368px",
        },
        backgroundRight: {
            flexGrow: 1,
            marginTop: theme.spacing(1),
            height: "calc(100vh - 80px)",
            background: "url('/images/general/reset-password-right.svg') no-repeat right bottom",
            backgroundSize: "auto 482px",
        },
        form: {
            maxWidth: "600px",
            margin: "0 auto",
        },
        heading: {
            fontSize: 36,
            fontWeight: 700,
            paddingTop: theme.spacing(6),
        },
        subheading: {
            fontSize: 20,
        },
        password: {
            paddingTop: 40,
        },
        confirmation: {

        },
        button: {
            margin: 'auto',
            paddingTop: 16,
            maxWidth: 200,
        }
    }),
);

export interface ResetPasswordFormData {
    password: string;
    passwordConfirmation: string
    email: string;
    token: string
}

const defaultValues: ResetPasswordFormData = {
    password: "",
    passwordConfirmation: "",
    email: "",
    token: ""
}

const validationSchema = yup.object().shape({
    password: yup.string()
        .required('Password is required.')
        .min(8, 'Password is too short, should be at least 8 characters.')
        .matches(/^(?=.*?[A-Z]).*/, "Password must contain at least one uppercase letter."),
    passwordConfirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

interface IProps extends RouteComponentProps {

}

const ResetPassword = ({ }: IProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [resetData, setResetData] = useState({
        token: "",
        email: ""
    });
    const onSubmitFunc = async (formValues: ResetPasswordFormData) => {
        const requestData = {
            ...formValues,
            ...resetData
        };

        await dispatch(resetPasswordAction(requestData));
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get("token");
        const email = searchParams.get("email");
        setResetData({ token: token ?? "", email: email ?? "" });
    }, []);

    const { register, errors, onSubmit } = useValidForm<ResetPasswordFormData>(onSubmitFunc, validationSchema, defaultValues);
    return (

        <MainLayout nofooter>
            <div className={classes.backgroundLeft}>
                <div className={classes.backgroundRight}>
                    <form onSubmit={onSubmit} className={classes.form}>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <Typography className={classes.heading} component="h1">Change your password</Typography>
                                <Typography className={classes.subheading} component="h3">Choose a new password for your account. Make sure that it is between 8 - 16 characters, and includes at least one lowercase letter, one uppercase letter, and a number.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.password} >
                                    <FormInput
                                        type="password"
                                        name="password"
                                        label="New Password"
                                        placeholder="New Password"
                                        inputRef={register}
                                        error={errors.password ? true : false}
                                        helperText={errors.password?.message}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.confirmation} >
                                    <FormInput
                                        type="password"
                                        name="passwordConfirmation"
                                        label="Confirm Password"
                                        placeholder="Confirm Password"
                                        inputRef={register}
                                        error={errors.passwordConfirmation ? true : false}
                                        helperText={errors.passwordConfirmation?.message}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.button}>
                                    <Button type="submit" variant="contained" fullWidth>
                                        Submit
                                </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </MainLayout>
    );
}

export default ResetPassword;
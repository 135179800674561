import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import CoursePlans from "domain/training/CoursePlans";
import TestingPlans from "domain/survey/TestingPlans";
import { IPlanConfiguration } from "../../types";
import GroupNavigation from "../GroupNavigation";

import { DevTool } from "@hookform/devtools";
import { FormProvider } from "react-hook-form";
import { usePlanConfigurationForm } from "./usePlanConfigurationForm";

const useStyles = makeStyles(() =>
    createStyles({
        groupRoot: {
        },
    })
);


interface IProps {
    handleSubmit: (data: IPlanConfiguration) => void
    nextStep: () => void
    prevStep: () => void
    data: IPlanConfiguration
    groupId?: string
}

const GroupPlanConfiguration = ({ handleSubmit, nextStep, prevStep, data, groupId }: IProps) => {
    const classes = useStyles();

    const onSubmitFunc = async (data: IPlanConfiguration) => {
        await handleSubmit(data);
        if (!groupId) nextStep();
    }

    const { form, selectedTests } = usePlanConfigurationForm({ onSubmitFunc, data });
    return (
        <>
            <FormProvider {...form} >
                <form onSubmit={form.onSubmit}>
                    <Grid container className={classes.groupRoot} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h1" component="h1">Select Tests</Typography>
                            <TestingPlans />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h1" component="h1">Select Training</Typography>
                            <CoursePlans />
                        </Grid>
                        <Grid item xs={12}>
                            <GroupNavigation
                               isEdit={groupId ? true : false}
                                hasForm
                                prevStep={prevStep}
                                nextStep={nextStep}
                                isNextDisabled={selectedTests?.length === 0}
                            />
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </>
    );
}

export default GroupPlanConfiguration;
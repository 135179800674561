import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button, Divider, Typography } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import * as yup from 'yup';
import FormInput from 'components/formComponents/form-input';
import StyledModal from 'components/StyledModal';
import useValidForm from 'hooks/useValidForm';
import { getPlan } from 'store/plan/planSelectors';
import { getAllPlans } from 'store/ui/uiSelectors';
import { getCountry } from 'store/user/userSelector';
import { getCurrencyForCountry } from 'pages/Plans';
import { upgradePlanAction } from 'store/plan/planSlice';
import { checkoutAction, planUpgradeStarted } from 'store/ui/uiSlice';
import FormEditableInput from 'components/formComponents/form-editable-input';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        modalContentTitle: {
            display: 'block',
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "1.2em",
            fontWeight: 900,
            marginBottom: "10px"
        },
        modalContentBody: {
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "12px",
            fontWeight: 400,
            margin: "10px 0px",
            "& a": {
                color: "#C8602A",
                fontWeight: 400,
                textDecoration: "underline",
                "&:hover, &:focus": {
                    textDecoration: "underline",
                }
            }
        },
        modalContentNumLicenses: {
            fontSize: "16px",
            fontWeight: 700,
            color: "#C8602A",
        },
        modalFieldLabel: {
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "1.5em",
            textAlign: "right",
            width: "33%",
        },
        modalFieldValue: {
            fontSize: "12px",
            lineHeight: "1.5em",
            fontWeight: 400,
            textAlign: "left",
            width: "66%",
            paddingLeft: 18,
        },
        modalActionButtonContainer: {
            textAlign: 'center',
            marginTop: "20px",
        },
        modalCancelButtonContainer: {
            textAlign: 'center',
        },
        modalActionButton: {

        },
        modalCancelButton: {
            borderWidth: "0px",
            textTransform: "none",
            backgroundColor: "transparent",
            fontWeight: "bold",
            "&:hover, &:focus": {
                backgroundColor: "transparent",
                borderWidth: "0px",
            },
        },
        modalDivider: {
            backgroundColor: "#9E9E9E",
        },
    }),
);

function getNumber(str: any, defaultValue: number) {
    const num = parseInt(str, 10);
    return isNaN(num) ? defaultValue : num;
}

function useUpdateDetails(
    totalLicensesAfterUpdate: number,
) {
    const plans = useSelector(getAllPlans);
    const currentPlan = useSelector(getPlan);
    const country = useSelector(getCountry);
    const currency = getCurrencyForCountry(country);

    const currentNumLicenses = currentPlan.currentSubscription?.quantity ?? currentPlan.features?.maxNumEmailUsers ?? 0;
    const planAfterUpdate = plans.find(planInfo =>
        totalLicensesAfterUpdate >= getNumber(planInfo.product?.metadata?.MinQuantity, 1)
        &&
        totalLicensesAfterUpdate <= getNumber(planInfo.product?.metadata?.MaxQuantity, Number.POSITIVE_INFINITY)
    );

    return {
        hasStripe: currentPlan.hasStripe,
        customerId: currentPlan.stripeCustomerID,
        subscriptionId: currentPlan.stripeSubscriptionID,
        oneTimePriceId: planAfterUpdate?.oneTimePrices?.find(price => (price.type === "one_time") && (price.currency?.toLowerCase() === currency.toLowerCase()))?.id ?? "",
        recurringPriceId: planAfterUpdate?.recurringPrices?.find(price => (price.type === "recurring") && (price.currency?.toLowerCase() === currency.toLowerCase()))?.id ?? "",
        quantity: totalLicensesAfterUpdate,
        quantityAdded: totalLicensesAfterUpdate - currentNumLicenses,
    }
}

interface IProps {
}

const AddLicensesField = ({ }: IProps) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const currentPlan = useSelector(getPlan);
    const [currentNumLicenses, setCurrentNumLicenses] = useState(currentPlan.currentSubscription?.quantity ?? currentPlan.features?.maxNumEmailUsers ?? 0);
    const [newNumLicenses, setNewNumLicenses] = useState(String(currentNumLicenses));

    const [readonly, setReadonly] = useState(true);
    const [validationError, setValidationError] = useState<string | undefined>(undefined);
    const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);

    const planUpdateDetails = useUpdateDetails(getNumber(newNumLicenses, currentNumLicenses));

    function validateNumLicenses(value: string) {
        const validationSchema = yup
            .number()
            .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)) // tests for whitespace
            .typeError('You must specify a number')
            .min(currentNumLicenses, `Minimum value: ${currentNumLicenses}`);
        try {
            validationSchema.validateSync(value);
            setValidationError(undefined);
        } catch (error) {
            setValidationError(error.message);
        }
        setNewNumLicenses(value);
    }

    const AddLicenseModal = () => (
        <StyledModal type="confirm" pretitle="My Plan" title="Purchase Confirmation" isOpen={showAddLicenseModal} handleModalClose={() => setShowAddLicenseModal(false)}>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                        <span className={classes.modalContentTitle}>
                            Thank you for your valued business.
                        </span>
                        <Typography variant="body1" component="p" className={classes.modalContentBody}>
                            <strong>By selecting Confirm, you agree to be charged for</strong>
                        </Typography>
                        <div className={classes.modalContentNumLicenses}>
                            {planUpdateDetails.hasStripe ? planUpdateDetails?.quantityAdded : planUpdateDetails?.quantity} additional licenses
                        </div>
                        <br />
                        {/* <Divider className={classes.modalDivider} />
                    <div className={classes.planInfoContainer} style={{ padding: "12px 0px" }}>
                        <div className={classes.planInfoRow}>
                            <div className={classes.modalFieldLabel}>Plan:</div>
                            <div className={classes.modalFieldValue}>{planUpdateDetails.planInfo?.product?.name} {planUpdateDetails.planChanged && <strong style={{ color: "#DB5205" }}>upgraded</strong>}</div>
                        </div>
                        <div className={classes.planInfoRow}>
                            <div className={classes.modalFieldLabel}>Per license:</div>
                            <div className={classes.modalFieldValue}>$20.00</div>
                        </div>
                        <div className={classes.planInfoRow}>
                            <div className={classes.modalFieldLabel}>Cost:</div>
                            <div className={classes.modalFieldValue}>$500.00</div>
                        </div>
                        <div className={classes.planInfoRow}>
                            <div className={classes.modalFieldLabel}>Tax:</div>
                            <div className={classes.modalFieldValue}>$65.00</div>
                        </div>
                        <div className={classes.planInfoRow}>
                            <div className={classes.modalFieldLabel}>Total:</div>
                            <div className={classes.modalFieldValue}>$565.00</div>
                        </div>
                    </div> */}
                        <Divider className={classes.modalDivider} />
                        <Typography variant="body1" component="p" className={classes.modalContentBody}>
                            For any questions about this message, please <a href="mailto:support@biasproof.com">contact our support staff</a>.
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.modalActionButtonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await dispatch(planUpgradeStarted());
                            if (planUpdateDetails.hasStripe) {
                                await dispatch(upgradePlanAction(
                                    {
                                        customerId: planUpdateDetails.customerId ?? "",
                                        subscriptionId: planUpdateDetails.subscriptionId ?? "",
                                        priceId: planUpdateDetails.oneTimePriceId,
                                        recurringPriceId: planUpdateDetails.recurringPriceId,
                                        quantity: planUpdateDetails.quantity,
                                        quantityAdded: planUpdateDetails.quantityAdded,
                                    }
                                ));
                            }
                            else {
                                await dispatch(checkoutAction(
                                    {
                                        priceId: planUpdateDetails?.recurringPriceId,
                                        quantity: planUpdateDetails?.quantity,
                                    }
                                ));
                            }
                            setReadonly(true);
                            setShowAddLicenseModal(false);
                        }}
                        className={classes.modalActionButton}
                    >
                        Confirm
                    </Button>
                </Grid>
                <Grid item xs={12} className={classes.modalCancelButtonContainer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setShowAddLicenseModal(false)}
                        className={classes.modalCancelButton}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </StyledModal>
    )

    return (
        <>
            <FormEditableInput
                value={newNumLicenses}
                readonly={readonly}
                error={validationError !== undefined}
                helperText={validationError}
                onChange={(v) => { validateNumLicenses(v); }}
                onClickEdit={() => { setReadonly(false) }}
                onClickCancel={() => {
                    setReadonly(true);
                    setNewNumLicenses(String(currentNumLicenses));
                    setValidationError(undefined);
                }
                }
                onClickSave={() => {
                    if (validationError === undefined) { // No errors
                        if (newNumLicenses !== String(currentNumLicenses)) { // new value
                            setShowAddLicenseModal(true);
                        }
                        else { // same value
                            setReadonly(true);
                        }
                    }
                }
                }
            />
            <AddLicenseModal />
        </>
    );
}

export default AddLicensesField;
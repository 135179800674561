import React from "react";
import { makeStyles, Theme, createStyles, withStyles, DialogTitleProps, IconButton, DialogContent } from "@material-ui/core";
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        modalHeading: {
            fontSize: "1.1875rem",
            fontWeight: 400,
            textTransform: "capitalize",
            color: "white",
            backgroundColor: "#D42C22",
            padding: '8px 24px 8px 16px',
        },
        headerTitle: {
            display: 'block',
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "12px",
            fontWeight: 300,
        },
        headerSubtitle: {
            display: 'block',
            color: "inherit",
            fontFamily: "inherit",
            fontSize: "18px",
            fontWeight: 900,
            textTransform: "uppercase",
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
            padding: '4px',
        },
    }),
);

interface IProps {
    type: "alert" | "confirm";
    pretitle: string;
    title: string;
    isOpen: boolean;
    handleModalClose: () => void;
    children: any,
}

const StyledModal = ({ type, pretitle, title, handleModalClose, isOpen, children }: IProps) => {
    const classes = useStyles();

    const Dialog = withStyles((theme: Theme) => ({
        paper: {
            height: "auto",
            width: 320,
            borderRadius: "1rem",
        },
    }))(MuiDialog);

    const DialogTitle = (props: DialogTitleProps) => {

        return (
            <MuiDialogTitle disableTypography className={classes.modalHeading} style={{ backgroundColor: type === "confirm" ? "#C8602A" : "#D42C22" }}>
                {props.children}
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleModalClose}>
                    <CloseIcon style={{ color: "white", fontSize: '18px' }} />
                </IconButton>
            </MuiDialogTitle>
        );
    };

    return (
        <>
            <Dialog onClose={handleModalClose} aria-labelledby="customized-dialog-title" open={isOpen}>
                <DialogTitle id="customized-dialog-title">
                    <span className={classes.headerTitle}>{pretitle}</span>
                    <span className={classes.headerSubtitle}>{title}</span>
                </DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default StyledModal;
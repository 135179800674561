import React from 'react'
import { Checkbox, CheckboxProps, createStyles, Grid, List, ListItemIcon, ListItemText, makeStyles, MenuItem, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.shades.lightgrey,
            width: 32,
            height: 32,
            minWidth: 0,
            color: "#fff",
            lineHeight: 1,
            textAlign: "center",
            background: theme.palette.primary.main,
            border: "3px solid #fff",
            borderRadius: "35px",
            boxShadow: "0 5px 5px 0 rgba(0,0,0,0.25)",
            boxSizing: "border-box",
            '&:before': {
                content: '""',
                display: 'block',
                width: 13.5,
                height: 15,
                margin: "auto",

            },

            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },

            'input:hover ~ &': {
                backgroundColor: theme.palette.shades.grey2,
            },

            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: theme.palette.primary.main,

            'input:hover ~ &': {
                backgroundColor: theme.palette.primary.main,
            },
        },
    })
);

// -- get course details
const StyledCheckBox = (props: CheckboxProps) => {
    const classes = useStyles();
    const { checked, ...others } = props;

    return (
        <Checkbox
            edge="end"
            key={props.key}
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<RemoveRoundedIcon className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<AddRoundedIcon className={classes.icon} />}
            inputProps={{ 'aria-label': 'Add course' }}
            checked={checked}
            {...others}
        />
    );
}

export default StyledCheckBox;
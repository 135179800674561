import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Grid, Theme, Typography } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';
import { navigate, RouteComponentProps } from '@reach/router';
import * as yup from 'yup';
import useValidForm from 'hooks/useValidForm';
import FormInput from 'components/formComponents/form-input';
import CustomButton from 'components/CustomButton';
import { confirmEmailAction, resetPasswordAction, reVerifyEmailAction } from 'store/user/userSlice';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { Paths } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(12),
            maxWidth: "600px",
            margin: "0 auto"
        },
        labelTitle: {
            fontSize: "20pt",
            textAlign: "center",
        }
    }),
);

interface IProps extends RouteComponentProps {

}

const ConfirmEmail = ({ }: IProps) => {
    const classes = useStyles();
    const dispatch: Dispatch = useDispatch();
    const [success, setSuccess] = useState<null | boolean>(null);

    useEffect(() => {
        async function confirmEmail() {
            const searchParams = new URLSearchParams(window.location.search);
            const token = searchParams.get("token");
            const email = searchParams.get("email");
            const oldEmail = searchParams.get("oldEmail");

            if (oldEmail) {
                const resp = await dispatch(reVerifyEmailAction({ token: token ?? "", email: email ?? "", oldEmail: oldEmail ?? "" }));
                if (reVerifyEmailAction.fulfilled.match(resp)) {
                    setSuccess(true);
                } else {
                    setSuccess(false);
                }
            } else {
                const resp = await dispatch(confirmEmailAction({ token: token ?? "", email: email ?? "" }));
                if (confirmEmailAction.fulfilled.match(resp)) {
                    setSuccess(true);
                } else {
                    setSuccess(false);
                }
            }
        }

        confirmEmail();
    }, []);

    function navigateHome() {
        navigate(Paths.LOGISTER);
    }

    if (success === null) return null;

    return (
        <MainLayout>
            <Grid container spacing={2} justify="center" className={classes.root}>
                <Grid item xs={12} className={classes.labelTitle}>
                    Confirm Email
                </Grid>
                {success === true &&
                    <>
                        <Grid item xs={12}>
                            <Typography component="p">Thank you for confirming your email.</Typography>
                            <Typography component="p">Please click the button below to login.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" fullWidth onClick={navigateHome}>
                                Return To Home
                            </Button>
                        </Grid>
                    </>
                }
                {success === false &&
                    <Grid item xs={12} >
                        There was an issue confirming your email.
                    </Grid>
                }
            </Grid>
        </MainLayout>
    );
}

export default ConfirmEmail;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { createStyles, Grid, makeStyles, Theme, Typography, } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';
import { DashboardAnalytics } from 'domain/analytic';
import { Intro } from 'domain/license'
import { hasPlan as hasPlanSelector } from 'store/plan/planSelectors';
import { hasGroup as hasGroupSelector } from 'store/group/groupSelectors';
import { getAllGroups } from 'store/group/groupSlice';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignContent: "flex-start",
            width: "auto",
            margin: `${theme.spacing(2)}px 64px ${theme.spacing(12)}px 64px`,
            height: "100%",
            minHeight: "calc(100vh - 300px)",

            "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "100vw",
                background: "linear-gradient(to bottom, #e6e6e6, #fff 520px)",
                borderTop: "1px solid" + theme.palette.shades.grey,
                zIndex: -1,
            }
        },
        heading: {
            fontWeight: 500,
            textTransform: "uppercase",
        },
    }),
);

const Home = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const hasPlan = useSelector(hasPlanSelector);
    const hasGroup = useSelector(hasGroupSelector);

    useEffect(() => {
        async function _getAllGroups() {
            dispatch(getAllGroups(true));
        }
        _getAllGroups();
    }, []);

    return (
        <MainLayout>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h1" className={classes.heading}>Dashboard</Typography>
                </Grid>
                {
                    !hasPlan &&
                    <Intro step={"plan"} />
                }
                {
                    hasPlan && !hasGroup &&
                    <Intro step={"group"} />
                }
                {
                    hasPlan && hasGroup &&
                    <DashboardAnalytics />
                }
            </Grid>
        </MainLayout>
    )
}

export default Home;
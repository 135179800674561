import { UnpackNestedValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export default function useValidForm<TData>(onSubmitFunc: (arg: TData) => void, validationSchema?: any, defaultValues?: TData, shouldUnregister = true) {
    const { handleSubmit, ...methods } = useForm<TData>({
        resolver: validationSchema ? yupResolver(validationSchema) : undefined,
        defaultValues: defaultValues as UnpackNestedValue<any>,
        shouldUnregister: shouldUnregister,
        mode: "onChange",
        reValidateMode: 'onChange',
    });

    return {
        onSubmit: handleSubmit(onSubmitFunc as UnpackNestedValue<any>),
        handleSubmit,
        ...methods
    }
}
import { useState } from "react";
import { GetCourseDetails } from "types/GetCourseDetails";

export const useCourseModuleData = (data: GetCourseDetails) => {
    const courseData = data?.courseCollection && data?.courseCollection.items[0];
    const courseModules = courseData?.courseModulesCollection?.items ?? [];
    const [activeModuleIndex, setActiveModule] = useState(0);
    const activeModuleContent = courseModules[activeModuleIndex];
    const activeModuleId = activeModuleContent?.sys.id ?? "";

    function selectModule(id: number) {
        setActiveModule(id);
    }

    return {
        activeModuleIndex,
        courseData,
        courseModules,
        activeModuleContent,
        activeModuleId,
        setActiveModule,
        selectModule
    }
}
import { Checkbox, FormHelperText, Grid, InputLabel, makeStyles } from "@material-ui/core";
import React from "react";
import { FC } from "react";
import { Controller } from "react-hook-form";

export interface CheckboxProps {
    label: any;
    name: string;
    id?: string;
    value?: any;
    checked?: boolean;
    control: any;
    error?: boolean
    errorMessage?: string
}

const useStyles = makeStyles(() => ({
    checkboxContainer: {
        display: "inline-flex",
        textAlign: "left",
        alignItems: "center",
        width: "100%",
        "& .Mui-checked": {
            color: "#c8602a",
        }
    },
    checkboxSpace: {
        flexBasis: "unset",
        maxWidth: "11.333333%;"
    },
    checkboxError: {
        color: "#e53935",
        marginLeft: "14px"
    }
}));

const FormCheckbox: FC<CheckboxProps> = ({
    label,
    name,
    id,
    value,
    checked,
    control,
    errorMessage,
    error = false
}) => {

    const classes = useStyles();

    return (
        <>
            <div className={classes.checkboxContainer}>
                <Grid item xs={12} sm={1} className={classes.checkboxSpace}>
                    <Controller
                        name={name}
                        id={id}
                        control={control}
                        render={(props) => (
                            <Checkbox
                                onChange={(e) => props.onChange(e.target.checked)}
                                checked={props.value}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>{label}</InputLabel>
                </Grid>
            </div>
            {error &&
                <Grid item xs={12}>
                    <FormHelperText className={classes.checkboxError}>{errorMessage}</FormHelperText>
                </Grid>
            }
        </>
    );
};

export default FormCheckbox;
import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";

import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Controller } from "react-hook-form";


const useStyles = makeStyles(() =>
    createStyles({
        richTextWrapper: {
            minheight: "200px"
        },
        richTextArea: {
        },
        richTextToolBar: {

        },
        richTextEditor: {
            padding: "0 1rem"
        }
    }),
);

interface IProps {
    name: string
    control: any
}

const options = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign'],
};


const RichTextEditor = ({ name, control }: IProps) => {
    const classes = useStyles();
    const editor = React.useRef(null);

    return (
        <>
            <Controller
                control={control}
                name={name}
                render={props => {
                    return (
                        <Editor
                            defaultContentState={props.value}
                            ref={editor}
                            onEditorStateChange={(editorState) => {
                                return props.onChange(
                                    convertToRaw(editorState.getCurrentContent())
                                );
                            }}
                            placeholder="Write something!"
                            wrapperClassName={classes.richTextWrapper}
                            toolbarClassName={classes.richTextToolBar}
                            editorClassName={classes.richTextEditor}
                            toolbar={options}
                        />
                    )
                }}
            />
        </>
    );
};

export default RichTextEditor;

import React, { useState } from 'react'
import * as yup from 'yup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from "countries-list";
import { getUser } from 'store/user/userSelector';
import useValidForm from 'hooks/useValidForm';
import FormInput from 'components/formComponents/form-input';
import FormNumber from 'components/formComponents/form-number';
import FormSelect from 'components/formComponents/form-select';
import EditableFormInput from 'components/formComponents/editable-form-input';
import StyledModal from 'components/StyledModal';
import { Divider } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import { updateEmailAction, updateUserInfoAction } from 'store/user/userSlice';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalHeader: {
            fontWeight: 600,
            fontSize: "1.3em",
            marginTop: "5px"
        },
        divider: {
            backgroundColor: "lightgray",
            width: "100%"
        },
        modalFooter: {
            fontSize: '0.9em',
            textAlign: "center"
        },
        saveButton: {
            minWidth: "150px"
        },
        buttonContainer: {
            textAlign: "center"
        }
    })
);


interface UpdatePhoneModalFormData extends UpdatePhoneFormData {
    onClose: () => void
}

const validationSchema = yup.object().shape({
    phoneNumber: yup.string()
        .typeError('A valid phone number is required')
        .required('A valid phone number is required'),
    ext: yup.string(),
});

const ModalContents = ({ phoneNumber, extension, onClose }: UpdatePhoneModalFormData) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const onSubmitFunc = async (formValues: UpdatePhoneFormData) => {
        await dispatch(updateUserInfoAction({ phoneNumber: formValues.phoneNumber, phoneNumberExt: formValues.extension }));
    }

    const { register, control, errors, onSubmit } = useValidForm<UpdatePhoneFormData>(onSubmitFunc, validationSchema, { phoneNumber, extension });

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={12}>
                    <FormInput
                        name="phoneNumber"
                        label="Phone Number"
                        placeholder="Phone Number"
                        inputRef={register}
                        error={errors.phoneNumber ? true : false}
                        helperText={errors.phoneNumber?.message}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormInput
                        name="extension"
                        label="Extension"
                        placeholder="Extension"
                        inputRef={register}
                        error={errors.extension ? true : false}
                        helperText={errors.extension?.message}
                    />
                </Grid>

                <Grid item xs={12} className={classes.buttonContainer}>
                    <CustomButton
                        label="Save"
                        type="submit"
                        className={classes.saveButton}
                    />
                    <br />
                    <CustomButton
                        secondary
                        label="Close"
                        onClick={onClose}
                    />

                </Grid>
            </Grid>
        </form>
    )
}


interface UpdatePhoneFormData {
    phoneNumber: string;
    extension: string;
}
const EditPhoneNumber = ({ phoneNumber, extension }: UpdatePhoneFormData) => {
    const classes = useStyles();

    const [showModal, setShowModal] = useState(false);

    function closeModal() {
        setShowModal(false);
    }

    return (
        <>
            <EditableFormInput
                name="phoneNumber"
                label="Phone Number"
                placeholder="Phone Number"
                readonly={true}
                value={phoneNumber}
                onEdit={() => setShowModal(true)}
            />

            <StyledModal
                type="confirm"
                pretitle="My Account"
                title="Change Phone Number"
                isOpen={showModal}
                handleModalClose={() => setShowModal(false)}
            >
                <ModalContents
                    phoneNumber={phoneNumber}
                    extension={extension}
                    onClose={closeModal}
                />
            </StyledModal>
        </>
    )
}

export default EditPhoneNumber;
import React, { useState } from 'react'
import * as yup from 'yup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from "countries-list";
import { getUser } from 'store/user/userSelector';
import useValidForm from 'hooks/useValidForm';
import FormInput from 'components/formComponents/form-input';
import FormNumber from 'components/formComponents/form-number';
import FormSelect from 'components/formComponents/form-select';
import EditableFormInput from 'components/formComponents/editable-form-input';
import StyledModal from 'components/StyledModal';
import { Divider } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import { updateEmailAction, updatePasswordAction, updateUserInfoAction } from 'store/user/userSlice';
import { UpdateEmailDto, UpdatePasswordDto } from 'external-api/account-api';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalHeader: {
            fontWeight: 600,
            fontSize: "1.3em",
            marginTop: "5px"
        },
        divider: {
            backgroundColor: "lightgray",
            width: "100%"
        },
        modalFooter: {
            fontSize: '0.9em',
            textAlign: "center"
        },
        saveButton: {
            minWidth: "150px"
        },
        buttonContainer: {
            textAlign: "center"
        }
    })
);



const validationSchema = yup.object().shape({
    oldPassword: yup.string().required('Password is required'),
    password: yup.string()
        .required('Password is required.')
        .min(8, 'Password is too short, should be at least 8 characters.')
        .matches(/^(?=.*?[A-Z]).*/, "Password must contain at least one uppercase letter."),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});
interface UpdatePasswordFormData {
    password: string
    confirmPassword: string
    oldPassword: string
}
const ModalContents = ({ onClose }: { onClose: () => void }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const onSubmitFunc = async (formValues: UpdatePasswordFormData) => {
        const data: UpdatePasswordDto = {
            currentPassword: formValues.oldPassword,
            newPassword: formValues.password,
            confirmPassword: formValues.confirmPassword
        }

        await dispatch(updatePasswordAction(data));
        onClose();
    }

    const { register, control, errors, onSubmit } = useValidForm<UpdatePasswordFormData>(onSubmitFunc, validationSchema);

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={2}>


                <Grid item xs={12} sm={12}>
                    <FormInput
                        name="oldPassword"
                        type="password"
                        label="Current Password"
                        placeholder="Current Password"
                        inputRef={register}
                        error={errors.oldPassword ? true : false}
                        helperText={errors.oldPassword?.message}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormInput
                        name="password"
                        type="password"
                        label="New Password"
                        placeholder="New Password"
                        inputRef={register}
                        error={errors.password ? true : false}
                        helperText={errors.password?.message}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormInput
                        name="confirmPassword"
                        type="password"
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        inputRef={register}
                        error={errors.confirmPassword ? true : false}
                        helperText={errors.confirmPassword?.message}
                    />
                </Grid>

                <Grid item xs={12} className={classes.buttonContainer}>
                    <CustomButton
                        label="Save"
                        type="submit"
                        className={classes.saveButton}
                    />
                    <br />
                    <CustomButton
                        secondary
                        label="Close"
                        onClick={onClose}
                    />

                </Grid>
            </Grid>
        </form>
    )
}




const EditPassword = () => {
    const classes = useStyles();

    const [showModal, setShowModal] = useState(false);

    function closeModal() {
        setShowModal(false);
    }

    return (
        <>
            <EditableFormInput
                name="Password"
                label="Password"
                placeholder="Password"
                readonly={true}
                value="***********"
                onEdit={() => setShowModal(true)}
            />

            <StyledModal
                type="confirm"
                pretitle="My Account"
                title="Change Password"
                isOpen={showModal}
                handleModalClose={() => setShowModal(false)}
            >
                <ModalContents
                    onClose={closeModal}
                />
            </StyledModal>
        </>
    )
}

export default EditPassword;
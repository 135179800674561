import React, { useState } from "react"
import { Button, createStyles, List, ListItem, ListItemText, TextField, Theme, Typography, makeStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";
import moment, { Moment } from 'moment';
import CustomButton from "./CustomButton";
import { IDateTime } from "domain/group/common/groupOverview/GroupOverview";

import { MobileDateTimePicker, DesktopDateTimePicker } from '@material-ui/pickers';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        customButton: {
            width: '100%',
            backgroundColor: "#c8602a",
            color: "white",
            fontSize: "0.875rem",
            lineHeight: '1.75',
            border: '0px',
            cursor: 'pointer',
            padding: '6px 16px',
            textTransform: 'uppercase',
            boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            '&:hover': {
                backgroundColor: '#b54308',
                borderColor: '#b54308',
                boxShadow: 'none',
            },
        },
        focusedDate: {
            border: "1px solid #c8602a",
        },
        dateTimeContainer: {
            marginTop: "10px"
        },
        dateTimeButtonContainer: {
            marginBottom: "30px"
        },
        dateRemoveIcon: {
            position: "relative",
            top: "5px",
            cursor: "pointer",
            fontSize: "20px",
            width: "10%"
        },
        datePickerInput: {
            width: "90%"
        }
    })
);

interface IProps {
    inline?: boolean
    value?: IDateTime
    onChange: any
    index?: number
    deleteDate: (index: number) => void
}

const CustomDatePicker = ({ onChange, deleteDate, value, index, inline = false }: IProps) => {
    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const isPastNow = moment(value?.dateTime).isSameOrBefore(moment());

    return (
        <>
            {inline ?
                <div className={classes.dateTimeButtonContainer}>
                    <CustomButton
                        label="Add Date"
                        onClick={() => setOpen(true)}
                    />
                    <MobileDateTimePicker
                        disablePast
                        minDateTime={new Date()}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        value={value?.dateTime}
                        minutesStep={15}
                        onChange={() => { }}
                        onAccept={(x: any) => {
                            const addedDate: IDateTime = {
                                dateTime: x?.toISOString(),
                                initialLoaded: false
                            }
                            onChange(addedDate)
                        }}
                        showToolbar={true}
                        renderInput={(props) => <></>}
                    />
                </div>
                :
                <ClickAwayListener
                    onClickAway={
                        () => setOpen(false)
                    }>
                    <div className={classes.dateTimeContainer}>
                        <Typography component="div" style={{ color: "gray", fontSize: "0.9em" }}>{index === 0 ? "First Date" : "Next Date"}</Typography>
                        <DesktopDateTimePicker
                            inputFormat="LLL"
                            disablePast={value?.initialLoaded}
                            disabled={isPastNow && value?.initialLoaded}
                            minutesStep={15}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            open={open}
                            value={value?.dateTime}
                            onChange={() => { }}
                            onAccept={(x: any) => {
                                const addedDate: IDateTime = {
                                    dateTime: x?.toISOString(),
                                    initialLoaded: false
                                }
                                onChange(index, addedDate);
                            }}
                            renderInput={(props) => <TextField className={classes.datePickerInput} {...props} helperText="" />}
                        />
                        {(!isPastNow || !value?.initialLoaded) && <CloseIcon onClick={() => deleteDate(index!)} className={classes.dateRemoveIcon} />}
                    </div>
                </ClickAwayListener>
            }
        </>
    )
}

export default CustomDatePicker
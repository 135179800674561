import { ApolloClient, InMemoryCache } from '@apollo/client';

const {
  REACT_APP_CONTENTFUL_SPACE_ID: SPACE_ID,
  REACT_APP_CONTENTFUL_CDN_ACCESS_TOKEN: CDN_ACCESS_TOKEN,
  REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN: PREVIEW_ACCESS_TOKEN
} = process.env

export const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}`,
  credentials: 'same-origin',
  headers: {
    Authorization: `Bearer ${process.env.NODE_ENV === 'production' ? CDN_ACCESS_TOKEN : PREVIEW_ACCESS_TOKEN}`
  },
  cache: new InMemoryCache()
});

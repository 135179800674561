import React, { useEffect } from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography, Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainLayout from 'layouts/MainLayout';
import AccountTabs from 'domain/account/AccountTabs';
import AccountInfo from 'domain/account/AccountInfo';
import SubscriptionInfo from 'domain/account/SubscriptionInfo';
import SubscriptionManagement from 'domain/account/SubscriptionManagement';
import { useDispatch } from 'react-redux';
import { getMyPlan } from 'store/plan/planSlice';
import { getAllPlansAction } from 'store/ui/uiSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        myAccountRoot: {
            width: "auto",
            margin: `${theme.spacing(2)}px 64px ${theme.spacing(12)}px 64px`,

            "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "100vw",
                background: "linear-gradient(to bottom, #e6e6e6, #fff 520px)",
                borderTop: "1px solid" + theme.palette.shades.grey,
                zIndex: -1,
            },
        },
        header: {
            fontWeight: 500,
            textTransform: "uppercase",
        },
        content: {
            borderRadius: 0,
            transition: "0.3s",
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.5)",
            "&:hover": {
                boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
            },
            height: "100%",
            minHeight: "300px",
            width: '100%',
            padding: theme.spacing(3)
        },
        subInfo: {
            padding: "12px 18px",
        },
        divider: {
            maxWidth: "25rem",
            backgroundColor: "#9E9E9E",
            margin: "0 2em 1em 0",
        },
    }),
);

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const MyAccount = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);

    const AccountTabsList = [
        { title: 'Account Information', render: () => <AccountInfo /> },
        { title: 'Subscription Management', render: () => <SubscriptionManagement /> },
    ]

    useEffect(() => {
        (async () => {
            await dispatch(getMyPlan());
            await dispatch(getAllPlansAction());
        })()
    }, []);

    return (
        <MainLayout>
            <Grid container className={classes.myAccountRoot} spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.header} variant="h1">My Account</Typography>
                </Grid>
                <Grid item md={4}>
                    <AccountTabs accountTabsList={AccountTabsList} onTabChange={(e) => setValue(e)} />
                    <div className={classes.subInfo}>
                        <Divider className={classes.divider} />
                        <SubscriptionInfo />
                    </div>
                </Grid>
                <Grid item md={4}>
                    <TabPanel value={value} index={value}>
                        {AccountTabsList[value].render()}
                    </TabPanel>
                </Grid>
            </Grid>
        </MainLayout>
    )
}

export default MyAccount;
import React from 'react'
import { gql } from "@apollo/client";
import { CardActionArea, CardContent, createStyles, IconButton, makeStyles, Modal, Typography } from '@material-ui/core';
import useContentfulQuery from 'hooks/useContentfulQuery';
import ContentfulLoading from 'components/contentful-loading';
import ContentfulError from 'components/contentful-error';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CustomCard from 'components/CustomCard';
import { useTestCategoryDetails } from './useTestCategoryDetails';
import TestSelection from './TestSelection';
import { GetSurveyDetails, GetSurveyDetailsVariables } from 'types/GetSurveyDetails';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const useStyles = makeStyles((theme) =>
  createStyles({

  })
);


const GET_TEST_DETAILS = gql`
query GetSurveyDetails($preview: Boolean!, $testLimit: Int!, $testIds: [String!]!) {
  surveyCollection(preview: $preview, limit: $testLimit, where: {sys: {id_in: $testIds}}) {
    items {
      sys {
        id
      }
      title
      slug
      category {
        label
        sys {
          id
        }
      }
      overview {
        json
        links {
          assets {
            block {
              ... on Asset {
                sys {
                  id
                }
                title
                contentType
                url
              }
            }
          }
        }
      }
    }
  }
}
`;

interface IProps {
  testIds: string[]
  title: string
  isSelected: boolean
}

// -- get course details
const TestCategoryDetails = ({ testIds, title, isSelected }: IProps) => {
  const { loading, error, data } = useContentfulQuery<GetSurveyDetails, GetSurveyDetailsVariables>(GET_TEST_DETAILS, { testLimit: 10, testIds: testIds });
  const { open, handleOpen, handleClose } = useTestCategoryDetails();

  function onModalOpen() {
    handleOpen();
  }

  function onModalClose() {
    handleClose();
  }

  if (loading) return <ContentfulLoading />;
  if (error) return <ContentfulError />;
  if (!data) return null;

  return (
    <>
      <CustomCard onClick={onModalOpen}>
        <CardActionArea>
          <CardContent style={{ textAlign: 'center' }}>
            <Typography component="h2">{title}</Typography>
            <IconButton aria-label="add">
              {isSelected ?
                <CheckCircleIcon style={{ fontSize: "3.5rem", color: "green" }} />
                :
                <AddCircleOutlineIcon color="error" style={{ fontSize: "3.5rem" }} />
              }
            </IconButton>
          </CardContent>
        </CardActionArea>
      </CustomCard>

      <Modal
        open={open}
        onClose={onModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <TestSelection data={data} onChildClick={onModalClose} />
      </Modal>
    </>
  )
}

export default TestCategoryDetails;
import { useSelector } from "react-redux";
import { getPlan } from "store/plan/planSelectors";
import { getTotalUsedSlots } from "store/group/groupSelectors";
import { getCurrentGroupSlotsUsed } from "store/ui/uiSelectors";

export const useMaxAvailableSlot = () => {
    const plan = useSelector(getPlan);
    const totalUsedSlots = useSelector(getTotalUsedSlots);
    const currentUsedSlots = useSelector(getCurrentGroupSlotsUsed);
    const availableSlots = (plan.features?.maxNumEmailUsers ?? 0) - totalUsedSlots + currentUsedSlots;
    return availableSlots > 0 ? availableSlots : 0;
}
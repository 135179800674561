import React from "react";
import { Box, Button, createStyles, Grid, makeStyles, Modal, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import MuiCardHeader from '@material-ui/core/CardHeader';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiCardActions from '@material-ui/core/CardActions';
import theme from "../../../theme";
import fileDownload from 'js-file-download';
import { useBadgeModal } from "./useBadgeModal";
import BadgeCardPreview from "./BadgeCardPreview";
import { useSelector } from "react-redux";
import { getUser } from "store/user/userSelector";

const useStyles = makeStyles((theme) =>
    createStyles({
        trainingPlanRoot: {
            marginTop: 15,
        },
        logo: {
            display: "block",
            maxHeight: 140,
            borderRadius: 6,
            boxShadow: "0 4px 3px 0 rgba(0,0,0,0.25)",
        },
        heading: {
            margin: "20px auto 5px auto",
            fontSize: 18,
            fontWeight: 900,
            textAlign: "center",
        },
        content: {
            fontSize: 12,
            textAlign: "center",
        },
        downloadButton: {
            margin: '0 auto',
            padding: "0 3.5em",
        }
    })
);

const Card = withStyles({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        margin: "0 6px",
        borderRadius: "12px",
        boxShadow: "8px 10px 12px 0 rgba(0,0,0,0.25)",
        overflow: "hidden",
    }
})(MuiCard);


const CardHeader = withStyles({
    root: {
        padding: 10,
        color: "#fff",
        background: theme.palette.orange.main,
        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.25)",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: "12px",
        fontWeight: 300,
    },
    subheader: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: "18px",
        fontWeight: 900,
        textTransform: "uppercase",
    },
})(MuiCardHeader);

const CardContent = withStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        padding: "30px 60px 0 60px",
    }
})(MuiCardContent);

const CardActions = withStyles({
    root: {
        padding: "0 60px 30px 60px",
    }
})(MuiCardActions);

interface IProps {
}

const BadgeCard = ({ }: IProps) => {
    const classes = useStyles();
    const { open, handleOpen, handleClose } = useBadgeModal();
    const userInfo = useSelector(getUser);

    function onDownload() {
        fileDownload(new Blob(), 'badge.zip');
    }

    function onModalOpen() {
        handleOpen();
    }

    function onModalClose() {
        handleClose();
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={userInfo.companyName}
                    subheader={"BiasProof Badge"}
                />
                <CardContent>

                    <img className={classes.logo} src="/Bias-Proof-Badge-Corporate-2021.svg" alt="Bias Proof Badge" />

                    <Typography className={classes.heading} component="strong">CONGRATULATIONS!</Typography>

                    <Typography className={classes.content} component="p">Your company can now display the BiasProof Certified Badge on your website!</Typography>
                    <Typography className={classes.content} component="p">Download the badge here.</Typography>

                </CardContent>
                <CardActions>
                    <Button onClick={onModalOpen} type="button" color="primary" variant="contained" size="medium" className={classes.downloadButton}>
                        Download
                    </Button>
                </CardActions>
            </Card>
            <Modal
                open={open}
                onClose={onModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <BadgeCardPreview
                    onModalClose={onModalClose}
                />
            </Modal>
        </>
    );
};

export default BadgeCard;

import { TextField, makeStyles, Theme, InputAdornment } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import React from "react";
import { FC } from "react";
import CreateIcon from '@material-ui/icons/Create';
export interface InputProps {
    name: string;
    inputRef?: any;
    type?: "text" | "email" | "password";
    label?: string;
    placeholder?: string;
    error?: boolean;
    helperText?: string;
    rows?: number;
    disabled?: boolean;
    fullWidth?: boolean;
    multiline?: boolean;
    readonly?: boolean;
    onEdit?: () => any
    value?: any
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {

        "& .MuiInputLabel-root": {
            position: "relative",
            padding: "0 0 0.5em 1.25em",
            color: theme.palette.orange.main,
            fontSize: 12,
            fontWeight: 700,
            transform: "none",
        },

        "& .MuiOutlinedInput-multiline": {
            padding: "0.5em 1em",
        },
    },
    rootInput: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid " + theme.palette.orange.main + "!important",
            borderRadius: "1em",

            "& legend": {
                width: 0,
            }
        },
    },
    input: {
        height: "2em",
        padding: "0 1em",

        "&::placeholder": {
            fontStyle: "italic",
        }
    },
    inputMultiline: {
        minHeight: "8.2em",
        padding: 0,
    },
    editIcon: {
        color: "#9e9e9e",
        fontSize: "1em",
        cursor: "pointer"
    }
}));

const EditableFormInput: FC<InputProps> = ({
    name,
    inputRef,
    type = "text",
    label,
    placeholder,
    error,
    value,
    helperText,
    rows = 1,
    onEdit,
    disabled = false,
    fullWidth = true,
    multiline = false,
    readonly = false
}) => {

    const classes = useStyles();

    return (
        <TextField
            value={value}
            name={name}
            inputRef={inputRef}
            type={type}
            label={label}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            variant="outlined"
            size="small"
            disabled={disabled || readonly}
            fullWidth={fullWidth}
            className={classes.root + " " + (readonly ? 'readonly' : '')}
            multiline={multiline}
            rows={rows}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                classes: {
                    root: classes.rootInput,
                    input: classes.input,
                    inputMultiline: classes.inputMultiline,
                },
                endAdornment: (
                    <>
                        {readonly &&
                            <InputAdornment position="end">
                                <CreateIcon className={classes.editIcon} onClick={onEdit} />
                            </InputAdornment>
                        }
                    </>
                ),
            }}
        />
    );
};

export default EditableFormInput;
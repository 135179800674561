import React from "react";
import { FC } from "react";
import * as yup from 'yup';
import useValidForm from "hooks/useValidForm";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import FormInput from "components/formComponents/form-input";
import { useDispatch } from "react-redux";
import { Dispatch } from "store";
import ErrorButton from "components/ErrorButton";

interface IProps {
}

interface FormData {
    email: string;
    password: string;
}

const defaultValues: FormData = {
    email: "",
    password: ""
}

const validationSchema = yup.object().shape({
    email: yup.string().email('A valid email address is required.').required('A valid email address is required.'),
    password: yup.string().required('Password is required'),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        submitButton: {
            textAlign: 'center',
        },
        labelTitle: {
            fontSize: "20pt",
            textAlign: "center",
        },
    }),
);


const DeleteAccountModalForm: FC<IProps> = () => {

    const classes = useStyles();
    const dispatch: Dispatch = useDispatch()

    const onSubmitFunc = async (formValues: FormData) => {
    }

    const { register, errors, onSubmit } = useValidForm<FormData>(onSubmitFunc, validationSchema, defaultValues);

    return (
        <form onSubmit={onSubmit} className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormInput
                        name="email"
                        placeholder="Account email address"
                        inputRef={register}
                        error={errors.email ? true : false}
                        helperText={errors.email?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInput
                        name="password"
                        type="password"
                        placeholder="Account password"
                        inputRef={register}
                        error={errors.password ? true : false}
                        helperText={errors.password?.message}
                    />
                </Grid>
                <Grid item xs={12} className={classes.submitButton}>
                    <ErrorButton
                        label="Delete account"
                        type="submit"
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default DeleteAccountModalForm;
import React from 'react'
import { gql } from "@apollo/client";
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import useContentfulQuery from 'hooks/useContentfulQuery';
import ContentfulLoading from 'components/contentful-loading';
import ContentfulError from 'components/contentful-error';
import { GetAllCoursesList, GetAllCoursesListVariables } from 'types/GetAllCoursesList';

import CourseDetails from './CourseDetails';
import { useCoursePlans } from './useCoursePlans';

const useStyles = makeStyles(() =>
  createStyles({
    trainingPlanRoot: {
    },
  })
);

const GET_ALL_COURSES_LIST = gql`
  query GetAllCoursesList($preview: Boolean!) {
    courseCollection(preview: $preview) {
      items {
        sys {
          id
        }
        courseTitle
        slug
        category {
          label
          sys {
            id
          }
        }
      }
    }
  }    
`;

interface IProps {
}

const CoursePlans = ({  }: IProps) => {
  const classes = useStyles();
  const { loading, error, data } = useContentfulQuery<GetAllCoursesList, GetAllCoursesListVariables>(GET_ALL_COURSES_LIST, { courseLimit: 5, courseModuleLimit: 10 })

  const { form, categories, append, remove, fields, selectedCourses } = useCoursePlans({ data });

  function addCourse({ courseId, categoryId }: { courseId: string, categoryId: string }) {
    const {courseConfiguration} = form.getValues();
    const courseData = courseConfiguration?.find(x => x.id === courseId);
    if (!courseData) {
      append({
        id: courseId,
        categoryId: categoryId,
        courseModuleIds: []
      });
    }
  }

  function removeCourse(id: string) {
    const {courseConfiguration} = form.getValues();
    const courseData = courseConfiguration?.find(x => x.id === id);
    if (courseData?.courseModuleIds?.length === 0) {
      const index = fields.findIndex(x => x.id === id);
      remove(index);
    }
  }

  if (loading) return <ContentfulLoading />;
  if (error) return <ContentfulError />;
  if (!data) return null;
  // -- render courses based on form.
  return (
        <Grid container className={classes.trainingPlanRoot} spacing={2}>
          {categories && categories.map((item, index) => {
            return (
              <Grid item md={4} xs={12} lg={4} key={index}>
                <CourseDetails
                  isSelected={selectedCourses?.some(x => x.id === item?.sys?.id!) ?? false}
                  categoryId={item?.category?.sys?.id!}
                  addCourse={addCourse}
                  removeCourse={removeCourse}
                  courseId={item?.sys?.id!}
                />
              </Grid>
            )
          })}
        </Grid>
  )
}

export default CoursePlans;
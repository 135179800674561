import { IPlanConfiguration } from "domain/group/types";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import { useState, useEffect } from "react";

export const useTestSelection = (testId: string) => {
    const form = useFormContext<IPlanConfiguration>();
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        if (testId) {
            const { testConfiguration } = form.getValues();
            const courseIndex = testConfiguration?.findIndex(x => x.id === testId) ?? -1;
            setIndex(courseIndex);
        }
    }, [testId])


    const fieldArrayProps = useFieldArray(
        {
            control: form.control,
            name: "testConfiguration",
            keyName: "fieldId"
        }
    );

    const selectedTests = useWatch<{ id: string, categoryId: string }[]>({
        control: form.control,
        name: `testConfiguration`
    });

    return {
        form,
        selectedTests,
        isSelected: selectedTests?.some(x => x.id === testId) ?? false,
        ...fieldArrayProps
    }
}
import React from 'react';
import { Redirect, Router } from "@reach/router"
import { Paths } from "utils/constants"
import Group from 'pages/Group';
import GroupsDashboard from 'pages/GroupsDashboard';
import Support from 'pages/Support';
import Privacy from 'pages/Privacy';
import About from 'pages/About';
import MyAccount from 'pages/MyAccount';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated, isEmailConfirmed } from 'store/user/userSelector';
import Home from 'pages/Home';
import Logister from 'pages/Logister';
import Plans from 'pages/Plans';
import Logos from 'pages/Logos';
import Page404 from 'pages/404';
import Page500 from 'pages/500';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import ConfirmEmail from 'pages/ConfirmEmail';
import PaymentSuccess from 'pages/PaymentSuccess';
import PaymentFailure from 'pages/PaymentFailure';
import { enqueueNotification } from 'store/ui/uiSlice';
import EditPlan from 'pages/EditPlan';
import { ScrollToTop } from 'hooks/useScrollToTop.';

interface IRoute {
  component: any
  path: string
  isAuthenticated: boolean
  isVerified?: boolean
}

const Route = ({ component: Component, isAuthenticated, isVerified = true, ...rest }: IRoute) => {
  const dispatch = useDispatch();
  if (!isVerified) {
    dispatch(enqueueNotification({
      message: 'Please verify your email before adding a group.',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error'
      }
    }));
    return <Redirect from="" to={Paths.HOME} noThrow />
  }
  if (isAuthenticated) {
    return <Component {...rest} />
  }
  return <Redirect from="" to={Paths.LOGISTER} noThrow />
};


function App() {
  const isAuth = useSelector(isAuthenticated);
  const isVerified = useSelector(isEmailConfirmed);
  return (
    <Router>
      <ScrollToTop path="/">
        <Logister path={Paths.LOGISTER} />
        <Route component={Home} path={Paths.HOME} isAuthenticated={isAuth} />
        <Route component={Plans} path={Paths.PLANS} isAuthenticated={isAuth} />
        <Route component={EditPlan} path={Paths.EDIT_PLAN} isAuthenticated={isAuth} />
        <Route component={MyAccount} path={Paths.MYACCOUNT} isAuthenticated={isAuth} />
        <Route component={GroupsDashboard} path={Paths.GROUPS} isAuthenticated={isAuth} />
        <Route component={Group} path={`${Paths.GROUPS}/add`} isAuthenticated={isAuth} isVerified={isVerified} />
        <Route component={Group} path={`${Paths.GROUPS}/:groupId`} isAuthenticated={isAuth} isVerified={isVerified} />
        <Route component={Support} path={Paths.SUPPORT} isAuthenticated={isAuth} />
        <Route component={About} path={Paths.ABOUT} isAuthenticated={isAuth} />
        <Privacy path={Paths.PRIVACY} />
        {/* <Logos path={Paths.LOGOS} /> */}
        <ForgotPassword path={Paths.FORGOT_PASSWORD} />
        <ResetPassword path={Paths.RESET_PASSWORD} />
        <ConfirmEmail path={Paths.CONFIRM_EMAIL} />
        <PaymentSuccess path={`${Paths.PAYMENT_SUCCESS}/:src`} />
        <PaymentSuccess path={`${Paths.PAYMENT_SUCCESS}`} />
        <PaymentFailure path={Paths.PAYMENT_FAILURE} />
        <Page404 path={Paths.HTTP_404_ERROR} />
        <Page500 path={Paths.HTTP_500_ERROR} />
        <Page404 default />
      </ScrollToTop>
    </Router>
  );
}

export default App;


import { ConfigurationParameters, GroupApi, Configuration, PlanApi, PlanConfigurationApi, ParticipantApi, PlanDTO, PlanConfigurationDTO } from "external-api/license-api";
import Cookies from "js-cookie";
import { trackPromise } from "react-promise-tracker";
import { CookieNames } from "utils/constants";
import { getToken } from "utils/cookieHelper";

import http from "./axiosService";
const apiUrl = `${process.env.REACT_APP_LICENSE_API_URL}`;

function getApi() {
    const token = getToken();
    const configParams: ConfigurationParameters = {
        basePath: apiUrl,
        baseOptions: {
            headers:
            {
                Authorization: `Bearer ${token}`
            }
        }
    };
    const axios = http.axios;
    const configuration = new Configuration(configParams);
    return new PlanConfigurationApi(configuration, apiUrl, axios);
}

export default class PlanConfigurationService {

    planConfigurationApi: PlanConfigurationApi

    constructor() {
        this.planConfigurationApi = getApi();
    }

    updatePlanConfiguration = async (planConfigurationId: string, planConfiguration: PlanConfigurationDTO) => {
        const resp = await trackPromise(this.planConfigurationApi.planConfigurationUpdatePlanConfiguration(planConfigurationId, planConfiguration));
        return resp.data;
    }
}
import React from 'react'
import { Checkbox, createStyles, Grid, List, ListItemIcon, ListItemText, makeStyles, MenuItem, Typography, withStyles, IconButton, ListItemSecondaryAction, Button } from '@material-ui/core';
import { GetCourseDetails } from 'types/GetCourseDetails';
import CustomButton from 'components/CustomButton';
import { useCourseModuleData } from './useCourseModuleData';
import { useCourseModuleStepper } from './useCourseModuleStepper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ContentfulRichText from 'components/contentful/ContenfulRichText';
import StyledCheckBox from './components/StyledCheckBox';
import { Controller } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import { Close } from '@material-ui/icons';
import CustomCard from 'components/CustomCard';

const useStyles = makeStyles((theme) =>
    createStyles({
        coursePreviewModal: {
            position: 'absolute',
            display: 'grid',
            grid: 'auto auto auto / auto 562px',
            gridTemplateAreas: '"header header" "list content" "footer footer"',
            top: '50%',
            left: '50%',
            width: theme.breakpoints.values.xl,
            height: '85%',
            maxWidth: 960,
            maxHeight: 720,
            minHeight: 600,
            margin: 0,
            padding: 0,
            background: "#fff",
            borderRadius: 20,
            boxShadow: '10px 10px 10px rgba(0,0,0,0.25)',
            overflow: 'hidden',
            transform: 'translate(-50%, -50%)',
        },
        heading: {
            gridArea: 'header',
            margin: 0,
            padding: 25,
            fontWeight: 500,
            textTransform: 'uppercase',
        },
        close: {
            position: "absolute",
            top: 12,
            right: 12,
            padding: 1,
            color: "#fff",
            fontWeight: 700,
            background: "#9d9d9d",
            border: "2px solid #fff",
            boxShadow: "0 4px 2px rgba(0,0,0,0.25)",

            "&:hover, &:focus": {
                background: theme.palette.shades.grey,
            }
        },
        list: {
            padding: '0 25px',
            overflow: 'auto',

            '& .MuiList-root': {
                paddingRight: 0,
            },
        },
        listText: {
            marginBottom: 20,
            fontSize: 14,
            lineHeight: 1.15,
        },
        richTextPreview: {
            padding: "0 25px",
            overflow: 'auto',

            "& video": {
                marginBottom: 20,
            },

        },
        footer: {
            gridArea: 'footer',
            padding: 20,
            textAlign: 'center',
        },
        done: {
            display: "flex",
            margin: "0 0 0 auto !important",
        },
        listContainer: {
            display: "flex"
        },
        customCard: {
            width: "auto",
            margin: "0 6px",
            padding: "10px 20px",
            backgroundColor: '#fff',
            boxShadow: "0px 1px 4px 1px rgba(0,0,0,0.25)",
            borderRadius: 6,
            overflow: "hidden",
        }
    })
);


const StyledMenuItem = withStyles((theme) => ({
    root: {
        marginTop: 5,
        marginBottom: 5,
        marginRight: 10,
        paddingRight: 0,
        backgroundColor: theme.palette.shades.white,
        border: "2px solid transparent",
        borderRadius: 6,
        boxShadow: '4px 4px 12px -3px rgba(0,0,0,0.50)',
        flexGrow: 1,
        '&.checked': {
            borderColor: theme.palette.primary.main,
            boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.25)',
            // border: `2px solid ${theme.palette.primary.main}`,
        },

        '&:focus': {
            backgroundColor: theme.palette.shades.grey2,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {

            },
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.shades.lightgrey,

            '&.checked': {
                borderColor: theme.palette.primary.main,
            }
        },
        '&:hover': {
            '&.Mui-selected': {
                backgroundColor: theme.palette.shades.lightgrey,

            }
        },

        '& .MuiTypography-root': {
            lineHeight: 1.15,
        }
    },
}))(MenuItem);

interface IProps {
    data: GetCourseDetails
    onChildClick: any
    ref: any
}
// -- get course details
const CourseModulePreview = React.forwardRef(({ data, onChildClick, ref }: IProps) => {
    const classes = useStyles();
    const { activeModuleIndex, courseData, courseModules, selectModule, activeModuleContent, setActiveModule, activeModuleId } = useCourseModuleData(data);
    const { append, removeModule, isSelected, selectedCourseModules, control } = useCourseModuleStepper(courseData?.sys?.id!, activeModuleId);

    function toggleModule(moduleId: string) {
        if (moduleId && selectedCourseModules) {
            if (selectedCourseModules.some(x => x.moduleId === moduleId)) {
                removeModule(moduleId);
            }
            else {
                append({ moduleId });
            }
        }
    }

    function removeAllModules(event: any) {
        selectedCourseModules?.forEach(mod => removeModule(mod.moduleId));
        // handleClose(event);
    }

    function handleClose(event: any) {
        onChildClick(event.target.name);
    }

    return (
        <div className={classes.coursePreviewModal} ref={ref}>
            <Typography variant="h1" component="h2" className={classes.heading}>{courseData?.courseTitle}</Typography>
            <IconButton aria-label="Close" component="button" className={classes.close} onClick={onChildClick}>
                <Close />
            </IconButton>
            <div className={classes.list}>
                <Typography component="div" className={classes.listText}>Please select your course modules. You can preview the content of each module on the right panel. </Typography>
                <Typography component="span">Click To Preview <span style={{ float: "right" }}> Add</span></Typography>
                < hr />

                <List component="nav" aria-label="secondary mailbox folders">
                    {courseModules && courseModules.map((module, index) => {
                        const checked = selectedCourseModules?.some(x => x.moduleId === module?.sys?.id!);
                        return (
                            <div className={classes.listContainer}>
                                <StyledMenuItem
                                    selected={index === activeModuleIndex} className={(checked ? "checked" : "")} style={{ whiteSpace: 'normal' }} onClick={() => selectModule(index)} >
                                    <ListItemText primary={module?.title} />
                                </StyledMenuItem>

                                <StyledCheckBox
                                    key={`checkbox-${index}`}
                                    checked={checked ? true : false}
                                    onChange={() => toggleModule(module?.sys?.id!)}
                                />
                            </div>
                        )
                    })}
                </List>
                <hr />

            </div>
            <div className={classes.richTextPreview}>
                <Typography variant="h2" component="h2" style={{ textTransform: 'uppercase', marginLeft: "20px", fontWeight: "bold" }}>{activeModuleContent?.title}</Typography>
                <div className={classes.customCard}>
                    {activeModuleContent &&
                        <ContentfulRichText
                            data={activeModuleContent?.hrAdminPreviewContent}
                        />
                    }
                </div>
            </div>
            <div className={classes.footer}>
                <Grid container spacing={0}>
                    <Grid item xs={5}>
                        <Grid item xs={12}>
                            <CustomButton
                                label="Add Selected Modules"
                                onClick={onChildClick}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={removeAllModules} disabled={selectedCourseModules?.length === 0}>
                                <Typography component="span" style={{ textDecoration: "underline", fontWeight: "bold" }}>Clear All</Typography>
                            </Button>
                        </Grid>

                    </Grid>
                    <Grid item xs={7}></Grid>
                </Grid>
            </div>
        </div >
    )
})

export default CourseModulePreview;
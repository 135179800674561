import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Theme as NivoTheme } from '@nivo/core'
import { BarSvgProps, ResponsiveBar } from '@nivo/bar'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    }),
);

const nivoTheme: NivoTheme = {
    "fontSize": 12,
}

const nivoBarCommonProps: Omit<BarSvgProps, 'data'> = {

    groupMode: 'stacked',
    layout: 'vertical',
    reverse: false,

    minValue: 'auto',
    maxValue: 'auto',

    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },

    padding: 0.15,
    innerPadding: 1,
    margin: { top: 10, right: 0, bottom: 30, left: 45 },

    axisBottom: {},
    axisLeft: {},
    enableGridX: false,
    enableGridY: true,

    enableLabel: true,
    label: 'value',
    labelSkipWidth: 0,
    labelSkipHeight: 0,
    labelLinkColor: 'theme',
    labelTextColor: 'theme',

    colors: { scheme: 'nivo' },

    defs: [],
    fill: [],

    borderRadius: 0,
    borderWidth: 0,
    borderColor: { from: 'color' },

    isInteractive: true,

    legends: [],

}

interface IProps {
    nivoBarProps: { [key: string]: any }
}
const BarChart = ({ nivoBarProps }: IProps) => {

    const classes = useStyles();

    return (
        <ResponsiveBar
            data={[]}
            theme={nivoTheme}
            {...nivoBarCommonProps}
            {...nivoBarProps}
        />
    )
}

export default BarChart;



import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Box, withStyles } from '@material-ui/core';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() =>
    createStyles({
        progressIndicator: {
            color: "white",
            fontSize: "3rem",
            fontWeight: "lighter"
        },
        progressLabel: {
            color: "white",
            fontSize: "0.85rem",
            textAlign: "center"
        }
    }),
);

const CircularProgress = withStyles({
    root: {
        color: "white",
        width: "100% !important",
        height: "100% !important",
        padding: "1rem"
    }
})(MuiCircularProgress);


interface IProps {
    value: number
    graphLabel: string
    label:string
}
const ParticipantProgressGraph = ({ label, value, graphLabel}: IProps) => {
    const classes = useStyles();
    return (
        <>
            <Box position="relative" display="inline-flex" style={{ width: "100%", height: "100%" }}>
                <CircularProgress thickness={2} variant="determinate" value={value} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" className={classes.progressIndicator}>{graphLabel}</Typography>
                </Box>
            </Box>
            <Typography component="div" className={classes.progressLabel}>{label}</Typography>
        </>
    );
}

export default ParticipantProgressGraph;
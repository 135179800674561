const EMAILS_TEMPLATE_URI = encodeURI("data:text/csv;charset=utf-8," +
  [
    ["user1@example.com"],
    ["user2@example.com"],
    ["user3@example.com"],
  ].map(e => e.join(",")).join("\n"));

const useEmailsTemplateURI = () => {
  return {
    dataURL : EMAILS_TEMPLATE_URI
  }
}

export default useEmailsTemplateURI;
import { Button, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { FC } from "react";
import * as yup from 'yup';
import useValidForm from "hooks/useValidForm";
import FormInput from "components/formComponents/form-input";
import { loginAction, mockLogin } from "store/user/userSlice";
import { useDispatch } from "react-redux";
import { navigate } from "@reach/router"
import { Paths } from "utils/constants";
import { getMyPlan } from "store/plan/planSlice";
import { getAllGroups } from "store/group/groupSlice";
import { Dispatch } from "store";

interface IProps {
}

interface LoginFormData {
    email: string;
    password: string;
}

const defaultValues: LoginFormData = {
    email: "",
    password: ""
}

const validationSchema = yup.object().shape({
    email: yup.string().email('A valid email address is required.').required('A valid email address is required.'),
    password: yup.string().required('Password is required'),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        submitButton: {
            marginTop: "15px",
        },
        labelTitle: {
            fontSize: "20pt",
            textAlign: "center",
        },
        forgotPasswordMessage: {
            marginBottom: "15px",
            "& > span": {
                color: "#c8602a",
                fontWeight: 600,
                cursor: "pointer",
            },
            fontSize: "15pt",
            textAlign: "center",
        },
        signWithLabel: {
            width: "100%",
            textAlign: "center",
            borderBottom: "1px solid #000",
            lineHeight: "0.1em",
            margin: "40px 0 20px",
            fontSize: "15pt",

            "& > span": {
                background: "#fff",
                padding: "0 20px",
            }
        },
        facebookButton: {
            backgroundColor: "#3b5998",

            '&:hover': {
                backgroundColor: '#8b9dc3',
            },

        },
        permissionLabel: {
            textAlign: 'center',
            fontSize: "12pt",
        }
    }),
);


const Login: FC<IProps> = () => {

    const dispatch: Dispatch = useDispatch()

    const onSubmitFunc = async (formValues: LoginFormData) => {
        const resp = await dispatch(loginAction(formValues));
        if (loginAction.fulfilled.match(resp)) {
            await dispatch(getMyPlan());
        }
    }

    const { register, errors, onSubmit } = useValidForm<LoginFormData>(onSubmitFunc, validationSchema, defaultValues);
    const classes = useStyles();

    function forgotPassword() {
        navigate(Paths.FORGOT_PASSWORD);
    }

    function mockLogins() {
        dispatch(mockLogin());
        navigate(Paths.HOME)
    }

    return (
        <form onSubmit={onSubmit} className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.labelTitle}>
                    Welcome back
                </Grid>
                <Grid item xs={12}>
                    <FormInput
                        name="email"
                        label="Email"
                        placeholder="Email"
                        inputRef={register}
                        error={errors.email ? true : false}
                        helperText={errors.email?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInput
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Password"
                        inputRef={register}
                        error={errors.password ? true : false}
                        helperText={errors.password?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" fullWidth className={classes.submitButton}>
                        SIGN IN
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.forgotPasswordMessage} component="p">Forgot your <span onClick={forgotPassword}>Password?</span></Typography>
                </Grid>
                {/* <Grid item xs={12}>
                    <Button type="button" onClick={mockLogins} variant="contained" fullWidth className={classes.submitButton}>
                        MOCK SIGN IN
                    </Button>
                </Grid>  */}
            </Grid>
        </form>
    );
};

export default Login;
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Container, Grid, Theme, Toolbar, Typography } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import { navigate, RouteComponentProps } from '@reach/router';
import { Paths } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignContent: "flex-start",
            width: "100%",
            height: "100vh",
            margin: 0,
            background: "url('/images/general/graphic_error404.svg') no-repeat center bottom",
            backgroundSize: "1575px auto",
        },

        headerWrapper: {
            height: "90px",
        },

        logo: {
            display: "block",
            width: 172,
            height: "auto",
        },

        container: {
            maxWidth: 400,
            paddingTop: 70,
        },

        heading: {
            fontSize: 36,
            fontWeight: 700,
        },

        subheading: {
            fontSize: 20,
        },

        message: {
            margin: "20px 0",
            color: theme.palette.shades.greyMiddle,
            fontSize: 14,
        },
        button: {
            display: "flex",
            margin: "0 auto !important",
            padding: "0 2.5em !important",
        }
    }),
);

interface IProps extends RouteComponentProps {

}

const Page404 = ({ }: IProps) => {
    const classes = useStyles();

    function goHome() {
        navigate(Paths.HOME);
    }

    return (
        <Grid className={classes.root} container spacing={2}>
            <Grid item xs={12}>
                <Toolbar className={classes.headerWrapper}>
                    <img className={classes.logo} src="/images/general/logo.svg" alt="Bias Proof logo" />
                </Toolbar>
            </Grid>
            <Container className={classes.container}>
                <Typography className={classes.heading} component="h1">Oops!</Typography>
                <Typography className={classes.subheading} component="h3">We can't seem to find the page you are looking for.</Typography>
                <Typography className={classes.message} component="div">Error code: 404</Typography>
                <CustomButton
                    label="Go to Home"
                    onClick={goHome}
                    className={classes.button }
                />
            </Container>
        </Grid>
    );
}

export default Page404;
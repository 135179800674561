import palette from "theme/palette";

export default {
    "@global": {
        body: {
            backgroundColor: "white",
        },
        '.readonly .MuiInputBase-root.Mui-disabled': {
              color: `${palette.text.primary} !important`
        }
    }
};

/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof License API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AggregateCourseResultDTO
 */
export interface AggregateCourseResultDTO {
    /**
     * 
     * @type {string}
     * @memberof AggregateCourseResultDTO
     */
    courseId?: string;
    /**
     * 
     * @type {number}
     * @memberof AggregateCourseResultDTO
     */
    numberOfPassed: number;
    /**
     * 
     * @type {number}
     * @memberof AggregateCourseResultDTO
     */
    numberOfCompleted: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AggregateCourseResultDTO
     */
    scores?: Array<number>;
}
/**
 * 
 * @export
 * @interface AggregateTestResultDTO
 */
export interface AggregateTestResultDTO {
    /**
     * 
     * @type {string}
     * @memberof AggregateTestResultDTO
     */
    testId?: string;
    /**
     * 
     * @type {number}
     * @memberof AggregateTestResultDTO
     */
    numberOfCompleted: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AggregateTestResultDTO
     */
    scores?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AggregateTestResultDTO
     */
    surveyResponses?: Array<string>;
}
/**
 * 
 * @export
 * @interface CourseConfigurationDTO
 */
export interface CourseConfigurationDTO {
    /**
     * 
     * @type {string}
     * @memberof CourseConfigurationDTO
     */
    categoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseConfigurationDTO
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseConfigurationDTO
     */
    courseModuleIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface CourseResultDTO
 */
export interface CourseResultDTO {
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    participantId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseResultDTO
     */
    score: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseResultDTO
     */
    passed: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    completedDate: string;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    courseId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    feedback?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseResultDTO
     */
    anonymousUser: boolean;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    scheduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseResultDTO
     */
    groupId?: string;
}
/**
 * 
 * @export
 * @interface CreateCheckoutSessionRequest
 */
export interface CreateCheckoutSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutSessionRequest
     */
    priceId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCheckoutSessionRequest
     */
    quantity: number;
}
/**
 * 
 * @export
 * @interface DowngradeSubscriptionRequest
 */
export interface DowngradeSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof DowngradeSubscriptionRequest
     */
    subscriptionID?: string;
    /**
     * 
     * @type {string}
     * @memberof DowngradeSubscriptionRequest
     */
    priceId?: string;
    /**
     * 
     * @type {number}
     * @memberof DowngradeSubscriptionRequest
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof DowngradeSubscriptionRequest
     */
    quantityRemoved: number;
}
/**
 * 
 * @export
 * @interface EcomPlanDTO
 */
export interface EcomPlanDTO {
    /**
     * 
     * @type {Product}
     * @memberof EcomPlanDTO
     */
    product?: Product;
    /**
     * 
     * @type {Array<Price>}
     * @memberof EcomPlanDTO
     */
    recurringPrices?: Array<Price>;
    /**
     * 
     * @type {Array<Price>}
     * @memberof EcomPlanDTO
     */
    oneTimePrices?: Array<Price>;
}
/**
 * 
 * @export
 * @interface EmailConfigurationDTO
 */
export interface EmailConfigurationDTO {
    /**
     * 
     * @type {string}
     * @memberof EmailConfigurationDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfigurationDTO
     */
    invitationEmailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfigurationDTO
     */
    invitationEmailBody?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfigurationDTO
     */
    reminderEmailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailConfigurationDTO
     */
    reminderEmailBody?: string;
    /**
     * 
     * @type {number}
     * @memberof EmailConfigurationDTO
     */
    numberOfReminders: number;
    /**
     * 
     * @type {number}
     * @memberof EmailConfigurationDTO
     */
    daysBetweenReminders: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailConfigurationDTO
     */
    invitationalEmailDates?: Array<string>;
}
/**
 * 
 * @export
 * @interface ExpandableFieldOfProduct
 */
export interface ExpandableFieldOfProduct {
    /**
     * 
     * @type {string}
     * @memberof ExpandableFieldOfProduct
     */
    id?: string;
    /**
     * 
     * @type {Product}
     * @memberof ExpandableFieldOfProduct
     */
    expandedObject?: Product;
}
/**
 * 
 * @export
 * @interface GroupDTO
 */
export interface GroupDTO {
    /**
     * 
     * @type {string}
     * @memberof GroupDTO
     */
    id?: string;
    /**
     * 
     * @type {EmailConfigurationDTO}
     * @memberof GroupDTO
     */
    emailConfiguration?: EmailConfigurationDTO;
    /**
     * 
     * @type {PlanConfigurationDTO}
     * @memberof GroupDTO
     */
    planConfiguration?: PlanConfigurationDTO;
    /**
     * 
     * @type {string}
     * @memberof GroupDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupDTO
     */
    numberOfSlotsUsed: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupDTO
     */
    participants?: Array<string>;
    /**
     * 
     * @type {GroupStatus}
     * @memberof GroupDTO
     */
    status: GroupStatus;
    /**
     * 
     * @type {boolean}
     * @memberof GroupDTO
     */
    anonymousUsers: boolean;
    /**
     * 
     * @type {Array<TestScheduleDTO>}
     * @memberof GroupDTO
     */
    testSchedules?: Array<TestScheduleDTO>;
}
/**
 * 
 * @export
 * @interface GroupOverviewDTO
 */
export interface GroupOverviewDTO {
    /**
     * 
     * @type {string}
     * @memberof GroupOverviewDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupOverviewDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupOverviewDTO
     */
    description?: string;
    /**
     * 
     * @type {EmailConfigurationDTO}
     * @memberof GroupOverviewDTO
     */
    emailConfiguration?: EmailConfigurationDTO;
}
/**
 * 
 * @export
 * @interface GroupResultsDTO
 */
export interface GroupResultsDTO {
    /**
     * 
     * @type {string}
     * @memberof GroupResultsDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupResultsDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupResultsDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupResultsDTO
     */
    numberOfSlotsUsed: number;
    /**
     * 
     * @type {string}
     * @memberof GroupResultsDTO
     */
    nextDate?: string;
    /**
     * 
     * @type {Array<ResultsDTO>}
     * @memberof GroupResultsDTO
     */
    results?: Array<ResultsDTO>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum GroupStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface ImplicitBiasInputDTO
 */
export interface ImplicitBiasInputDTO {
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasInputDTO
     */
    responseTimeMilliseconds: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasInputDTO
     */
    blockID: number;
    /**
     * 
     * @type {number}
     * @memberof ImplicitBiasInputDTO
     */
    trialID: number;
    /**
     * 
     * @type {boolean}
     * @memberof ImplicitBiasInputDTO
     */
    correct: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ImplicitBiasInterpretation {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ImplicitBiasInterpretationDirection {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface ParticipantsDTO
 */
export interface ParticipantsDTO {
    /**
     * 
     * @type {string}
     * @memberof ParticipantsDTO
     */
    groupId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParticipantsDTO
     */
    participants?: Array<string>;
}
/**
 * 
 * @export
 * @interface PlanConfigurationDTO
 */
export interface PlanConfigurationDTO {
    /**
     * 
     * @type {string}
     * @memberof PlanConfigurationDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanConfigurationDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanConfigurationDTO
     */
    groupId?: string;
    /**
     * 
     * @type {Array<TestConfigurationDTO>}
     * @memberof PlanConfigurationDTO
     */
    testConfiguration?: Array<TestConfigurationDTO>;
    /**
     * 
     * @type {Array<CourseConfigurationDTO>}
     * @memberof PlanConfigurationDTO
     */
    courseConfiguration?: Array<CourseConfigurationDTO>;
}
/**
 * 
 * @export
 * @interface PlanDTO
 */
export interface PlanDTO {
    /**
     * 
     * @type {string}
     * @memberof PlanDTO
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanDTO
     */
    hasStripe: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PlanDTO
     */
    price: number;
    /**
     * 
     * @type {PlanFeaturesDTO}
     * @memberof PlanDTO
     */
    features?: PlanFeaturesDTO;
    /**
     * 
     * @type {boolean}
     * @memberof PlanDTO
     */
    pendingPayment: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanDTO
     */
    stripeCustomerID?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanDTO
     */
    stripeSubscriptionID?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanDTO
     */
    stripeProductID?: string;
    /**
     * 
     * @type {SubscriptionDTO}
     * @memberof PlanDTO
     */
    currentSubscription?: SubscriptionDTO;
    /**
     * 
     * @type {SubscriptionDTO}
     * @memberof PlanDTO
     */
    nextSubscription?: SubscriptionDTO;
}
/**
 * 
 * @export
 * @interface PlanFeaturesDTO
 */
export interface PlanFeaturesDTO {
    /**
     * 
     * @type {boolean}
     * @memberof PlanFeaturesDTO
     */
    allowAnonymousUsers: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlanFeaturesDTO
     */
    maxNumAnonymousUsers: number;
    /**
     * 
     * @type {boolean}
     * @memberof PlanFeaturesDTO
     */
    allowEmailUsers: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlanFeaturesDTO
     */
    maxNumEmailUsers: number;
}
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    object?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Price
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    billing_scheme?: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    currency?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Price
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Price
     */
    livemode: boolean;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    lookup_key?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Price
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    nickname?: string;
    /**
     * 
     * @type {ExpandableFieldOfProduct}
     * @memberof Price
     */
    product?: ExpandableFieldOfProduct;
    /**
     * 
     * @type {PriceRecurring}
     * @memberof Price
     */
    recurring?: PriceRecurring;
    /**
     * 
     * @type {Array<PriceTier>}
     * @memberof Price
     */
    tiers?: Array<PriceTier>;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    tiers_mode?: string;
    /**
     * 
     * @type {PriceTransformQuantity}
     * @memberof Price
     */
    transform_quantity?: PriceTransformQuantity;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    unit_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    unit_amount_decimal?: number;
}
/**
 * 
 * @export
 * @interface PriceAllOf
 */
export interface PriceAllOf {
    /**
     * 
     * @type {string}
     * @memberof PriceAllOf
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceAllOf
     */
    object?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PriceAllOf
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof PriceAllOf
     */
    billing_scheme?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceAllOf
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof PriceAllOf
     */
    currency?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PriceAllOf
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PriceAllOf
     */
    livemode: boolean;
    /**
     * 
     * @type {string}
     * @memberof PriceAllOf
     */
    lookup_key?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PriceAllOf
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PriceAllOf
     */
    nickname?: string;
    /**
     * 
     * @type {ExpandableFieldOfProduct}
     * @memberof PriceAllOf
     */
    product?: ExpandableFieldOfProduct;
    /**
     * 
     * @type {PriceRecurring}
     * @memberof PriceAllOf
     */
    recurring?: PriceRecurring;
    /**
     * 
     * @type {Array<PriceTier>}
     * @memberof PriceAllOf
     */
    tiers?: Array<PriceTier>;
    /**
     * 
     * @type {string}
     * @memberof PriceAllOf
     */
    tiers_mode?: string;
    /**
     * 
     * @type {PriceTransformQuantity}
     * @memberof PriceAllOf
     */
    transform_quantity?: PriceTransformQuantity;
    /**
     * 
     * @type {string}
     * @memberof PriceAllOf
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceAllOf
     */
    unit_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceAllOf
     */
    unit_amount_decimal?: number;
}
/**
 * 
 * @export
 * @interface PriceRecurring
 */
export interface PriceRecurring {
    /**
     * 
     * @type {string}
     * @memberof PriceRecurring
     */
    aggregate_usage?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceRecurring
     */
    interval?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceRecurring
     */
    interval_count: number;
    /**
     * 
     * @type {number}
     * @memberof PriceRecurring
     */
    trial_period_days?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceRecurring
     */
    usage_type?: string;
}
/**
 * 
 * @export
 * @interface PriceRecurringAllOf
 */
export interface PriceRecurringAllOf {
    /**
     * 
     * @type {string}
     * @memberof PriceRecurringAllOf
     */
    aggregate_usage?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceRecurringAllOf
     */
    interval?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceRecurringAllOf
     */
    interval_count: number;
    /**
     * 
     * @type {number}
     * @memberof PriceRecurringAllOf
     */
    trial_period_days?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceRecurringAllOf
     */
    usage_type?: string;
}
/**
 * 
 * @export
 * @interface PriceTier
 */
export interface PriceTier {
    /**
     * 
     * @type {number}
     * @memberof PriceTier
     */
    flat_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceTier
     */
    flat_amount_decimal?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceTier
     */
    unit_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceTier
     */
    unit_amount_decimal?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceTier
     */
    up_to?: number;
}
/**
 * 
 * @export
 * @interface PriceTierAllOf
 */
export interface PriceTierAllOf {
    /**
     * 
     * @type {number}
     * @memberof PriceTierAllOf
     */
    flat_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceTierAllOf
     */
    flat_amount_decimal?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceTierAllOf
     */
    unit_amount?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceTierAllOf
     */
    unit_amount_decimal?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceTierAllOf
     */
    up_to?: number;
}
/**
 * 
 * @export
 * @interface PriceTransformQuantity
 */
export interface PriceTransformQuantity {
    /**
     * 
     * @type {number}
     * @memberof PriceTransformQuantity
     */
    divide_by: number;
    /**
     * 
     * @type {string}
     * @memberof PriceTransformQuantity
     */
    round?: string;
}
/**
 * 
 * @export
 * @interface PriceTransformQuantityAllOf
 */
export interface PriceTransformQuantityAllOf {
    /**
     * 
     * @type {number}
     * @memberof PriceTransformQuantityAllOf
     */
    divide_by: number;
    /**
     * 
     * @type {string}
     * @memberof PriceTransformQuantityAllOf
     */
    round?: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    object?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    attributes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    caption?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    created: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    deactivate_on?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    images?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    livemode: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Product
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name?: string;
    /**
     * 
     * @type {ProductPackageDimensions}
     * @memberof Product
     */
    package_dimensions?: ProductPackageDimensions;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    shippable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    statement_descriptor?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    unit_label?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    updated: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface ProductAllOf
 */
export interface ProductAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    object?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAllOf
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductAllOf
     */
    attributes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    caption?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    created: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductAllOf
     */
    deactivate_on?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAllOf
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductAllOf
     */
    images?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAllOf
     */
    livemode: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProductAllOf
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    name?: string;
    /**
     * 
     * @type {ProductPackageDimensions}
     * @memberof ProductAllOf
     */
    package_dimensions?: ProductPackageDimensions;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAllOf
     */
    shippable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    statement_descriptor?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    unit_label?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    updated: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAllOf
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface ProductPackageDimensions
 */
export interface ProductPackageDimensions {
    /**
     * 
     * @type {number}
     * @memberof ProductPackageDimensions
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPackageDimensions
     */
    length: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPackageDimensions
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPackageDimensions
     */
    width: number;
}
/**
 * 
 * @export
 * @interface ProductPackageDimensionsAllOf
 */
export interface ProductPackageDimensionsAllOf {
    /**
     * 
     * @type {number}
     * @memberof ProductPackageDimensionsAllOf
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPackageDimensionsAllOf
     */
    length: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPackageDimensionsAllOf
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPackageDimensionsAllOf
     */
    width: number;
}
/**
 * 
 * @export
 * @interface ResultsDTO
 */
export interface ResultsDTO {
    /**
     * 
     * @type {string}
     * @memberof ResultsDTO
     */
    iterationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResultsDTO
     */
    startDate: string;
    /**
     * 
     * @type {number}
     * @memberof ResultsDTO
     */
    numberOfParticipants: number;
    /**
     * 
     * @type {Array<AggregateTestResultDTO>}
     * @memberof ResultsDTO
     */
    tests?: Array<AggregateTestResultDTO>;
    /**
     * 
     * @type {Array<AggregateCourseResultDTO>}
     * @memberof ResultsDTO
     */
    courses?: Array<AggregateCourseResultDTO>;
    /**
     * 
     * @type {Array<TestConfigurationDTO>}
     * @memberof ResultsDTO
     */
    testConfiguration?: Array<TestConfigurationDTO>;
    /**
     * 
     * @type {Array<CourseConfigurationDTO>}
     * @memberof ResultsDTO
     */
    courseConfiguration?: Array<CourseConfigurationDTO>;
}
/**
 * 
 * @export
 * @interface StripeEntityOfPrice
 */
export interface StripeEntityOfPrice {
}
/**
 * 
 * @export
 * @interface StripeEntityOfPriceRecurring
 */
export interface StripeEntityOfPriceRecurring {
}
/**
 * 
 * @export
 * @interface StripeEntityOfPriceTier
 */
export interface StripeEntityOfPriceTier {
}
/**
 * 
 * @export
 * @interface StripeEntityOfPriceTransformQuantity
 */
export interface StripeEntityOfPriceTransformQuantity {
}
/**
 * 
 * @export
 * @interface StripeEntityOfProduct
 */
export interface StripeEntityOfProduct {
}
/**
 * 
 * @export
 * @interface StripeEntityOfProductPackageDimensions
 */
export interface StripeEntityOfProductPackageDimensions {
}
/**
 * 
 * @export
 * @interface StripeEntityOfStripeListOfPrice
 */
export interface StripeEntityOfStripeListOfPrice {
}
/**
 * 
 * @export
 * @interface StripeEntityOfStripeListOfProduct
 */
export interface StripeEntityOfStripeListOfProduct {
}
/**
 * 
 * @export
 * @interface StripeListOfPrice
 */
export interface StripeListOfPrice {
    /**
     * 
     * @type {string}
     * @memberof StripeListOfPrice
     */
    object?: string;
    /**
     * 
     * @type {Array<Price>}
     * @memberof StripeListOfPrice
     */
    data?: Array<Price>;
    /**
     * 
     * @type {boolean}
     * @memberof StripeListOfPrice
     */
    has_more: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeListOfPrice
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface StripeListOfPriceAllOf
 */
export interface StripeListOfPriceAllOf {
    /**
     * 
     * @type {string}
     * @memberof StripeListOfPriceAllOf
     */
    object?: string;
    /**
     * 
     * @type {Array<Price>}
     * @memberof StripeListOfPriceAllOf
     */
    data?: Array<Price>;
    /**
     * 
     * @type {boolean}
     * @memberof StripeListOfPriceAllOf
     */
    has_more: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeListOfPriceAllOf
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface StripeListOfProduct
 */
export interface StripeListOfProduct {
    /**
     * 
     * @type {string}
     * @memberof StripeListOfProduct
     */
    object?: string;
    /**
     * 
     * @type {Array<Product>}
     * @memberof StripeListOfProduct
     */
    data?: Array<Product>;
    /**
     * 
     * @type {boolean}
     * @memberof StripeListOfProduct
     */
    has_more: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeListOfProduct
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface StripeListOfProductAllOf
 */
export interface StripeListOfProductAllOf {
    /**
     * 
     * @type {string}
     * @memberof StripeListOfProductAllOf
     */
    object?: string;
    /**
     * 
     * @type {Array<Product>}
     * @memberof StripeListOfProductAllOf
     */
    data?: Array<Product>;
    /**
     * 
     * @type {boolean}
     * @memberof StripeListOfProductAllOf
     */
    has_more: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeListOfProductAllOf
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionDTO
 */
export interface SubscriptionDTO {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDTO
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDTO
     */
    priceID?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDTO
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDTO
     */
    endDate: string;
}
/**
 * 
 * @export
 * @interface SupportDTO
 */
export interface SupportDTO {
    /**
     * 
     * @type {string}
     * @memberof SupportDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportDTO
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportDTO
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface TestConfigurationDTO
 */
export interface TestConfigurationDTO {
    /**
     * 
     * @type {string}
     * @memberof TestConfigurationDTO
     */
    categoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof TestConfigurationDTO
     */
    id?: string;
}
/**
 * 
 * @export
 * @interface TestResultDTO
 */
export interface TestResultDTO {
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    testId?: string;
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    groupId?: string;
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    participantId?: string;
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    scheduleId?: string;
    /**
     * 
     * @type {ImplicitBiasInterpretation}
     * @memberof TestResultDTO
     */
    interpretation: ImplicitBiasInterpretation;
    /**
     * 
     * @type {ImplicitBiasInterpretationDirection}
     * @memberof TestResultDTO
     */
    direction: ImplicitBiasInterpretationDirection;
    /**
     * 
     * @type {TestResultDetailsDTO}
     * @memberof TestResultDTO
     */
    testResultDetails?: TestResultDetailsDTO;
    /**
     * 
     * @type {Array<ImplicitBiasInputDTO>}
     * @memberof TestResultDTO
     */
    rawInputs?: Array<ImplicitBiasInputDTO>;
    /**
     * 
     * @type {string}
     * @memberof TestResultDTO
     */
    implicitBiasSurveyInputs?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TestResultDTO
     */
    anonymousUser: boolean;
}
/**
 * 
 * @export
 * @interface TestResultDetailsDTO
 */
export interface TestResultDetailsDTO {
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    score: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    highResponseTimeCount: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    lowResponseTimePercentage: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    practiceSTD: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    testSTD: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    practiceMeanDiff: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    testMeanDiff: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    practiceEffectSize: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDetailsDTO
     */
    testEffectSize: number;
}
/**
 * 
 * @export
 * @interface TestScheduleDTO
 */
export interface TestScheduleDTO {
    /**
     * 
     * @type {string}
     * @memberof TestScheduleDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TestScheduleDTO
     */
    testUrl?: string;
}
/**
 * 
 * @export
 * @interface TestSummaryDTO
 */
export interface TestSummaryDTO {
    /**
     * 
     * @type {number}
     * @memberof TestSummaryDTO
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof TestSummaryDTO
     */
    averageScore: number;
    /**
     * 
     * @type {Array<TestSummaryItemDTO>}
     * @memberof TestSummaryDTO
     */
    interpretationItems?: Array<TestSummaryItemDTO>;
}
/**
 * 
 * @export
 * @interface TestSummaryItemDTO
 */
export interface TestSummaryItemDTO {
    /**
     * 
     * @type {number}
     * @memberof TestSummaryItemDTO
     */
    count: number;
    /**
     * 
     * @type {ImplicitBiasInterpretation}
     * @memberof TestSummaryItemDTO
     */
    interpretation: ImplicitBiasInterpretation;
    /**
     * 
     * @type {ImplicitBiasInterpretationDirection}
     * @memberof TestSummaryItemDTO
     */
    direction: ImplicitBiasInterpretationDirection;
    /**
     * 
     * @type {number}
     * @memberof TestSummaryItemDTO
     */
    averageScore: number;
}
/**
 * 
 * @export
 * @interface TestTakerScheduleDTO
 */
export interface TestTakerScheduleDTO {
    /**
     * 
     * @type {string}
     * @memberof TestTakerScheduleDTO
     */
    groupId?: string;
    /**
     * 
     * @type {string}
     * @memberof TestTakerScheduleDTO
     */
    scheduleId?: string;
    /**
     * 
     * @type {Array<TestConfigurationDTO>}
     * @memberof TestTakerScheduleDTO
     */
    testConfigurations?: Array<TestConfigurationDTO>;
    /**
     * 
     * @type {Array<CourseConfigurationDTO>}
     * @memberof TestTakerScheduleDTO
     */
    courseConfigurations?: Array<CourseConfigurationDTO>;
    /**
     * 
     * @type {string}
     * @memberof TestTakerScheduleDTO
     */
    participantId?: string;
    /**
     * 
     * @type {Array<CourseResultDTO>}
     * @memberof TestTakerScheduleDTO
     */
    courseResults?: Array<CourseResultDTO>;
    /**
     * 
     * @type {Array<TestResultDTO>}
     * @memberof TestTakerScheduleDTO
     */
    testResults?: Array<TestResultDTO>;
}
/**
 * 
 * @export
 * @interface UpgradeSubscriptionReqDTO
 */
export interface UpgradeSubscriptionReqDTO {
    /**
     * 
     * @type {string}
     * @memberof UpgradeSubscriptionReqDTO
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeSubscriptionReqDTO
     */
    subscriptionId: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeSubscriptionReqDTO
     */
    priceId: string;
    /**
     * 
     * @type {string}
     * @memberof UpgradeSubscriptionReqDTO
     */
    recurringPriceId: string;
    /**
     * 
     * @type {number}
     * @memberof UpgradeSubscriptionReqDTO
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof UpgradeSubscriptionReqDTO
     */
    quantityAdded: number;
}

/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GroupDTO} groupDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAddGroup: async (groupDTO: GroupDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupDTO' is not null or undefined
            assertParamExists('groupAddGroup', 'groupDTO', groupDTO)
            const localVarPath = `/Group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupDeleteGroup: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('groupDeleteGroup', 'groupId', groupId)
            const localVarPath = `/Group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupGetGroup: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('groupGetGroup', 'id', id)
            const localVarPath = `/Group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupGetGroups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Group/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupGetGroupsWithResults: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Group/groups/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {GroupOverviewDTO} groupOverviewDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupUpdateGroupOverview: async (groupId: string, groupOverviewDTO: GroupOverviewDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('groupUpdateGroupOverview', 'groupId', groupId)
            // verify required parameter 'groupOverviewDTO' is not null or undefined
            assertParamExists('groupUpdateGroupOverview', 'groupOverviewDTO', groupOverviewDTO)
            const localVarPath = `/Group/overview/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupOverviewDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GroupDTO} groupDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupAddGroup(groupDTO: GroupDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupAddGroup(groupDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupDeleteGroup(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupDeleteGroup(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupGetGroup(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupGetGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupGetGroups(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupResultsDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupGetGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupGetGroupsWithResults(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupResultsDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupGetGroupsWithResults(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {GroupOverviewDTO} groupOverviewDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupUpdateGroupOverview(groupId: string, groupOverviewDTO: GroupOverviewDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupOverviewDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupUpdateGroupOverview(groupId, groupOverviewDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @param {GroupDTO} groupDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAddGroup(groupDTO: GroupDTO, options?: any): AxiosPromise<GroupDTO> {
            return localVarFp.groupAddGroup(groupDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupDeleteGroup(groupId: string, options?: any): AxiosPromise<any> {
            return localVarFp.groupDeleteGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupGetGroup(id: string, options?: any): AxiosPromise<GroupDTO> {
            return localVarFp.groupGetGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupGetGroups(options?: any): AxiosPromise<Array<GroupResultsDTO>> {
            return localVarFp.groupGetGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupGetGroupsWithResults(options?: any): AxiosPromise<Array<GroupResultsDTO>> {
            return localVarFp.groupGetGroupsWithResults(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {GroupOverviewDTO} groupOverviewDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupUpdateGroupOverview(groupId: string, groupOverviewDTO: GroupOverviewDTO, options?: any): AxiosPromise<GroupOverviewDTO> {
            return localVarFp.groupUpdateGroupOverview(groupId, groupOverviewDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @param {GroupDTO} groupDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupAddGroup(groupDTO: GroupDTO, options?: any) {
        return GroupApiFp(this.configuration).groupAddGroup(groupDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupDeleteGroup(groupId: string, options?: any) {
        return GroupApiFp(this.configuration).groupDeleteGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupGetGroup(id: string, options?: any) {
        return GroupApiFp(this.configuration).groupGetGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupGetGroups(options?: any) {
        return GroupApiFp(this.configuration).groupGetGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupGetGroupsWithResults(options?: any) {
        return GroupApiFp(this.configuration).groupGetGroupsWithResults(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {GroupOverviewDTO} groupOverviewDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupUpdateGroupOverview(groupId: string, groupOverviewDTO: GroupOverviewDTO, options?: any) {
        return GroupApiFp(this.configuration).groupUpdateGroupOverview(groupId, groupOverviewDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParticipantApi - axios parameter creator
 * @export
 */
export const ParticipantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ParticipantsDTO} participantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participantAddParticipants: async (participantDTO: ParticipantsDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantDTO' is not null or undefined
            assertParamExists('participantAddParticipants', 'participantDTO', participantDTO)
            const localVarPath = `/Participant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParticipantsDTO} participantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participantUpdateParticipants: async (participantDTO: ParticipantsDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantDTO' is not null or undefined
            assertParamExists('participantUpdateParticipants', 'participantDTO', participantDTO)
            const localVarPath = `/Participant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participantDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParticipantApi - functional programming interface
 * @export
 */
export const ParticipantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParticipantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ParticipantsDTO} participantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async participantAddParticipants(participantDTO: ParticipantsDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.participantAddParticipants(participantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ParticipantsDTO} participantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async participantUpdateParticipants(participantDTO: ParticipantsDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.participantUpdateParticipants(participantDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParticipantApi - factory interface
 * @export
 */
export const ParticipantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParticipantApiFp(configuration)
    return {
        /**
         * 
         * @param {ParticipantsDTO} participantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participantAddParticipants(participantDTO: ParticipantsDTO, options?: any): AxiosPromise<ParticipantsDTO> {
            return localVarFp.participantAddParticipants(participantDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParticipantsDTO} participantDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participantUpdateParticipants(participantDTO: ParticipantsDTO, options?: any): AxiosPromise<ParticipantsDTO> {
            return localVarFp.participantUpdateParticipants(participantDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParticipantApi - object-oriented interface
 * @export
 * @class ParticipantApi
 * @extends {BaseAPI}
 */
export class ParticipantApi extends BaseAPI {
    /**
     * 
     * @param {ParticipantsDTO} participantDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantApi
     */
    public participantAddParticipants(participantDTO: ParticipantsDTO, options?: any) {
        return ParticipantApiFp(this.configuration).participantAddParticipants(participantDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParticipantsDTO} participantDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantApi
     */
    public participantUpdateParticipants(participantDTO: ParticipantsDTO, options?: any) {
        return ParticipantApiFp(this.configuration).participantUpdateParticipants(participantDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCheckoutSessionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCheckoutFreeTier: async (req: CreateCheckoutSessionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('paymentsCheckoutFreeTier', 'req', req)
            const localVarPath = `/Payments/checkout-free`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCheckoutSessionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCreateCheckoutSession: async (req: CreateCheckoutSessionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('paymentsCreateCheckoutSession', 'req', req)
            const localVarPath = `/Payments/create-checkout-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCustomerPortal: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payments/create-customer-portal-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsErrorCheckout: async (sessionId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payments/checkout-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsErrorUpdateCheckout: async (sessionId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payments/update-checkout-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsFinalizeCheckout: async (sessionId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payments/checkout-finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsFinalizeUpdateCheckout: async (sessionId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Payments/update-checkout-finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionId !== undefined) {
                localVarQueryParameter['session_id'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DowngradeSubscriptionRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsSubscriptionDowngradeStart: async (request: DowngradeSubscriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('paymentsSubscriptionDowngradeStart', 'request', request)
            const localVarPath = `/Payments/subscription-downgrade-start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpgradeSubscriptionReqDTO} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsUpgradeSubscription: async (req: UpgradeSubscriptionReqDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('paymentsUpgradeSubscription', 'req', req)
            const localVarPath = `/Payments/upgrade-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCheckoutSessionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsCheckoutFreeTier(req: CreateCheckoutSessionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsCheckoutFreeTier(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCheckoutSessionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsCreateCheckoutSession(req: CreateCheckoutSessionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsCreateCheckoutSession(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsCustomerPortal(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsCustomerPortal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsErrorCheckout(sessionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsErrorCheckout(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsErrorUpdateCheckout(sessionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsErrorUpdateCheckout(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsFinalizeCheckout(sessionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsFinalizeCheckout(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsFinalizeUpdateCheckout(sessionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsFinalizeUpdateCheckout(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DowngradeSubscriptionRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsSubscriptionDowngradeStart(request: DowngradeSubscriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsSubscriptionDowngradeStart(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpgradeSubscriptionReqDTO} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsUpgradeSubscription(req: UpgradeSubscriptionReqDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsUpgradeSubscription(req, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCheckoutSessionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCheckoutFreeTier(req: CreateCheckoutSessionRequest, options?: any): AxiosPromise<any> {
            return localVarFp.paymentsCheckoutFreeTier(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCheckoutSessionRequest} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCreateCheckoutSession(req: CreateCheckoutSessionRequest, options?: any): AxiosPromise<string> {
            return localVarFp.paymentsCreateCheckoutSession(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCustomerPortal(options?: any): AxiosPromise<string> {
            return localVarFp.paymentsCustomerPortal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsErrorCheckout(sessionId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.paymentsErrorCheckout(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsErrorUpdateCheckout(sessionId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.paymentsErrorUpdateCheckout(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsFinalizeCheckout(sessionId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.paymentsFinalizeCheckout(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsFinalizeUpdateCheckout(sessionId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.paymentsFinalizeUpdateCheckout(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DowngradeSubscriptionRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsSubscriptionDowngradeStart(request: DowngradeSubscriptionRequest, options?: any): AxiosPromise<any> {
            return localVarFp.paymentsSubscriptionDowngradeStart(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpgradeSubscriptionReqDTO} req 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsUpgradeSubscription(req: UpgradeSubscriptionReqDTO, options?: any): AxiosPromise<string> {
            return localVarFp.paymentsUpgradeSubscription(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {CreateCheckoutSessionRequest} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCheckoutFreeTier(req: CreateCheckoutSessionRequest, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsCheckoutFreeTier(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCheckoutSessionRequest} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCreateCheckoutSession(req: CreateCheckoutSessionRequest, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsCreateCheckoutSession(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCustomerPortal(options?: any) {
        return PaymentsApiFp(this.configuration).paymentsCustomerPortal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sessionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsErrorCheckout(sessionId?: string, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsErrorCheckout(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sessionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsErrorUpdateCheckout(sessionId?: string, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsErrorUpdateCheckout(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sessionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsFinalizeCheckout(sessionId?: string, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsFinalizeCheckout(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sessionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsFinalizeUpdateCheckout(sessionId?: string, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsFinalizeUpdateCheckout(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DowngradeSubscriptionRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsSubscriptionDowngradeStart(request: DowngradeSubscriptionRequest, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsSubscriptionDowngradeStart(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpgradeSubscriptionReqDTO} req 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsUpgradeSubscription(req: UpgradeSubscriptionReqDTO, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsUpgradeSubscription(req, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGRPC: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping/GRPC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingIndex: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingGRPC(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingGRPC(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingIndex(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGRPC(options?: any): AxiosPromise<any> {
            return localVarFp.pingGRPC(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingIndex(options?: any): AxiosPromise<any> {
            return localVarFp.pingIndex(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingGRPC(options?: any) {
        return PingApiFp(this.configuration).pingGRPC(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingIndex(options?: any) {
        return PingApiFp(this.configuration).pingIndex(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanApi - axios parameter creator
 * @export
 */
export const PlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planGetPlan: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planGetPlan', 'id', id)
            const localVarPath = `/plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanApi - functional programming interface
 * @export
 */
export const PlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planGetPlan(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planGetPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanApi - factory interface
 * @export
 */
export const PlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planGetPlan(id: string, options?: any): AxiosPromise<PlanDTO> {
            return localVarFp.planGetPlan(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanApi - object-oriented interface
 * @export
 * @class PlanApi
 * @extends {BaseAPI}
 */
export class PlanApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    public planGetPlan(id: string, options?: any) {
        return PlanApiFp(this.configuration).planGetPlan(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlanConfigurationApi - axios parameter creator
 * @export
 */
export const PlanConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PlanConfigurationDTO} planConfigurationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planConfigurationAddPlanConfiguration: async (planConfigurationDTO: PlanConfigurationDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'planConfigurationDTO' is not null or undefined
            assertParamExists('planConfigurationAddPlanConfiguration', 'planConfigurationDTO', planConfigurationDTO)
            const localVarPath = `/PlanConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(planConfigurationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planConfigurationGetPlanConfiguration: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('planConfigurationGetPlanConfiguration', 'id', id)
            const localVarPath = `/PlanConfiguration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} planConfigurationId 
         * @param {PlanConfigurationDTO} updatePlanConfigurationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planConfigurationUpdatePlanConfiguration: async (planConfigurationId: string, updatePlanConfigurationDTO: PlanConfigurationDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'planConfigurationId' is not null or undefined
            assertParamExists('planConfigurationUpdatePlanConfiguration', 'planConfigurationId', planConfigurationId)
            // verify required parameter 'updatePlanConfigurationDTO' is not null or undefined
            assertParamExists('planConfigurationUpdatePlanConfiguration', 'updatePlanConfigurationDTO', updatePlanConfigurationDTO)
            const localVarPath = `/PlanConfiguration/{planConfigurationId}`
                .replace(`{${"planConfigurationId"}}`, encodeURIComponent(String(planConfigurationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlanConfigurationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanConfigurationApi - functional programming interface
 * @export
 */
export const PlanConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PlanConfigurationDTO} planConfigurationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planConfigurationAddPlanConfiguration(planConfigurationDTO: PlanConfigurationDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanConfigurationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planConfigurationAddPlanConfiguration(planConfigurationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planConfigurationGetPlanConfiguration(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanConfigurationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planConfigurationGetPlanConfiguration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} planConfigurationId 
         * @param {PlanConfigurationDTO} updatePlanConfigurationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planConfigurationUpdatePlanConfiguration(planConfigurationId: string, updatePlanConfigurationDTO: PlanConfigurationDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanConfigurationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planConfigurationUpdatePlanConfiguration(planConfigurationId, updatePlanConfigurationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanConfigurationApi - factory interface
 * @export
 */
export const PlanConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {PlanConfigurationDTO} planConfigurationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planConfigurationAddPlanConfiguration(planConfigurationDTO: PlanConfigurationDTO, options?: any): AxiosPromise<PlanConfigurationDTO> {
            return localVarFp.planConfigurationAddPlanConfiguration(planConfigurationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planConfigurationGetPlanConfiguration(id: string, options?: any): AxiosPromise<PlanConfigurationDTO> {
            return localVarFp.planConfigurationGetPlanConfiguration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} planConfigurationId 
         * @param {PlanConfigurationDTO} updatePlanConfigurationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planConfigurationUpdatePlanConfiguration(planConfigurationId: string, updatePlanConfigurationDTO: PlanConfigurationDTO, options?: any): AxiosPromise<PlanConfigurationDTO> {
            return localVarFp.planConfigurationUpdatePlanConfiguration(planConfigurationId, updatePlanConfigurationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanConfigurationApi - object-oriented interface
 * @export
 * @class PlanConfigurationApi
 * @extends {BaseAPI}
 */
export class PlanConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {PlanConfigurationDTO} planConfigurationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanConfigurationApi
     */
    public planConfigurationAddPlanConfiguration(planConfigurationDTO: PlanConfigurationDTO, options?: any) {
        return PlanConfigurationApiFp(this.configuration).planConfigurationAddPlanConfiguration(planConfigurationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanConfigurationApi
     */
    public planConfigurationGetPlanConfiguration(id: string, options?: any) {
        return PlanConfigurationApiFp(this.configuration).planConfigurationGetPlanConfiguration(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} planConfigurationId 
     * @param {PlanConfigurationDTO} updatePlanConfigurationDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanConfigurationApi
     */
    public planConfigurationUpdatePlanConfiguration(planConfigurationId: string, updatePlanConfigurationDTO: PlanConfigurationDTO, options?: any) {
        return PlanConfigurationApiFp(this.configuration).planConfigurationUpdatePlanConfiguration(planConfigurationId, updatePlanConfigurationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetAllPlans: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Product/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetAllPrices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Product/prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetAllProducts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Product/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetPriceById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productGetPriceById', 'id', id)
            const localVarPath = `/Product/prices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetProductById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productGetProductById', 'id', id)
            const localVarPath = `/Product/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGetAllPlans(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EcomPlanDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGetAllPlans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGetAllPrices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeListOfPrice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGetAllPrices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGetAllProducts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeListOfProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGetAllProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGetPriceById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Price>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGetPriceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGetProductById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGetProductById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetAllPlans(options?: any): AxiosPromise<Array<EcomPlanDTO>> {
            return localVarFp.productGetAllPlans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetAllPrices(options?: any): AxiosPromise<StripeListOfPrice> {
            return localVarFp.productGetAllPrices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetAllProducts(options?: any): AxiosPromise<StripeListOfProduct> {
            return localVarFp.productGetAllProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetPriceById(id: string, options?: any): AxiosPromise<Price> {
            return localVarFp.productGetPriceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGetProductById(id: string, options?: any): AxiosPromise<Product> {
            return localVarFp.productGetProductById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productGetAllPlans(options?: any) {
        return ProductApiFp(this.configuration).productGetAllPlans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productGetAllPrices(options?: any) {
        return ProductApiFp(this.configuration).productGetAllPrices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productGetAllProducts(options?: any) {
        return ProductApiFp(this.configuration).productGetAllProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productGetPriceById(id: string, options?: any) {
        return ProductApiFp(this.configuration).productGetPriceById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productGetProductById(id: string, options?: any) {
        return ProductApiFp(this.configuration).productGetProductById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupportApi - axios parameter creator
 * @export
 */
export const SupportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SupportDTO} supportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportPost: async (supportRequest: SupportDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportRequest' is not null or undefined
            assertParamExists('supportPost', 'supportRequest', supportRequest)
            const localVarPath = `/Support`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportApi - functional programming interface
 * @export
 */
export const SupportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SupportDTO} supportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportPost(supportRequest: SupportDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportPost(supportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupportApi - factory interface
 * @export
 */
export const SupportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportApiFp(configuration)
    return {
        /**
         * 
         * @param {SupportDTO} supportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportPost(supportRequest: SupportDTO, options?: any): AxiosPromise<SupportDTO> {
            return localVarFp.supportPost(supportRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportApi - object-oriented interface
 * @export
 * @class SupportApi
 * @extends {BaseAPI}
 */
export class SupportApi extends BaseAPI {
    /**
     * 
     * @param {SupportDTO} supportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportApi
     */
    public supportPost(supportRequest: SupportDTO, options?: any) {
        return SupportApiFp(this.configuration).supportPost(supportRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestTakerApi - axios parameter creator
 * @export
 */
export const TestTakerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CourseResultDTO} courseResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerAddCourseResults: async (courseResultsDTO: CourseResultDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseResultsDTO' is not null or undefined
            assertParamExists('testTakerAddCourseResults', 'courseResultsDTO', courseResultsDTO)
            const localVarPath = `/TestTaker/AddCourseResults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseResultsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CourseResultDTO} courseResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerAddPublicCourseResults: async (courseResultsDTO: CourseResultDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseResultsDTO' is not null or undefined
            assertParamExists('testTakerAddPublicCourseResults', 'courseResultsDTO', courseResultsDTO)
            const localVarPath = `/TestTaker/AddPublicCourseResults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(courseResultsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TestResultDTO} testResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerAddPublicTestResults: async (testResultsDTO: TestResultDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'testResultsDTO' is not null or undefined
            assertParamExists('testTakerAddPublicTestResults', 'testResultsDTO', testResultsDTO)
            const localVarPath = `/TestTaker/AddPublicTestResults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testResultsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TestResultDTO} testResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerAddTestResults: async (testResultsDTO: TestResultDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'testResultsDTO' is not null or undefined
            assertParamExists('testTakerAddTestResults', 'testResultsDTO', testResultsDTO)
            const localVarPath = `/TestTaker/AddTestResults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testResultsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerGetGroupDetails: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('testTakerGetGroupDetails', 'token', token)
            const localVarPath = `/TestTaker/GetGroupDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(token, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerGetGroupDetailsBySchedule: async (scheduleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleId' is not null or undefined
            assertParamExists('testTakerGetGroupDetailsBySchedule', 'scheduleId', scheduleId)
            const localVarPath = `/TestTaker/GetGroupDetailsBySchedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerGetSummary: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/TestTaker/GetSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestTakerApi - functional programming interface
 * @export
 */
export const TestTakerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestTakerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CourseResultDTO} courseResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTakerAddCourseResults(courseResultsDTO: CourseResultDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTakerAddCourseResults(courseResultsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CourseResultDTO} courseResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTakerAddPublicCourseResults(courseResultsDTO: CourseResultDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseResultDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTakerAddPublicCourseResults(courseResultsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TestResultDTO} testResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTakerAddPublicTestResults(testResultsDTO: TestResultDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestSummaryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTakerAddPublicTestResults(testResultsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TestResultDTO} testResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTakerAddTestResults(testResultsDTO: TestResultDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestSummaryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTakerAddTestResults(testResultsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTakerGetGroupDetails(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestTakerScheduleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTakerGetGroupDetails(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTakerGetGroupDetailsBySchedule(scheduleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestTakerScheduleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTakerGetGroupDetailsBySchedule(scheduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTakerGetSummary(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestSummaryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTakerGetSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestTakerApi - factory interface
 * @export
 */
export const TestTakerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestTakerApiFp(configuration)
    return {
        /**
         * 
         * @param {CourseResultDTO} courseResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerAddCourseResults(courseResultsDTO: CourseResultDTO, options?: any): AxiosPromise<CourseResultDTO> {
            return localVarFp.testTakerAddCourseResults(courseResultsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CourseResultDTO} courseResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerAddPublicCourseResults(courseResultsDTO: CourseResultDTO, options?: any): AxiosPromise<CourseResultDTO> {
            return localVarFp.testTakerAddPublicCourseResults(courseResultsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TestResultDTO} testResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerAddPublicTestResults(testResultsDTO: TestResultDTO, options?: any): AxiosPromise<TestSummaryDTO> {
            return localVarFp.testTakerAddPublicTestResults(testResultsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TestResultDTO} testResultsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerAddTestResults(testResultsDTO: TestResultDTO, options?: any): AxiosPromise<TestSummaryDTO> {
            return localVarFp.testTakerAddTestResults(testResultsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerGetGroupDetails(token: string, options?: any): AxiosPromise<TestTakerScheduleDTO> {
            return localVarFp.testTakerGetGroupDetails(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerGetGroupDetailsBySchedule(scheduleId: string, options?: any): AxiosPromise<TestTakerScheduleDTO> {
            return localVarFp.testTakerGetGroupDetailsBySchedule(scheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTakerGetSummary(options?: any): AxiosPromise<TestSummaryDTO> {
            return localVarFp.testTakerGetSummary(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestTakerApi - object-oriented interface
 * @export
 * @class TestTakerApi
 * @extends {BaseAPI}
 */
export class TestTakerApi extends BaseAPI {
    /**
     * 
     * @param {CourseResultDTO} courseResultsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTakerApi
     */
    public testTakerAddCourseResults(courseResultsDTO: CourseResultDTO, options?: any) {
        return TestTakerApiFp(this.configuration).testTakerAddCourseResults(courseResultsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CourseResultDTO} courseResultsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTakerApi
     */
    public testTakerAddPublicCourseResults(courseResultsDTO: CourseResultDTO, options?: any) {
        return TestTakerApiFp(this.configuration).testTakerAddPublicCourseResults(courseResultsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TestResultDTO} testResultsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTakerApi
     */
    public testTakerAddPublicTestResults(testResultsDTO: TestResultDTO, options?: any) {
        return TestTakerApiFp(this.configuration).testTakerAddPublicTestResults(testResultsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TestResultDTO} testResultsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTakerApi
     */
    public testTakerAddTestResults(testResultsDTO: TestResultDTO, options?: any) {
        return TestTakerApiFp(this.configuration).testTakerAddTestResults(testResultsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTakerApi
     */
    public testTakerGetGroupDetails(token: string, options?: any) {
        return TestTakerApiFp(this.configuration).testTakerGetGroupDetails(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} scheduleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTakerApi
     */
    public testTakerGetGroupDetailsBySchedule(scheduleId: string, options?: any) {
        return TestTakerApiFp(this.configuration).testTakerGetGroupDetailsBySchedule(scheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTakerApi
     */
    public testTakerGetSummary(options?: any) {
        return TestTakerApiFp(this.configuration).testTakerGetSummary(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhookApi - axios parameter creator
 * @export
 */
export const WebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookSubscriptionUpdate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Webhook/subscription-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhookApi - functional programming interface
 * @export
 */
export const WebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookSubscriptionUpdate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookSubscriptionUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhookApi - factory interface
 * @export
 */
export const WebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhookApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookSubscriptionUpdate(options?: any): AxiosPromise<any> {
            return localVarFp.webhookSubscriptionUpdate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhookApi - object-oriented interface
 * @export
 * @class WebhookApi
 * @extends {BaseAPI}
 */
export class WebhookApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookApi
     */
    public webhookSubscriptionUpdate(options?: any) {
        return WebhookApiFp(this.configuration).webhookSubscriptionUpdate(options).then((request) => request(this.axios, this.basePath));
    }
}



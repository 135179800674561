import React from 'react';
import MainLayout from 'layouts/MainLayout';
import { RouteComponentProps } from '@reach/router';
import EditGroupStepper from 'domain/group/editGroup/EditGroupStepper';
import AddGroupStepper from 'domain/group/addGroup/AddGroupStepper';

interface IProps extends RouteComponentProps {
    groupId: string
}

const Group = ({ groupId }: IProps) => {
    if (groupId) {
        return (
            <MainLayout>
                <EditGroupStepper groupId={groupId} />
            </MainLayout>
        )
    }
    return (
        <MainLayout>
            <AddGroupStepper />
        </MainLayout>
    )
};

export default Group;
import React from 'react';
import clsx from 'clsx';
import { navigate } from "@reach/router";
import { createStyles, makeStyles, Theme, Button, Typography } from '@material-ui/core';
import { Paths } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        wrapper: {
            width: 800,
            margin: "0 auto",
        },
        header: {
            fontWeight: 500,
            textTransform: "uppercase",
        },
        heading: {
            fontSize: 36,
            fontWeight: 700,
            lineHeight: 1,

            "& sup ~ sup": {
                color: theme.palette.orange.main,
                fontWeight: 700,
            }
        },
        subheading: {
            margin: "15px 0",
            fontSize: 20,
            lineHeight: 1,
        },
        content: {
            fontSize: 14,
            lineHeight: 1.15,

            "& a": {
                color: "#29ABE2",
                fontWeight: 700,
                textDecoration: "none",

                "&:hover, &:focus": {
                    textDecoration: "underline",
                }
            }
        },
        subheading2: {
            margin: "30px 0 0 ",
            fontSize: 18,
            fontWeight: 900,
            textTransform: "uppercase",
            lineHeight: 1,
        },
        image: {
            display: "block",
            width: "100%",
            height: "auto",
        },
        border: {
            border: "2px solid #C8602A",
            borderRadius: 8,
            boxShadow: "4px 6px 6px 0 rgba(0,0,0,0.25)",
        },
        list: {
            display: "flex",
            justifyContent: "space-between",
            margin: "0 -10px",
            padding: 0,
            listStyle: "none",
        },
        listItem: {
            display: "block",
            width: `calc(100% / 5)`,
            padding: "0 10px 15px 10px",
            marginTop: 10,
        },
        listItemImageWrapper: {
            display: "block",
            width: "100%",
            height: 172,
        },
        listItemImage: {
            display: "block",
            width: "100%",
            maxHeight: "100%",
        },
        listItemText: {
            display: "block",
            padding: "0 5px 0 10px",
            fontSize: 14,
            textAlign: "center",
            lineHeight: 1.15,
        },
        button: {
            margin: 'auto',
            marginTop: '30px',
            padding: "0 3em",
        },
        buttons: {
            display: "flex",
        }
    }),
);

interface IProps {
    step: "plan" | "group";
};

const Intro = ({ step }: IProps) => {
    const classes = useStyles();

    function navigateToPlan() {
        navigate(Paths.PLANS);
    }

    function navigateToGroup() {
        navigate(`${Paths.GROUPS}/add`);
    }

    return (
        <div className={classes.wrapper}>
            <Typography className={classes.heading} component="h2">Welcome to BiasProof<sup>&reg;</sup><sup>BETA</sup></Typography>
            <Typography className={classes.subheading} component="h3">BiasProof<sup>&reg;</sup> People Make BiasProof<sup>&reg;</sup> Companies</Typography>
            <Typography className={classes.content} component="p">We are thrilled that you have joined the BiasProof<sup>&reg;</sup> beta release, an exciting program created for your company and its most valuable assets in mind, your employees.  With your help, we hope to fully test and improve our product in real-world scenarios before being released.  For more information, visit <a href="https://www.biasproof.com/">www.biasproof.com</a> to get an overview of our service and why it is important, now more than ever,  for companies like yours to incorporate BiasProof<sup>&reg;</sup> into your business ecosystem.</Typography>
            <Typography className={classes.subheading2} component="h3">Setting Up Your First Bias Test</Typography>

            <ul className={classes.list}>
                <li className={clsx(classes.listItem, { [classes.border]: step === "plan" })}>
                    <div className={classes.listItemImageWrapper}>
                        <img className={classes.listItemImage} src={'/images/general/graphic_intro1.svg'} alt='Step 1' />
                    </div>
                    <Typography className={classes.listItemText} component="span">Select a plan that best suits your company’s needs</Typography>
                </li>
                <li className={clsx(classes.listItem, { [classes.border]: step === "group" })}>
                    <div className={classes.listItemImageWrapper}>
                        <img className={classes.listItemImage} src={'/images/general/graphic_intro2.svg'} alt='Step 2' />
                    </div>
                    <Typography className={classes.listItemText} component="span">Create a group that will be participate and assign bias tests to your group</Typography>
                </li>
                <li className={classes.listItem}>
                    <div className={classes.listItemImageWrapper}>
                        <img className={classes.listItemImage} src={'/images/general/graphic_intro3.svg'} alt='Step 3' />
                    </div>
                    <Typography className={classes.listItemText} component="span">Add training modules to tests that will inform and guide your group</Typography>
                </li>
                <li className={classes.listItem}>
                    <div className={classes.listItemImageWrapper}>
                        <img className={classes.listItemImage} src={'/images/general/graphic_intro4.svg'} alt='Step 4' />
                    </div>
                    <Typography className={classes.listItemText} component="span">Set up and send email invites and reminders</Typography>
                </li>
                <li className={classes.listItem}>
                    <div className={classes.listItemImageWrapper}>
                        <img className={classes.listItemImage} src={'/images/general/graphic_intro5.svg'} alt='Step 5' />
                    </div>
                    <Typography className={classes.listItemText} component="span">Monitor your groups from  the dashboard for results and data</Typography>
                </li>
            </ul>
            <div className={classes.buttons}>
                {
                    step === "plan" &&
                    <Button onClick={navigateToPlan} type="button" color="primary" variant="contained" size="medium" className={classes.button}>Select a plan to begin</Button>
                }
                {
                    step === "group" &&
                    <Button onClick={navigateToGroup} type="button" color="primary" variant="contained" size="medium" className={classes.button}>Create your first group</Button>
                }
            </div>
        </div>
    );
}

export default Intro;
import { IPlanConfiguration } from "domain/group/types";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

export const useCourseModuleStepper = (courseId: string, activeModuleId: string) => {
    const { control, getValues } = useFormContext<IPlanConfiguration>();
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (courseId) {
            const { courseConfiguration } = getValues();
            const courseIndex = courseConfiguration?.findIndex(x => x.id === courseId) ?? 0;
            setIndex(courseIndex);
        }
    }, [courseId])

    const selectedCourseModules = useWatch<{ moduleId: string }[]>({
        control: control,
        name: `courseConfiguration[${index}].courseModuleIds`
    });
    const fieldArrayProps = useFieldArray(
        {
            control: control,
            name: `courseConfiguration[${index}].courseModuleIds`,
            keyName: "fieldId"
        }
    );

    function removeModule(moduleId: string) {
        const { courseConfiguration } = getValues();
        if (courseConfiguration) {
            const selectedModules = courseConfiguration[index].courseModuleIds as unknown as { moduleId: string }[];
            const moduleIndex = selectedModules.findIndex(x => x.moduleId === moduleId);
            fieldArrayProps.remove(moduleIndex);
        }
    }

    return {
        ...fieldArrayProps,
        selectedCourseModules,
        getValues,
        control,
        removeModule,
        isSelected: selectedCourseModules?.some(x => x.moduleId === activeModuleId) ?? false
    }
}
import { IPlanConfiguration } from "domain/group/types";
import { useFormContext, useWatch } from "react-hook-form";
import { GetAllSurveysList } from "types/GetAllSurveysList";

export const useTestingPlans = (data: GetAllSurveysList) => {
    const form = useFormContext<IPlanConfiguration>();
    let allCategories = data?.surveyCollection?.items
        .map(survey => {
            return { categoryLabel: survey?.category?.label, id: survey?.category?.sys?.id, tests: [] } as { categoryLabel: string, id: string, tests: string[] }
        }).filter((v, i, a) => a.map(x => x.id).indexOf(v.id) === i)


    data?.surveyCollection?.items.forEach(survey => {
        const index = allCategories?.findIndex(x => x.id === survey?.category?.sys?.id) as number;
        if (index !== -1 && allCategories) {
            allCategories[index].tests.push(survey?.sys.id!);
        }
    })
 

    const selectedTests = useWatch<{ id: string, categoryId: string }[]>({
        control: form.control,
        name: "testConfiguration"
    });


    return {
        selectedTests,
        allCategories,
        form
    }
}
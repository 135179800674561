import { createStyles, Grid, makeStyles, Theme, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@material-ui/core";
import React, { memo } from "react";
import CustomButton from 'components/CustomButton';
import GroupNavigation from "./GroupNavigation";
import { useDispatch } from "react-redux";
import { addGroup } from "store/group/groupSlice";
import { IGroupOverviewEmailConfiguration, IPlanConfiguration } from "../types";
import moment from "moment";
import { navigate } from "@reach/router";
import { Paths } from "utils/constants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        title: {
            fontWeight: 700,
            textTransform: "uppercase",
        },
        titleUnderlined: {
            margin: "40px 0 0 0",
            padding: "0 15px 5px 15px",
            fontWeight: 700,
            textTransform: "uppercase",
            borderBottom: `1px solid ${theme.palette.shades.grey}`,
        },
        box: {
            padding: "10px 15px",
        },
        label: {
            margin: "0 0 5px 0",
            color: theme.palette.orange.main,
            fontSize: 12,
            fontWeight: 700,
        },
        text: {
            margin: "0 0 10px 0",
            fontSize: 16,
            fontWeight: 700,
        },
        table: {

        },
        theadhead: {
            paddingTop: 5,
            paddingBottom: 5,
            color: theme.palette.orange.main,
            fontSize: 12,
            fontWeight: 700,
            border: "none",
        },
        tcell: {
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: 16,
            fontWeight: 700,
            border: "none",
        },
        buttonDiscard: {
            margin: "10px auto",
            display: "flex",
            fontSize: 16,
            fontWeight: 700,
            textTransform: "uppercase",
        },
        buttonCustom: {
            margin: "10px auto !important",
            padding: "0 3.5em",
            display: "flex",
        },
        buttonBack: {
            margin: "0 0 30px auto",
            display: "flex",
            fontSize: 16,
            fontWeight: 700,
            textTransform: "uppercase",
        },
        buttonBackIcon: {
            flex: "0 0 auto",
            display: "inline-block",
            width: 7.41,
            height: 12,
            marginRight: "0.5em",
            color: "inherit",
        },
    }),
);

interface IProps {
    nextStep: () => void
    prevStep?: () => void
    overview: IGroupOverviewEmailConfiguration
    participants: string[]
    planConfiguration: IPlanConfiguration
}

const GroupOverview = ({ nextStep, prevStep, overview, participants, planConfiguration }: IProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    async function handleNext() {
        await dispatch(addGroup());
    }

    function onDiscard() {
        navigate(Paths.GROUPS);
    }

    return (
        <Grid container spacing={4} className={classes.root}>
            <Grid item xs={1}>
                &nbsp;
            </Grid>
            <Grid item xs={5}>
                <Typography className={classes.title} variant="h2">Confirmation</Typography>
                <Typography className={classes.titleUnderlined} variant="h2" component="h3">Overview</Typography>
                <Box className={classes.box}>
                    <Typography className={classes.label} component="h4">Group Name:</Typography>
                    <Typography className={classes.text} component="p">{overview.name}</Typography>

                    <Typography className={classes.label} component="h4">Description:</Typography>
                    <Typography className={classes.text} component="p">
                        {overview.description}
                    </Typography>
                </Box>

                <Typography className={classes.titleUnderlined} variant="h2" component="h3">Tests And Training</Typography>

                <TableContainer>
                    <Table className={classes.table} aria-label="Tests And Training">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.theadhead}>Test Name:</TableCell>
                                <TableCell className={classes.theadhead} align="right">Training Modules:</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {planConfiguration.testConfiguration?.map(x => {
                                const associatedCourse = planConfiguration?.courseConfiguration?.find(y => y.categoryId === x.categoryId);
                                return (
                                    <TableRow>
                                        <TableCell className={classes.tcell} component="th" scope="row">{x.testName}</TableCell>
                                        <TableCell className={classes.tcell} align="right">{associatedCourse?.courseModuleIds?.length ?? 0}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography className={classes.titleUnderlined} variant="h2" component="h3">Email List</Typography>
                <Box className={classes.box}>
                    <Typography className={classes.label} component="h4">Number of Participants:</Typography>
                    <Typography className={classes.text} component="p">{participants.length}</Typography>
                </Box>

                <Typography className={classes.titleUnderlined} variant="h2" component="h3">Invitations And Reminders</Typography>
                <Box className={classes.box}>
                    <Typography className={classes.label} component="h4">Invite Dates:</Typography>
                    {overview.emailConfiguration?.invitationDates.map(x => {
                        return (
                            <Typography className={classes.text} component="p">{moment(x.dateTime).format("LL")}</Typography>
                        )
                    })}

                    <Typography className={classes.label} component="h4">Number of Reminders:</Typography>
                    <Typography className={classes.text} component="p">{overview.emailConfiguration?.numberOfReminders}</Typography>

                    <Typography className={classes.label} component="h4">Days Between Reminders:</Typography>
                    <Typography className={classes.text} component="p">{overview.emailConfiguration?.daysBetweenReminders ?? 0}</Typography>
                </Box>
                <CustomButton
                    label="Save Group"
                    onClick={handleNext}
                    className={classes.buttonCustom}
                />
                <Button
                    className={classes.buttonDiscard}
                    onClick={onDiscard}
                >
                    Discard
                </Button>
            </Grid>

            <Grid item xs={1}>
                &nbsp;
            </Grid>

            <Grid item xs={5}>
                <Button className={classes.buttonBack} onClick={prevStep}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={classes.buttonBackIcon} viewBox="0 0 7.41 12"><defs><style>{`.cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;}`}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polyline className="cls-1" points="6.41 1 1.41 6 6.41 11" /></g></g></svg>
                    Back
                </Button>

                Take some time to review the details of your group.  Once you are happy with your new group, press the Save Group button below.
            </Grid>
        </Grid>
    );
};

export default memo(GroupOverview);
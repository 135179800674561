import React, { useEffect } from 'react';
import { navigate } from "@reach/router";
import { gql } from "@apollo/client";
import { Grid, Typography, Tab, Tabs, createStyles, makeStyles, Theme, Button, Icon, Box } from '@material-ui/core';
import VisCard from './components/VisCard';
import BarChart from './components/BarChart';
import { convertIATHistoricalDataToBarChartData, convertScoreDataToIATHistoricalData } from './BarUtils';
import { convertGroupDataToScores, convertGroupDataToScoreCardsData, isCurrentScoreNull, MOCK_GET_GROUPS_DATA } from './ScoreUtils';
import MultiCarousel from './components/MultiCarousel';
import ScoreCard from './components/ScoreCard';
import TabPanel from 'components/TabPanel';
import { GetAllSurveysLabels, GetAllSurveysLabelsVariables } from 'types/GetAllSurveysLabels';
import useContentfulQuery from 'hooks/useContentfulQuery';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupList } from 'store/group/groupSelectors';
import { getAllGroups } from 'store/group/groupSlice';
import { BadgeCard } from 'domain/group/badge';
import { Paths } from "utils/constants";
import { GetAllCourseLabels, GetAllCourseLabelsVariables } from 'types/GetAllCourseLabels';
import { convertGroupDataToCourseCardsData } from './CourseUtils';
import CourseCard from './components/CourseCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        section: {
            position: "relative",
            paddingTop: "20px !important",

            "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: "50%",
                width: "100vw",
                marginRight: "-50vw",
                background: "linear-gradient(to bottom, #e6e6e6, #fff 520px)",
                borderTop: "1px solid" + theme.palette.shades.grey,
                zIndex: -1,
            }
        },
        box: {
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",
        },
        tabs: {
        },
        tab: {
            minWidth: 144,
            color: theme.palette.shades.black,
            fontWeight: 700,

            "&.Mui-selected": {
                color: theme.palette.orange.main,
            },
        },
        indicator: {
            height: 8,
            background: theme.palette.orange.main,
        },
        addGroupButton: {
            flex: "0 0 auto",
            marginLeft: "auto",
            fontSize: "1rem",
            fontWeight: 700,
            textTransform: "uppercase",
            borderRadius: 0,
        },
        groupSection: {
            marginBottom: 40,
        },
        addTestButton: {
            display: "flex",
            margin: "0 auto 50px auto",
            fontSize: "1rem",
            fontWeight: 700,
            textTransform: "uppercase",

            "& .MuiButton-startIcon": {
                padding: 4,
                color: "#fff",
                fontWeight: 700,
                background: theme.palette.shades.grey,
                borderRadius: 20,

                "& .MuiIcon-root": {
                    fontSize: "1.5em",
                    textShadow: "0.5px 0.5px 0 #fff, -0.5px 0.5px 0 #fff, 0.5px -0.5px 0 #fff, -0.5px -0.5px 0 #fff",
                }
            }
        },
        verticalCenter: {
            height: '100%',
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
    }),
);

const GET_ALL_COURSE_LABELS = gql`
query GetAllCourseLabels($preview: Boolean!) {
    courseCollection(preview: $preview, limit: 100) {
      items {
        sys {
          id
        }
        courseTitle
      }
    }
  }    
`;

const GET_ALL_SURVEY_LABELS = gql`
  query GetAllSurveysLabels($preview: Boolean!) {
    surveyCollection(preview:$preview, limit: 25) {
        items {
            sys {
              id
            }
            title
            surveyModulesCollection(limit: 25) {
              items {
                sys {
                  id
                }
                contentCollection(limit: 3) {
                  items {
                    ... on ImplicitAssociationAssessment {
                      sys {
                        id
                      }
                      firstAssociationPairCollection(limit: 2) {
                        items {
                          text
                        }
                      }
                      secondAssociationPairCollection(limit: 2) {
                        items {
                          text
                        }
                      }
                      iatType
                      primaryCategory
                    }
                  }
                }
              }
            }
          }
        }
      }
`;

const useCourseLabels = () => {

    const { data } = useContentfulQuery<GetAllCourseLabels, GetAllCourseLabelsVariables>(GET_ALL_COURSE_LABELS)

    let courseLabels = {} as { [key: string]: { title: string } }
    data?.courseCollection?.items?.forEach(course => {
        if (course?.sys.id)
            courseLabels[course.sys.id] = { 'title': course.courseTitle as string }
    })

    return courseLabels;
}

const useSurveyLabels = () => {

    const { data } = useContentfulQuery<GetAllSurveysLabels, GetAllSurveysLabelsVariables>(GET_ALL_SURVEY_LABELS)

    let surveyLabels = {} as { [key: string]: { title: string, labels: string[], iatType: boolean, primaryCategory: boolean } }
    data?.surveyCollection?.items?.forEach(survey => {
        if (survey?.sys.id)
            surveyLabels[survey.sys.id] = { 'title': survey.title as string, 'labels': [] as string[], 'iatType': true, 'primaryCategory': true }
        survey?.surveyModulesCollection?.items.forEach(module => {
            module?.contentCollection?.items.forEach(content => {
                if (content?.__typename === 'ImplicitAssociationAssessment') {
                    content.firstAssociationPairCollection?.items.forEach(element => {
                        surveyLabels[survey.sys.id]['labels'].push(element?.text as string)
                    });
                    content.secondAssociationPairCollection?.items.forEach(element => {
                        surveyLabels[survey.sys.id]['labels'].push(element?.text as string)
                    });
                    surveyLabels[survey.sys.id]['iatType'] = content?.iatType !== null ? content?.iatType : true;
                    surveyLabels[survey.sys.id]['primaryCategory'] = content?.primaryCategory !== null ? content?.primaryCategory : true;
                }
            })
        })
    })

    return surveyLabels;
}

const useGroupsResults = () => {
    const _groups = useSelector(getGroupList);
    return {
        groupsResults: _groups
    }
}

const DashboardAnalytics = () => {

    const classes = useStyles();

    const courseTitles = useCourseLabels();
    const surveyTitles = useSurveyLabels();
    const { groupsResults } = useGroupsResults();
    // const groupsResults = MOCK_GET_GROUPS_DATA;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };
    return (
        <Grid container spacing={2} >

            <Grid item xs={12} className={classes.section} >
                <Typography variant="h2">OVERVIEW</Typography>
                <MultiCarousel slider>
                    <BadgeCard />
                    {
                        (() => {
                            return groupsResults.map(groupData => [
                                ...convertGroupDataToScoreCardsData(groupData, surveyTitles).map(scoreCardData => <ScoreCard {...scoreCardData} />),
                                ...convertGroupDataToCourseCardsData(groupData, courseTitles).map(courseCardData => <CourseCard {...courseCardData} />),
                            ]).flat();
                        })()
                    }
                </MultiCarousel>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h2">GROUP BREAKDOWN</Typography>
                <Box className={classes.box}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        classes={{ root: classes.tab, indicator: classes.indicator }}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="Tabs component"
                    >
                        {groupsResults.map(group => <Tab className={classes.tab} label={group.name} />)}
                    </Tabs>

                    <Button
                        onClick={() => { navigate(`${Paths.GROUPS}/add`) }}
                        className={classes.addGroupButton}
                        endIcon={<Icon>add</Icon>}
                    >Add Group</Button>
                </Box>
                {groupsResults.map((groupData, index) =>
                    <TabPanel value={value} index={index}>
                        <Grid container className={classes.section}>
                            {surveyTitles !== {} && convertGroupDataToScores(groupData, surveyTitles, true).map((scoreData, index) => {
                                return (<Grid item xs={12} className={classes.groupSection}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <ScoreCard {...scoreData} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <VisCard title={`${scoreData.subheader} - Historical Results`}>
                                                {
                                                    (isCurrentScoreNull(scoreData.scores) || surveyTitles === {}) ?
                                                        <div className={classes.verticalCenter}>
                                                            <Box textAlign="center" fontWeight={600}>NO RESULTS AVAILABLE</Box>
                                                            <Box textAlign="center">Dashboard reporting will only be visible when the greater of 5 people or 10% of participants have completed the tests.</Box>
                                                        </div>
                                                        :
                                                        <BarChart nivoBarProps={convertIATHistoricalDataToBarChartData(convertScoreDataToIATHistoricalData(scoreData))} />
                                                }
                                            </VisCard>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                )
                            }
                            )}
                        </Grid>
                    </TabPanel>
                )}
            </Grid>
            {/* <Grid item xs={12}>
                <Button className={classes.addTestButton} color="default" startIcon={<Icon>add</Icon>}>
                    Add a new test
                </Button>
            </Grid> */}
        </Grid >
    )
}

export default DashboardAnalytics;
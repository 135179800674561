import React, { useState } from "react";
import { Grid, makeStyles, Typography, withStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import theme from "../../../theme";

const useStyles = makeStyles({
    currentWrapper: {
        margin: "25px auto 0 auto",
    },
    textCurrent: {
        marginBottom: 4,
        fontSize: 10,
        fontWeight: 700,
        textAlign: "center",
    },
    textCurrentDate: {
        width: "7.5em",
        padding: "0 0 0.1em 0",
        color: "#fff",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "1.2",
        textAlign: "center",
        backgroundColor: theme.palette.orange.main,
        borderRadius: 20,
    },
    nav: {
        margin: "10px auto 15px auto",
    },
    div: {
        display: "flex",
        alignItems: "center",
    },
    ul: {
        display: 'inline-flex',
        alignItems: "center",
        padding: 0,
        margin: 0,
        listStyle: 'none',
    },
    button: {
        padding: 0,
        background: "none",
        border: "none",
        cursor: "pointer",

        "& svg": {
            display: "block",
            fontSize: "2em",
        }
    },
    textPrevious: {
        marginBottom: 0,
        fontSize: 10,
        fontWeight: 700,
        textAlign: "center",
    },
    date: {
    },
    textDate: {
        width: "7.5em",
        padding: "0 0 0.1em 0",
        color: "#fff",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "1.2",
        textAlign: "center",
        backgroundColor: theme.palette.orange.dark,
        borderRadius: 20,
    },
});

interface IProps {
    date: string | Date;
    current?: boolean;
    onNextFunc?: () => void;
    onPrevFunc?: () => void;
}

const DatePagination = ({ date, current = false, onNextFunc, onPrevFunc }: IProps) => {
    const classes = useStyles();
    const dateString = new Date(date).toLocaleDateString()
    if (current)
        return (
            <div className={classes.currentWrapper}>
                <Typography className={classes.textCurrent} component="div">Current Score</Typography>
                <Typography className={classes.textCurrentDate} component="div">{dateString}</Typography>
            </div>
        )
    else
        return (
            <nav className={classes.nav}>
                <Typography className={classes.textPrevious} component="div">Previous Scores</Typography>
                <div className={classes.div}>
                    <button className={classes.button} onClick={onPrevFunc}><NavigateBeforeIcon /></button>
                    <ul className={classes.ul}>
                        <li className={classes.date}><Typography className={classes.textDate} component="div">{dateString}</Typography></li>
                    </ul>
                    <button className={classes.button} onClick={onNextFunc}><NavigateNextIcon /></button>
                </div>
            </nav>
        )
}

export default DatePagination;
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Grid, Theme, Typography } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';
import { RouteComponentProps } from '@reach/router';
import * as yup from 'yup';
import useValidForm from 'hooks/useValidForm';
import FormInput from 'components/formComponents/form-input';
import { forgotPasswordAction } from 'store/user/userSlice';
import { useDispatch } from 'react-redux';

export interface ForgotPasswordFormData {
    email: string;
}

const defaultValues: ForgotPasswordFormData = {
    email: ""
}

const validationSchema = yup.object().shape({
    email: yup.string().email('A valid email address is required.').required('A valid email address is required.'),
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        background: {
            flexGrow: 1,
            marginTop: theme.spacing(1),
            height: "calc(100vh - 80px)",
            background: "url('/images/general/forgot-password.svg') no-repeat left bottom",
            backgroundSize: "auto 300px",
        },
        form: {
            maxWidth: "600px",
            margin: "0 auto",
        },
        heading: {
            fontSize: 36,
            fontWeight: 700,
            paddingTop: theme.spacing(6),
        },
        subheading: {
            fontSize: 20,
        },
        email: {
            paddingTop: 40,
        },
        button: {
            margin: 'auto',
            paddingTop: 16,
            maxWidth: 200,
        }
    }),
);

interface IProps extends RouteComponentProps {

}

const ForgotPassword = ({ }: IProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onSubmitFunc = async (formValues: ForgotPasswordFormData) => {
        await dispatch(forgotPasswordAction(formValues));
    }

    const { register, errors, onSubmit } = useValidForm<ForgotPasswordFormData>(onSubmitFunc, validationSchema, defaultValues);
    return (
        <MainLayout nofooter>
            <div className={classes.background}>
                <form onSubmit={onSubmit} className={classes.form}>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12}>
                            <Typography className={classes.heading} component="h1">Forgot your password</Typography>
                            <Typography className={classes.subheading} component="h3">Don’t worry! It happens to the best of us. Just fill in your email and we will send you a link to reset your password.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.email} >
                                <FormInput
                                    name="email"
                                    label="Email"
                                    placeholder="Email"
                                    inputRef={register}
                                    error={errors.email ? true : false}
                                    helperText={errors.email?.message}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.button}>
                                <Button type="submit" variant="contained" fullWidth>
                                    Submit
                            </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </MainLayout>
    );
}

export default ForgotPassword;
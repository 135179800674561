import palette from "./palette";


export default {
    fontFamily: [
        'ff-tisa-sans-web-pro',
        'Helvetica',
        'Arial',
        'sans-serif'
    ].join(', '),

    h1: {
        color: palette.text.primary,
        fontSize: "2em",
        fontWeight: 100,
        marginBottom:"1rem"
    },

    h2: {
        color: palette.text.primary,
        fontSize: "1.5em",
        fontWeight: 100,
        marginBottom:"1rem",
        marginTop:"0"
    },
};

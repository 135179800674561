
import { Action, AnyAction, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import userReducer from 'store/user/userSlice';
import groupReducer from 'store/group/groupSlice';
import planReducer from 'store/plan/planSlice';
import uiReducer from 'store/ui/uiSlice';
import { loadState, saveState } from 'utils/persistence';
import interceptor from 'services/axiosService';
import { navigate } from "@reach/router";
import { Paths } from "utils/constants";
import { removeToken } from "utils/cookieHelper";
const persistedState = loadState();

export interface ValidationErrors {
  message: string
  field_errors: Record<string, string>
};

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "@@user/logout") {
    state = {} as IApplicationState;
    removeToken();
    navigate(Paths.LOGISTER)
  }
  return combinedReducer(state, action);
};


const combinedReducer = combineReducers({
  group: groupReducer,
  plan: planReducer,
  ui: uiReducer,
  user: userReducer,
});

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  devTools: process.env.NODE_ENV !== "development" ? false : true,
});

export type IApplicationState = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IApplicationState,
  unknown,
  Action<string>
>;

store.subscribe(() => {
  saveState(store.getState());
})

interceptor.interceptor(store);

export default store;
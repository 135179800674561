import { createMuiTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
    palette,
    typography,
    overrides,
    breakpoints: {
        values: {
            xs: 320,
            sm: 640,
            md: 800,
            lg: 1028,
            xl: 1456,
        }
    }
});

theme.overrides = {
    ...theme.overrides,
    MuiButton: {
        ...theme.overrides?.MuiButton,
        root: {
            ...theme.overrides?.MuiButton?.root,

            textTransform: "none",
        },
        contained: {
            ...theme.overrides?.MuiButton?.contained,

            minHeight: 32,
            padding: "0 1.5em",
            color: "#fff",
            fontWeight: 700,
            backgroundColor: theme.palette.orange.main,
            border: "2px solid #fff",
            borderRadius: "10em",

            "&:hover, &:focus": {
                backgroundColor: theme.palette.orange.highlight,
                borderColor: "#fff",
            },
        },
        containedSizeLarge: {
            ...theme.overrides?.MuiButton?.containedSizeLarge,

            fontSize: "1rem",
            borderWidth: 4,
        },
        label: {
            ...theme.overrides?.MuiButton?.label,
        }
    },
}

export default theme;
import React from "react";
import { FC } from "react";
import * as yup from 'yup';
import { Button, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { countries } from "countries-list";
import useValidForm from "hooks/useValidForm";
import FormInput from "components/formComponents/form-input";
import FormNumber from "components/formComponents/form-number";
import FormCheckbox from "components/formComponents/form-checkbox";
import { createAccountAction } from "store/user/userSlice";
import { useDispatch } from "react-redux";
import { navigate } from "@reach/router";
import { Paths } from "utils/constants";
import FormSelect from "components/formComponents/form-select";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        submitButton: {
        },
        privacyMessage: {
            marginBottom: "15px",
            "& > span": {
                color: "#c8602a",
                fontWeight: 600,
                cursor: "pointer",
            }
        },
        labelTitle: {
            fontSize: "20pt",
            textAlign: "center",
        },
        checkboxLabel: {
            color: theme.palette.orange.main,
            cursor: "pointer"
        }
    }),
);

const ListOfCountries = Object.entries(countries)
    .map(([countryCode, countryData]) => ({ label: countryData.name, value: countryCode }))
    .sort((a, b) => a.label > b.label ? 1 : -1);

export interface CreateAccountFormData {
    firstName: string;
    lastName: string;
    companyName: string;
    jobTitle: string;
    country: string;
    phoneNumber: string;
    ext: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    sendNews: boolean;
    confirmPrivacyPolicy: boolean;
}

const defaultValues: CreateAccountFormData = {
    firstName: "",
    lastName: "",
    companyName: "",
    jobTitle: "",
    country: "",
    phoneNumber: "",
    ext: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    sendNews: undefined!,
    confirmPrivacyPolicy: undefined!
}

const validationSchema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    companyName: yup.string().required('Company name is required'),
    jobTitle: yup.string(),
    country: yup.string().required("Country is required"),
    phoneNumber: yup.string()
        .required('Phone number is required')
        .matches(/^[+]?[-()./\s0-9]*$/, 'A valid phone number is required.'),
    ext: yup.string(),
    email: yup.string().email('A valid email address is required.').required('A valid email address is required.'),
    password: yup.string()
        .required('Password is required.')
        .min(8, 'Password is too short, should be at least 8 characters.')
        .matches(/^(?=.*?[A-Z]).*/, "Password must contain at least one uppercase letter."),
    passwordConfirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    sendNews: yup.boolean(),
    confirmPrivacyPolicy: yup.boolean().required("You must confirm the privacy policy").oneOf([true], 'You must confirm the privacy policy')
});

interface IProps {
}

const CreateAccount: FC<IProps> = () => {
    const dispatch = useDispatch()

    const onSubmitFunc = (formValues: CreateAccountFormData) => {
        dispatch(createAccountAction(formValues));
    }

    const { register, control, errors, onSubmit } = useValidForm<CreateAccountFormData>(onSubmitFunc, validationSchema, defaultValues);
    const classes = useStyles();

    function navigateToPrivacyPolicy() {
        navigate(Paths.PRIVACY);
    }

    return (
        <>
            <form onSubmit={onSubmit} className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.labelTitle}>
                        New to BiasProof?
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormInput
                            name="firstName"
                            label="First name*"
                            placeholder="First name"
                            inputRef={register}
                            error={errors.firstName ? true : false}
                            helperText={errors.firstName?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormInput
                            name="lastName"
                            label="Last name*"
                            placeholder="Last name"
                            inputRef={register}
                            error={errors.lastName ? true : false}
                            helperText={errors.lastName?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput
                            name="companyName"
                            label="Company Name*"
                            placeholder="Company Name"
                            inputRef={register}
                            error={errors.companyName ? true : false}
                            helperText={errors.companyName?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormInput
                            label="Job title"
                            placeholder="Job title"
                            name="jobTitle"
                            inputRef={register}
                            error={errors.jobTitle ? true : false}
                            helperText={errors.jobTitle?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormSelect
                            label="Country*"
                            name="country"
                            options={ListOfCountries}
                            control={control}
                            error={errors.country ? true : false}
                            helperText={errors.country?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormNumber
                            name="phoneNumber"
                            placeholder="Phone Number"
                            label="Phone number*"
                            inputRef={register}
                            error={errors.phoneNumber ? true : false}
                            helperText={errors.phoneNumber?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormInput
                            name="ext"
                            label="Ext."
                            placeholder="Ext."
                            inputRef={register}
                            error={errors.ext ? true : false}
                            helperText={errors.ext?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput
                            name="email"
                            type="email"
                            label="Email*"
                            placeholder="Email"
                            inputRef={register}
                            error={errors.email ? true : false}
                            helperText={errors.email?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormInput
                            name="password"
                            type="password"
                            label="Password*"
                            placeholder="Password"
                            inputRef={register}
                            error={errors.password ? true : false}
                            helperText={errors.password?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormInput
                            name="passwordConfirmation"
                            type="password"
                            label="Password Confirmation*"
                            placeholder="Password"
                            inputRef={register}
                            error={errors.passwordConfirmation ? true : false}
                            helperText={errors.passwordConfirmation?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            id="sendNews"
                            label="Send me news and information on implict bias."
                            name="sendNews"
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            id="confirmPrivacyPolicy"
                            label={<span>I confirm I have read and agree to BiasProof’s <span className={classes.checkboxLabel} onClick={navigateToPrivacyPolicy}>Privacy Policy.*</span></span>}
                            name="confirmPrivacyPolicy"
                            control={control}
                            error={errors.confirmPrivacyPolicy ? true : false}
                            errorMessage={errors.confirmPrivacyPolicy?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" fullWidth className={classes.submitButton}>
                            Register
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default CreateAccount;
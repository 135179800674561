import { Button, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import React, { memo } from "react";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            borderWidth: "2px",
            textTransform: "none",
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
        }
    }),
);

interface IProps {
    label: string;
    onClick?: (args: any) => any
    disabled?: boolean
    type?: "button" | "reset" | "submit" | undefined
    secondary?: boolean
    className?: any
}



const CustomButton = ({ label, onClick, disabled = false, type = "button", secondary = false, className }: IProps) => {
    const classes = useStyles();
    if (secondary) return (
        <Button
            className={classes.button}
            style={{ marginLeft: "10px", fontWeight: 600 }}
            color="primary"
            onClick={onClick}
            type={type}
            size="medium"
            disabled={disabled}
        >{label}</Button>
    )
    return (
        <Button
            className={`${classes.button} ${className}`}
            style={{ marginLeft: "10px" }}
            variant="contained"
            color="primary"
            onClick={onClick}
            type={type}
            size="medium"
            disabled={disabled}
        >{label}</Button>

    );
};

export default CustomButton;
import React, { useState } from "react";
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import MuiCardHeader from '@material-ui/core/CardHeader';
import MuiCardContent from '@material-ui/core/CardContent';
import ScoreProgressBar from "./ScoreProgressBar";
import DatePagination from "./DatePagination";
import ScoreCircleProgressBar from "./ScoreCircleProgressBar";
import theme from "../../../theme";

const Card = withStyles({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        margin: "0 6px",
        borderRadius: "12px",
        boxShadow: "8px 10px 12px 0 rgba(0,0,0,0.25)",
        overflow: "hidden",
    }
})(MuiCard);

const CardHeader = withStyles({
    root: {
        padding: 10,
        color: "#fff",
        background: theme.palette.orange.main,
        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.25)",
    },
    title: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: "12px",
        fontWeight: 300,
    },
    subheader: {
        color: "inherit",
        fontFamily: "inherit",
        fontSize: "18px",
        fontWeight: 900,
        textTransform: "uppercase",
    },
})(MuiCardHeader);

const CardContent = withStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        padding: "30px 56px",
    },
})(MuiCardContent);

const Box = withStyles({
    root: {
        margin: "10px 0",
        padding: 0,
        textAlign: "center",
    }
})(MuiCardContent);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textNoResults: {
            color: theme.palette.orange.dark,
            fontSize: 14,
            fontWeight: 800,
        },
        textNoResultsInfo: {
            marginTop: 20,
            fontSize: 12,
            fontWeight: 500,
        },
        circleProgress: {
            width: 150,
            margin: "0 0 10px 0",
        }
    })
);


const usePrevCurrentScores = (
    scores: {
        date: Date;
        score: number | null;
        completed: number;
        numberOfParticipants: number;
    }[]
) => {
    const sortedScores = scores.map(
        scoreData => ({ ...scoreData, date: new Date(scoreData.date) })
    ).sort(
        (a, b) => b.date.getTime() - a.date.getTime()
    )
    return {
        currentScore: sortedScores[0],
        prevScores: sortedScores.slice(1)
    }
}

interface IProps {
    title: string;
    subheader: string;
    scores: {
        date: Date;
        score: number | null;
        completed: number;
        numberOfParticipants: number;
    }[];
}

const ScoreCard = ({ title, subheader, scores }: IProps) => {
    const classes = useStyles();

    const { currentScore, prevScores } = usePrevCurrentScores(scores)
    const [prevScoreIndex, setPrevScoreIndex] = useState(0)

    function onPrev() {
        if (prevScoreIndex < prevScores.length - 1)
            setPrevScoreIndex(prevScoreIndex + 1);
    }

    function onNext() {
        if (0 < prevScoreIndex)
            setPrevScoreIndex(prevScoreIndex - 1);
    }
    return (
        <Card>
            <CardHeader
                title={title}
                subheader={subheader}
            />
            <CardContent>
                <Box className={classes.circleProgress}>
                    <ScoreCircleProgressBar
                        currentScore={currentScore.score}
                        prevScore={prevScores.length > 0 ? prevScores[prevScoreIndex].score : undefined}
                    />
                </Box>
                {
                    currentScore.score !== null &&
                    <DatePagination
                        date={currentScore.date}
                        current
                    />
                }
                {
                    currentScore.score !== null && prevScores.length > 0 &&
                    <DatePagination
                        date={prevScores[prevScoreIndex].date}
                        onNextFunc={onNext}
                        onPrevFunc={onPrev}
                    />
                }
                {
                    currentScore.score === null &&
                    <Box>
                        <strong className={classes.textNoResults}>No Results Available</strong>
                        <div className={classes.textNoResultsInfo}>Dashboard reporting will only be visible when the greater of 5 people or 10% of participants have completed the tests.</div>
                    </Box>
                }
                <ScoreProgressBar value={currentScore.completed} total={currentScore.numberOfParticipants} />
            </CardContent>
        </Card>
    )
}

export default ScoreCard;
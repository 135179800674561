import {
    createAsyncThunk,
    createSlice,
} from "@reduxjs/toolkit";
import { DowngradeSubscriptionRequest, PlanConfigurationDTO, PlanDTO, UpgradeSubscriptionReqDTO } from "external-api/license-api";
import PlanConfigurationService from "services/planConfigurationService";
import PlanService from "services/planService";
import EcomService from "services/ecomService";
import { IApplicationState, ValidationErrors } from "store";
import { enqueueNotification } from "store/ui/uiSlice";
import { tryRefreshTokenAction } from "store/user/userSlice";
import { handleAuthErrors } from "utils/errorHandling";
import { loadStripe } from "@stripe/stripe-js";

const initialState: PlanDTO = {
    id: "",
    name: "",
    description: "",
    price: 0,
    hasStripe: false,
    features:
    {
        allowAnonymousUsers: false,
        maxNumAnonymousUsers: 0,
        allowEmailUsers: false,
        maxNumEmailUsers: 0,
    },
    pendingPayment: false,
    stripeCustomerID: "",
    stripeSubscriptionID: "",
    stripeProductID: ""
}

const getMyPlan = createAsyncThunk<
    PlanDTO,
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/plan/get",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch, getState } = thunkAPI;
            const { user } = getState();
            if (!user.info?.planId)
                return rejectWithValue({ message: "Invalid planId.", field_errors: { planId: user.info?.planId as string } }) as any
            else {
                try {
                    await dispatch(tryRefreshTokenAction());
                    const service = new PlanService();
                    const resData: PlanDTO = await service.getPlan(user.info?.planId);
                    return resData as any;
                }
                catch (err) {
                    handleAuthErrors(dispatch, err);
                    return rejectWithValue(err.body) as any
                }
            }
        }
    );

const downgradePlanAction = createAsyncThunk<
    { status: string, },
    DowngradeSubscriptionRequest,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@plan/downgrade",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {

                await dispatch(tryRefreshTokenAction());
                const ecomService = new EcomService();
                await ecomService.downgradeSubscription(data);

                dispatch(enqueueNotification({
                    message: 'Successfully Updated Plan',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));

                return {
                    status: 'success',
                }
            }
            catch (err) {
                handleAuthErrors(dispatch, err, 'There was an issue with updating plan');
                return rejectWithValue(err.body)
            }
        });

const upgradePlanAction = createAsyncThunk<
    { status: string, sessionId: string },
    UpgradeSubscriptionReqDTO,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@plan/upgrade",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch } = thunkAPI;
            try {
                await dispatch(tryRefreshTokenAction());
                const stripeKey = `${process.env.REACT_APP_STRIPE_KEY}`;
                const stripe = await loadStripe(stripeKey);

                const ecomService = new EcomService();
                const resp = await ecomService.upgradeSubscription(data);

                if (stripe && resp) {
                    const stripeRes = await stripe.redirectToCheckout({ sessionId: resp });
                }
                return {
                    status: 'success',
                    sessionId: resp,
                }
            }
            catch (err) {
                handleAuthErrors(dispatch, err, 'There was an issue with updating plan');
                return rejectWithValue(err.body)
            }
        });

const updatePlanConfiguration = createAsyncThunk<
    PlanConfigurationDTO,
    undefined,
    {
        rejectValue: ValidationErrors,
        state: IApplicationState
    }>("@@/plan/update",
        async (data, thunkAPI) => {
            const { rejectWithValue, dispatch, getState } = thunkAPI;
            try {

                await dispatch(tryRefreshTokenAction());
                const { ui } = getState();
                const service = new PlanConfigurationService();
                const currentPlanConfiguration = ui.group.planConfiguration;
                const planConfigurationDTO: PlanConfigurationDTO = {
                    name: currentPlanConfiguration?.name,
                    groupId: ui.group?.id,
                    courseConfiguration: currentPlanConfiguration?.courseConfiguration?.map(x => {
                        return {
                            categoryId: x.categoryId,
                            id: x.id,
                            courseModuleIds: x.courseModuleIds?.map(x => x.moduleId)
                        }
                    }),
                    testConfiguration: currentPlanConfiguration?.testConfiguration
                };

                const resData: PlanConfigurationDTO = await service.updatePlanConfiguration(ui.group.planConfiguration?.id!, planConfigurationDTO);

                dispatch(enqueueNotification({
                    message: 'Successfully Updated Plan configuration',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    }
                }));

                return resData;
            }
            catch (err) {
                handleAuthErrors(dispatch, err);
                return rejectWithValue(err.body)
            }
        }
    );

const planSlice = createSlice({
    name: "@@plan",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getMyPlan.fulfilled, (state, action) => {
            return action.payload as PlanDTO;
        });
        builder.addCase(getMyPlan.rejected, (state, action) => {
        });
    }
});

export {
    getMyPlan,
    downgradePlanAction,
    upgradePlanAction,
    updatePlanConfiguration
}
export default planSlice.reducer;
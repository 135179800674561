import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import store from 'store';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import * as serviceWorker from 'serviceWorker';
import { client } from 'apollo-client'
import theme from 'theme';
import { SnackbarProvider } from 'notistack';
import Notifier from "components/SnackBarNotifier";
import GlobalLoader from 'components/Loader';
import CustomToaster from 'components/CustomToaster';
import { LocalizationProvider } from '@material-ui/pickers';

import MomentAdapter from '@material-ui/pickers/adapter/moment'; // choose your lib

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={MomentAdapter}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} autoHideDuration={5000}
              content={(key, message) => (
                <CustomToaster id={key} message={message} />
              )}>
              <CssBaseline />
              <GlobalLoader />
              <Notifier />
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </ApolloProvider>
      </LocalizationProvider >
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import { usePromiseTracker } from "react-promise-tracker";

const useStyles = makeStyles(theme => ({
    spinner: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    backdrop:{
        zIndex: 9999
    }
}));

const GlobalLoader = () => {
    const classes = useStyles();
    const { promiseInProgress } = usePromiseTracker();
    //other logic
    return (
        <>
            {promiseInProgress &&
                <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    );
}
export default GlobalLoader
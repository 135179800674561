/* tslint:disable */
/* eslint-disable */
/**
 * Bias Proof Account API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    normalizedUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    normalizedEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    emailConfirmed: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    passwordHash?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    securityStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    concurrencyStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    phoneNumberConfirmed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    twoFactorEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    lockoutEnd?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    lockoutEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    accessFailedCount: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    publicID?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    phoneNumberExt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    emailOptIn: boolean;
    /**
     * 
     * @type {AccountRole}
     * @memberof Account
     */
    role: AccountRole;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    planId?: string;
    /**
     * 
     * @type {Badge}
     * @memberof Account
     */
    badge?: Badge;
    /**
     * 
     * @type {Array<RefreshToken>}
     * @memberof Account
     */
    refreshTokens?: Array<RefreshToken>;
}
/**
 * 
 * @export
 * @interface AccountAllOf
 */
export interface AccountAllOf {
    /**
     * 
     * @type {string}
     * @memberof AccountAllOf
     */
    publicID?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAllOf
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAllOf
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAllOf
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAllOf
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAllOf
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAllOf
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAllOf
     */
    phoneNumberExt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountAllOf
     */
    emailOptIn: boolean;
    /**
     * 
     * @type {AccountRole}
     * @memberof AccountAllOf
     */
    role: AccountRole;
    /**
     * 
     * @type {string}
     * @memberof AccountAllOf
     */
    planId?: string;
    /**
     * 
     * @type {Badge}
     * @memberof AccountAllOf
     */
    badge?: Badge;
    /**
     * 
     * @type {Array<RefreshToken>}
     * @memberof AccountAllOf
     */
    refreshTokens?: Array<RefreshToken>;
}
/**
 * 
 * @export
 * @interface AccountInfo
 */
export interface AccountInfo {
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    publicID?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    phoneNumberExt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInfo
     */
    emailOptIn: boolean;
    /**
     * 
     * @type {BadgeResponseDTO}
     * @memberof AccountInfo
     */
    badge?: BadgeResponseDTO;
    /**
     * 
     * @type {AuthResult}
     * @memberof AccountInfo
     */
    authResult?: AuthResult;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    planId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInfo
     */
    authenticatedEmail: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AccountRole {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface AuthResult
 */
export interface AuthResult {
    /**
     * 
     * @type {string}
     * @memberof AuthResult
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthResult
     */
    refreshToken?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthResult
     */
    success: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthResult
     */
    errors?: Array<string>;
}
/**
 * 
 * @export
 * @interface AuthenticatedModel
 */
export interface AuthenticatedModel {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedModel
     */
    accountName: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedModel
     */
    password: string;
}
/**
 * 
 * @export
 * @interface Badge
 */
export interface Badge {
    /**
     * 
     * @type {number}
     * @memberof Badge
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    dateCreated: string;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    dateModified: string;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    publicID: string;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    websiteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    companyLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    blobId?: string;
    /**
     * 
     * @type {Account}
     * @memberof Badge
     */
    account?: Account;
    /**
     * 
     * @type {string}
     * @memberof Badge
     */
    accountId?: string;
}
/**
 * 
 * @export
 * @interface BadgeAllOf
 */
export interface BadgeAllOf {
    /**
     * 
     * @type {string}
     * @memberof BadgeAllOf
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeAllOf
     */
    websiteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeAllOf
     */
    companyLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeAllOf
     */
    blobId?: string;
    /**
     * 
     * @type {Account}
     * @memberof BadgeAllOf
     */
    account?: Account;
    /**
     * 
     * @type {string}
     * @memberof BadgeAllOf
     */
    accountId?: string;
}
/**
 * 
 * @export
 * @interface BadgeDTO
 */
export interface BadgeDTO {
    /**
     * 
     * @type {string}
     * @memberof BadgeDTO
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeDTO
     */
    websiteUrl: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeDTO
     */
    companyLogo?: string;
    /**
     * 
     * @type {any}
     * @memberof BadgeDTO
     */
    logo?: any;
}
/**
 * 
 * @export
 * @interface BadgeResponseDTO
 */
export interface BadgeResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof BadgeResponseDTO
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeResponseDTO
     */
    websiteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof BadgeResponseDTO
     */
    companyLogo?: string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordModel
 */
export interface ForgotPasswordModel {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordModel
     */
    email: string;
}
/**
 * 
 * @export
 * @interface IdentityOfIntegerAndString
 */
export interface IdentityOfIntegerAndString {
    /**
     * 
     * @type {number}
     * @memberof IdentityOfIntegerAndString
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IdentityOfIntegerAndString
     */
    dateCreated: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityOfIntegerAndString
     */
    dateModified: string;
}
/**
 * 
 * @export
 * @interface IdentityUserOfInteger
 */
export interface IdentityUserOfInteger {
    /**
     * 
     * @type {number}
     * @memberof IdentityUserOfInteger
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IdentityUserOfInteger
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityUserOfInteger
     */
    normalizedUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityUserOfInteger
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityUserOfInteger
     */
    normalizedEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityUserOfInteger
     */
    emailConfirmed: boolean;
    /**
     * 
     * @type {string}
     * @memberof IdentityUserOfInteger
     */
    passwordHash?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityUserOfInteger
     */
    securityStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityUserOfInteger
     */
    concurrencyStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityUserOfInteger
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityUserOfInteger
     */
    phoneNumberConfirmed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityUserOfInteger
     */
    twoFactorEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof IdentityUserOfInteger
     */
    lockoutEnd?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityUserOfInteger
     */
    lockoutEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof IdentityUserOfInteger
     */
    accessFailedCount: number;
}
/**
 * 
 * @export
 * @interface PublicIdentityOfIntegerAndString
 */
export interface PublicIdentityOfIntegerAndString {
    /**
     * 
     * @type {number}
     * @memberof PublicIdentityOfIntegerAndString
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PublicIdentityOfIntegerAndString
     */
    dateCreated: string;
    /**
     * 
     * @type {string}
     * @memberof PublicIdentityOfIntegerAndString
     */
    dateModified: string;
    /**
     * 
     * @type {string}
     * @memberof PublicIdentityOfIntegerAndString
     */
    publicID: string;
}
/**
 * 
 * @export
 * @interface PublicIdentityOfIntegerAndStringAllOf
 */
export interface PublicIdentityOfIntegerAndStringAllOf {
    /**
     * 
     * @type {string}
     * @memberof PublicIdentityOfIntegerAndStringAllOf
     */
    publicID: string;
}
/**
 * 
 * @export
 * @interface RefreshToken
 */
export interface RefreshToken {
    /**
     * 
     * @type {number}
     * @memberof RefreshToken
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    dateCreated: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    dateModified: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    publicID: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    jwtId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RefreshToken
     */
    isUsed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RefreshToken
     */
    isRevoked: boolean;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    addedDate: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    expiryDate: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    accountId?: string;
    /**
     * 
     * @type {Account}
     * @memberof RefreshToken
     */
    account?: Account;
}
/**
 * 
 * @export
 * @interface RefreshTokenAllOf
 */
export interface RefreshTokenAllOf {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenAllOf
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenAllOf
     */
    jwtId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RefreshTokenAllOf
     */
    isUsed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RefreshTokenAllOf
     */
    isRevoked: boolean;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenAllOf
     */
    addedDate: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenAllOf
     */
    expiryDate: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenAllOf
     */
    accountId?: string;
    /**
     * 
     * @type {Account}
     * @memberof RefreshTokenAllOf
     */
    account?: Account;
}
/**
 * 
 * @export
 * @interface ResetPasswordModel
 */
export interface ResetPasswordModel {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordModel
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordModel
     */
    confirmPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordModel
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface TokenRequestDTO
 */
export interface TokenRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof TokenRequestDTO
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequestDTO
     */
    refreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequestDTO
     */
    accountId: string;
}
/**
 * 
 * @export
 * @interface UpdateAccountInfoDto
 */
export interface UpdateAccountInfoDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInfoDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInfoDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInfoDto
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInfoDto
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInfoDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountInfoDto
     */
    phoneNumberExt?: string;
}
/**
 * 
 * @export
 * @interface UpdateEmailDto
 */
export interface UpdateEmailDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailDto
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface UpdatePasswordDto
 */
export interface UpdatePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDto
     */
    currentPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDto
     */
    newPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDto
     */
    confirmPassword?: string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyName] 
         * @param {string} [websiteUrl] 
         * @param {string} [companyLogo] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAddBadge: async (companyName?: string, websiteUrl?: string, companyLogo?: string, logo?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/AddBadge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (companyName !== undefined) { 
                localVarFormParams.append('CompanyName', companyName as any);
            }
    
            if (websiteUrl !== undefined) { 
                localVarFormParams.append('WebsiteUrl', websiteUrl as any);
            }
    
            if (companyLogo !== undefined) { 
                localVarFormParams.append('CompanyLogo', companyLogo as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('Logo', logo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAddPlan: async (planId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('accountAddPlan', 'planId', planId)
            const localVarPath = `/Account/AddPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(planId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthenticatedModel} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAuthenticate: async (info: AuthenticatedModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'info' is not null or undefined
            assertParamExists('accountAuthenticate', 'info', info)
            const localVarPath = `/Account/IsAuthenticated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(info, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountConfirmEmail: async (token?: string, email?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/ConfirmEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountInfo} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountCreateHRAdmin: async (info: AccountInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'info' is not null or undefined
            assertParamExists('accountCreateHRAdmin', 'info', info)
            const localVarPath = `/Account/CreateHRAdmin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(info, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordModel} forgotPasswordModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForgotPassword: async (forgotPasswordModel: ForgotPasswordModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordModel' is not null or undefined
            assertParamExists('accountForgotPassword', 'forgotPasswordModel', forgotPasswordModel)
            const localVarPath = `/Account/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetLogos: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/logos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetUserInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/GetUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthenticatedModel} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogin: async (info: AuthenticatedModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'info' is not null or undefined
            assertParamExists('accountLogin', 'info', info)
            const localVarPath = `/Account/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(info, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [email] 
         * @param {string} [oldEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountReConfirmEmail: async (token?: string, email?: string, oldEmail?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/ReConfirmEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (oldEmail !== undefined) {
                localVarQueryParameter['oldEmail'] = oldEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenRequestDTO} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRefreshToken: async (tokenRequest: TokenRequestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRequest' is not null or undefined
            assertParamExists('accountRefreshToken', 'tokenRequest', tokenRequest)
            const localVarPath = `/Account/RefreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResendConfirmation: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Account/ResendConfirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordModel} resetPasswordModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPassword: async (resetPasswordModel: ResetPasswordModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordModel' is not null or undefined
            assertParamExists('accountResetPassword', 'resetPasswordModel', resetPasswordModel)
            const localVarPath = `/Account/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAccountInfoDto} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateAccount: async (info: UpdateAccountInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'info' is not null or undefined
            assertParamExists('accountUpdateAccount', 'info', info)
            const localVarPath = `/Account/UpdateAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(info, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateEmailDto} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateEmail: async (info: UpdateEmailDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'info' is not null or undefined
            assertParamExists('accountUpdateEmail', 'info', info)
            const localVarPath = `/Account/UpdateEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(info, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePasswordDto} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdatePassword: async (info: UpdatePasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'info' is not null or undefined
            assertParamExists('accountUpdatePassword', 'info', info)
            const localVarPath = `/Account/UpdatePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(info, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [companyName] 
         * @param {string} [websiteUrl] 
         * @param {string} [companyLogo] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountAddBadge(companyName?: string, websiteUrl?: string, companyLogo?: string, logo?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BadgeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountAddBadge(companyName, websiteUrl, companyLogo, logo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountAddPlan(planId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountAddPlan(planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthenticatedModel} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountAuthenticate(info: AuthenticatedModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountAuthenticate(info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountConfirmEmail(token?: string, email?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountConfirmEmail(token, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AccountInfo} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountCreateHRAdmin(info: AccountInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountCreateHRAdmin(info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordModel} forgotPasswordModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountForgotPassword(forgotPasswordModel: ForgotPasswordModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountForgotPassword(forgotPasswordModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetLogos(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BadgeResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetLogos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetUserInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthenticatedModel} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountLogin(info: AuthenticatedModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountLogin(info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [email] 
         * @param {string} [oldEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountReConfirmEmail(token?: string, email?: string, oldEmail?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountReConfirmEmail(token, email, oldEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TokenRequestDTO} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountRefreshToken(tokenRequest: TokenRequestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountRefreshToken(tokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountResendConfirmation(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountResendConfirmation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordModel} resetPasswordModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountResetPassword(resetPasswordModel: ResetPasswordModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountResetPassword(resetPasswordModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAccountInfoDto} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdateAccount(info: UpdateAccountInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdateAccount(info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateEmailDto} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdateEmail(info: UpdateEmailDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdateEmail(info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePasswordDto} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdatePassword(info: UpdatePasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdatePassword(info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [companyName] 
         * @param {string} [websiteUrl] 
         * @param {string} [companyLogo] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAddBadge(companyName?: string, websiteUrl?: string, companyLogo?: string, logo?: any, options?: any): AxiosPromise<BadgeDTO> {
            return localVarFp.accountAddBadge(companyName, websiteUrl, companyLogo, logo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAddPlan(planId: string, options?: any): AxiosPromise<Account> {
            return localVarFp.accountAddPlan(planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticatedModel} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAuthenticate(info: AuthenticatedModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.accountAuthenticate(info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountConfirmEmail(token?: string, email?: string, options?: any): AxiosPromise<any> {
            return localVarFp.accountConfirmEmail(token, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountInfo} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountCreateHRAdmin(info: AccountInfo, options?: any): AxiosPromise<AccountInfo> {
            return localVarFp.accountCreateHRAdmin(info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordModel} forgotPasswordModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForgotPassword(forgotPasswordModel: ForgotPasswordModel, options?: any): AxiosPromise<any> {
            return localVarFp.accountForgotPassword(forgotPasswordModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetLogos(options?: any): AxiosPromise<Array<BadgeResponseDTO>> {
            return localVarFp.accountGetLogos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetUserInfo(options?: any): AxiosPromise<AccountInfo> {
            return localVarFp.accountGetUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticatedModel} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogin(info: AuthenticatedModel, options?: any): AxiosPromise<AccountInfo> {
            return localVarFp.accountLogin(info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [email] 
         * @param {string} [oldEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountReConfirmEmail(token?: string, email?: string, oldEmail?: string, options?: any): AxiosPromise<any> {
            return localVarFp.accountReConfirmEmail(token, email, oldEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenRequestDTO} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRefreshToken(tokenRequest: TokenRequestDTO, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.accountRefreshToken(tokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResendConfirmation(options?: any): AxiosPromise<string> {
            return localVarFp.accountResendConfirmation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordModel} resetPasswordModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPassword(resetPasswordModel: ResetPasswordModel, options?: any): AxiosPromise<any> {
            return localVarFp.accountResetPassword(resetPasswordModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAccountInfoDto} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateAccount(info: UpdateAccountInfoDto, options?: any): AxiosPromise<AccountInfo> {
            return localVarFp.accountUpdateAccount(info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateEmailDto} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdateEmail(info: UpdateEmailDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.accountUpdateEmail(info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePasswordDto} info 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdatePassword(info: UpdatePasswordDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.accountUpdatePassword(info, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {string} [companyName] 
     * @param {string} [websiteUrl] 
     * @param {string} [companyLogo] 
     * @param {any} [logo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountAddBadge(companyName?: string, websiteUrl?: string, companyLogo?: string, logo?: any, options?: any) {
        return AccountApiFp(this.configuration).accountAddBadge(companyName, websiteUrl, companyLogo, logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountAddPlan(planId: string, options?: any) {
        return AccountApiFp(this.configuration).accountAddPlan(planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticatedModel} info 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountAuthenticate(info: AuthenticatedModel, options?: any) {
        return AccountApiFp(this.configuration).accountAuthenticate(info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountConfirmEmail(token?: string, email?: string, options?: any) {
        return AccountApiFp(this.configuration).accountConfirmEmail(token, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountInfo} info 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountCreateHRAdmin(info: AccountInfo, options?: any) {
        return AccountApiFp(this.configuration).accountCreateHRAdmin(info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordModel} forgotPasswordModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountForgotPassword(forgotPasswordModel: ForgotPasswordModel, options?: any) {
        return AccountApiFp(this.configuration).accountForgotPassword(forgotPasswordModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetLogos(options?: any) {
        return AccountApiFp(this.configuration).accountGetLogos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetUserInfo(options?: any) {
        return AccountApiFp(this.configuration).accountGetUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticatedModel} info 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountLogin(info: AuthenticatedModel, options?: any) {
        return AccountApiFp(this.configuration).accountLogin(info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [email] 
     * @param {string} [oldEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountReConfirmEmail(token?: string, email?: string, oldEmail?: string, options?: any) {
        return AccountApiFp(this.configuration).accountReConfirmEmail(token, email, oldEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TokenRequestDTO} tokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountRefreshToken(tokenRequest: TokenRequestDTO, options?: any) {
        return AccountApiFp(this.configuration).accountRefreshToken(tokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountResendConfirmation(options?: any) {
        return AccountApiFp(this.configuration).accountResendConfirmation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordModel} resetPasswordModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountResetPassword(resetPasswordModel: ResetPasswordModel, options?: any) {
        return AccountApiFp(this.configuration).accountResetPassword(resetPasswordModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAccountInfoDto} info 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdateAccount(info: UpdateAccountInfoDto, options?: any) {
        return AccountApiFp(this.configuration).accountUpdateAccount(info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateEmailDto} info 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdateEmail(info: UpdateEmailDto, options?: any) {
        return AccountApiFp(this.configuration).accountUpdateEmail(info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePasswordDto} info 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdatePassword(info: UpdatePasswordDto, options?: any) {
        return AccountApiFp(this.configuration).accountUpdatePassword(info, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingIndex: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingIndex(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingIndex(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingIndex(options?: any): AxiosPromise<any> {
            return localVarFp.pingIndex(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingIndex(options?: any) {
        return PingApiFp(this.configuration).pingIndex(options).then((request) => request(this.axios, this.basePath));
    }
}



import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainLayoutRoot: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            height: "100%",
            minHeight: "100vh",
        },
        content: {
            flexGrow: 1,
            overflow: "hidden",
        },
        maxWidth: {
            maxWidth: 1800,
            padding: "0 24px",
            margin: 'auto',
        }
    }),
);

export interface IProps {
    children: any;
    nofooter?: boolean;
}

const MainLayout = ({ children, nofooter = false }: IProps) => {

    const classes = useStyles();

    return (
        <div className={classes.mainLayoutRoot}>
            <Header />
            <main className={classes.content}>
                <div className={classes.maxWidth}>
                    {children}
                </div>
            </main>
            {
                !nofooter &&
                <Footer />
            }
        </div>
    );
}

export default MainLayout;
import React, { useState } from 'react'
import * as yup from 'yup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from "countries-list";
import { getUser } from 'store/user/userSelector';
import useValidForm from 'hooks/useValidForm';
import FormInput from 'components/formComponents/form-input';
import FormNumber from 'components/formComponents/form-number';
import FormSelect from 'components/formComponents/form-select';
import EditableFormInput from 'components/formComponents/editable-form-input';
import StyledModal from 'components/StyledModal';
import { Divider } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import { updateEmailAction, updatePasswordAction, updateUserInfoAction } from 'store/user/userSlice';
import { UpdateEmailDto, UpdatePasswordDto } from 'external-api/account-api';
import { Dispatch } from 'store';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalHeader: {
            fontWeight: 600,
            fontSize: "1.3em",
            marginTop: "5px"
        },
        divider: {
            backgroundColor: "lightgray",
            width: "100%"
        },
        modalFooter: {
            fontSize: '0.9em',
            textAlign: "center"
        },
        saveButton: {
            minWidth: "150px"
        },
        buttonContainer: {
            textAlign: "center"
        }
    })
);



const validationSchema = yup.object().shape({
    email: yup.string().email('A valid email address is required.').required('A valid email address is required.'),
    confirmEmail: yup.string()
        .oneOf([yup.ref('email'), null], 'Passwords must match'),
});

interface UpdateEmailModalFormData {
    onClose: () => void
}

const ModalContents = ({ onClose }: UpdateEmailModalFormData) => {
    const classes = useStyles();
    const dispatch: Dispatch = useDispatch();
    const onSubmitFunc = async (formValues: UpdateEmailFormData) => {
        const resp = await dispatch(updateEmailAction({ email: formValues.email }));

        if (updateEmailAction.fulfilled.match(resp)) {
            onClose();
        } else {
        }
    }

    const { register, control, errors, onSubmit } = useValidForm<UpdateEmailFormData>(onSubmitFunc, validationSchema);

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography component="span" variant="h2" className={classes.modalHeader}>Changes to your Email will be reflected across your account.</Typography>
                </Grid>
                <Grid item xs={12}><Divider className={classes.divider} /></Grid>
                <Grid item xs={12} sm={12}>
                    <FormInput
                        name="email"
                        type="email"
                        label="New Email"
                        placeholder="New Email"
                        inputRef={register}
                        error={errors.email ? true : false}
                        helperText={errors.email?.message}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormInput
                        name="confirmEmail"
                        type="email"
                        label="Confirm Email"
                        placeholder="Confirm Email"
                        inputRef={register}
                        error={errors.confirmEmail ? true : false}
                        helperText={errors.confirmEmail?.message}
                    />
                </Grid>
                <Grid item xs={12}><Divider className={classes.divider} /></Grid>

                <Grid item xs={12}>
                    <Typography className={classes.modalFooter}>Team members can see this when they communicate with you or view content you create in BiasProof.  For more information, contact our support staff.</Typography>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                    <CustomButton
                        label="Save"
                        type="submit"
                        className={classes.saveButton}
                    />
                    <br />
                    <CustomButton
                        secondary
                        label="Close"
                        onClick={onClose}
                    />

                </Grid>
            </Grid>
        </form>
    )
}

interface UpdateEmailFormData {
    email: string
    confirmEmail: string
}
const EditEmail = ({ email }: { email: string }) => {
    const classes = useStyles();

    const [showModal, setShowModal] = useState(false);

    function closeModal() {
        setShowModal(false);
    }

    return (
        <>
            <EditableFormInput
                name="Email"
                label="Email"
                placeholder="Email"
                readonly={true}
                value={email}
                onEdit={() => setShowModal(true)}
            />

            <StyledModal
                type="confirm"
                pretitle="My Account"
                title="Change Email"
                isOpen={showModal}
                handleModalClose={() => setShowModal(false)}
            >
                <ModalContents
                    onClose={closeModal}
                />
            </StyledModal>
        </>
    )
}

export default EditEmail;

import { createSelector } from "@reduxjs/toolkit";
import { IGroup, IGroupOverviewEmailConfiguration } from "domain/group/types";
import { IUiCurrentPlan, IUiState } from "./uiSlice";
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw } from "draft-js";
import moment from "moment";
import { BadgeResponseDTO } from "external-api/account-api";
import { EcomPlanDTO } from "external-api/license-api";
import { IDateTime } from "domain/group/common/groupOverview/GroupOverview";
const _getCurrentGroup = (state: { ui: IUiState }) => state.ui.group;
const _getCurrentPlan = (state: { ui: IUiState }) => state.ui.currentPlan;
const _getAllPlans = (state: { ui: IUiState }) => state.ui.plans;
const _getAllLogos = (state: { ui: IUiState }) => state.ui.logos;
const _getNotifications = (state: { ui: IUiState }) => state.ui.notifications;

export const getNotifications = createSelector(
    [_getNotifications],
    (notifications: any[]) => {
        return notifications;
    }
)

export const getAllPlans = createSelector(
    [_getAllPlans],
    (plans: EcomPlanDTO[]) => {
        return plans;
    }
)

export const getAllLogos = createSelector(
    [_getAllLogos],
    (logos: BadgeResponseDTO[]) => {
        return logos;
    }
)

export const getCurrentGroup = createSelector(
    [_getCurrentGroup],
    (group: IGroup) => {
        return group
    }
)

export const getCurrentGroupIsAnonymous = createSelector(
    [_getCurrentGroup],
    (group: IGroup) => {
        return group.anonymousUsers ?? false;
    }
)

export const getCurrentGroupParticipants = createSelector(
    [_getCurrentGroup],
    (group: IGroup) => {
        return group.participants ?? [];
    }
)

export const getCurrentGroupAnonLinks = createSelector(
    [_getCurrentGroup],
    (group: IGroup) => {
        return group.anonymousLinks?.map(link => link.url) ?? [];
    }
)

export const getCurrentGroupSlotsUsed = createSelector(
    [_getCurrentGroup],
    (group: IGroup) => {
        return group.numberOfSlotsUsed;
    }
)

export const getCurrentGroupPlanConfiguration = createSelector(
    [_getCurrentGroup],
    (group: IGroup) => {
        return group.planConfiguration;
    }
)

export const getCurrentGroupOverview = createSelector(
    [_getCurrentGroup],
    (group: IGroup) => {
        const invitationBlocksFromHtml = htmlToDraft(group.emailConfiguration?.invitationEmailBody as string ?? "");
        const { contentBlocks: invitationBlocks, entityMap: invitationEntity } = invitationBlocksFromHtml;
        const invitationContentState = ContentState.createFromBlockArray(invitationBlocks, invitationEntity);
        const invitationEditorState = convertToRaw(invitationContentState);

        const reminderBlocksFromHtml = htmlToDraft(group.emailConfiguration?.reminderEmailBody as string ?? "");
        const { contentBlocks, entityMap } = reminderBlocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const reminderEditorState = convertToRaw(contentState);

        const localDates = group?.emailConfiguration?.invitationDates.map(x => {
            return {
                dateTime: moment.utc(x.dateTime as string).local().toDate(),
                initialLoaded: x.initialLoaded
            } as IDateTime
        }) ?? [];

        return {
            name: group.name,
            description: group.description,
            emailConfiguration: {
                ...group.emailConfiguration,
                invitationEmailBody: invitationEditorState,
                reminderEmailBody: reminderEditorState,
                invitationDates: localDates
            }
        } as IGroupOverviewEmailConfiguration;
    }
)

export const getIsPlanSelectionStarted = createSelector(
    [_getCurrentPlan],
    (currentPlan: IUiCurrentPlan) => {
        return currentPlan.change === "PLAN_SELECTION_STARTED";
    }
);

export const getIsPlanSelectionCompleted = createSelector(
    [_getCurrentPlan],
    (currentPlan: IUiCurrentPlan) => {
        return currentPlan.change === "PLAN_SELECTION_COMPLETED";
    }
);

export const getIsPlanUpgradeStarted = createSelector(
    [_getCurrentPlan],
    (currentPlan: IUiCurrentPlan) => {
        return currentPlan.change === "UPGRADE_STARTED";
    }
);

export const getIsPlanUpgradeCompleted = createSelector(
    [_getCurrentPlan],
    (currentPlan: IUiCurrentPlan) => {
        return currentPlan.change === "UPGRADE_COMPLETED";
    }
);

export const getIsPlanUpgradeForMoreEmailsRequested = createSelector(
    [_getCurrentPlan],
    (currentPlan: IUiCurrentPlan) => {
        return currentPlan.planUpgradeForMoreEmails === "REQUESTED";
    }
);

export const getIsPlanUpgradeForMoreEmailsCompleted = createSelector(
    [_getCurrentPlan],
    (currentPlan: IUiCurrentPlan) => {
        return currentPlan.planUpgradeForMoreEmails === "COMPLETED";
    }
);

export const getIsPlanUpgradeForMoreEmailsCanceled = createSelector(
    [_getCurrentPlan],
    (currentPlan: IUiCurrentPlan) => {
        return currentPlan.planUpgradeForMoreEmails === "CANCELED";
    }
);

export const getIsPlanUpgradeForMoreEmailsNotNeeded = createSelector(
    [_getCurrentPlan],
    (currentPlan: IUiCurrentPlan) => {
        return currentPlan.planUpgradeForMoreEmails === "NOT_NEEDED";
    }
);
import React from 'react';
import { useSelector } from "react-redux";
import { navigate } from "@reach/router";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Container, Theme, Typography, Button } from '@material-ui/core';
import MainLayout from 'layouts/MainLayout';
import { GroupListing } from 'domain/group';
import { hasPlan as hasPlanSelector } from 'store/plan/planSelectors';
import { Paths } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            alignContent: "flex-start",
            width: "auto",
            margin: `${theme.spacing(2)}px 64px 0 64px`,

            "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "100vw",
                background: "linear-gradient(to bottom, #e6e6e6, #fff 520px)",
                borderTop: "1px solid" + theme.palette.shades.grey,
                zIndex: -1,
            },
        },
        heading: {
            fontWeight: 500,
            textTransform: "uppercase",
        },
        container: {
            maxWidth: 400,
        },
        title: {
            fontSize: 20,
            fontWeight: 700,
            textTransform: "uppercase",
            marginBottom: 24,
        },
        message: {
            fontSize: 14,
            fontWeight: 400,
        },
        button: {
            margin: 'auto',
            marginTop: '30px',
            padding: "0 3em",
        },
        background: {
            background: "url('/images/general/plan_team.svg') no-repeat left bottom",
            backgroundSize: "auto 415px",
            minHeight: "77vh"
        }
    }),
);

const GroupDashboard = () => {
    const classes = useStyles();
    const hasPlan = useSelector(hasPlanSelector);



    function navigateToPlan() {
        navigate(Paths.PLANS);
    }

    const GroupNoPlan = () => {
        return (
            <Grid item xs={12} className={classes.background}>
                <Container className={classes.container}>
                    <Typography className={classes.title} component="h2">Before we begin...</Typography>
                    <Typography className={classes.message} component="p">Before you can start creating your groups and tests you will need to choose a plan.  Follow the link and select a plan that best fits your needs.</Typography>
                    <div style={{ display: "flex" }}>
                        <Button onClick={navigateToPlan} type="button" color="primary" variant="contained" size="medium" className={classes.button}>Select a plan to begin</Button>
                    </div>
                </Container>
            </Grid>
        );
    }

    return (
        <MainLayout>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h1" className={classes.heading}>Groups</Typography>
                </Grid>
                {
                    hasPlan ? <GroupListing /> : <GroupNoPlan />
                }
            </Grid>
        </MainLayout >
    );
}

export default GroupDashboard;
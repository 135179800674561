import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentGroupAnonLinks, getCurrentGroupOverview, getCurrentGroupParticipants, getCurrentGroupPlanConfiguration, getIsPlanUpgradeForMoreEmailsNotNeeded } from "store/ui/uiSelectors";
import { planUpgradeForMoreEmailsNotNeeded, updateConfigurationSection, updateOverviewSection, updateParticipantSection } from "store/ui/uiSlice";
import ParticipantConfiguration from "domain/participant/ParticipantConfiguration";
import { GroupConfirmation, GroupOverview, GroupPlanConfiguration } from "../common";
import { IEmailConfiguration, IGroupOverviewEmailConfiguration, IParticipantsConfiguration, IPlanConfiguration } from "../types";
import { RawDraftContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html'
import { useMaxAvailableSlot } from "../common/useMaxAvailableSlot";
import { getPlan } from "store/plan/planSelectors";
import { IDateTime } from "../common/groupOverview/GroupOverview";
import { useEffect } from "react";

export const useAddGroupStepper = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const groupOverviewValues = useSelector(getCurrentGroupOverview);
    const participantsValues = useSelector(getCurrentGroupParticipants);
    const anonLinksValues = useSelector(getCurrentGroupAnonLinks);
    const planConfigurationValues = useSelector(getCurrentGroupPlanConfiguration);
    const planValues = useSelector(getPlan);
    const isPlanUpgradeForMoreEmailsNotNeeded = useSelector(getIsPlanUpgradeForMoreEmailsNotNeeded);
    const maxAvailableSlot = useMaxAvailableSlot();

    useEffect(() => {
        if (!isPlanUpgradeForMoreEmailsNotNeeded) { // An upgrade was requested, canceled, or completed from email uploader
            dispatch(planUpgradeForMoreEmailsNotNeeded());
            setActiveStep(1); // Move to Participant Configuration
        }
    }, []);

    const stepperData = [
        {
            title: "Overview",
            component: <GroupOverview
                data={groupOverviewValues}
                handleSubmit={addGroupOverview}
                nextStep={nextStep}
                prevStep={prevStep}
            />
        },
        {
            title: "Configure Participants",
            component: <ParticipantConfiguration
                emails={participantsValues}
                links={anonLinksValues}
                allowAnonymousUsers={planValues.features?.allowAnonymousUsers ?? false}
                allowEmailUsers={planValues.features?.allowEmailUsers ?? false}
                maxNumAnonymousUsers={planValues.features?.maxNumAnonymousUsers ?? 0}
                maxNumEmailUsers={planValues.features?.maxNumEmailUsers ?? 0}
                numAvailAnonymousUsers={undefined}
                numAvailEmailUsers={maxAvailableSlot}
                handleSubmit={addGroupParticipants}
                nextStep={nextStep}
                prevStep={prevStep}
            />,
        },
        {
            title: "Configure Tests + Training",
            component: <GroupPlanConfiguration
                data={planConfigurationValues!}
                handleSubmit={addPlanConfiguration}
                nextStep={nextStep}
                prevStep={prevStep}
            />,
        },
        {
            title: "Group Confirmation",
            component: <GroupConfirmation
                nextStep={nextStep}
                prevStep={prevStep}
                overview={groupOverviewValues}
                participants={participantsValues}
                planConfiguration={planConfigurationValues!}
            />,
        },
    ];

    function setStep(step: number) {
        setActiveStep(step);
    }

    function nextStep() {
        if (activeStep < stepperData.length - 1) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    function prevStep() {
        if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    async function addGroupOverview(data: IGroupOverviewEmailConfiguration) {
        // submit and save to store
        const invitationBodyMarkup = draftToHtml(
            data?.emailConfiguration?.invitationEmailBody as unknown as RawDraftContentState,
        );
        const reminderBodymarkup = draftToHtml(
            data?.emailConfiguration?.reminderEmailBody as unknown as RawDraftContentState,
        );


        const sortedDates = data.emailConfiguration?.invitationDates.sort(
            (a: IDateTime, b: IDateTime) => new Date(a.dateTime as Date).getTime() - new Date(b.dateTime as Date).getTime()
        ) ?? [];

        const invitationDate = sortedDates?.map((date: IDateTime) => {
            return {
                dateTime: moment(date.dateTime).toISOString() ?? "",
                initialLoaded: date.initialLoaded ?? false
            }
        }) as IDateTime[];



        const dataSubmission: IGroupOverviewEmailConfiguration = {
            ...data,
            emailConfiguration: {
                ...data?.emailConfiguration,
                invitationEmailBody: invitationBodyMarkup,
                reminderEmailBody: reminderBodymarkup,
                invitationDates: invitationDate
            } as IEmailConfiguration
        }

        await dispatch(updateOverviewSection(dataSubmission));
    }

    async function addGroupParticipants(data: IParticipantsConfiguration) {
        // submit and save to store
        await dispatch(updateParticipantSection(data));
    }

    async function addPlanConfiguration(data: IPlanConfiguration) {
        // submit and save to store
        await dispatch(updateConfigurationSection(data));
    }

    return {
        currentComponent: stepperData[activeStep].component,
        labels: stepperData.map(x => x.title),
        activeStep,
        setStep,
    }
}

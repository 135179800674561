import React from 'react'
import { createStyles, Grid, List, ListItemText, ListItemIcon, makeStyles, MenuItem, Typography, withStyles, IconButton, Button } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import { GetSurveyDetails } from 'types/GetSurveyDetails';
import { useTestSelection } from './useTestSelection';
import { useTestSelectionData } from './useTestSelectionData';
import ContentfulRichText from 'components/contentful/ContenfulRichText';
import StyledCheckBox from '../training/components/StyledCheckBox';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
    createStyles({
        coursePreviewModal: {
            position: 'absolute',
            display: 'grid',
            grid: 'min-content auto min-content / auto 562px',
            gridTemplateAreas: '"header header" "list content" "footer footer"',
            top: '50%',
            left: '50%',
            width: theme.breakpoints.values.xl,
            height: '85%',
            maxWidth: 960,
            maxHeight: 720,
            minHeight: 600,
            margin: 0,
            padding: 0,
            background: "#fff",
            borderRadius: 20,
            boxShadow: '10px 10px 10px rgba(0,0,0,0.25)',
            overflow: 'hidden',
            transform: 'translate(-50%, -50%)',
        },
        heading: {
            gridArea: 'header',
            margin: 0,
            padding: 25,
            fontWeight: 500,
            textTransform: 'uppercase',
        },
        close: {
            position: "absolute",
            top: 12,
            right: 12,
            padding: 1,
            color: "#fff",
            fontWeight: 700,
            background: "#9d9d9d",
            border: "2px solid #fff",
            boxShadow: "0 4px 2px rgba(0,0,0,0.25)",

            "&:hover, &:focus": {
                background: theme.palette.shades.grey,
            }
        },
        list: {
            padding: '0 25px',
            overflow: 'auto',

            '& .MuiList-root': {
                paddingRight: 0,
            },
        },
        listText: {
            marginBottom: 20,
            fontSize: 14,
            lineHeight: 1.15,
        },
        richTextPreview: {
            padding: "0 25px",
            overflow: 'auto'
        },
        footer: {
            gridArea: 'footer',
            marginTop: "auto",
            padding: 20,
            textAlign: 'center',
        },
        listContainer: {
            display: "flex"
        },
        customCard: {
            width: "auto",
            margin: "0 6px",
            padding: "10px 20px",
            backgroundColor: '#fff',
            boxShadow: "0px 1px 4px 1px rgba(0,0,0,0.25)",
            borderRadius: 6,
            overflow: "hidden",
        }
    })
);


const StyledMenuItem = withStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 5,
        marginBottom: 5,
        marginRight: 10,
        paddingRight: 0,
        backgroundColor: theme.palette.shades.white,
        border: "2px solid transparent",
        borderRadius: 6,
        boxShadow: '4px 4px 12px -3px rgba(0,0,0,0.50)',
        '&.checked': {
            borderColor: theme.palette.primary.main,
            boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.25)',
        },

        '&:focus': {
            backgroundColor: theme.palette.shades.grey2,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.shades.lightgrey,

            '&.checked': {
                borderColor: theme.palette.primary.main,
            }
        },
        '&:hover': {
            '&.Mui-selected': {
                backgroundColor: theme.palette.shades.lightgrey,
            }
        },

        '& .MuiTypography-root': {
            lineHeight: 1.15,
        }
    },
}))(MenuItem);

interface IProps {
    data: GetSurveyDetails
    onChildClick: any
    ref: any
}
// -- get course details
const TestSelection = React.forwardRef(({ data, onChildClick, ref }: IProps) => {
    const classes = useStyles();

    const { activeTestContent, tests, activeTestIndex, activeTestId, setActiveTest } = useTestSelectionData(data);
    const { append, remove, fields, form, isSelected, selectedTests } = useTestSelection(activeTestContent?.sys?.id!);

    function selectTest(id: number) {
        setActiveTest(id);
    }

    function skipTest() {
        if (activeTestIndex < tests.length - 1) {
            setActiveTest(index => index + 1);
        } else {
            // -- Last step. close modal?
        }
    }

    function addTest() {
        const { testConfiguration } = form.getValues();
        const testData = testConfiguration?.find(x => x.id === activeTestId);

        if (!testData) {
            append({
                id: activeTestId,
                categoryId: activeTestContent?.category?.sys?.id!,
                testName: activeTestContent?.title
            });
        }
    }

    function removeTest() {

        const { testConfiguration, courseConfiguration } = form.getValues();
        if (testConfiguration) {
            const selectedTestIndex = testConfiguration.findIndex(x => x.id === activeTestId);
            remove(selectedTestIndex);

            const selectedCourses = courseConfiguration?.filter(x => x.categoryId !== activeTestContent?.category?.sys?.id!);
            form.setValue("courseConfiguration", selectedCourses);
        }
    }

    function toggleTests(testId: string) {
        const { testConfiguration, courseConfiguration } = form.getValues();
        if (testConfiguration) {
            const currentSelectedTest = selectedTests?.find(x => x.id === testId);
            if (selectedTests && currentSelectedTest) {
                // -- Find index in array
                const selectedTestIndex = testConfiguration.findIndex(x => x.id === testId);
                // -- Remove index
                remove(selectedTestIndex);

                const selectedCourses = courseConfiguration?.filter(x => x.categoryId !== currentSelectedTest?.categoryId);
                form.setValue("courseConfiguration", selectedCourses);
            }
            else {
                append({
                    id: testId,
                    categoryId: activeTestContent?.category?.sys?.id!,
                    testName: activeTestContent?.title
                });
            }
        }
    }

    function handleClose(event: any) {
        onChildClick(event.target.name);
    }

    return (
        <div className={classes.coursePreviewModal} ref={ref}>
            <Typography variant="h1" component="h2" className={classes.heading}>{tests[0]?.category?.label}</Typography>
            <IconButton aria-label="Close" component="button" className={classes.close} onClick={handleClose}>
                <Close />
            </IconButton>
            <div className={classes.list}>
                <Typography component="span">Click To Preview <span style={{ float: "right" }}> Add</span></Typography>
                < hr />
                <List component="nav" aria-label="secondary mailbox folders">
                    {tests && tests.map((test, index) => {
                        const checked = selectedTests?.some(x => x.id === test?.sys?.id);
                        return (
                            <div className={classes.listContainer}>
                                <StyledMenuItem selected={index === activeTestIndex} className={(checked ? "checked" : "")} onClick={() => selectTest(index)}>
                                    <ListItemText primary={test?.title} />
                                </StyledMenuItem>
                                <StyledCheckBox
                                    key={`checkbox-${index}`}
                                    checked={checked}
                                    onChange={() => toggleTests(test?.sys?.id!)}
                                />
                            </div>
                        )
                    })}
                </List>
            </div>
            <div className={classes.richTextPreview}>
                <Typography variant="h2" component="h2" style={{ textTransform: 'uppercase', marginLeft: "20px", fontWeight: "bold" }}>{activeTestContent?.title}</Typography>
                <div className={classes.customCard}>
                    {activeTestContent &&
                        <ContentfulRichText
                            data={activeTestContent?.overview}
                        />
                    }
                </div>
            </div>
            <div className={classes.footer}>
                <Grid container spacing={0}>
                    <Grid item xs={5}>
                        <Grid item xs={12}>
                            <CustomButton
                                label="Add Selected Test"
                                onClick={handleClose}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={removeTest} disabled={selectedTests?.length === 0}>
                                <Typography component="span" style={{ textDecoration: "underline", fontWeight: "bold" }}>Clear All</Typography>
                            </Button>
                        </Grid>

                    </Grid>
                    <Grid item xs={7}></Grid>
                </Grid>

            </div>
        </div>
    )
})

export default TestSelection;
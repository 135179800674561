import React, { useState } from 'react'
import * as yup from 'yup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from "countries-list";
import { getUser } from 'store/user/userSelector';
import useValidForm from 'hooks/useValidForm';
import FormInput from 'components/formComponents/form-input';
import FormNumber from 'components/formComponents/form-number';
import FormSelect from 'components/formComponents/form-select';
import EditableFormInput from 'components/formComponents/editable-form-input';
import StyledModal from 'components/StyledModal';
import { Divider } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import { updateEmailAction, updateUserInfoAction } from 'store/user/userSlice';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalHeader: {
            fontWeight: 600,
            fontSize: "1.3em",
            marginTop: "5px"
        },
        divider: {
            backgroundColor: "lightgray",
            width: "100%"
        },
        modalFooter: {
            fontSize: '0.9em',
            textAlign: "center"
        },
        saveButton: {
            minWidth: "150px"
        },
        buttonContainer: {
            textAlign: "center"
        }
    })
);


interface UpdateNameModalFormData extends UpdateNameFormData {
    onClose: () => void
}

const validationSchema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
});

const ModalContents = ({ firstName, lastName, onClose }: UpdateNameModalFormData) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const onSubmitFunc = async (formValues: UpdateNameFormData) => {
        await dispatch(updateUserInfoAction({ firstName: formValues.firstName, lastName: formValues.lastName }));
        onClose();
    }

    const { register, control, errors, onSubmit } = useValidForm<UpdateNameFormData>(onSubmitFunc, validationSchema, { firstName, lastName });

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography component="span" variant="h2" className={classes.modalHeader}>Changes to your name will be reflected across your account.</Typography>
                </Grid>
                <Grid item xs={12}><Divider className={classes.divider} /></Grid>

                <Grid item xs={12} sm={12}>
                    <FormInput
                        name="firstName"
                        label="First name"
                        placeholder="First Name"
                        inputRef={register}
                        error={errors.firstName ? true : false}
                        helperText={errors.firstName?.message}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormInput
                        name="lastName"
                        label="Last name"
                        placeholder="Last Name"
                        inputRef={register}
                        error={errors.lastName ? true : false}
                        helperText={errors.lastName?.message}
                    />
                </Grid>
                <Grid item xs={12}><Divider className={classes.divider} /></Grid>

                <Grid item xs={12}>
                    <Typography className={classes.modalFooter}>Team members can see this when they communicate with you or view content you create in BiasProof.  For more information, contact our support staff.</Typography>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                    <CustomButton
                        label="Save"
                        type="submit"
                        className={classes.saveButton}
                    />
                    <br />
                    <CustomButton
                        secondary
                        label="Close"
                        onClick={onClose}
                    />

                </Grid>
            </Grid>
        </form>
    )
}


interface UpdateNameFormData {
    firstName: string;
    lastName: string;
}
const EditName = ({ firstName, lastName }: UpdateNameFormData) => {
    const classes = useStyles();

    const [showModal, setShowModal] = useState(false);

    function closeModal() {
        setShowModal(false);
    }

    return (
        <>
            <EditableFormInput
                name="name"
                label="Name"
                placeholder="Name"
                readonly={true}
                value={`${firstName} ${lastName}`}
                onEdit={() => setShowModal(true)}
            />

            <StyledModal
                type="confirm"
                pretitle="My Account"
                title="Change Name"
                isOpen={showModal}
                handleModalClose={() => setShowModal(false)}
            >
                <ModalContents
                    firstName={firstName}
                    lastName={lastName}
                    onClose={closeModal}
                />
            </StyledModal>
        </>
    )
}

export default EditName;
import React, { useState } from 'react'
import * as yup from 'yup';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from "countries-list";
import { getUser } from 'store/user/userSelector';
import useValidForm from 'hooks/useValidForm';
import FormInput from 'components/formComponents/form-input';
import FormNumber from 'components/formComponents/form-number';
import FormSelect from 'components/formComponents/form-select';
import EditableFormInput from 'components/formComponents/editable-form-input';
import StyledModal from 'components/StyledModal';
import { Divider } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import { updateEmailAction, updateUserInfoAction } from 'store/user/userSlice';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalHeader: {
            fontWeight: 600,
            fontSize: "1.3em",
            marginTop: "5px"
        },
        divider: {
            backgroundColor: "lightgray",
            width: "100%"
        },
        modalFooter: {
            fontSize: '0.9em',
            textAlign: "center"
        },
        saveButton: {
            minWidth: "150px"
        },
        buttonContainer: {
            textAlign: "center"
        }
    })
);


interface UpdateJobTitleModalFormData extends UpdateJobTitleFormData {
    onClose: () => void
}

const validationSchema = yup.object().shape({
    jobTitle: yup.string().required('Job title is required'),
});

const ModalContents = ({ jobTitle, onClose }: UpdateJobTitleModalFormData) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const onSubmitFunc = async (formValues: UpdateJobTitleFormData) => {
        await dispatch(updateUserInfoAction({ jobTitle: formValues.jobTitle }));
        onClose();
    }

    const { register, control, errors, onSubmit } = useValidForm<UpdateJobTitleFormData>(onSubmitFunc, validationSchema, { jobTitle });

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={2}>


                <Grid item xs={12} sm={12}>
                    <FormInput
                        name="jobTitle"
                        label="Job Title"
                        placeholder="Job Title
                        "
                        inputRef={register}
                        error={errors.jobTitle ? true : false}
                        helperText={errors.jobTitle?.message}
                    />
                </Grid>


                <Grid item xs={12} className={classes.buttonContainer}>
                    <CustomButton
                        label="Save"
                        type="submit"
                        className={classes.saveButton}
                    />
                    <br />
                    <CustomButton
                        secondary
                        label="Close"
                        onClick={onClose}
                    />

                </Grid>
            </Grid>
        </form>
    )
}


interface UpdateJobTitleFormData {
    jobTitle: string;

}
const EditCompanyName = ({ jobTitle }: UpdateJobTitleFormData) => {
    const classes = useStyles();

    const [showModal, setShowModal] = useState(false);

    function closeModal() {
        setShowModal(false);
    }

    return (
        <>
            <EditableFormInput
                name="jobTitle"
                label="Job Title"
                placeholder="Job Title"
                readonly={true}
                value={jobTitle}
                onEdit={() => setShowModal(true)}
            />

            <StyledModal
                type="confirm"
                pretitle="My Account"
                title="Change Job Title"
                isOpen={showModal}
                handleModalClose={() => setShowModal(false)}
            >
                <ModalContents
                    jobTitle={jobTitle}
                    onClose={closeModal}
                />
            </StyledModal>
        </>
    )
}

export default EditCompanyName;